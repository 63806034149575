import { FuseUtils } from '@fuse/utils';
import { ModuleUnitSessionModel } from 'app/models/module-unit-session-model';
import { StudentAttendanceModel } from 'app/models/student.attendance.model';

export class VTFieldIndustryVisitConductedModel {
    VTId: string;
    VTFieldIndustryVisitConductedId: string;
    ClassTaughtId: string;
    ReportingDate: any;
    SectionIds: string;
    FieldVisitTheme: string;
    FieldVisitActivities: string;
    ModuleId: string;
    UnitId: string;
    SessionIds: string;
    FVOrganisation: string;
    FVOrganisationAddress: string;
    FVDistance: number;
    FVPictureFile: any;
    FVContactPersonName: string;
    FVContactPersonMobile: string;
    FVContactPersonEmail: string;
    FVContactPersonDesignation: string;
    FVOrganisationInterestStatus: string;
    FVOrignisationOJTStatus: string;
    FeedbackFromOrgnisation: string;
    Remarks: string;
    FVStudentSafety: string;
    
    UnitSessionsModels: ModuleUnitSessionModel[];
    StudentAttendances: StudentAttendanceModel[];

    GeoLocation: string;
    Latitude: string;
    Longitude: string;
    IsActive: boolean;    
    RequestType: any;

    IsVenueVisited: boolean;
    IsPlaceIntroduced: boolean;
    IsStudentDivided: boolean;
    DetailsOfPlanning: string;
    IsReception: boolean;
    IsFireSafety: boolean;
    IsAccidentFireSafety: boolean;
    IsBasicSafety: boolean;
    IsStudentSecurity: boolean;
    IsTransport: boolean;
    ModeOfTransport : string;
    TransportCost: Number;
    NoTransportReason: string;
    TransportConfirmed: string;
    IsHMConsentSigned: boolean
    IsTeacherAssignedbyHM: boolean;
    TeacherName: string;
    TeacherContact: any;
    IsParentAwareOfVisit: boolean; 
    IsParentVolunteer: boolean; 
    ParentName: string;
    ParentContact: number; 
    PermissionDetail: string; 
    IsBRBehaviour: boolean;
    IsBREmergencies: boolean;
    IsBRAttention: boolean;
    IsBRTransportation: boolean;
    IsKidViolationBR: boolean;
    BPDiscussion: string;
    IsFoodsArranged: boolean;
  IsStudentIdentity: boolean;
  IsFirstAidKit: boolean;
  MaterialsPreparation: string;
//   "PrincipleConsentForm": string,
  IsParentConsent: boolean;
  NoOfStudentConsent: number;
  IsConsentReceived: boolean;
  UploadPrincipleConsentForm: any;
  IsStatus: boolean;

    constructor(vtFieldIndustryVisitConductedItem?: any) {
        vtFieldIndustryVisitConductedItem = vtFieldIndustryVisitConductedItem || {};

        this.VTId = vtFieldIndustryVisitConductedItem.VTId || FuseUtils.NewGuid();
        this.VTFieldIndustryVisitConductedId = vtFieldIndustryVisitConductedItem.VTFieldIndustryVisitConductedId || FuseUtils.NewGuid();
        this.ClassTaughtId = vtFieldIndustryVisitConductedItem.ClassTaughtId || '';
        this.ReportingDate = vtFieldIndustryVisitConductedItem.ReportingDate || '';
        this.SectionIds = vtFieldIndustryVisitConductedItem.SectionIds || '';
        this.FieldVisitTheme = vtFieldIndustryVisitConductedItem.FieldVisitTheme || '';
        this.FieldVisitActivities = vtFieldIndustryVisitConductedItem.FieldVisitActivities || '';
        this.ModuleId = vtFieldIndustryVisitConductedItem.ModuleId || '';
        this.UnitId = vtFieldIndustryVisitConductedItem.UnitId || '';
        this.SessionIds = vtFieldIndustryVisitConductedItem.SessionIds || '';
        this.FVOrganisation = vtFieldIndustryVisitConductedItem.FVOrganisation || '';
        this.FVOrganisationAddress = vtFieldIndustryVisitConductedItem.FVOrganisationAddress || '';
        this.FVDistance = vtFieldIndustryVisitConductedItem.FVDistance || '';
        this.FVPictureFile = vtFieldIndustryVisitConductedItem.FVPictureFile || '';
        this.FVContactPersonName = vtFieldIndustryVisitConductedItem.FVContactPersonName || '';
        this.FVContactPersonMobile = vtFieldIndustryVisitConductedItem.FVContactPersonMobile || '';
        this.FVContactPersonEmail = vtFieldIndustryVisitConductedItem.FVContactPersonEmail || '';
        this.FVContactPersonDesignation = vtFieldIndustryVisitConductedItem.FVContactPersonDesignation || '';
        this.FVOrganisationInterestStatus = vtFieldIndustryVisitConductedItem.FVOrganisationInterestStatus || '';
        this.FVOrignisationOJTStatus = vtFieldIndustryVisitConductedItem.FVOrignisationOJTStatus || '';
        this.FeedbackFromOrgnisation = vtFieldIndustryVisitConductedItem.FeedbackFromOrgnisation || '';
        this.Remarks = vtFieldIndustryVisitConductedItem.Remarks || '';
        this.FVStudentSafety = vtFieldIndustryVisitConductedItem.FVStudentSafety || '';
        
        this.UnitSessionsModels = <ModuleUnitSessionModel[]>[];
        this.StudentAttendances = <StudentAttendanceModel[]>[];
                
        this.GeoLocation = '3-3';
        this.Latitude = '3';
        this.Longitude = '3';
        this.IsActive = vtFieldIndustryVisitConductedItem.IsActive || true;
        this.RequestType = 0; // New

        this.IsVenueVisited = vtFieldIndustryVisitConductedItem.IsVenueVisited || '' ;
        this.IsPlaceIntroduced = vtFieldIndustryVisitConductedItem.IsPlaceIntroduced || '';
        this.IsStudentDivided = vtFieldIndustryVisitConductedItem.IsStudentDivided || '';
        this.DetailsOfPlanning = vtFieldIndustryVisitConductedItem.DetailsOfPlanning || '';
        this.IsReception = vtFieldIndustryVisitConductedItem.IsReception || false
        this.IsFireSafety = vtFieldIndustryVisitConductedItem.IsFireSafety || '';
        this.IsAccidentFireSafety = vtFieldIndustryVisitConductedItem.IsAccidentFireSafety || '';
        this.IsBasicSafety = vtFieldIndustryVisitConductedItem.IsBasicSafety || '';
        this.IsStudentSecurity = vtFieldIndustryVisitConductedItem.IsStudentSecurity || '';
        this.IsTransport = vtFieldIndustryVisitConductedItem.IsTransport || '';
        this.ModeOfTransport = vtFieldIndustryVisitConductedItem.ModeOfTransport || '';
        this.TransportCost = vtFieldIndustryVisitConductedItem.TransportCost || '';
        this.NoTransportReason = vtFieldIndustryVisitConductedItem.NoTransportReason || '' ;
        this.TransportConfirmed = vtFieldIndustryVisitConductedItem.TransportConfirmed || '';
        this.IsHMConsentSigned = vtFieldIndustryVisitConductedItem.IsHMConsentSigned || '';
        this.IsTeacherAssignedbyHM = vtFieldIndustryVisitConductedItem.IsTeacherAssignedbyHM || '';
        this.TeacherName = vtFieldIndustryVisitConductedItem.TeacherName || '';
        this.TeacherContact = vtFieldIndustryVisitConductedItem.TeacherContact || '';
        this.IsParentAwareOfVisit = vtFieldIndustryVisitConductedItem.IsParentAwareOfVisit || '';
        this.IsParentVolunteer = vtFieldIndustryVisitConductedItem.IsParentVolunteer || '';
        this.ParentName = vtFieldIndustryVisitConductedItem.ParentName || '';
        this.ParentContact = vtFieldIndustryVisitConductedItem.ParentContact || '';
        this.PermissionDetail = vtFieldIndustryVisitConductedItem.PermissionDetail || '';
        this.IsBRBehaviour = vtFieldIndustryVisitConductedItem.IsBRBehaviour || '';
        this.IsBREmergencies = vtFieldIndustryVisitConductedItem.IsBREmergencies || '' ;
        this.IsBRAttention = vtFieldIndustryVisitConductedItem.IsBRAttention || '';
        this.IsBRTransportation = vtFieldIndustryVisitConductedItem.IsBRTransportation || '';
        this.IsKidViolationBR = vtFieldIndustryVisitConductedItem.IsKidViolationBR || '';
        this.BPDiscussion = vtFieldIndustryVisitConductedItem.BPDiscussion || '';
        this.IsFoodsArranged = vtFieldIndustryVisitConductedItem.IsFoodsArranged || '';
        this.IsStudentIdentity = vtFieldIndustryVisitConductedItem.IsStudentIdentity || '';
        this.IsFirstAidKit = vtFieldIndustryVisitConductedItem.IsFirstAidKit || '';
        this.MaterialsPreparation = vtFieldIndustryVisitConductedItem.MaterialsPreparation || '';
        this.IsParentConsent = vtFieldIndustryVisitConductedItem.IsParentConsent || '';
        this.NoOfStudentConsent = vtFieldIndustryVisitConductedItem.NoOfStudentConsent || 0;
        this.IsConsentReceived = vtFieldIndustryVisitConductedItem.IsConsentReceived || '';
        this.UploadPrincipleConsentForm = vtFieldIndustryVisitConductedItem.UploadPrincipleConsentForm || '';
        this.IsStatus = vtFieldIndustryVisitConductedItem.IsStatus || false;

    }
}
