import {
    Component,
    OnInit,
    ViewChild,
    NgZone,
    ViewEncapsulation,
} from "@angular/core";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from "@angular/forms";
import { BaseListComponent } from "app/common/base-list/base.list.component";
import { Report001FormModel } from "./report001-form.model";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CommonService } from "app/services/common.service";
import { fuseAnimations } from "@fuse/animations";

@Component({
    selector: "app-report001-form",
    templateUrl: "./report001-form.component.html",
    animations: fuseAnimations,
    styleUrls: ["./report001-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class Report001FormComponent
    extends BaseListComponent<Report001FormModel>
    implements OnInit
{
    constructor(
        private formBuilder: FormBuilder,
        public router: Router,
        public routeParams: ActivatedRoute,
        public snackBar: MatSnackBar,
        public zone: NgZone,
        private route: ActivatedRoute,
        public commonService: CommonService
    ) {
        super(commonService, router, routeParams, snackBar, zone);
    }

    ngOnInit(): void {}
}
