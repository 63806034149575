<div id="vt-next-month-plan" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Events Detail
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a month plan">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
            <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                class="add-vt-daily-reporting-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            
            <!-- <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exporter.exportTable('xlsx', {fileName:'VTDailyReporting', sheet: 'VTDailyReporting', Props: {Author: 'Lighthouse'}})">
                <mat-icon> cloud_download</mat-icon>
            </a> -->
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="vt-next-month-table" #table [dataSource]="tableDataSource" #exporter="matTableExporter" [hiddenColumns]="[5]"
            matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

            <!-- EventName Column -->
            <ng-container matColumnDef="EventDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                    Events
                </mat-header-cell>
                <mat-cell *matCellDef="let event">
                    <p class="text-truncate">{{event.EventDate | date: 'dd/MM/yyyy'}}</p>
                </mat-cell>
            </ng-container>

                <!-- EventName Column -->
                <ng-container matColumnDef="EventName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        Events
                    </mat-header-cell>
                    <mat-cell *matCellDef="let event">
                        <p class="text-truncate">{{event.EventDetails}}</p>
                    </mat-cell>
                </ng-container>

                <!-- CaseStudy Column -->
                <ng-container matColumnDef="CaseStudy">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        Case Study
                    </mat-header-cell>
                    <mat-cell *matCellDef="let event">
                        <p class="text-truncate">{{event.CaseStudy}}</p>
                    </mat-cell>
                </ng-container>

                

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let event">
                        <button *ngIf="PageRights.IsView" mat-icon-button
                                [routerLink]="['/events', 'view', event.EventDetailId]"
                                skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit" mat-icon-button
                                [routerLink]="['/events', 'edit', event.EventDetailId]"
                                skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <!-- <button mat-icon-button color="warn"
                                (click)="onDeleteVTDailyReporting(vtDailyReporting.VTDailyReportingId)"
                                *ngIf="PageRights.IsDelete">
                            <mat-icon>delete_outline</mat-icon>
                        </button> -->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let event; columns: displayedColumns;" class="vt-daily-reporting"
                         matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>