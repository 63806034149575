export class Report001FormModel {
    VPlaceName: string;
    FName: string;
    FContact: number;
    FDesignation: string;
    DateOfVisit: Date;
    AcademicYearId: string;
    SectionId: string;
    ClassId: string;
    ModuleId: string;
    UnitId: string;
    NoOfStudents: string;
    AcademinYear: Date;
    IsVisitedTheVennu: boolean;
    IsIntroducedPlace: boolean;
    IsDividedTheStudents: boolean;
    DetailsOfPlanning: string;
    IsAnySecurityGurad: boolean;
    IsFireSafty: boolean;
    IsAccidentFireSafety: boolean;
    IsBasicSafety: boolean;
    StudentSecurity: string;
    IsTransport: boolean;
    TransportMode: string;
    TransportCost: Number;
    TransportlReason: string;
    TransportDetails: string;
    IsHMAware: boolean
    IsConcernedTeacher: boolean;
    TName: string;
    TContact: string;
    IsParentAware: boolean;
    IsVoluntererParent: boolean;
    ParentName: string;
    PContact: string;
    DetailsOfpermission: string;
    IsBehaviourRules: boolean;
    IsBRForEmergency: boolean;
    IsBRAttention: boolean;
    IsBrWhereToCome: boolean;
    IsKidViolationBR: boolean;
    BRDetails: string;

    constructor(report001Item?: any) {
        report001Item = report001Item || {};

        this.VPlaceName = report001Item.VPlaceName || '';
        this.AcademicYearId = report001Item.AcademicYearId || '';
        this.ClassId = report001Item.ClassId || '';
        this.SectionId = report001Item.SectionId || '';
        this.ModuleId = report001Item.ModuleId || '';
        this.FName = report001Item.FName || '';
        this.FContact = report001Item.FContact || '';
        this.FDesignation = report001Item.FDesignation || '';
        this.UnitId = report001Item.UnitId || '';
        this.NoOfStudents = report001Item.NoOfStudents || '';
        this.DateOfVisit = report001Item.DateOfDropout || '';
        this.IsVisitedTheVennu = report001Item.IsVisitedTheVennu || '' ;
        this.IsIntroducedPlace = report001Item.IsIntroducedPlace || '';
        this.IsDividedTheStudents = report001Item.IsDividedTheStudents || '';
        this.DetailsOfPlanning = report001Item.DetailsOfPlanning || '';
        this.IsAnySecurityGurad = report001Item.IsAnySecurityGurad || false
        this.IsFireSafty = report001Item.IsFireSafty || '';
        this.IsAccidentFireSafety = report001Item.IsAccidentFireSafety || '';
        this.IsBasicSafety = report001Item.IsBasicSafety || '';
        this.StudentSecurity = report001Item.StudentSecurity || '';
        this.IsTransport = report001Item.IsTransport || '';
        this.TransportMode = report001Item.TransportMode || '';
        this.TransportCost = report001Item.TransportCost || '';
        this.TransportDetails = report001Item.TransportDetails || '' ;
        this.TransportlReason = report001Item.TransportlReason || '';
        this.IsHMAware = report001Item.IsHMAware || '';
        this.IsConcernedTeacher = report001Item.IsConcernedTeacher || '';
        this.TName = report001Item.TName || '';
        this.TContact = report001Item.TContact || '';
        this.IsParentAware = report001Item.IsParentAware || '';
        this.IsVoluntererParent = report001Item.IsVoluntererParent || '';
        this.ParentName = report001Item.ParentName || '';
        this.PContact = report001Item.PContact || '';
        this.DetailsOfpermission = report001Item.DetailsOfpermission || '';
        this.IsBehaviourRules = report001Item.IsBehaviourRules || '';
        this.IsBRForEmergency = report001Item.IsBRForEmergency || '' ;
        this.IsBRAttention = report001Item.IsBRAttention || '';
        this.IsBrWhereToCome = report001Item.IsBrWhereToCome || '';
        this.IsKidViolationBR = report001Item.IsKidViolationBR || '';
        this.BRDetails = report001Item.BRDetails || '';
        
      
    }
}
