<div id="account" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    USERS
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)" placeholder="Search for a accounts">
                </div>
            </div>
            <!-- / SEARCH -->
            
            <!-- ADD BUTTON -->
            <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false" class="add-account-button fuse-white mt-24 mt-md-0">
                <span>ADD</span>
            </button>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="account-table responsive-table" #table [dataSource]="tableDataSource" matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- LoginId Column -->
                <ng-container matColumnDef="LoginId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>LoginId</mat-header-cell>
                    <mat-cell *matCellDef="let account" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{account.LoginId}}</p>
                    </mat-cell>
                </ng-container>                                

                <!-- UserName Column -->
                <ng-container matColumnDef="UserName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>User Name</mat-header-cell>
                    <mat-cell *matCellDef="let account">
                        <p class="text-truncate">{{account.UserName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmailId Column -->
                <ng-container matColumnDef="EmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>EmailId</mat-header-cell>
                    <mat-cell *matCellDef="let account" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{account.EmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Mobile Column -->
                <ng-container matColumnDef="Mobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Mobile</mat-header-cell>
                    <mat-cell *matCellDef="let account" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{account.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                 <!-- AccountType Column -->
                 <ng-container matColumnDef="AccountType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Account Type</mat-header-cell>
                    <mat-cell *matCellDef="let account">
                        <p class="text-truncate">{{account.AccountType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsLocked Column -->
                <ng-container matColumnDef="IsLocked">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Locked?</mat-header-cell>
                    <mat-cell *matCellDef="let account" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="account.IsLocked" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!account.IsLocked" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let account" fxHide fxShow.gt-xs>
                        <mat-icon *ngIf="account.IsActive" class="active-icon green-600 s-16">check</mat-icon>
                        <mat-icon *ngIf="!account.IsActive" class="active-icon red-500 s-16">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let account">
                        <button *ngIf="PageRights.IsView" mat-icon-button
                        [routerLink]="['/users', 'view', account.AccountId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsEdit && 'Roles : VC, VT, HM'.indexOf(account.RoleCode) == -1" mat-icon-button
                        [routerLink]="['/users', 'edit', account.AccountId]" skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button *ngIf="PageRights.IsDelete && 'Roles : VC, VT, HM'.indexOf(account.RoleCode) == -1" mat-icon-button (click)="onDeleteAccount(account.AccountId)">
                            <mat-icon color="warn">delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let account; columns: displayedColumns;" class="account" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
