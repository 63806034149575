import {
    Component,
    OnInit,
    NgZone,
    ViewEncapsulation,
    Inject,
} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { Report001Model } from "./report001.model";
import { CommonService } from "app/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from "app/common/base-list/base.list.component";
import { fuseAnimations } from "@fuse/animations";
import { ReportService } from "app/reports/report.service";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { VocationalTrainerService } from "../../main/vocational-trainers/vocational-trainer.service";
// tslint:disable-next-line:no-duplicate-imports

import { VocationalCoordinatorService } from "../../main/vocational-coordinators/vocational-coordinator.service";
import { default as _rollupMoment, Moment } from "moment";
import { BaseService } from "../../services/base.service";
// import { async } from '@angular/core/testing'

const moment = _rollupMoment || _moment;
import { MatDatepicker } from "@angular/material/datepicker";
import { Observable } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { ErrorDialogService } from "app/common/error-dialog/error-dialog.service";

export const MY_FORMATS = {
    parse: {
        dateInput: "MM/YYYY",
    },
    display: {
        dateInput: "MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

@Component({
    selector: "app-report001",
    templateUrl: "./report001.component.html",
    styleUrls: ["./report001.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class Report001Component
    extends BaseListComponent<Report001Model>
    implements OnInit
{
    report001Form: FormGroup;
    report001Model: Report001Model;
    vtList: any;
    filteredVTItems: any;
    academicYear: any;
    academicYearList: any;
    vcList: any;
    filteredVCItems: any;
    vcId: any;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public commonService: CommonService,
        public router: Router,
        public routeParams: ActivatedRoute,
        public snackBar: MatSnackBar,
        public zone: NgZone,
        public formBuilder: FormBuilder,
        private reportService: ReportService,
        private vocationalTrainerService: VocationalTrainerService,
        private vocationalCoordinatorService: VocationalCoordinatorService,
        private errorDialog: ErrorDialogService,
        private baseService: BaseService
    ) {
        super(commonService, router, routeParams, snackBar, zone);

        this.report001Model = new Report001Model();
    }

    ngOnInit(): void {
        this.baseService
            .GetMasterDataByType({
                DataType: "AcademicYears",
                SelectTitle: "Academic Year",
            })
            .subscribe((response) => {
                this.academicYearList = response.Results;

                let year = this.academicYearList.find(
                    (ay) => ay.IsSelected == true
                );
                this.academicYear = year.Name;

                if (this.UserModel.RoleCode === "VC") {
                    this.getVocationalTrainer();
                } else if (this.UserModel.RoleCode === "ADM") {
                    this.getVocationalCoordinator();
                }
            });

        this.report001Form = this.createReport001Form();
    }

    //Create VCMonthlyAttendance form and returns {FormGroup}
    createReport001Form(): FormGroup {
        return this.formBuilder.group({
            VTId: new FormControl(
                {
                    value:
                        this.UserModel.RoleCode === "ADM"
                            ? ""
                            : this.UserModel.RoleCode === "VC"
                            ? ""
                            : this.UserModel.UserTypeId,
                    disabled: false,
                },
                Validators.required
            ),
            ReportDate: new FormControl(moment(), Validators.required),
            VCId: new FormControl(
                {
                    value:
                        this.UserModel.RoleCode === "ADM"
                            ? ""
                            : this.UserModel.UserTypeId,
                    disabled: false,
                },
                Validators.required
            ),
        });
    }

    chosenYearHandler(normalizedYear: Moment) {
        const ctrlValue = this.report001Form.get("ReportDate").value;
        ctrlValue.year(normalizedYear.year());
        this.academicYear = ctrlValue;
        this.report001Form.get("ReportDate").setValue(ctrlValue);
    }

    chosenMonthHandler(
        normalizedMonth: Moment,
        datepicker: MatDatepicker<Moment>
    ) {
        const ctrlValue = this.report001Form.get("ReportDate").value;
        ctrlValue.month(normalizedMonth.month());
        ctrlValue.year(normalizedMonth.year());

        this.report001Form.get("ReportDate").setValue(ctrlValue);
        this.academicYear = ctrlValue.year();
        datepicker.close();

        if (this.UserModel.RoleCode === "ADM") {
            this.getVocationalCoordinator();
        }

        if (this.UserModel.RoleCode === "VC") {
            this.getVocationalTrainer();
        }
    }

    getVocationalCoordinator(): void {
        let currentYearItem = this.academicYearList.find((ay) =>
            ay.Name.includes(this.academicYear)
        );
        let vcParams = {
            AcademicYearId: currentYearItem.Id,
            VTPId: null,
            NatureOfAppointmentId: null,
            Status: null,
            Name: null,
            CharBy: null,
            PageIndex: 0,
        };

        this.vocationalCoordinatorService
            .GetAllByCriteria(vcParams)
            .subscribe((response) => {
                if (response.Results.length === 0) {
                    this.errorDialog.popupErrorMessage("There is no VC data.");
                } else {
                    console.log("response VC", response);
                    this.vcList = response.Results;
                    this.filteredVCItems = this.vcList.slice();
                }
            });
    }

    getVocationalTrainer(): void {
        this.vcId = this.report001Form.get("VCId").value;

        let currentYearItem = this.academicYearList.find((ay) =>
            ay.Name.includes(this.academicYear)
        );

        this.vocationalTrainerService
            .GetAllByCriteria({
                AcademicYearId: currentYearItem.Id,
                VTPId: null,
                VCId:
                    this.UserModel.RoleCode === "VC"
                        ? this.UserModel.UserTypeId
                        : this.UserModel.RoleCode === "VT"
                        ? ""
                        : this.vcId,
                SocialCategoryId: null,
                Status: null,
                Name: null,
                CharBy: null,
                PageIndex: 0,
            })
            .subscribe(
                (response) => {
                    if (response.Results.length === 0) {
                        this.errorDialog.popupErrorMessage(
                            "There is no VT data"
                        );
                    } else {
                        response.Results.forEach(function (obj) {
                            if (obj.hasOwnProperty("IsActive")) {
                                obj.IsActive = obj.IsActive ? "Yes" : "No";
                            }
                            if (obj.hasOwnProperty("IsResigned")) {
                                obj.IsResigned = obj.IsResigned ? "Yes" : "No";
                            }
                            delete obj.TotalRows;
                        });
                        this.vtList = response.Results;
                        this.filteredVTItems = this.vtList.slice();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    getMonthlyReport001(): void {
        this.IsLoading = true;
        if (!this.report001Form.valid) {
            this.validateAllFormFields(this.report001Form);
            return;
        }

        let vtId = this.report001Form.get("VTId").value;

        let reportDate = this.DateFormatPipe.transform(
            this.report001Form.get("ReportDate").value,
            this.Constants.ServerDateFormat
        );

        var reportParams = {
            UserId: this.UserModel.LoginId,
            VTId: vtId,
            ReportDate: reportDate,
        };

        this.reportService
            .getMonthlyReport001(reportParams)
            .subscribe(async (response) => {
                if (response.Result === "No Records for this Month") {
                    this.errorDialog.popupErrorMessage(response.Result);
                } else if (response.Result != "") {
                    let pdfReportUrl =
                        (await this.Constants.Services.BaseUrl) +
                        "Lighthouse/DownloadVTform001ReportFile?fileId=" +
                        response.Result +
                        "&folderName=VTForm001AttendancePDF";
                    window.open(pdfReportUrl, "_blank", "");
                }
            });
        this.IsLoading = false;
    }
}
