<div id="vt-next-month-plan" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/next-month-plan'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">

                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New Next month Plan
                    </div>
                    <div class="subtitle secondary-text">
                        <span> New Next month Plan</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-hm-issue-reporting-button" *ngIf="PageRights.ActionType ==='new'"  
                (click)="saveOrUpdateNextMonthPlanDetails()" [disabled]="nextMonthPlanForm.invalid ">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-hm-issue-reporting-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateNextMonthPlanDetails()" [disabled]="nextMonthPlanForm.invalid">
                <!-- || monthValidate(nextMonthPlan.PlanMonth)" > -->
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="nextMonthPlanForm" [formGroup]="nextMonthPlanForm"
                    class="hm-issue-reporting w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="this.UserModel.RoleCode !== 'VC'">
                        <mat-form-field appearance="outline" fxFlex="45" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Plan Month</mat-label>

                            <input matInput name="PlanMonth" formControlName="PlanMonth" required
                                [matDatepicker]="reportDate" [min]="NextMonth">
                            <mat-datepicker-toggle matSuffix [for]="reportDate"></mat-datepicker-toggle>
                            <mat-datepicker #reportDate startView="year" (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, reportDate)"></mat-datepicker>

                            <mat-error *ngIf="nextMonthPlanForm.controls.PlanMonth.invalid">
                                    {{getErrorMessage(nextMonthPlanForm, 'PlanMonth')}}
                                </mat-error>
                        </mat-form-field>
                    </div>
                    
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="this.UserModel.RoleCode === 'VC'">


                        <mat-form-field appearance="outline" fxFlex="45" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Plan Month</mat-label>

                            <input matInput name="PlanMonth" formControlName="PlanMonth" required
                                [matDatepicker]="reportDate" [min]="NextMonth">
                            <mat-datepicker-toggle matSuffix [for]="reportDate"></mat-datepicker-toggle>
                            <mat-datepicker #reportDate startView="year" (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, reportDate)"></mat-datepicker>

                                <mat-error *ngIf="nextMonthPlanForm.controls.PlanMonth.invalid">
                                    {{getErrorMessage(nextMonthPlanForm, 'PlanMonth')}}
                                </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Trainers</mat-label>

                            <mat-select name="userId" formControlName="UserId" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeVT($event.value)">
                                <mat-select-filter class="select-filter" [array]="vtList"
                                    (filteredReturn)="this.filteredVTItems = $event" [displayMember]="'VTName'">
                                </mat-select-filter>
                                <mat-option [value]="vtItem.VTId" *ngFor="let vtItem of filteredVTItems">
                                    {{vtItem.VTName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nextMonthPlanForm.controls.UserId.invalid">
                                {{getErrorMessage(nextMonthPlanForm, 'UserId')}}
                            </mat-error>
                        </mat-form-field>


                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">


                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Class</mat-label>
                            <mat-select name="classTaughtId" formControlName="ClassTaughtId" appearance="outline"
                                required [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeClass($event.value)">
                                <mat-option [value]='classitem' *ngFor="let classitem of classList">
                                    {{classitem.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nextMonthPlanForm.controls.ClassTaughtId.invalid">
                                {{getErrorMessage(nextMonthPlanForm, 'ClassTaughtId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Section</mat-label>
                            <mat-select name="SectionId" formControlName="SectionId" appearance="outline" required [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]='section' *ngFor="let section of sectionList">
                                    {{section.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nextMonthPlanForm.controls.SectionId.invalid">
                                {{getErrorMessage(nextMonthPlanForm, 'SectionId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Activity Type</mat-label>
                            <mat-select name="activityTypeId" formControlName="ActivityTypeId" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required>
                               
                                <mat-option [value]='activity' *ngFor="let activity of activityTypeList">
                                    {{activity.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nextMonthPlanForm.controls.ActivityTypeId.invalid">
                                {{getErrorMessage(nextMonthPlanForm, 'ActivityTypeId')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Modules</mat-label>
                            <mat-select name="moduleId" formControlName="ModuleId" appearance="outline" 
                                (selectionChange)="onChangeCourseModule($event.value)"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]='module' *ngFor="let module of moduleList">
                                    {{module.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nextMonthPlanForm.controls.ModuleId.invalid">
                                {{getErrorMessage(nextMonthPlanForm, 'ModuleId')}}
                            </mat-error>
                        </mat-form-field>


                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="40" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Units</mat-label>
                            <mat-select name="unitId" formControlName="UnitId" appearance="outline" 
                                (selectionChange)="onChangeUnitsTaught($event.value)"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]='unitItem' *ngFor="let unitItem of unitList">
                                    {{ unitItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nextMonthPlanForm.controls.UnitId.invalid">
                                {{getErrorMessage(nextMonthPlanForm, 'UnitId')}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" fxFlex="40" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Topic</mat-label>
                            <mat-select name="TopicIdValues" formControlName="TopicIdValues" appearance="outline" multiple
                                 [disabled]="PageRights.IsReadOnly" (selectionChange)="OnSelectTopics($event.value)">
                                <mat-option [value]='topic' *ngFor="let topic of topicList">
                                    <!-- (onSelectionChange)="OnSelectTopics($event.value)"> -->
                                    {{ topic.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nextMonthPlanForm.controls.TopicIdValues.invalid">
                                {{getErrorMessage(nextMonthPlanForm, 'TopicIdValues')}}
                            </mat-error>
                        </mat-form-field>
                        <div class="add-course-taught">
                            <span class="material-icons add-session"
                                (click)="addUnitSession()">add_circle_outline</span>
                            <span class="add-course-text">Kindly press "+" to add information</span>
                        </div>

                    </div>

                    
                    <div>
                        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="space-between"
                            animationDuration="2000ms">
                            <mat-tab label="Planned sessions">

                                <mat-table matTableExporter class="vt-next-month-table" #table
                                    [dataSource]="plannedSectionModules" #exporter="matTableExporter" [hiddenColumns]="[5]"
                                    matSort fusePerfectScrollbar
                                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                    <ng-container matColumnDef="Class">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Class
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.ClassTaught }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>  
                                    <ng-container matColumnDef="Section">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Section
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.Section }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>  
                                    <ng-container matColumnDef="ActivityType">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            ActivityType
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.ActivityType }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Module">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Modules
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.ModuleName }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Units">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Units
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.UnitName }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Sessions">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Sessions
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.SessionNames }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Status" stickyEnd>
                                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan">
                                            <mat-slide-toggle 
                                            [disabled]="PageRights.IsReadOnly" [checked]='nextMonthPlan.IsStatus' (change)="toggle(nextMonthPlan,$event)">
                                        </mat-slide-toggle>                   
                    
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                                    <mat-row *matRowDef="let nextMonthPlan; columns: displayedColumns;"
                                        class="vt-next-month-plan" matRipple>
                                    </mat-row>
                                </mat-table>
                            </mat-tab>
                            <mat-tab label="Completed Sessions"> 
                                <mat-table matTableExporter class="vt-next-month-table" #table
                                    [dataSource]="completedUnitSessionModels" #exporter="matTableExporter" [hiddenColumns]="[5]"
                                    matSort fusePerfectScrollbar
                                    [fusePerfectScrollbarOptions]="{'suppressScrollX': false}" >
                                    <ng-container matColumnDef="Class">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Class
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.ClassTaught }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>  
                                    <ng-container matColumnDef="Section">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Section
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.Section }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>  
                                    <ng-container matColumnDef="ActivityType">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            ActivityType
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.ActivityType }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Module">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Modules
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.ModuleName }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Units">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Units
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.UnitName }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Sessions">
                                        <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide
                                            fxShow.gt-xs>
                                            Sessions
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide
                                            fxShow.gt-xs>
                                            <p class="text-truncate">
                                                {{nextMonthPlan.SessionNames }}
                                            </p>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="Status" stickyEnd>
                                        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                                        <mat-cell *matCellDef="let nextMonthPlan">
                                            <mat-slide-toggle 
                                            [disabled]="PageRights.IsReadOnly" [checked]='true' (change)="toggle(nextMonthPlan,$event)">
                                        </mat-slide-toggle>
                                            
                    
                    
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                                    <mat-row *matRowDef="let nextMonthPlan; columns: displayedColumns;"
                                        class="vt-next-month-plan" matRipple>
                                    </mat-row>
                                </mat-table>
                                 </mat-tab>

                        </mat-tab-group>
                    </div>
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>