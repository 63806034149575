export class VCCommnityWorkModel {
    UserId: string;
    ClassTaughtId: string;
    WorkDetails: string;
    NoOfDays: string;
    NoOfStudents: number;
    ClientName: string;
    ClientAddress: string; 
    TotalExpense: number;
    IsRawMaterial: any;
    TotalProfit: number;
    StudentsExperience: number;
    MaterialBillFile: any;
    BillsPhotoFile: string;
    Description: string;
       
    constructor(vcDailyReportingItem?: any) {
        vcDailyReportingItem = vcDailyReportingItem || {};

        this.UserId = vcDailyReportingItem.UserId || '';
        this.ClassTaughtId = vcDailyReportingItem.ClassTaughtId || '';
        this.WorkDetails = vcDailyReportingItem.WorkDetails || '';
        this.NoOfDays = vcDailyReportingItem.NoOfDays || '';
        this.NoOfStudents = vcDailyReportingItem.NoOfStudents || '';
        this.ClientName = vcDailyReportingItem.ClientName || '';
        this.ClientAddress = vcDailyReportingItem.ClientAddress || '';
        this.TotalExpense = vcDailyReportingItem.TotalExpense || '';
        this.IsRawMaterial = vcDailyReportingItem.IsRawMaterial || '';
        this.TotalProfit = vcDailyReportingItem.TotalProfit || '';
        this.MaterialBillFile = vcDailyReportingItem.MaterialBillFile || '';
        this.StudentsExperience = vcDailyReportingItem.StudentsExperience || '';
        this.Description = vcDailyReportingItem.Description || '';
}
}
