<div id="privacy-policy" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <!-- CENTER -->
    <div class="center">
        <!-- CONTENT CARD -->
        <div class="content-card">
            <p>Privacy Policy</p>
            <p>Last updated: January 03, 2023</p>
            <p>
                This Privacy Policy describes Our policies and procedures on the collection, use and
                disclosure of Your information when You use the Service and tells You about Your privacy
                rights and how the law protects You.
            </p>
            <p>
                We use Your Personal data to provide and improve the Service. By using the Service, You
                agree to the collection and use of information in accordance with this Privacy Policy.
            </p>

            <h1><u>Interpretation and Definitions</u></h1>
            <p><b>Interpretation</b></p>
            <p>
                The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they
                appear in singular or in plural.
            </p>
            <p><b>Definitions </b></p>
            <p>
                For the purposes of this Privacy Policy:
            </p>
            <p>
                <b>Account</b> means a unique account created for You to access our Service or parts of our Service.
            </p>
            <p>
                <b>Affiliate</b> means an entity that controls, is controlled by or is under common control
                with a party, where "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of directors or other managing
                authority.

            </p>
            <p>
                <b>Application</b> means the software program provided by the Organization downloaded
                by You on any electronic device, named Lighthouse MH
            </p>
            <p>
                <b>Company</b> (referred to as either "the organization", "We", "Us" or "Our" in this
                Agreement) refers to Lend A Hand India, 9, Jeevan Vihar Society, Off Senapati Bapat
                Road, Pune 411016.
            </p>
            <p>
                <b>Cookies</b> are small files that are placed on Your computer, mobile device or any other
                device by a website, containing the details of Your browsing history on that website
                among its many uses.
            </p>
            <p>
                <b>Country</b> refers to: Maharashtra, India
            </p>
            <p>
                <b>Device</b> means any device that can access the Service such as a computer, a cellphone
                or a digital tablet.
            </p>
            <p>
                <b>Personal Data</b> is any information that relates to an identified or identifiable
                individual.
            </p>
            <p>
                <b>Service</b> refers to the Application or the Website or both.
            </p>
            <p>
                <b>Service Provider</b> means any natural or legal person who processes the data on
                behalf of the Organization. It refers to third-party companies or individuals employed by the
                Organization to facilitate the Service, to provide the Service on behalf of the
                Organization, to perform services related to the Service or to assist the Organization
                in analyzing how the Service is used.
            </p>
            <p>
                <b>Usage Data</b> refers to data collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example, the duration of a page
                visit).
            </p>
            <p>
                <b>Website</b> refers to Lighthouse, accessible from <a href="https://maharashtra.lighthouse.net.in"
                    target="_blank">https://maharashtra.lighthouse.net.in</a>
            </p>
            <p>
                <b>You</b> means the individual accessing or using the Service, or the Organization, or other
                legal entity on behalf of which such individual is accessing or using the Service, as
                applicable.
            </p>

            <h1><u>Collecting and Using Your Personal Data</u></h1>
            <h3>Types of Data Collected</h3>

            <p><b>Personal Data</b></p>
            <p>
                Our services are offered either as part of the organization you work for or you have directly
                consented to avail our services. All information we are collecting in application is as per the
                organization policy and requirement of employment.
            </p>

            <p><b>Usage Data</b></p>
            <p>
                Usage Data is collected automatically when using the Service by the Browser used by you.
            </p>
            <p>
                Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP
                address), browser type, browser version, the pages of our Service that You visit, the time and
                date of Your visit, the time spent on those pages, unique device identifiers and other
                diagnostic data.
            </p>
            <p>
                When You access the Service by or through a mobile device, some information automatically
                collected by the Browser app, including, but not limited to, the type of mobile device You use,
                Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating
                system, the type of mobile Internet browser You use, unique device identifiers and other
                diagnostic data.
            </p>

            <p><b>Information Collected while Using the Application</b></p>
            <p>
                While using Our Application, in order to provide features of our Application, We may collect,
                with Your prior permission:
            </p>
            <p>Information regarding your location </p>
            <p>
                We use this information to provide features of Our Service, to improve and customize Our
                Service. The information uploaded to the data of the Organization you are working for or
                services you have agreed to as part of service offering..
            </p>
            <p>
                You can enable or disable access to this information at any time, through Your Device settings
            </p>

            <h3>Use of Your Personal Data</h3>
            <p>The data collected is used by your Organization for the following purposes:</p>
            <p>
                <b>To provide and maintain Services,</b> including to monitor the usage of our Service.
            </p>
            <p>
                <b>To manage Your Account:</b> to manage Your registration as a user of the Service. You
                can access different functionalities of the Service that are available to You as a
                registered user.
            </p>
            <p>
                <b>To contact You:</b> To contact You by email, telephone calls, SMS, or other equivalent
                forms of electronic communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the functionalities,
                products or contracted services, including the security updates, when necessary or
                reasonable for their implementation.
            </p>
            <p>
                <b>To provide You</b> with general information about other services and new features of
                our website and application.
            </p>
            <p>
                <b>To manage Your requests:</b> To attend and manage Your requests..
            </p>
            <p>No information is shared outside the organization or its implementation partners.</p>

            <h3>Retention of Your Personal Data</h3>
            <p>
                The Organization will retain Your Personal Data only for as long as is necessary for the
                purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent
                necessary to comply with our legal obligations (for example, if we are required to retain your
                data to comply with applicable laws), resolve disputes, and enforce our legal agreements and
                policies.
            </p>

            <p>
                The Organization will also retain Usage Data for internal analysis purposes. Usage Data is
                generally retained for a shorter period of time, except when this data is used to strengthen
                the security or to improve the functionality of Our Service, or We are legally obligated to
                retain this data for longer time periods.
            </p>
            <h3>Transfer of Your Personal Data</h3>
            <p>
                Your information, including Personal Data, is processed at the servers agreed by the
                Organization's policies . The information may be transferred to the authorized agencies
                identified by the organization.
            </p>
            <p>
                Your consent to this Privacy Policy followed by Your submission of such information
                represents Your agreement to that transfer.
            </p>
            <p>
                The Organization will take all steps reasonably necessary to ensure that Your data is treated
                securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will
                take place to an organization unless there are adequate controls in place including the
                security of Your data and other personal information.
            </p>

            <h3>Disclosure of Your Personal Data</h3>
            <h4>Law enforcement</h4>
            <p>
                Under certain circumstances, the Organization may be required to disclose Your Personal
                Data if required to do so by law or in response to valid requests by public authorities (e.g. a
                court or a government agency).

            </p>
            <h4>Other legal requirements </h4>
            <p>
                The Organization may disclose Your Personal Data in the good faith belief that such action is
                necessary to:
            </p>
            <ul>
                <li>Comply with a legal obligation</li>
                <li>Protect and defend the rights or property of the Organization </li>
                <li>Prevent or investigate possible wrongdoing in connection with the Service </li>
                <li>Protect the personal safety of Users of the Service or the public</li>
                <li>Protect the personal safety of Users of the Service or the public</li>
            </ul>

            <h3>Security of Your Personal Data </h3>
            <p>
                The security of Your Personal Data is important to Us, but remember that no method of
                transmission over the Internet, or method of electronic storage is 100% secure. While We
                strive to use commercially acceptable means to protect Your Personal Data, We cannot
                guarantee its absolute security.
            </p>

            <h1><u>Children's Privacy</u></h1>
            <p>
                Onboarding of the students below 18 years, for providing learning, vocational education,
                career awareness and internship are done after due process of parents consent and under
                the supervision of a teacher monitoring the work of students. The record of such consent is
                maintained manually in the respective schools.
            </p>

            <h1><u>Links to Other Websites</u></h1>
            <p>
                Our Service may contain links to other websites that are not operated by Us. If You click on a
                third-party link, you will be directed to that third party's site. We strongly advise You to
                review the Privacy Policy of every site You visit.
            </p>

            <p>
                We have no control over and assume no responsibility for the content, privacy policies or
                practices of any third-party sites or services.
            </p>

            <h1><u>Changes to this Privacy Policy </u></h1>
            <p>
                We may update Our Privacy Policy from time to time. We will notify You of any changes by
                posting the new Privacy Policy on this page.
            </p>

            <p>
                We will let You know via email and/or a prominent notice on Our Service, prior to the change
                becoming effective and update the "Last updated" date at the top of this Privacy Policy.
            </p>

            <p>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this
                Privacy Policy are effective when they are posted on this page.
            </p>

            <h1><u>Contact Us </u></h1>
            <p>
                If you have any questions about this Privacy Policy, you can contact us:
            </p>

            <ul>
                <li>By email: support.mh.lighthouse@lend-a-hand-india.org</li>
            </ul>


        </div>
    </div>

</div>