import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ErrorDialogComponent } from "./error-dialog.component";

@Injectable({
    providedIn: "root",
})
export class ErrorDialogService {
    constructor(private dialog: MatDialog) {}

    popupErrorMessage(msg) {
        return this.dialog.open(ErrorDialogComponent, {
            minWidth: "400px",
            disableClose: true,
            panelClass: ["error-dialog-container"],
            position: { top: "10px" },
            data: {
                message: msg,
            },
        });
    }
}
