import {
  Component,
  OnInit,
  NgZone,
  ViewEncapsulation,
  ViewChild,
} from "@angular/core";
import { BaseComponent } from "app/common/base/base.component";
import { CommonService } from "app/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { DialogService } from "app/common/confirm-dialog/dialog.service";
import { RouteConstants } from "app/constants/route.constant";
import { VTDailyReportingService } from "../vt-daily-reporting.service";
import { VTDailyReportingModel } from "../vt-daily-reporting.model";
import { DropdownModel } from "app/models/dropdown.model";
import { ModuleUnitSessionModel } from "app/models/module-unit-session-model";
import { VTROnJobTrainingCoordinationModel } from "../vt-on-job-training-coordination.model";
import { VTRAssessorInOtherSchoolForExamModel } from "../vt-assessor-in-other-school-for-exam.model";
import { VTRParentTeachersMeetingModel } from "../vt-parent-teachers-meeting.model";
import { VTRCommunityHomeVisitModel } from "../vt-community-home-visit.model";
import { VTRVisitToIndustryModel } from "../vt-visit-to-industry.model";
import { VTRVisitToEducationalInstitutionModel } from "../vt-visit-to-educational-institution.model";
import { VTRAssignmentFromVocationalDepartmentModel } from "../vt-assignment-from-vocational-department.model";
import { VTRTeachingNonVocationalSubjectModel } from "../vt-teaching-non-vocational-subject.model";
import { VTRHolidayModel } from "../vt-holiday.model";
import { VTRObservationDayModel } from "../vt-observation-day.model";
import { VTRLeaveModel } from "../vt-leave.model";
import { VTRTrainingOfTeacherModel } from "../vt-training-of-teacher.model";
import { VTRTeachingVocationalEducationModel } from "../vt-teaching-vocational-education.model";
import { FileUploadModel } from "app/models/file.upload.model";
import { StudentAttendanceModel } from "app/models/student.attendance.model";
import { ClassSectionModel } from "app/models/class.section.model";
import { UnitSessionModel } from "app/models/unit.session.model";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { VTHMMeetingModel } from "../vt-hm-meetig.model";
import { VTHomeVisitModel } from "../vt-home-visit.model";
import { VTCommnityWorkModel } from "../vt-community-work.model";
import { VocationalTrainerService } from "../../vocational-trainers/vocational-trainer.service";
import { StudentClassDetailService } from "../../student-class-details/student-class-detail.service";
import { BaseService } from "app/services/base.service";
import { VTVCDailyReportingService } from "../../vt-vc-daily-report.service";
import { DatePipe } from "@angular/common";
import { HMMeetInvolvedModel } from 'app/models/Hmmeet.model';
import { log } from "console";


@Component({
  selector: 'vt-daily-reporting',
  templateUrl: './create-vt-daily-reporting.component.html',
  styleUrls: ['./create-vt-daily-reporting.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateVTDailyReportingComponent extends BaseComponent<VTDailyReportingModel> implements OnInit {
  vtDailyReportingForm: FormGroup;
  homeVisitGroup: FormGroup;
  vtDailyReportingModel: VTDailyReportingModel;

  vtSchoolSectorList: [DropdownModel];
  reportTypeList: [DropdownModel];
  classTaughtList: [DropdownModel];
  studentClassList: [DropdownModel];
  sectionList: DropdownModel[];
  sectionByClassList: ClassSectionModel[];
  studentList: StudentAttendanceModel[];
  moduleTaughtList: [DropdownModel];
  unitSessionList: UnitSessionModel[];

  classSectionList: any;
  unitList: any;
  sessionList: any;
  unitSessionsModels: any;
  activityTypeList: any;
  tveClassSectionList: any;
  filteredStudentList: any;

  displayColumns: string[] = ['StudentName', 'IsPresent'];
  dataSourceAttendance: any;
  workingDayTypeIdsList: [DropdownModel];
  otherWorkList: [DropdownModel];
  trainingTypeList: [DropdownModel];
  trainingTopicsList: [DropdownModel];
  holidayDayList: [DropdownModel];
  ojtActivityList: [DropdownModel];
  leaveApproverList: [DropdownModel];
  leaveTypeList: [DropdownModel];
  leaveModeList: [DropdownModel];
  holidayTypeList: [DropdownModel];
  observationDayList: [DropdownModel];
  classTypeList: [DropdownModel];
  classPictureFile: FileUploadModel;
  lessonPlanPictureFile: FileUploadModel;
  assignmentPhotoFile: FileUploadModel;
  MaterialBillFile: FileUploadModel;
  HomeVisitPictureFile: FileUploadModel;

  isAllowTeachingVocationalEducation: boolean = false;
  isAllowTrainingOfTeacher: boolean = false;
  isAllowOnJobTrainingCoordination: boolean = false;
  isAllowAssessorInOtherSchool: boolean = false;
  isAllowParentTeacherMeeting: boolean = false;
  isAllowCommunityHomeVisit: boolean = false;
  isAllowIndustryVisit: boolean = false;
  isAllowVisitToEducationalInstitute: boolean = false;
  isAllowAssignmentFromVocationalDepartment: boolean = false;
  isAllowTeachingNonVocationalSubject: boolean = false;
  isAllowWorkAssignedByHeadMaster: boolean = false;
  isAllowSchoolExamDuty: boolean = false;
  isAllowOtherWork: boolean = false;
  isAllowHoliday: boolean = false;
  isAllowObservationDay: boolean = false;
  isAllowLeave: boolean = false;
  isAllowSchoolEventCelebration: boolean = false;
  isAllowCommunityWork: boolean = false;
  isAllowHomeVisit: boolean = false
  minReportingDate: Date;
  isAllowClassObservation: boolean = false;
  isAllowHMmeet: boolean = false;
  isRawMaterialBill: boolean = false;

  selectedObjectsFromArray: any;
  practicalStatusList: any = [{ value: 'In-complete', label: 'In-complete' },
  { value: 'Complete', label: 'Complete' }]


  vtList: any;
  vtId: any;
  classList: any;

  ClassId: any;
  // sectionList: any;
  // studentList: any;
  academicYearList: any;
  generationList: any = [
    { Id: "1", value: "First" },
    { Id: "2", value: "Second" },
    { Id: "3", value: "Third" }
  ]
  ageList: any = [
    { Id: "eldest", value: "Eldest" },
    { Id: "youngest", value: "Youngest" },
    { Id: "other", value: "Other" }
  ]
  hygeinList: any = [
    { Id: "excellent", value: "Excellent" },
    { Id: "acceptable", value: "Acceptable" },
    { Id: "notacceptable", value: "Not acceptable" },
  ]
  educationList: any = [
    { Id: "illeterate", value: "Illeterate" },
    { Id: "upto5", value: "Upto 5th standard" },
    { Id: "upto10", value: "Upto 10th standard" },
    { Id: "upto12", value: "Upto 12 th standard" },
    { Id: "graduate", value: "Graduate" },
    { Id: "pgraduate", value: "Post Graduate" },
    { Id: "iti", value: "ITI" }
  ]

  vTReport: any;

  HMMeetParticipantList: HMMeetInvolvedModel[]

  meetList: any = []
  otherMemberList: any = []
  isAddHMMember: boolean = false;

  isIsMSpracticalworkdoneStatus: boolean = false;
  isParentIntrestedEdu: boolean = false;
  isKeentoknow: boolean = false;
  isChronical: boolean = false;
  isParentCommunicatd: boolean = false;
  academicYear: any;
  isOtherSelected: boolean = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private vtDailyReportingService: VTDailyReportingService,
    private vtvcDailyReportingService: VTVCDailyReportingService,
    private dialogService: DialogService,
    private vocationalTrainerService: VocationalTrainerService,
    private studentClassDetailService: StudentClassDetailService,
    private baseService: BaseService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe) {
    super(commonService, router, routeParams, snackBar);

    // Set the default vtDailyReporting Model
    this.vtDailyReportingModel = new VTDailyReportingModel();
    this.sectionList = <DropdownModel[]>[];
    this.studentList = <StudentAttendanceModel[]>[];
    this.classSectionList = [];
    this.unitList = [];
    this.sessionList = [];
    this.unitSessionsModels = [];
    this.tveClassSectionList = [];

    this.classPictureFile = new FileUploadModel();
    this.lessonPlanPictureFile = new FileUploadModel();
    this.assignmentPhotoFile = new FileUploadModel();
    this.MaterialBillFile = new FileUploadModel();
    this.HomeVisitPictureFile = new FileUploadModel();

    let dateOfAllocation = new Date(this.UserModel.DateOfAllocation);
    let maxDate = new Date(Date.now());

    let Time = maxDate.getTime() - dateOfAllocation.getTime();
    let Days = Math.floor(Time / (1000 * 3600 * 24));
    if (Days < this.Constants.BackDatedReportingDays) {
      this.minReportingDate = new Date(this.UserModel.DateOfAllocation);
    }
    else {
      let past7days = maxDate;
      past7days.setDate(past7days.getDate() - this.Constants.BackDatedReportingDays)
      this.minReportingDate = past7days;
    }
  }

  ngOnInit(): void {

    this.selectedObjectsFromArray = ['204', '205']

    this.baseService.GetMasterDataByType({ DataType: 'AcademicYears', SelectTitle: 'Academic Year' }).subscribe(response => {
      this.academicYearList = response.Results
      this.academicYear = this.academicYearList.find(
        (ay) => ay.IsSelected == true
      );
    })

    this.baseService
      .GetMasterDataByType({
        DataType: "AcademicYears",
        SelectTitle: "Academic Year",
      })
      .subscribe((response) => {
        this.academicYearList = response.Results;

        this.academicYear = this.academicYearList.find(
          (ay) => ay.IsSelected == true
        );

        // this.vcDailyReportingModel.HomeVisit.AcademicYearId = this.academicYear.Id
      });

    this.vtDailyReportingService
      .getDropdownForVTDailyReporting()
      .subscribe(results => {

        if (results[0].Success) {
          this.reportTypeList = results[0].Results;
        }

        this.route.paramMap.subscribe(params => {
          if (params.keys.length > 0) {
            this.PageRights.ActionType = params.get('actionType');

            if (this.PageRights.ActionType == this.Constants.Actions.New) {
              this.vtDailyReportingModel = new VTDailyReportingModel();

            } else {
              var vtDailyReportingId: string = params.get('vtDailyReportingId')

              this.vtDailyReportingService.getVTDailyReportingById(vtDailyReportingId)
                .subscribe((response) => {

                  this.vtDailyReportingModel = response.Result;
                  this.vTReport = response.Result;

                  if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                    this.vtDailyReportingModel.RequestType = this.Constants.PageType.Edit;
                  else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                    this.vtDailyReportingModel.RequestType = this.Constants.PageType.View;
                    this.PageRights.IsReadOnly = true;
                  }

                  this.vtDailyReportingForm = this.createVTDailyReportingForm();

                  this.onChangeReportType(this.vtDailyReportingModel.ReportType).then(response => {
                    if (this.vtDailyReportingModel.WorkingDayTypeIds.length > 0) {
                      (this.vtDailyReportingModel.WorkingDayTypeIds).forEach(element => {                        
                        let result = this.workingDayTypeIdsList.find(ele => ele.Id === element)
                        const index = (this.vtDailyReportingModel.WorkingDayTypeIds).indexOf(element); 
                        this.vtDailyReportingModel.WorkingDayTypeIds.splice(index, 1, result.Name);
                    });
                                      
                      this.onChangeWorkingType(this.vtDailyReportingModel.WorkingDayTypeIds);
                    }
                  });
                });
            }
          }
        });
      });

    this.vtDailyReportingForm = this.createVTDailyReportingForm();

  }

  onChangeReportType(reportTypeId): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      this.commonService.GetMasterDataByType({ DataType: 'WorkingDayType', ParentId: reportTypeId, SelectTitle: 'Working Day' }, false).subscribe((response: any) => {
        if (response.Success) {
          this.resetReportTypeFormGroups();
          // On Leave
          if (reportTypeId == 38) {
            this.isAllowLeave = true;
            this.vtDailyReportingModel.Leave = new VTRLeaveModel(this.vtDailyReportingModel.Leave);

            this.vtDailyReportingForm = this.formBuilder.group({
              ...this.vtDailyReportingForm.controls,

              leaveGroup: this.formBuilder.group({
                LeaveTypeId: new FormControl({ value: this.vtDailyReportingModel.Leave.LeaveTypeId, disabled: this.PageRights.IsReadOnly }),
                LeaveModeId: new FormControl({ value: (this.vtDailyReportingModel.Leave.LeaveModeId === ''? '' :(this.vtDailyReportingModel.Leave.LeaveModeId === '2'?'383':'384')), disabled: this.PageRights.IsReadOnly }),
                LeaveApprovalStatus: new FormControl({ value: this.vtDailyReportingModel.Leave.LeaveApprovalStatus, disabled: this.PageRights.IsReadOnly }),
                LeaveApprover: new FormControl({ value: this.vtDailyReportingModel.Leave.LeaveApprover, disabled: this.PageRights.IsReadOnly }),
                LeaveReason: new FormControl({ value: this.vtDailyReportingModel.Leave.LeaveReason, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
              })
            });

            this.leaveTypeList = response.Results;
            this.onChangeLeaveApprovalStatus();
            this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'LeaveApprover', SelectTitle: 'Leave Approver' }).subscribe((response) => {
              if (response.Success) {
                this.leaveApproverList = response.Results;
              }
            });

            this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'LeaveMode', SelectTitle: 'Leave Mode' }).subscribe((response) => {
              if (response.Success) {
                this.leaveModeList = response.Results;
              }
            });
          }
          // Holiday/ School Off        
          else if (reportTypeId == 40) {
            this.isAllowHoliday = true;
            this.vtDailyReportingModel.Holiday = new VTRHolidayModel(this.vtDailyReportingModel.Holiday);

            this.vtDailyReportingForm = this.formBuilder.group({
              ...this.vtDailyReportingForm.controls,

              holidayGroup: this.formBuilder.group({
                HolidayTypeId: new FormControl({ value: this.vtDailyReportingModel.Holiday.HolidayTypeId, disabled: this.PageRights.IsReadOnly }, Validators.required),
                HolidayDetails: new FormControl({ value: this.vtDailyReportingModel.Holiday.HolidayDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
              })
            });

            this.holidayTypeList = response.Results;

          }
          // Observation Day
          else if (reportTypeId == 123) {
            this.isAllowObservationDay = true;
            this.vtDailyReportingModel.ObservationDay = new VTRObservationDayModel(this.vtDailyReportingModel.ObservationDay);

            this.vtDailyReportingForm = this.formBuilder.group({
              ...this.vtDailyReportingForm.controls,

              observationDayGroup: this.formBuilder.group({
                ObservationDetails: new FormControl({ value: this.vtDailyReportingModel.ObservationDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
                OBStudentCount: new FormControl({ value: this.vtDailyReportingModel.OBStudentCount, disabled: this.PageRights.IsReadOnly }),
                StudentAttendances: this.formBuilder.array([])
              })
            });

            this.observationDayList = response.Results;
          }
          else {
            this.vtDailyReportingForm = this.formBuilder.group({
              ...this.vtDailyReportingForm.controls,

              WorkingDayTypeIds: new FormControl({ value: this.vtDailyReportingModel.WorkingDayTypeIds, disabled: this.PageRights.IsReadOnly })
            });

            this.workingDayTypeIdsList = response.Results;
          }

          this.onChangeReportingDate();
        }
        resolve(true);
      });
    });
    return promise;
  }

  onChangeReportingDate(): boolean {
    let reportingDate = this.vtDailyReportingForm.get('ReportingDate').value;

    if (reportingDate != null && new Date(reportingDate).getDay() == 0) {
      this.dialogService.openShowDialog("User cannot submit the VT Daily Reporting on Sunday");
      return false
    }

    return true
  }

  onChangeWorkingType(workingTypes): void {
    this.resetWorkTypesFormGroups();

    workingTypes.forEach(workTypeId => {

      //1. Teaching Vocational Education
      if (workTypeId == 'Teaching - Vocational Education') {
        this.isAllowTeachingVocationalEducation = true;
        let teachingVocationalEducation = new VTRTeachingVocationalEducationModel();

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          teachingVocationalEducationGroup: this.formBuilder.group({
            teachingVocationalEducations: this.formBuilder.array([this.createTeachingVocationalEducation(teachingVocationalEducation)])
          })
        });

        this.vtDailyReportingService.getDropdownForTeachingVocationalEducation(this.UserModel).subscribe((response) => {
          if (response[0].Success) {
            this.classTaughtList = response[0].Results;
          }

          if (response[1].Success) {
            this.moduleTaughtList = response[1].Results;
          }

          if (response[2].Success) {
            this.otherWorkList = response[2].Results;
          }

          if (response[3].Success) {
            this.classTypeList = response[3].Results;
          }

          if (response[4].Success) {
            this.selectedObjectsFromArray = ['204', '205']

            this.activityTypeList = response[4].Results;
          }

          if (response[5].Success) {
            this.sectionByClassList = response[5].Results;
          }

          if (response[6].Success) {
            this.studentList = response[6].Results;
            this.filteredStudentList = this.studentList.slice()

          }

          if (response[7].Success) {
            this.unitSessionList = response[7].Results;
          }

          // if (this.classTaughtList.length > 1) {
          //   let teachingVocationalEducationControls = <FormArray>this.vtDailyReportingForm.controls.teachingVocationalEducationGroup.get('teachingVocationalEducations');
          //   teachingVocationalEducationControls.clear();

          //   this.tveClassSectionList = [];
          //   this.classSectionList = [];

          //   for (let classIndex = 1; classIndex < this.classTaughtList.length; classIndex++) {
          //     let sections = this.sectionList.filter(c => c.ClassId == this.classTaughtList[classIndex].Id);

          //     for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
          //       this.tveClassSectionList.push({ ClassId: this.classTaughtList[classIndex].Id, SectionId: sections[sectionIndex].SectionId });

          //       this.classSectionList.push(sections);
          //     }
          //   }

          //   for (let tveIndex = 0; tveIndex < this.tveClassSectionList.length; tveIndex++) {
          //     teachingVocationalEducation = new VTRTeachingVocationalEducationModel();
          //     teachingVocationalEducation.SequenceNo = tveIndex + 1;
          //     teachingVocationalEducation.ClassTaughtId = this.tveClassSectionList[tveIndex].ClassId;
          //     teachingVocationalEducation.ClassSectionIds = this.tveClassSectionList[tveIndex].SectionId;

          //     this.unitList[tveIndex] = <DropdownModel[]>[];
          //     this.sessionList[tveIndex] = <DropdownModel[]>[];
          //     this.unitSessionsModels[tveIndex] = <ModuleUnitSessionModel[]>[];

          //     let tveFormGroup = this.createTeachingVocationalEducation(teachingVocationalEducation);
          //     let studentsForSection = this.studentList.filter(s => s.ClassId == teachingVocationalEducation.ClassTaughtId && s.SectionId == teachingVocationalEducation.ClassSectionIds);
          //     let studentAttendancesControls = <FormArray>tveFormGroup.get('StudentAttendances');
          //     studentAttendancesControls.clear();

          //     if (studentsForSection.length > 0) {
          //       studentsForSection.forEach(studentItem => {
          //         studentAttendancesControls.push(this.formBuilder.group(studentItem));
          //       });
          //     }

          //     teachingVocationalEducationControls.push(tveFormGroup);
          //   }
          // }

          if (this.PageRights.ActionType == this.Constants.Actions.View) {
            let teachingVocationalEducationControls = <FormArray>this.vtDailyReportingForm.controls.teachingVocationalEducationGroup.get('teachingVocationalEducations');
            teachingVocationalEducationControls.clear();
            this.unitSessionsModels = [];

            this.sectionList = <DropdownModel[]>[];
            this.sectionByClassList.forEach(sectionItem => {
              let section = this.sectionList.find(s => s.Id == sectionItem.SectionId);

              if (section == null) {
                this.sectionList.push(new DropdownModel({ Id: sectionItem.SectionId, Name: sectionItem.SectionName }));
              }
            });

            let sortTeachingVocationalEducations = this.vtDailyReportingModel.TeachingVocationalEducations.sort((a, b) => { return a.SequenceNo - b.SequenceNo; });
            sortTeachingVocationalEducations.forEach(tveItem => {
              this.unitSessionsModels.push(tveItem.UnitSessionsModels);

              let tveFormGroup: FormGroup = this.createTeachingVocationalEducation(tveItem);

              let studentAttendancesControls = <FormArray>tveFormGroup.get('StudentAttendances');
              studentAttendancesControls.clear();

              tveItem.StudentAttendances.forEach(studentItem => {
                studentAttendancesControls.push(this.formBuilder.group(studentItem));
              });

              teachingVocationalEducationControls.push(tveFormGroup);
            });
          }

        });
      }

      //2. Training Of Teacher 
      if (workTypeId == 'Training of Teacher/ Trainer (ToT)') {
        this.isAllowTrainingOfTeacher = true;
        this.vtDailyReportingModel.TrainingOfTeacher = new VTRTrainingOfTeacherModel(this.vtDailyReportingModel.TrainingOfTeacher);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          trainingOfTeacherGroup: this.formBuilder.group({
            TrainingTypeId: new FormControl({ value: this.vtDailyReportingModel.TrainingOfTeacher.TrainingTypeId, disabled: this.PageRights.IsReadOnly }),
            TrainingBy: new FormControl({ value: this.vtDailyReportingModel.TrainingOfTeacher.TrainingBy, disabled: this.PageRights.IsReadOnly }),
            TrainingTopicIds: new FormControl({ value: this.vtDailyReportingModel.TrainingOfTeacher.TrainingTopicIds, disabled: this.PageRights.IsReadOnly }),
            TrainingDetails: new FormControl({ value: this.vtDailyReportingModel.TrainingOfTeacher.TrainingDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
          })
        });

        this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'VTTrainingType', SelectTitle: 'Training Type' }).subscribe((response) => {
          if (response.Success) {
            this.trainingTypeList = response.Results;
            this.onChangeTrainingOfTeacherType();
          }
        });

        this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'VTTrainingTopics', SelectTitle: 'Training Topics' }, false).subscribe((response) => {
          if (response.Success) {
            this.trainingTopicsList = response.Results;
          }
        });
      }

      //3. On Job Training Coordination
      if (workTypeId == 'On-job Training Coordination') {
        this.isAllowOnJobTrainingCoordination = true;
        this.vtDailyReportingModel.OnJobTrainingCoordination = new VTROnJobTrainingCoordinationModel(this.vtDailyReportingModel.OnJobTrainingCoordination);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          onJobTrainingCoordinationGroup: this.formBuilder.group({
            OnJobTrainingActivityId: new FormControl({ value: this.vtDailyReportingModel.OnJobTrainingCoordination.OnJobTrainingActivityId, disabled: this.PageRights.IsReadOnly }),
            IndustryName: new FormControl({ value: this.vtDailyReportingModel.OnJobTrainingCoordination.IndustryName, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(150)),
            IndustryContactPerson: new FormControl({ value: this.vtDailyReportingModel.OnJobTrainingCoordination.IndustryContactPerson, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(100), Validators.pattern(this.Constants.Regex.CharsWithTitleCase)]),
            IndustryContactNumber: new FormControl({ value: this.vtDailyReportingModel.OnJobTrainingCoordination.IndustryContactNumber, disabled: this.PageRights.IsReadOnly }, [Validators.pattern(this.Constants.Regex.MobileNumber)]),
            OJTActivityDetails: new FormControl({ value: this.vtDailyReportingModel.OnJobTrainingCoordination.OJTActivityDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
          })
        });

        this.commonService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'OnJobTrainingActivity', SelectTitle: 'On Job Training Activity' }).subscribe((response) => {
          if (response.Success) {
            this.ojtActivityList = response.Results;
            this.onChangeOnjobTrainingCoordinationType();
          }
        });
      }

      //4. Assessor In Other School
      if (workTypeId == 'Assessor in Other School for Exam') {
        this.isAllowAssessorInOtherSchool = true;
        this.vtDailyReportingModel.AssessorInOtherSchoolForExam = new VTRAssessorInOtherSchoolForExamModel(this.vtDailyReportingModel.AssessorInOtherSchoolForExam);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          assessorInOtherSchoolGroup: this.formBuilder.group({
            SchoolName: new FormControl({ value: this.vtDailyReportingModel.AssessorInOtherSchoolForExam.SchoolName, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(200)),
            Udise: new FormControl({ value: this.vtDailyReportingModel.AssessorInOtherSchoolForExam.Udise, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(11), Validators.minLength(11), Validators.pattern(this.Constants.Regex.Number)]),
            ClassId: new FormControl({ value: this.vtDailyReportingModel.AssessorInOtherSchoolForExam.ClassId, disabled: this.PageRights.IsReadOnly }),
            BoysPresent: new FormControl({ value: this.vtDailyReportingModel.AssessorInOtherSchoolForExam.BoysPresent, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
            GirlsPresent: new FormControl({ value: this.vtDailyReportingModel.AssessorInOtherSchoolForExam.GirlsPresent, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
            ExamDetails: new FormControl({ value: this.vtDailyReportingModel.AssessorInOtherSchoolForExam.ExamDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
          })
        });

        this.commonService.GetClassesByVTId({ DataId: this.UserModel.LoginId, DataId1: this.UserModel.UserTypeId, SelectTitle: 'Class' }).subscribe((response) => {
          if (response.Success) {
            this.studentClassList = response.Results;
          }
        });
      }

      //5. School Event/ Celebration
      if (workTypeId == 'School Event/ Celebration') {
        this.isAllowSchoolEventCelebration = true;
        this.vtDailyReportingModel.SchoolEventCelebration = this.vtDailyReportingModel.SchoolEventCelebration;

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          schoolEventCelebrationGroup: this.formBuilder.group({
            SchoolEventCelebration: new FormControl({ value: this.vtDailyReportingModel.SchoolEventCelebration, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
          })
        });
      }

      //5. Parent Teacher Meeting
      if (workTypeId == 'Parent Teachers Meeting (PTA)') {
        this.isAllowParentTeacherMeeting = true;
        this.vtDailyReportingModel.ParentTeachersMeeting = new VTRParentTeachersMeetingModel(this.vtDailyReportingModel.ParentTeachersMeeting);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          parentTeacherMeetingGroup: this.formBuilder.group({
            VocationalParentsCount: new FormControl({ value: this.vtDailyReportingModel.ParentTeachersMeeting.VocationalParentsCount, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
            OtherParentsCount: new FormControl({ value: this.vtDailyReportingModel.ParentTeachersMeeting.OtherParentsCount, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
            PTADetails: new FormControl({ value: this.vtDailyReportingModel.ParentTeachersMeeting.PTADetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
          })
        });
      }

      //6. Community Home Visit
      if (workTypeId == 'Community Home Visit') {
        this.isAllowCommunityHomeVisit = true;
        this.vtDailyReportingModel.CommunityHomeVisit = new VTRCommunityHomeVisitModel(this.vtDailyReportingModel.CommunityHomeVisit);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          communityHomeVisitGroup: this.formBuilder.group({
            VocationalParentsCount: new FormControl({ value: this.vtDailyReportingModel.CommunityHomeVisit.VocationalParentsCount, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
            OtherParentsCount: new FormControl({ value: this.vtDailyReportingModel.CommunityHomeVisit.OtherParentsCount, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
            CommunityVisitDetails: new FormControl({ value: this.vtDailyReportingModel.CommunityHomeVisit.CommunityVisitDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
          })
        });
      }

      //7. Industry Visit
      if (workTypeId == 'Visit to Industry') {
        this.isAllowIndustryVisit = true;
        this.vtDailyReportingModel.VisitToIndustry = new VTRVisitToIndustryModel();        

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          industryVisitGroup: this.formBuilder.group({
            IndustryVisitCount: new FormControl({ value: this.vtDailyReportingModel.VisitToIndustries[0].IndustryVisitCount, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
            IndustryVisits: this.formBuilder.array([this.createIndustryVisit(this.vtDailyReportingModel.VisitToIndustry)])
          })
        });

        if (this.PageRights.ActionType != this.Constants.Actions.New) {
          let industryVisitControls = <FormArray>this.vtDailyReportingForm.controls.industryVisitGroup.get('IndustryVisits');
          industryVisitControls.clear();

          if (this.vtDailyReportingModel.VisitToIndustries.length > 0) {
            this.vtDailyReportingModel.VisitToIndustries.forEach(visitToIndustryItem => {
              industryVisitControls.push(this.createIndustryVisit(visitToIndustryItem));
            });
          }
          else {
            industryVisitControls.push(this.createVisitToEducationalInstitute(this.vtDailyReportingModel.VisitToEducationalInstitution));
          }
        }
      }

      //8. Visit to Educational Institute
      if (workTypeId == 'Visit to Educational Institution') {
        this.isAllowVisitToEducationalInstitute = true;
        this.vtDailyReportingModel.VisitToEducationalInstitution = new VTRVisitToEducationalInstitutionModel();

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          visitToEducationalInstituteGroup: this.formBuilder.group({
            EducationalInstituteVisitCount: new FormControl({ value: this.vtDailyReportingModel.VisitToEducationalInstitution.EducationalInstituteVisitCount, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
            VisitToEducationalInstitutes: this.formBuilder.array([this.createVisitToEducationalInstitute(this.vtDailyReportingModel.VisitToEducationalInstitution)])
          })
        });

        if (this.PageRights.ActionType != this.Constants.Actions.New) {
          let educationalInstituteVisitControls = <FormArray>this.vtDailyReportingForm.controls.visitToEducationalInstituteGroup.get('VisitToEducationalInstitutes');
          educationalInstituteVisitControls.clear();

          if (this.vtDailyReportingModel.VisitToEducationalInstitutions.length > 0) {
            this.vtDailyReportingModel.VisitToEducationalInstitutions.forEach(visitToEducationalInstitutionItem => {
              educationalInstituteVisitControls.push(this.createVisitToEducationalInstitute(visitToEducationalInstitutionItem));
            });
          }
          else {
            educationalInstituteVisitControls.push(this.createVisitToEducationalInstitute(this.vtDailyReportingModel.VisitToEducationalInstitution));
          }
        }
      }

      //9. Assignment From Vocational Department
      if (workTypeId == 'Assignment from Vocational Department') {
        this.isAllowAssignmentFromVocationalDepartment = true;
        this.vtDailyReportingModel.AssignmentFromVocationalDepartment = new VTRAssignmentFromVocationalDepartmentModel(this.vtDailyReportingModel.AssignmentFromVocationalDepartment);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          assignmentFromVocationalDepartmentGroup: this.formBuilder.group({
            AssigmentNumber: new FormControl({ value: this.vtDailyReportingModel.AssignmentFromVocationalDepartment.AssigmentNumber, disabled: this.PageRights.IsReadOnly }, [Validators.pattern(this.Constants.Regex.Number)]),
            AssignmentDetails: new FormControl({ value: this.vtDailyReportingModel.AssignmentFromVocationalDepartment.AssignmentDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
            AssignmentPhotoFile: new FormControl({ value: this.vtDailyReportingModel.AssignmentFromVocationalDepartment.AssignmentPhotoFile, disabled: this.PageRights.IsReadOnly }, Validators.required),
            IsAssignmentPhotoFile: new FormControl({ value: false, disabled: this.PageRights.IsReadOnly }),
          })
        });
      }

      //10. Teaching Non Vocational Subject  
      if (workTypeId == 'Teaching Non Vocational Subject') {
        this.isAllowTeachingNonVocationalSubject = true;
        this.vtDailyReportingModel.TeachingNonVocationalSubject = new VTRTeachingNonVocationalSubjectModel(this.vtDailyReportingModel.TeachingNonVocationalSubject);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          teachingNonVocationalSubjectGroup: this.formBuilder.group({
            OtherClassTakenDetails: new FormControl({ value: this.vtDailyReportingModel.TeachingNonVocationalSubject.OtherClassTakenDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(150)),
            OtherClassTime: new FormControl({ value: this.vtDailyReportingModel.TeachingNonVocationalSubject.OtherClassTime, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.Number)),
          })
        });
      }

      //11. Work Assigned By Head Master
      if (workTypeId == 'Work Assigned by Head Master') {
        this.isAllowWorkAssignedByHeadMaster = true;
        this.vtDailyReportingModel.WorkAssignedByHeadMaster = this.vtDailyReportingModel.WorkAssignedByHeadMaster;

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          workAssignedByHeadMasterGroup: this.formBuilder.group({
            WorkAssignedByHeadMaster: new FormControl({ value: this.vtDailyReportingModel.WorkAssignedByHeadMaster, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(150)),
          })
        });
      }

      //11. School Exam Duty
      if (workTypeId == 'School Exam Duty') {
        this.isAllowSchoolExamDuty = true;
        this.vtDailyReportingModel.SchoolExamDuty = this.vtDailyReportingModel.SchoolExamDuty;

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          schoolExamDutyGroup: this.formBuilder.group({
            SchoolExamDuty: new FormControl({ value: this.vtDailyReportingModel.SchoolExamDuty, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(150)),
          })
        });
      }


      // HM Meeting
      if (workTypeId == 'HM Meeting') {
        this.getHM()
        this.isAllowHMmeet = true;

        this.vtDailyReportingModel.HMMeeting = new VTHMMeetingModel(this.vtDailyReportingModel.HMMeeting);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          hmMeetingGroup: this.formBuilder.group({
            MembersCount: new FormControl({ value: this.vtDailyReportingModel.HMMeeting.MembersCount, disabled: true }),
            Members: new FormControl({ value: this.vtDailyReportingModel.HMMeeting.Members, disabled: this.PageRights.IsReadOnly }),
            MeetingDetails: new FormControl({ value: this.vtDailyReportingModel.HMMeeting.MeetingDetails, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(100)]),
            OtherMember: new FormControl({ value: this.vtDailyReportingModel.HMMeeting.OtherMember, disabled: this.PageRights.IsReadOnly }),
          })
        });

      }

      if (workTypeId == 'Community Work') {
        this.isAllowCommunityWork = true;
        this.vtDailyReportingModel.CommunityWork = new VTCommnityWorkModel(this.vtDailyReportingModel.CommunityWork);



        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          communityWorkGroup: this.formBuilder.group({
            ClassTaughtId: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.ClassTaughtId, disabled: this.PageRights.IsReadOnly }),
            WorkDetails: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.WorkDetails, disabled: this.PageRights.IsReadOnly }),
            NoOfDays: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.NoOfDays, disabled: this.PageRights.IsReadOnly }),
            NoOfStudents: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.NoOfStudents, disabled: this.PageRights.IsReadOnly }),
            ClientName: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.ClientName, disabled: this.PageRights.IsReadOnly }),
            ClientAddress: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.ClientAddress, disabled: this.PageRights.IsReadOnly }),
            TotalExpense: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.TotalExpense, disabled: this.PageRights.IsReadOnly }),
            IsRawMaterial: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.IsRawMaterial, disabled: this.PageRights.IsReadOnly }),
            TotalProfit: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.TotalProfit, disabled: this.PageRights.IsReadOnly }),
            StudentsExperience: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.StudentsExperience, disabled: this.PageRights.IsReadOnly }),
            MaterialBillFile: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.MaterialBillFile, disabled: this.PageRights.IsReadOnly }),
            Description: new FormControl({ value: this.vtDailyReportingModel.CommunityWork.Description, disabled: this.PageRights.IsReadOnly }),
          })
        })
        this.vtDailyReportingService.getDropdownForClass(this.UserModel).subscribe((response) => {
          if (response[0].Success) {
            this.classTaughtList = response[0].Results;
          }
        })
        if (this.PageRights.ActionType == this.Constants.Actions.View) {
          this.vtDailyReportingForm.get('communityWorkGroup.IsRawMaterial').setValue(this.vTReport.CommunityWork.IsRawMaterial == true ? "0" : "1")

        }


      }

      if (workTypeId == 'Home Visit') {
        // this.getVTlist()




        this.isAllowHomeVisit = true;

        this.vtDailyReportingModel.HomeVisit = new VTHomeVisitModel(this.vtDailyReportingModel.HomeVisit);

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          homeVisitGroup: this.formBuilder.group({
            DateOfVisit: new FormControl({ value: new Date(this.vtDailyReportingModel.HomeVisit.DateOfVisit), disabled: this.PageRights.IsReadOnly }),
            PurposeOfVisit: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.PurposeOfVisit, disabled: this.PageRights.IsReadOnly }),
            ClassTaughtId: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.ClassTaughtId, disabled: this.PageRights.IsReadOnly }),
            ClassSectionIds: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.ClassSectionIds, disabled: this.PageRights.IsReadOnly }),
            StudentId: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.StudentId, disabled: this.PageRights.IsReadOnly }),
            AcademicYearId: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.AcademicYearId, disabled: this.PageRights.IsReadOnly }),
            FatherName: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.FatherName, disabled: this.PageRights.IsReadOnly }),
            MotherName: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.MotherName, disabled: this.PageRights.IsReadOnly }),
            FatherEducation: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.FatherEducation, disabled: this.PageRights.IsReadOnly }),
            MotherEducation: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.MotherEducation, disabled: this.PageRights.IsReadOnly }),
            Village: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.Village, disabled: this.PageRights.IsReadOnly }),
            LandMark: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.LandMark, disabled: this.PageRights.IsReadOnly }),
            ContactNum: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.ContactNum, disabled: this.PageRights.IsReadOnly }),
            MotherAge: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.MotherAge, disabled: this.PageRights.IsReadOnly }),
            FatherAge: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.FatherAge, disabled: this.PageRights.IsReadOnly }),
            StudentMSFC: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.StudentMSFC, disabled: this.PageRights.IsReadOnly }),
            MainSouce: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.MainSouce, disabled: this.PageRights.IsReadOnly }),
            AdditionalSource: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.AdditionalSource, disabled: this.PageRights.IsReadOnly }),
            Generation: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.Generation, disabled: this.PageRights.IsReadOnly }),
            SibilingsCount: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.SibilingsCount, disabled: this.PageRights.IsReadOnly }),
            SilbilingType: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.SilbilingType, disabled: this.PageRights.IsReadOnly }),
            IsParentAware: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.IsParentAware, disabled: this.PageRights.IsReadOnly }),
            IsMSChildSpoketoParent: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.IsMSChildSpoketoParent, disabled: this.PageRights.IsReadOnly }),
            IsMSpracticalworkdone: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.IsMSpracticalworkdone, disabled: this.PageRights.IsReadOnly }),
            ChildSharedDetails: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.ChildSharedDetails, disabled: this.PageRights.IsReadOnly }),
            IsParentWilling: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.IsParentWilling, disabled: this.PageRights.IsReadOnly }),
            KindOfEducation: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.KindOfEducation, disabled: this.PageRights.IsReadOnly }),
            StudentDropOut: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.StudentDropOut, disabled: this.PageRights.IsReadOnly }),
            DropoutReason: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.DropoutReason, disabled: this.PageRights.IsReadOnly }),
            IsSchoolingAwareness: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.IsSchoolingAwareness, disabled: this.PageRights.IsReadOnly }),
            DetailsKnown: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.DetailsKnown, disabled: this.PageRights.IsReadOnly }),
            Reaction: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.Reaction, disabled: this.PageRights.IsReadOnly }),
            IsChronicallyIll: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.IsChronicallyIll, disabled: this.PageRights.IsReadOnly }),
            IllCount: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.IllCount, disabled: this.PageRights.IsReadOnly }),
            IsParentCommunicate: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.IsParentCommunicate, disabled: this.PageRights.IsReadOnly }),
            CommunicationDetails: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.CommunicationDetails, disabled: this.PageRights.IsReadOnly }),
            ImportantDetails: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.ImportantDetails, disabled: this.PageRights.IsReadOnly }),
            Remarks: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.Remarks, disabled: this.PageRights.IsReadOnly }),
            Hygienerate: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.Hygienerate, disabled: this.PageRights.IsReadOnly }),
            HomeVisitPictureFile: new FormControl({ value: this.vtDailyReportingModel.HomeVisit.HomeVisitPictureFile, disabled: this.PageRights.IsReadOnly }, Validators.required),
            IsHomeVisitPictureFile: new FormControl({ value: false, disabled: this.PageRights.IsReadOnly }),
          })
        })
        this.vtDailyReportingService.getDropdownForClass(this.UserModel).subscribe((response) => {
          if (response[0].Success) {
            this.classList = response[0].Results;
          }
        })

        this.vtDailyReportingForm.controls.homeVisitGroup["controls"].AcademicYearId.setValue(this.academicYear.Id);


        if (this.PageRights.ActionType == this.Constants.Actions.View) {
          this.onViewHomeVisit();
        }
      }

      //11. Other Work
      if (workTypeId == 'Other Work') {
        this.isAllowOtherWork = true;
        this.vtDailyReportingModel.OtherWork = this.vtDailyReportingModel.OtherWork;

        this.vtDailyReportingForm = this.formBuilder.group({
          ...this.vtDailyReportingForm.controls,

          otherWorkGroup: this.formBuilder.group({
            OtherWork: new FormControl({ value: this.vtDailyReportingModel.OtherWork, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(250)),
          })
        });
      }
    });

    if (this.PageRights.ActionType != this.Constants.Actions.View) {
      let initialFormValues = this.vtDailyReportingForm.value;
      this.vtDailyReportingForm.reset(initialFormValues);
    }
  }


  onChangeIndustryVisitCount(industryVisitCount: number): void {
    let industryVisitControls = <FormArray>this.vtDailyReportingForm.controls.industryVisitGroup.get('IndustryVisits');
    industryVisitControls.clear();

    for (let industryVisitIndex = 1; industryVisitIndex <= industryVisitCount; industryVisitIndex++) {
      var visitToIndustry = new VTRVisitToIndustryModel();
      industryVisitControls.push(this.createIndustryVisit(visitToIndustry));
    }
  }

  createTeachingVocationalEducation(teachingVocationalEducation: VTRTeachingVocationalEducationModel): FormGroup {
    return this.formBuilder.group({
      SequenceNo: new FormControl({ value: teachingVocationalEducation.SequenceNo, disabled: true }),
      ClassTaughtId: new FormControl({ value: teachingVocationalEducation.ClassTaughtId, disabled: this.PageRights.IsReadOnly }),
      DidYouTeachToday: new FormControl({ value: teachingVocationalEducation.DidYouTeachToday, disabled: this.PageRights.IsReadOnly }),
      ClassSectionIds: new FormControl({ value: teachingVocationalEducation.ClassSectionIds, disabled: this.PageRights.IsReadOnly }),
      ActivityTypeIds: new FormControl({ value: this.activityTypeList, disabled: true }),
      PracticalStatus: new FormControl({ value: teachingVocationalEducation.PracticalStatus, disabled: this.PageRights.IsReadOnly }),
      ModuleId: new FormControl({ value: teachingVocationalEducation.ModuleId, disabled: this.PageRights.IsReadOnly }),
      UnitId: new FormControl({ value: teachingVocationalEducation.UnitId, disabled: this.PageRights.IsReadOnly }),
      SessionsTaught: new FormControl({ value: teachingVocationalEducation.SessionsTaught, disabled: this.PageRights.IsReadOnly }),
      ClassTypeId: new FormControl({ value: teachingVocationalEducation.ClassTypeId, disabled: this.PageRights.IsReadOnly }),
      ClassTime: new FormControl({ value: teachingVocationalEducation.ClassTime, disabled: this.PageRights.IsReadOnly }, [Validators.pattern(this.Constants.Regex.Number)]),
      ClassPictureFile: new FormControl({ value: teachingVocationalEducation.ClassPictureFile, disabled: this.PageRights.IsReadOnly }, Validators.required),
      LessonPlanPictureFile: new FormControl({ value: teachingVocationalEducation.LessonPlanPictureFile, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ReasonOfNotConductingTheClassIds: new FormControl({ value: teachingVocationalEducation.ReasonOfNotConductingTheClassIds, disabled: this.PageRights.IsReadOnly }),
      ReasonDetails: new FormControl({ value: teachingVocationalEducation.ReasonDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
      IsClassPictureFile: new FormControl({ value: false, disabled: this.PageRights.IsReadOnly }),
      IsLessonPlanPictureFile: new FormControl({ value: false, disabled: this.PageRights.IsReadOnly }),
      StudentAttendances: this.formBuilder.array([])
    })
  }

  createIndustryVisit(visitToIndustry: VTRVisitToIndustryModel): FormGroup {
    return this.formBuilder.group({
      IndustryName: new FormControl({ value: visitToIndustry.IndustryName, disabled: this.PageRights.IsReadOnly }),
      IndustryContactPerson: new FormControl({ value: visitToIndustry.IndustryContactPerson, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(100), Validators.pattern(this.Constants.Regex.CharsWithTitleCase)]),
      IndustryContactNumber: new FormControl({ value: visitToIndustry.IndustryContactNumber, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.MobileNumber)),
      IndustryVisitDetails: new FormControl({ value: visitToIndustry.IndustryVisitDetails, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(150)),
    })
  }

  onChangeEducationalInstituteVisitCount(educationalInstituteVisitCount: number): void {
    let educationalInstituteVisitControls = <FormArray>this.vtDailyReportingForm.controls.visitToEducationalInstituteGroup.get('VisitToEducationalInstitutes');
    educationalInstituteVisitControls.clear();

    for (let visitToEducationalInstituteIndex = 1; visitToEducationalInstituteIndex <= educationalInstituteVisitCount; visitToEducationalInstituteIndex++) {
      var visitToEducationalInstitution = new VTRVisitToEducationalInstitutionModel();
      educationalInstituteVisitControls.push(this.createVisitToEducationalInstitute(visitToEducationalInstitution));
    }
  }

  createVisitToEducationalInstitute(visitToEducationalInstitution: VTRVisitToEducationalInstitutionModel): FormGroup {
    return this.formBuilder.group({
      EducationalInstitute: new FormControl({ value: visitToEducationalInstitution.EducationalInstitute, disabled: this.PageRights.IsReadOnly }),
      InstituteContactPerson: new FormControl({ value: visitToEducationalInstitution.InstituteContactPerson, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(100), Validators.pattern(this.Constants.Regex.CharsWithTitleCase)]),
      InstituteContactNumber: new FormControl({ value: visitToEducationalInstitution.InstituteContactNumber, disabled: this.PageRights.IsReadOnly }, Validators.pattern(this.Constants.Regex.MobileNumber)),
      InstituteVisitDetails: new FormControl({ value: visitToEducationalInstitution.InstituteVisitDetails, disabled: this.PageRights.IsReadOnly }),
    })
  }

  getHM() {
    this.HMMeetParticipantList = <HMMeetInvolvedModel[]>[]
    this.vtDailyReportingService.getHMByVTId({
      DataId: this.UserModel.UserTypeId,
    }).subscribe((response) => {

      if (response[0].Success) {

        this.HMMeetParticipantList.push({ Id: response[0].Result.HMId, Name: response[0].Result.FullName, IsPresent: false })
        this.HMMeetParticipantList.push({ Id: 'Other', Name: 'Other', IsPresent: false })

      }

    })
  }



  onEnterOtherMembers() {

    this.otherMemberList.push(this.vtDailyReportingForm.get('hmMeetingGroup.OtherMember').value)
    this.vtDailyReportingForm.get("hmMeetingGroup.OtherMember").setValue("");

    let memberCount = this.meetList.length + this.otherMemberList.length
        this.vtDailyReportingForm.get("hmMeetingGroup.MembersCount").setValue(memberCount);
  }

  removeOtherMembers(member) {

    const memberIndex = this.otherMemberList.indexOf(member);
    this.otherMemberList.splice(memberIndex, 1);

    let memberCount = this.meetList.length + this.otherMemberList.length
        this.vtDailyReportingForm.get("hmMeetingGroup.MembersCount").setValue(memberCount);
  }



  OnSelectMembers(event) {

    this.isAddHMMember = false;

    if (event.source._selected === true) {
      if (event.source.value === 'Other')
        this.isOtherSelected = true;
      else
        this.meetList.push(event.source.value);
    }
    else {
      const memberIndex = this.meetList.indexOf(event.source.value);
      this.meetList.splice(memberIndex, 1);
    }

    let memberCount = this.meetList.length + this.otherMemberList.length
        this.vtDailyReportingForm.get("hmMeetingGroup.MembersCount").setValue(memberCount);

  }

  onChangeIsMRawMaterialStatus(chk) {
    if (chk.value == '0')
      this.isRawMaterialBill = true;
    else {
      this.isRawMaterialBill = false;
      this.vtDailyReportingForm.get('communityWorkGroup.MaterialBillFile').setValue('')
    }
  }

  onChangeClassForTaught(formGroup, classId): void {
    if (classId != null) {
      formGroup.get('DidYouTeachToday').setValue(true);

      this.commonService.GetSectionsByVTClassId({ DataId: this.UserModel.UserTypeId, DataId1: classId }).subscribe(response => {
        if (response.Success) {
          this.sectionList = response.Results;
        }
      });

      let moduleItem = formGroup.get('ModuleId').value;
      if (moduleItem != null && moduleItem.Id != null) {
        this.onChangeCourseModule(null, 1, moduleItem);
      }
    }

    this.unitList[0] = <DropdownModel[]>[];
    this.sessionList = <DropdownModel[]>[];
    this.unitSessionsModels[0] = <ModuleUnitSessionModel[]>[];

    if (this.PageRights.ActionType != this.Constants.Actions.View) {
      formGroup.get("ClassSectionIds").setValue(null);
    }

    let studentAttendancesControls = <FormArray>formGroup.get('StudentAttendances');
    studentAttendancesControls.clear();
  }

  onChangeSectionForTaught(formGroup, sectionId) {
    let classId = ''
    if (sectionId != null) {
      if (this.isAllowHomeVisit === true) {
        classId = this.ClassId;
      } else {
        classId = formGroup.get("ClassTaughtId").value;
      }

      this.commonService.getStudentsByClassId({ DataId: this.UserModel.UserTypeId, DataId1: classId, DataId2: sectionId }).subscribe(response => {
        if (response.Success) {
          //let studentsForSection = this.studentList.filter(s => s.ClassId == classId && s.SectionId == sectionId);
          let studentAttendancesControls = <FormArray>formGroup.get('StudentAttendances');
          studentAttendancesControls.clear();

          response.Results.forEach(studentItem => {
            studentAttendancesControls.push(this.formBuilder.group(studentItem));
          });

          if(response.Results.length === 0){
            this.dialogService.openShowDialog("Please add students for Class");
            formGroup.get("ClassSectionIds").setValue(null);
          }
        }
      });
    }
    else {
      let studentAttendancesControls = <FormArray>formGroup.get('StudentAttendances');
      studentAttendancesControls.clear();
    }
  }

  getSectionByClassId(classId): any {
    return this.sectionByClassList.filter(s => s.ClassId == classId);
  }

  getUnitByClassAndModule(moduleId, classId): any {
    return this.unitSessionList.filter(s => s.ClassId == classId && s.ModuleTypeId == moduleId.Id);
  }

  onChangeCourseModule(formGroup, fgIndex, moduleItem): void {
    let classId = formGroup.get('ClassTaughtId').value;

    if (classId != '' && moduleItem.Id != null) {
      this.commonService.GetUnitsByClassAndModuleId({ DataId: classId, DataId1: moduleItem.Id, DataId2: this.UserModel.UserTypeId, SelectTitle: 'Unit Taught' }).subscribe((response: any) => {
        if (response.Success) {
          this.unitList[fgIndex] = response.Results;
          this.sessionList[fgIndex] = <DropdownModel[]>[];
        }
      });
    }
    else {
      this.unitList[fgIndex] = <DropdownModel[]>[];
      this.sessionList[fgIndex] = <DropdownModel[]>[];
    }
  }

  onChangeUnitsTaught(formGroup, fgIndex, unitItem): void {
    let classId = formGroup.get('ClassTaughtId').value;

    if (classId != '' && unitItem.Id != null) {
      this.commonService.GetSessionsByUnitId({ DataId: unitItem.Id, SelectTitle: 'Sessions Taught' }).subscribe((response: any) => {
        if (response.Success) {
          this.sessionList[fgIndex] = response.Results;
        }
      });
    }
    else {
      this.sessionList[fgIndex] = <DropdownModel[]>[];
    }
  }

  addUnitSession(fieldGroup, tveIndex) {
    let moduleCtrl = fieldGroup.get('ModuleId');
    let unitCtrl = fieldGroup.get('UnitId');
    let sessionIdsCtrl = fieldGroup.get('SessionsTaught');

    if (moduleCtrl.value !== '' && unitCtrl.value !== '' && sessionIdsCtrl.value !== '') {
      this.unitSessionsModels[tveIndex].push(
        new ModuleUnitSessionModel({
          ClassId: fieldGroup.get('ClassTaughtId').value,
          SectionId: fieldGroup.get('ClassSectionIds').value,
          ModuleId: moduleCtrl.value.Id,
          ModuleName: moduleCtrl.value.Name,
          UnitId: unitCtrl.value.Id,
          UnitName: unitCtrl.value.Name,
          SessionIds: sessionIdsCtrl.value.map(x => x.Id),
          SessionNames: sessionIdsCtrl.value.map(x => x.Name).join(',')
        })
      );

      moduleCtrl.setValue({ Id: null, Name: "Select Modules Taught", Description: "", SequenceNo: 1 });
      // unitCtrl.setValue('');
      // sessionIdsCtrl.setValue('');

      this.unitList[tveIndex] = <DropdownModel[]>[];
      this.sessionList[tveIndex] = <DropdownModel[]>[];
    }
  }

  removeUnitSession(tveIndex, sessionItem) {
    const sessionIndex = this.unitSessionsModels[tveIndex].indexOf(sessionItem);
    this.unitSessionsModels[tveIndex].splice(sessionIndex, 1);
  }



  onChangeClass(classId): Promise<any> {
    this.ClassId = classId;
    this.IsLoading = true;
    let promise = new Promise((resolve, reject) => {

      this.commonService.GetSectionsByVTClassId({ DataId: this.UserModel.UserTypeId, DataId1: classId }).subscribe(response => {
        if (response.Success) {
          this.sectionList = response.Results;
        }
      });
    });
    return promise;
  }

  onChangeSection(sectionId) {
    if (sectionId != null) {

      this.commonService.getStudentsByClassId({ DataId: this.UserModel.UserTypeId, DataId1: this.ClassId, DataId2: sectionId }).subscribe(response => {
        if (response.Success) {
          this.studentList = response.Results;
          this.filteredStudentList = this.studentList.slice()


        }
      });
    }

  }

  onChangeOfStudents(studentId) {

    this.vtDailyReportingForm.get('homeVisitGroup.StudentId').setValue(studentId)

    this.studentClassDetailService.getStudentClassDetailById(studentId)
      .subscribe((response: any) => {

        this.vtDailyReportingForm.get('homeVisitGroup.FatherName').setValue(response.Result.FatherName)
        this.vtDailyReportingForm.get('homeVisitGroup.MotherName').setValue(response.Result.MotherName)
        this.vtDailyReportingForm.get('homeVisitGroup.ContactNum').setValue(response.Result.Mobile)

      });
  }

  onChangeIsMSpracticalworkdoneStatus(chk) {

    if (chk.value == '0')
      this.isIsMSpracticalworkdoneStatus = true;
    else {
      this.isIsMSpracticalworkdoneStatus = false;
      this.vtDailyReportingForm.get('homeVisitGroup.ChildSharedDetails').setValue('')
    }
  }

  onChangeParentInterestStatus(chk) {

    if (chk.value == '0')
      this.isParentIntrestedEdu = true;
    else {
      this.isParentIntrestedEdu = false;
      this.vtDailyReportingForm.get('homeVisitGroup.KindOfEducation').setValue('')
    }
  }

  onChangeKeentoMoreStatus(chk) {
    if (chk.value == "0") this.isKeentoknow = true;
    else {
      this.isKeentoknow = false;
      this.vtDailyReportingForm
        .get("homeVisitGroup.Reaction")
        .setValue("");
    }
  }

  onChangeChronicalStatus(chk) {

    if (chk.value == '0')
      this.isChronical = true;
    else {
      this.isChronical = false;
      this.vtDailyReportingForm.get('homeVisitGroup.IllCount').setValue(0)
    }

  }
  onChangeCommunicationStatus(chk) {

    if (chk.value == '0')
      this.isParentCommunicatd = true;
    else {
      this.isParentCommunicatd = false;
      this.vtDailyReportingForm.get('homeVisitGroup.CommunicationDetails').setValue('')
    }

  }

  onChangeIsRawmaterialStatus(chk) {

    if (chk.value == 0) {
      this.vtDailyReportingForm.get('communityWorkGroup.IsRawMaterial').setValue(0)
    }
    else {
      this.vtDailyReportingForm.get('communityWorkGroup.IsRawMaterial').setValue(1)
    }

  }

  onChangeVTByClass(Vtid): Promise<any> {
    this.vtId = Vtid;
    this.IsLoading = true;
    let promise = new Promise((resolve, reject) => {

      this.commonService.GetMasterDataByType({ DataType: 'ClassesByVT', ParentId: this.UserModel.AcademicYearId, UserId: this.vtId, SelectTitle: 'Class' }).subscribe((response) => {
        if (response.Success) {

          this.classList = response.Results;
        }
        resolve(true);
      });
    });
    return promise;
  }

  onViewCommunityWork() {
    this.vtDailyReportingForm.get('communityWorkGroup.WorkDetails').setValue(this.vTReport.CommunityWork.WorkDetails)
    this.vtDailyReportingForm.get('communityWorkGroup.NoOfDays').setValue(this.vTReport.CommunityWork.NoOfDays)
    this.vtDailyReportingForm.get('communityWorkGroup.NoOfStudents').setValue(this.vTReport.CommunityWork.NoOfStudents)
    this.vtDailyReportingForm.get('communityWorkGroup.ClientName').setValue(this.vTReport.CommunityWork.ClientName)
    this.vtDailyReportingForm.get('communityWorkGroup.ClientAddress').setValue(this.vTReport.CommunityWork.ClientAddress)
    this.vtDailyReportingForm.get('communityWorkGroup.TotalExpense').setValue(this.vTReport.CommunityWork.TotalExpense)
    this.vtDailyReportingForm.get('communityWorkGroup.IsRawMaterial').setValue(this.vTReport.CommunityWork.IsRawMaterial == true ? "0" : "1")
    this.vtDailyReportingForm.get('communityWorkGroup.TotalProfit').setValue(this.vTReport.CommunityWork.TotalProfit)
    this.vtDailyReportingForm.get('communityWorkGroup.StudentsExperience').setValue(this.vTReport.CommunityWork.StudentsExperience)
    this.vtDailyReportingForm.get('communityWorkGroup.MaterialBillFile').setValue(this.vTReport.CommunityWork.MaterialBillFile)
    this.vtDailyReportingForm.get('communityWorkGroup.Description').setValue(this.vTReport.CommunityWork.Description)
  }


  onViewHomeVisit() {
    let homeVisit = this.vTReport.HomeVisitView
    this.vtDailyReportingForm.get('homeVisitGroup.DateOfVisit').setValue(this.datePipe.transform(homeVisit.DateOfVisit, 'yyyy-MM-dd'))
    // this.vtDailyReportingForm.get('homeVisitGroup.DateOfVisit').setValue(homeVisit.DateOfVisit)
    this.vtDailyReportingForm.get('homeVisitGroup.PurposeOfVisit').setValue(homeVisit.PurposeOfVisit)
    // this.vtDailyReportingForm.get('homeVisitGroup.UserId').setValue(homeVisit.VTId)     
    this.vtDailyReportingForm.get('homeVisitGroup.ClassTaughtId').setValue(homeVisit.ClassTaughtId)
    // this.vtDailyReportingForm.get('homeVisitGroup.ClassSectionIds').setValue(homeVisit.ClassSectionIds)
    this.vtDailyReportingForm.get('homeVisitGroup.StudentId').setValue(homeVisit.StudentId)
    this.vtDailyReportingForm.get('homeVisitGroup.AcademicYearId').setValue(homeVisit.AcademicYearId)
    this.vtDailyReportingForm.get('homeVisitGroup.FatherName').setValue(homeVisit.FatherName)
    this.vtDailyReportingForm.get('homeVisitGroup.MotherName').setValue(homeVisit.MotherName)
    this.vtDailyReportingForm.get('homeVisitGroup.FatherEducation').setValue(homeVisit.FatherEducation)
    this.vtDailyReportingForm.get('homeVisitGroup.MotherEducation').setValue(homeVisit.MotherEducation)
    this.vtDailyReportingForm.get('homeVisitGroup.Village').setValue(homeVisit.Village)
    this.vtDailyReportingForm.get('homeVisitGroup.LandMark').setValue(homeVisit.Landmark)
    this.vtDailyReportingForm.get('homeVisitGroup.ContactNum').setValue(homeVisit.ContactNum)
    this.vtDailyReportingForm.get('homeVisitGroup.MotherAge').setValue(homeVisit.MotherAge)
    this.vtDailyReportingForm.get('homeVisitGroup.FatherAge').setValue(homeVisit.FatherAge)
    this.vtDailyReportingForm.get('homeVisitGroup.StudentMSFC').setValue(homeVisit.StudentMSFC)
    this.vtDailyReportingForm.get('homeVisitGroup.MainSouce').setValue(homeVisit.MainSouce)
    this.vtDailyReportingForm.get('homeVisitGroup.AdditionalSource').setValue(homeVisit.AdditionalSource)
    this.vtDailyReportingForm.get('homeVisitGroup.Generation').setValue(homeVisit.Generation)
    this.vtDailyReportingForm.get('homeVisitGroup.SibilingsCount').setValue(homeVisit.SibilingsCount)
    this.vtDailyReportingForm.get('homeVisitGroup.SilbilingType').setValue(homeVisit.SilbilingType)
    this.vtDailyReportingForm.get('homeVisitGroup.IsParentAware').setValue(homeVisit.IsParentAware == true ? "0" : "1")
    this.vtDailyReportingForm.get('homeVisitGroup.IsMSChildSpoketoParent').setValue(homeVisit.IsMSChildSpoketoParent == true ? "0" : "1")
    this.vtDailyReportingForm.get('homeVisitGroup.IsMSpracticalworkdone').setValue(homeVisit.IsMSpracticalworkdone == true ? "0" : "1")
    if (homeVisit.IsMSpracticalworkdone == true) {
      this.isIsMSpracticalworkdoneStatus = true
    }
    this.vtDailyReportingForm.get('homeVisitGroup.ChildSharedDetails').setValue(homeVisit.ChildSharedDetails)
    this.vtDailyReportingForm.get('homeVisitGroup.IsParentWilling').setValue(homeVisit.IsParentWilling == true ? "0" : "1")
    if (homeVisit.IsParentWilling == true) {
      this.isParentIntrestedEdu = true
    }
    this.vtDailyReportingForm.get('homeVisitGroup.KindOfEducation').setValue(homeVisit.KindOfEducation)
    this.vtDailyReportingForm.get('homeVisitGroup.StudentDropOut').setValue(homeVisit.StudentDropOut)
    this.vtDailyReportingForm.get('homeVisitGroup.DropoutReason').setValue(homeVisit.DropoutReason)
    this.vtDailyReportingForm.get('homeVisitGroup.IsSchoolingAwareness').setValue(homeVisit.IsSchoolingAwareness == true ? "0" : "1")
    if (homeVisit.IsSchoolingAwareness == true) {
      this.isKeentoknow = true
    }
    this.vtDailyReportingForm.get('homeVisitGroup.DetailsKnown').setValue(homeVisit.DetailsKnown)
    this.vtDailyReportingForm.get('homeVisitGroup.Reaction').setValue(homeVisit.Reaction)
    this.vtDailyReportingForm.get('homeVisitGroup.IsChronicallyIll').setValue(homeVisit.IsChronicallyIll == true ? "0" : "1")
    if (homeVisit.IsChronicallyIll == true) {
      this.isChronical = true
    }
    this.vtDailyReportingForm.get('homeVisitGroup.IllCount').setValue(homeVisit.IllCount)
    this.vtDailyReportingForm.get('homeVisitGroup.IsParentCommunicate').setValue(homeVisit.IsParentCommunicate == true ? "0" : "1")
    if (homeVisit.IsParentCommunicate == true) {
      this.isParentCommunicatd = true
    }
    this.vtDailyReportingForm.get('homeVisitGroup.CommunicationDetails').setValue(homeVisit.CommunicationDetails)
    this.vtDailyReportingForm.get('homeVisitGroup.ImportantDetails').setValue(homeVisit.ImportantDetails)
    this.vtDailyReportingForm.get('homeVisitGroup.Remarks').setValue(homeVisit.Remarks)
    this.vtDailyReportingForm.get('homeVisitGroup.Hygienerate').setValue(homeVisit.Hygienerate)


  }

  resetReportTypeFormGroups(): void {
    this.isAllowTeachingVocationalEducation = false;
    this.isAllowTrainingOfTeacher = false;
    this.isAllowOnJobTrainingCoordination = false;
    this.isAllowAssessorInOtherSchool = false;
    this.isAllowParentTeacherMeeting = false;
    this.isAllowSchoolEventCelebration = false;
    this.isAllowCommunityHomeVisit = false;
    this.isAllowIndustryVisit = false;
    this.isAllowVisitToEducationalInstitute = false;
    this.isAllowAssignmentFromVocationalDepartment = false;
    this.isAllowTeachingNonVocationalSubject = false;
    this.isAllowWorkAssignedByHeadMaster = false;
    this.isAllowSchoolExamDuty = false;
    this.isAllowOtherWork = false;
    this.isAllowHoliday = false;
    this.isAllowObservationDay = false;
    this.isAllowLeave = false;
    this.isAllowHMmeet = false;
    this.isAllowCommunityWork = false;
    this.isAllowHomeVisit = false;
    this.isAddHMMember = false;
    this.isRawMaterialBill = false;



    if (this.PageRights.ActionType != this.Constants.Actions.View) {
      delete this.vtDailyReportingForm.controls['teachingVocationalEducationGroup'];
      delete this.vtDailyReportingForm.value['teachingVocationalEducationGroup'];
      delete this.vtDailyReportingForm.controls['trainingOfTeacherGroup'];
      delete this.vtDailyReportingForm.value['trainingOfTeacherGroup'];
      delete this.vtDailyReportingForm.controls['onJobTrainingCoordinationGroup'];
      delete this.vtDailyReportingForm.value['onJobTrainingCoordinationGroup'];
      delete this.vtDailyReportingForm.controls['assessorInOtherSchoolGroup'];
      delete this.vtDailyReportingForm.value['assessorInOtherSchoolGroup'];
      delete this.vtDailyReportingForm.controls['schoolEventCelebrationGroup'];
      delete this.vtDailyReportingForm.value['schoolEventCelebrationGroup'];
      delete this.vtDailyReportingForm.controls['parentTeacherMeetingGroup'];
      delete this.vtDailyReportingForm.value['parentTeacherMeetingGroup'];
      delete this.vtDailyReportingForm.controls['communityHomeVisitGroup'];
      delete this.vtDailyReportingForm.value['communityHomeVisitGroup'];
      delete this.vtDailyReportingForm.controls['industryVisitGroup'];
      delete this.vtDailyReportingForm.value['industryVisitGroup'];
      delete this.vtDailyReportingForm.controls['visitToEducationalInstituteGroup'];
      delete this.vtDailyReportingForm.value['visitToEducationalInstituteGroup'];
      delete this.vtDailyReportingForm.controls['assignmentFromVocationalDepartmentGroup'];
      delete this.vtDailyReportingForm.value['assignmentFromVocationalDepartmentGroup'];
      delete this.vtDailyReportingForm.controls['teachingNonVocationalSubjectGroup'];
      delete this.vtDailyReportingForm.value['teachingNonVocationalSubjectGroup'];
      delete this.vtDailyReportingForm.controls['workAssignedByHeadMasterGroup'];
      delete this.vtDailyReportingForm.value['workAssignedByHeadMasterGroup'];
      delete this.vtDailyReportingForm.controls['schoolExamDutyGroup'];
      delete this.vtDailyReportingForm.value['schoolExamDutyGroup'];
      delete this.vtDailyReportingForm.controls['otherWorkGroup'];
      delete this.vtDailyReportingForm.value['otherWorkGroup'];
      delete this.vtDailyReportingForm.controls['leaveGroup'];
      delete this.vtDailyReportingForm.value['leaveGroup'];
      delete this.vtDailyReportingForm.controls['holidayGroup'];
      delete this.vtDailyReportingForm.value['holidayGroup'];
      delete this.vtDailyReportingForm.controls['observationDayGroup'];
      delete this.vtDailyReportingForm.value['observationDayGroup'];
      delete this.vtDailyReportingForm.controls['WorkingDayTypeIds'];
      delete this.vtDailyReportingForm.value['WorkingDayTypeIds'];

      delete this.vtDailyReportingForm.controls['hmMeetingGroup'];
      delete this.vtDailyReportingForm.value['hmMeetingGroup'];
      delete this.vtDailyReportingForm.value['communityWorkGroup'];
      delete this.vtDailyReportingForm.value['communityWorkGroup'];
      delete this.vtDailyReportingForm.value['homeVisitGroup'];
      delete this.vtDailyReportingForm.value['homeVisitGroup'];

      let initialFormValues = this.vtDailyReportingForm.value;
      this.vtDailyReportingForm.reset(initialFormValues);
    }
  }

  resetWorkTypesFormGroups(): void {
    this.isAllowTeachingVocationalEducation = false;
    this.isAllowTrainingOfTeacher = false;
    this.isAllowOnJobTrainingCoordination = false;
    this.isAllowAssessorInOtherSchool = false;
    this.isAllowParentTeacherMeeting = false;
    this.isAllowSchoolEventCelebration = false;
    this.isAllowCommunityHomeVisit = false;
    this.isAllowIndustryVisit = false;
    this.isAllowVisitToEducationalInstitute = false;
    this.isAllowAssignmentFromVocationalDepartment = false;
    this.isAllowTeachingNonVocationalSubject = false;
    this.isAllowWorkAssignedByHeadMaster = false;
    this.isAllowSchoolExamDuty = false;
    this.isAllowOtherWork = false;
    this.isAllowHMmeet = false;
    this.isAllowCommunityWork = false;
    this.isAllowHomeVisit = false;
    this.isAddHMMember = false;
    this.isRawMaterialBill = false;


    if (this.PageRights.ActionType != this.Constants.Actions.View) {
      delete this.vtDailyReportingForm.controls['teachingVocationalEducationGroup'];
      delete this.vtDailyReportingForm.value['teachingVocationalEducationGroup'];
      delete this.vtDailyReportingForm.controls['trainingOfTeacherGroup'];
      delete this.vtDailyReportingForm.value['trainingOfTeacherGroup'];
      delete this.vtDailyReportingForm.controls['onJobTrainingCoordinationGroup'];
      delete this.vtDailyReportingForm.value['onJobTrainingCoordinationGroup'];
      delete this.vtDailyReportingForm.controls['assessorInOtherSchoolGroup'];
      delete this.vtDailyReportingForm.value['assessorInOtherSchoolGroup'];
      delete this.vtDailyReportingForm.controls['schoolEventCelebrationGroup'];
      delete this.vtDailyReportingForm.value['schoolEventCelebrationGroup'];
      delete this.vtDailyReportingForm.controls['parentTeacherMeetingGroup'];
      delete this.vtDailyReportingForm.value['parentTeacherMeetingGroup'];
      delete this.vtDailyReportingForm.controls['communityHomeVisitGroup'];
      delete this.vtDailyReportingForm.value['communityHomeVisitGroup'];
      delete this.vtDailyReportingForm.controls['industryVisitGroup'];
      delete this.vtDailyReportingForm.value['industryVisitGroup'];
      delete this.vtDailyReportingForm.controls['visitToEducationalInstituteGroup'];
      delete this.vtDailyReportingForm.value['visitToEducationalInstituteGroup'];
      delete this.vtDailyReportingForm.controls['assignmentFromVocationalDepartmentGroup'];
      delete this.vtDailyReportingForm.value['assignmentFromVocationalDepartmentGroup'];
      delete this.vtDailyReportingForm.controls['teachingNonVocationalSubjectGroup'];
      delete this.vtDailyReportingForm.value['teachingNonVocationalSubjectGroup'];
      delete this.vtDailyReportingForm.controls['workAssignedByHeadMasterGroup'];
      delete this.vtDailyReportingForm.value['workAssignedByHeadMasterGroup'];
      delete this.vtDailyReportingForm.controls['schoolExamDutyGroup'];
      delete this.vtDailyReportingForm.value['schoolExamDutyGroup'];
      delete this.vtDailyReportingForm.controls['otherWorkGroup'];
      delete this.vtDailyReportingForm.value['otherWorkGroup'];
      delete this.vtDailyReportingForm.controls['hmMeetingGroup'];
      delete this.vtDailyReportingForm.value['hmMeetingGroup'];
      delete this.vtDailyReportingForm.value['communityWorkGroup'];
      delete this.vtDailyReportingForm.value['communityWorkGroup'];

      let initialFormValues = this.vtDailyReportingForm.value;
      this.vtDailyReportingForm.reset(initialFormValues);
    }
  }

  uploadedClassPhotoFile(formGroup, event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        formGroup.get('ClassPictureFile').setValue(null);
        this.dialogService.openShowDialog("Please upload image file only.");
        return;
      }

      this.getUploadedFileData(event, this.Constants.DocumentType.VTReporting).then((response: FileUploadModel) => {
        this.classPictureFile = response;

        formGroup.get('IsClassPictureFile').setValue(false);
        this.setMandatoryFieldControl(formGroup, 'ClassPictureFile', this.Constants.DefaultImageState);
      });
    }
  }

  uploadedLessonPlanPhotoFile(formGroup, event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        formGroup.get('LessonPlanPictureFile').setValue(null);
        this.dialogService.openShowDialog("Please upload image file only.");
        return;
      }

      this.getUploadedFileData(event, this.Constants.DocumentType.VTReporting).then((response: FileUploadModel) => {
        this.lessonPlanPictureFile = response;

        formGroup.get('IsLessonPlanPictureFile').setValue(false);
        this.setMandatoryFieldControl(formGroup, 'LessonPlanPictureFile', this.Constants.DefaultImageState);
      });
    }
  }

  uploadedHomeVisitPictureFile(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.vtDailyReportingForm.controls.homeVisitGroup.get('HomeVisitPictureFile').setValue(null);
        this.dialogService.openShowDialog("Please upload image file only.");
        return;
      }

      this.getUploadedFileData(event, this.Constants.DocumentType.VTReporting).then((response: FileUploadModel) => {
        this.HomeVisitPictureFile = response;

        this.vtDailyReportingForm.controls.homeVisitGroup.get('IsHomeVisitPictureFile').setValue(false);
        this.setMandatoryFieldControl(this.vtDailyReportingForm.controls.homeVisitGroup as FormGroup, 'HomeVisitPictureFile', this.Constants.DefaultImageState);
      });
    }
  }

  uploadedAssignmentPhotoFile(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.vtDailyReportingForm.controls.assignmentFromVocationalDepartmentGroup.get('AssignmentPhotoFile').setValue(null);
        this.dialogService.openShowDialog("Please upload image file only.");
        return;
      }

      this.getUploadedFileData(event, this.Constants.DocumentType.VTReporting).then((response: FileUploadModel) => {
        this.assignmentPhotoFile = response;

        this.vtDailyReportingForm.controls.assignmentFromVocationalDepartmentGroup.get('IsAssignmentPhotoFile').setValue(false);
        this.setMandatoryFieldControl(this.vtDailyReportingForm.controls.assignmentFromVocationalDepartmentGroup as FormGroup, 'AssignmentPhotoFile', this.Constants.DefaultImageState);
      });
    }
  }

  uploadedBillsPhotoFile(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.vtDailyReportingForm.controls.communityWorkGroup.get('MaterialBillFile').setValue(null);
        this.dialogService.openShowDialog("Please upload image file only.");
        return;
      }

      this.getUploadedFileData(event, this.Constants.DocumentType.VTReporting).then((response: FileUploadModel) => {
        this.MaterialBillFile = response;

      });
    }
  }
  saveOrUpdateVTDailyReportingDetails() {
    if (!this.vtDailyReportingForm.valid) {
      this.validateDynamicFormArrayFields(this.vtDailyReportingForm);
      return;
    }
    if (!this.onChangeReportingDate()) {
      return;
    }

    let workingDayTypeControl = this.vtDailyReportingForm.get('WorkingDayTypeIds');
    if (workingDayTypeControl && workingDayTypeControl.value) {
   
      let hasTeachingVocationalEducation = workingDayTypeControl.value.includes('Teaching - Vocational Education');
      if (!hasTeachingVocationalEducation) {
        let has101 = workingDayTypeControl.value.includes('101');
        if (has101 && this.unitSessionsModels[0].length === 0) {
          this.dialogService.openShowDialog("Please add course module taught first!");
          return;
        }
      } else {
        if (this.unitSessionsModels[0].length === 0) {
          this.dialogService.openShowDialog("Please add course module taught first!");
          return;
        }
      }
    }
    // if (workingDayTypeControl != null && workingDayTypeControl.value != null) {

    //   let findvalue = this.vtDailyReportingForm.get('WorkingDayTypeIds').value[0]

    //   if (findvalue != undefined && this.unitSessionsModels[0].length == 0) {

    //     this.dialogService.openShowDialog("Please add course module taught first!");
    //     return;
    //   }
    // }

    this.vtDailyReportingModel = this.vtDailyReportingService.getVTDailyReportingModelFromFormGroup(this.vtDailyReportingForm);
    this.vtDailyReportingModel.VTId = this.UserModel.UserTypeId;

    if (this.vtDailyReportingModel.TeachingVocationalEducations.length > 0) {
      this.vtDailyReportingModel.TeachingVocationalEducations[0].UnitSessionsModels = this.unitSessionsModels[0];
      this.vtDailyReportingModel.TeachingVocationalEducations[0].ClassPictureFile = (this.classPictureFile.Base64Data != '' ? this.setUploadedFile(this.classPictureFile) : null);
      this.vtDailyReportingModel.TeachingVocationalEducations[0].LessonPlanPictureFile = (this.lessonPlanPictureFile.Base64Data != '' ? this.setUploadedFile(this.lessonPlanPictureFile) : null);
    }
    else {
      delete this.vtDailyReportingModel['TeachingVocationalEducations'];
    }


    if (this.vtDailyReportingModel.CommunityWork != undefined) {
      this.vtDailyReportingModel.CommunityWork.MaterialBillFile =
        this.MaterialBillFile.Base64Data != ""
          ? this.setUploadedFile(this.MaterialBillFile)
          : null;
    } else {
      delete this.vtDailyReportingModel["CommunityWork"];
    }

    if (
      this.vtDailyReportingModel.AssignmentFromVocationalDepartment !=
      undefined
    ) {
      this.vtDailyReportingModel.AssignmentFromVocationalDepartment.AssignmentPhotoFile =
        this.assignmentPhotoFile.Base64Data != ""
          ? this.setUploadedFile(this.assignmentPhotoFile)
          : null;
    }

    if (
      this.vtDailyReportingModel.HomeVisit !=
      undefined
    ) {
      this.vtDailyReportingModel.HomeVisit.HomeVisitPictureFile =
        this.HomeVisitPictureFile.Base64Data != ""
          ? this.setUploadedFile(this.HomeVisitPictureFile)
          : null;
    }
    else {
        delete this.vtDailyReportingModel['HomeVisits'];
        }

    if (this.vtDailyReportingModel.HMMeeting != undefined) {
      if (this.isOtherSelected) {
        this.otherMemberList.forEach(element => {
          this.meetList.push(element)
        });
      }
      this.vtDailyReportingModel.HMMeeting.Members = this.meetList;
    } else {
      delete this.vtDailyReportingModel["HMMeeting"];
    }

    (this.vtDailyReportingModel.WorkingDayTypeIds).forEach(element => {
      let result = this.workingDayTypeIdsList.find(ele => ele.Name === element)
      console.log("REsult",result)  
      
      const index = (this.vtDailyReportingModel.WorkingDayTypeIds).indexOf(element); 

      this.vtDailyReportingModel.WorkingDayTypeIds.splice(index, 1, result.Id);
  });


    this.vtDailyReportingService.createOrUpdateVTDailyReporting(this.vtDailyReportingModel)
      .subscribe((vtDailyReportingResp: any) => {

        if (vtDailyReportingResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.VTDailyReporting.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(vtDailyReportingResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('VTDailyReporting deletion errors =>', error);
      });
  }

  //Create vtDailyReporting form and returns {FormGroup}
  createVTDailyReportingForm(): FormGroup {
    return this.formBuilder.group({
      VTDailyReportingId: new FormControl(this.vtDailyReportingModel.VTDailyReportingId),
      ReportingDate: new FormControl({ value: new Date(this.vtDailyReportingModel.ReportingDate), disabled: this.PageRights.IsReadOnly }),
      ReportType: new FormControl({ value: this.vtDailyReportingModel.ReportType, disabled: this.PageRights.IsReadOnly }, Validators.required),
      WorkingDayTypeIds: new FormControl({ value: this.vtDailyReportingModel.WorkingDayTypeIds, disabled: this.PageRights.IsReadOnly }),
    });
  }

  private onChangeTrainingOfTeacherType() {
    this.vtDailyReportingForm.controls.trainingOfTeacherGroup.get('TrainingTypeId').valueChanges
      .subscribe(data => {

        if (data == "126") {
          this.vtDailyReportingForm.controls.trainingOfTeacherGroup.get("TrainingBy").setValidators([Validators.required, Validators.maxLength(100)]);
          this.vtDailyReportingForm.controls.trainingOfTeacherGroup.get("TrainingTopicIds").clearValidators();
        }
        else if (data == "124" || data == "125") {
          this.vtDailyReportingForm.controls.trainingOfTeacherGroup.get("TrainingTopicIds").setValidators(Validators.required);
          this.vtDailyReportingForm.controls.trainingOfTeacherGroup.get("TrainingBy").clearValidators();
        }

        this.vtDailyReportingForm.controls.trainingOfTeacherGroup.get("TrainingTopicIds").updateValueAndValidity();
        this.vtDailyReportingForm.controls.trainingOfTeacherGroup.get("TrainingBy").updateValueAndValidity();
      });
  }

  private onChangeOnjobTrainingCoordinationType() {
    this.vtDailyReportingForm.get("OnJobTrainingActivityId").valueChanges
      .subscribe(data => {

        if (data == "139") {
          this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryName").setValidators(Validators.required);
          this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryContactPerson").setValidators([Validators.required, Validators.maxLength(100), Validators.pattern(this.Constants.Regex.CharsWithTitleCase)]);
          this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryContactNumber").setValidators([Validators.required, Validators.pattern(this.Constants.Regex.MobileNumber)]);
        }
        else if (data == "140") {
          this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryName").setValidators(Validators.required);

          this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryContactPerson").clearValidators();
          this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryContactNumber").clearValidators();
        }

        this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryName").updateValueAndValidity();
        this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryContactPerson").updateValueAndValidity();
        this.vtDailyReportingForm.controls.onJobTrainingCoordinationGroup.get("IndustryContactNumber").updateValueAndValidity();
      });
  }

  private onChangeLeaveApprovalStatus() {
    this.vtDailyReportingForm.controls.leaveGroup.get('LeaveApprovalStatus').valueChanges
      .subscribe(leaveApprovalStatusId => {

        if (leaveApprovalStatusId == 'Yes') {
          this.vtDailyReportingForm.controls.leaveGroup.get("LeaveApprover").setValidators([Validators.required]);
        }
        else {
          this.vtDailyReportingForm.controls.leaveGroup.get("LeaveApprover").clearValidators();
        }

        this.vtDailyReportingForm.controls.leaveGroup.get("LeaveApprover").updateValueAndValidity();
      });

  }
}
