export class VTCommnityWorkModel {
    ClassTaughtId: string;
    WorkDetails: string;
    NoOfDays: string;
    NoOfStudents: number;
    ClientName: string;
    ClientAddress: string; 
    TotalExpense: number;
    IsRawMaterial: any;
    TotalProfit: string;
    StudentsExperience: number;
    MaterialBillFile: any;
    Description: string;
       
    constructor(vtDailyReportingItem?: any) {
        vtDailyReportingItem = vtDailyReportingItem || {};

        this.ClassTaughtId = vtDailyReportingItem.ClassTaughtId || '';
        this.WorkDetails = vtDailyReportingItem.WorkDetails || '';
        this.NoOfDays = vtDailyReportingItem.NoOfDays || '';
        this.NoOfStudents = vtDailyReportingItem.NoOfStudents || '';
        this.ClientName = vtDailyReportingItem.ClientName || '';
        this.ClientAddress = vtDailyReportingItem.ClientAddress || '';
        this.TotalExpense = vtDailyReportingItem.TotalExpense || '';
        this.IsRawMaterial = vtDailyReportingItem.IsRawMaterial || '';
        this.TotalProfit = vtDailyReportingItem.TotalProfit || '';
        this.MaterialBillFile = vtDailyReportingItem.MaterialBillFile || '';
        this.StudentsExperience = vtDailyReportingItem.StudentsExperience || '';
        this.Description = vtDailyReportingItem.Description || '';
}
}
