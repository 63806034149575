<div id="hm-issue-reporting" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/events'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.ActionType ==='Edit'">
                       <span>New Event Details</span>
                       
                    </div>
                    <div class="h2" *ngIf="PageRights.ActionType ==='New'">
                        <span>New Event Details</span>
                    </div>
                    <!-- <div class="subtitle secondary-text">
                        <span> New Next month Plan</span>
                    </div> -->
                    <div class="subtitle secondary-text">
                        <span> New Event</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-hm-issue-reporting-button" *ngIf="PageRights.ActionType ==='new'" [disabled]="newEventForm.invalid"
                (click)="saveOrUpdateEventDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-hm-issue-reporting-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateEventDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="newEventForm" [formGroup]="newEventForm" class="hm-issue-reporting w-100-p p-24 mr-24"
                    fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Event Date</mat-label>

                            <input matInput name="eventDate" formControlName="EventDate" required
                            [matDatepicker]="reportDate" >
                        <mat-datepicker-toggle matSuffix [for]="reportDate"></mat-datepicker-toggle>
                        <mat-datepicker #reportDate></mat-datepicker>
                        <mat-error *ngIf="newEventForm.controls.EventDate.invalid">
                            {{getErrorMessage(newEventForm, 'EventDate')}}
                        </mat-error>
                        </mat-form-field>

                    

                        <mat-form-field appearance="outline" fxFlex="75" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Events</mat-label>
                            <mat-select name="eventDetails" formControlName="EventDetails" appearance="outline" required
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeEvent($event.value)">
                                <mat-option [value]="event.Id" *ngFor="let event of EventList">
                                    {{ event.Value }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="newEventForm.controls.EventDetails.invalid">
                                {{getErrorMessage(newEventForm, 'EventDetails')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="isOtherSelected">
                        <mat-form-field appearance="outline" fxFlex="75" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Other Event</mat-label>
                            <input matInput name="other" formControlName="Other" placeholder="Other Events" required
                                (keypress)="onChangeOtherEvent($event)">
                        </mat-form-field>


                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="75" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Case Study</mat-label>
                            <textarea matInput name="CaseStudy"
                                        formControlName="CaseStudy" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25"></textarea>
                            
                        </mat-form-field>


                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" >
                        <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Event File</mat-label>
                            <ngx-mat-file-input name="eventFileUpload" formControlName="EventFileUpload"
                                [accept]="AllowedImageExtensions" (change)="uploadedEventFile($event)"
                                [disabled]="PageRights.IsReadOnly"></ngx-mat-file-input>
                            <mat-icon matSuffix>folder</mat-icon>
                            <!-- <mat-error *ngIf="EventPhoto.invalid">
                                {{getErrorMessage('EventPhoto')}}
                            </mat-error> -->
                        </mat-form-field>
                        
                    </div>

                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>