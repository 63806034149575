<div id="vocational-coordinator" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    {{'VC.VC' | translate }}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form name="vcSearchForm" [formGroup]="vcSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input name="searchText" formControlName="SearchText"
                            placeholder="Search for Vocational Coordinators"
                            matTooltip="Search By VTP, VC Name & EmailId" matTooltipPosition='above'
                            matTooltipClass="allow-cr">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                    <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                </a>

                <button mat-raised-button class="fuse-white filter-button"
                    (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span>
                </button>

                <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true"
                    replaceUrl="false" class="add-vocational-coordinator-button fuse-white mt-24 mt-md-0">
                    <span>{{'VC.ADD_NEW_VC' | translate}}</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="vcFilterForm" [formGroup]="vcFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="20" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Academic Year</mat-label>

                            <mat-select name="academicYearId" formControlName="AcademicYearId" appearance="fill">
                                <mat-option [value]="academicYearIdItem.Id"
                                    *ngFor="let academicYearIdItem of academicYearList">
                                    {{ academicYearIdItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Vocational Training Provider</mat-label>
                            <mat-select name="vtpId" formControlName="VTPId" appearance="fill" #SelectVTP>
                                <mat-select-filter class="select-filter" *ngIf="SelectVTP.focused" [array]="vtpList"
                                    (filteredReturn)="this.filteredVTPItems = $event" [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of filteredVTPItems">
                                    {{ vtpItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Nature Of Appointment</mat-label>
                            <mat-select name="natureOfAppointmentId" formControlName="NatureOfAppointmentId"
                                appearance="fill" filteredVTPItems>
                                <mat-option [value]="noaItem.Id" *ngFor="let noaItem of natureOfAppointmentList">
                                    {{ noaItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="10" fxFlex.lt-md="10" fxFlex.lt-sm="100">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" formControlName="Status" appearance="fill">
                                <mat-option value="">Status</mat-option>
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadVocationalCoordinatorsByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="vocational-coordinator-table responsive-table" #table
                #exporter="matTableExporter" [hiddenColumns]="[8]" [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- NatureOfAppointment Column -->
                <ng-container matColumnDef="NatureOfAppointment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Nature Of Appointment
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalCoordinator.NatureOfAppointment}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTPName Column -->
                <ng-container matColumnDef="VTPName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTP Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator">
                        <p class="text-truncate">{{vocationalCoordinator.VTPName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- FullName Column -->
                <ng-container matColumnDef="FullName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Full Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator">
                        <p class="text-truncate">{{vocationalCoordinator.FullName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Mobile Column -->
                <ng-container matColumnDef="Mobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>First Mobile Number
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalCoordinator.Mobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- EmailId Column -->
                <ng-container matColumnDef="EmailId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Email</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalCoordinator.EmailId}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Gender</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator">
                        <p class="text-truncate">{{vocationalCoordinator.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfJoining Column -->
                <ng-container matColumnDef="DateOfJoining">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Joining
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{vocationalCoordinator.DateOfJoining | date: Constants.ShortDateFormat
                            }}</p>
                    </mat-cell>
                </ng-container>

                <!-- IsResigned Column -->
                <ng-container matColumnDef="IsResigned">
                    <mat-header-cell *matHeaderCellDef class="mat-header-center">Is Resigned?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" class="text-center">
                        <span>{{ (vocationalCoordinator.IsResigned ? 'Yes' : 'No') }}</span>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef class="mat-header-center">Is Active?</mat-header-cell>
                    <mat-cell *matCellDef="let vocationalCoordinator" class="text-center">
                        <mat-icon *ngIf="vocationalCoordinator.IsActive" class="data-active s-20">check
                        </mat-icon>
                        <mat-icon *ngIf="!vocationalCoordinator.IsActive" class="data-inactive s-20">close
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs class="mat-header-center">Actions</mat-header-cell>

                    <mat-cell *matCellDef="let vocationalCoordinator" fxShow.gt-xs class="text-center">
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/vocational-coordinators', 'view', vocationalCoordinator.AcademicYearId, vocationalCoordinator.VTPId, vocationalCoordinator.VCId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-view s-22">visibility</mat-icon>
                        </button>
                        <button
                            *ngIf="((PageRights.IsEdit && vocationalCoordinator.IsActive) || UserModel.RoleCode == 'SUR')"
                            mat-icon-button
                            [routerLink]="['/vocational-coordinators', 'edit', vocationalCoordinator.AcademicYearId, vocationalCoordinator.VTPId, vocationalCoordinator.VCId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-edit s-20">edit</mat-icon>
                        </button>
                        <button
                            *ngIf="((PageRights.IsDelete && vocationalCoordinator.IsActive) || UserModel.RoleCode == 'SUR')"
                            mat-icon-button color="warn"
                            (click)="onDeleteVocationalCoordinator(vocationalCoordinator.AcademicYearId, vocationalCoordinator.VTPId, vocationalCoordinator.VCId)">
                            <mat-icon class="data-delete s-22">delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let vocationalCoordinator; columns: displayedColumns;"
                    class="vocational-coordinator" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>