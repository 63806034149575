import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { UserModel } from 'app/models/user.model';
import { CommonService } from 'app/services/common.service';
import { FormGroup } from '@angular/forms';
import { VTFieldIndustryVisitConductedModel } from './vt-field-industry-visit-conducted.model';

@Injectable()
export class VTFieldIndustryVisitConductedService {
    constructor(private http: CommonService) { }

    getVTFieldIndustryVisitConducteds(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.VTFieldIndustryVisitConducted.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.VTFieldIndustryVisitConducted.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getVTFieldIndustryVisitConductedById(vtFieldIndustryVisitConductedId: string) {
        let requestParams = {
            DataId: vtFieldIndustryVisitConductedId
        };

        return this.http
            .HttpPost(this.http.Services.VTFieldIndustryVisitConducted.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateVTFieldIndustryVisitConducted(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VTFieldIndustryVisitConducted.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteVTFieldIndustryVisitConductedById(vtFieldIndustryVisitConductedId: string) {
        var vtFieldIndustryVisitConductedParams = {
            DataId: vtFieldIndustryVisitConductedId
        };

        return this.http
            .HttpPost(this.http.Services.VTFieldIndustryVisitConducted.Delete, vtFieldIndustryVisitConductedParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getStudentList(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.StudentClass.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getDropdownForVTFieldIndustry(currentUser: UserModel): Observable<any[]> {
        let classRequest = this.http.GetClassesByVTId({ DataId: currentUser.LoginId, DataId1: currentUser.UserTypeId, SelectTitle: 'Class' });
        let moduleRequest = this.http.GetMasterDataByType({ DataType: 'CourseModules', SelectTitle: 'Modules Taught' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6        
        return forkJoin([classRequest, moduleRequest]);
    }

    // getFieldIndustryVisitModelFromFormGroup(formGroup: FormGroup): VTFieldIndustryVisitConductedModel {
    //     let fieldIndustryVisitModel = new VTFieldIndustryVisitConductedModel();

    //     fieldIndustryVisitModel.ClassTaughtId = formGroup.get("ClassTaughtId").value;
    //     fieldIndustryVisitModel.ReportingDate = this.http.getDateTimeFromControl(formGroup.get("ReportingDate").value);
    //     fieldIndustryVisitModel.SectionIds = formGroup.get("SectionIds").value;
    //     fieldIndustryVisitModel.FieldVisitTheme = formGroup.get("FieldVisitTheme").value;
    //     fieldIndustryVisitModel.FieldVisitActivities = formGroup.get("FieldVisitActivities").value;
    //     fieldIndustryVisitModel.FVOrganisation = formGroup.get("FVOrganisation").value;
    //     fieldIndustryVisitModel.FVOrganisationAddress = formGroup.get("FVOrganisationAddress").value;
    //     fieldIndustryVisitModel.FVDistance = formGroup.get("FVDistance").value;
    //     //fieldIndustryVisitModel.FVPicture = formGroup.get("FVPhoto").value;
    //     fieldIndustryVisitModel.FVContactPersonName = formGroup.get("FVContactPersonName").value;
    //     fieldIndustryVisitModel.FVContactPersonMobile = formGroup.get("FVContactPersonMobile").value;
    //     fieldIndustryVisitModel.FVContactPersonEmail = formGroup.get("FVContactPersonEmail").value;
    //     fieldIndustryVisitModel.FVContactPersonDesignation = formGroup.get("FVContactPersonDesignation").value;
    //     fieldIndustryVisitModel.FVOrganisationInterestStatus = formGroup.get("FVOrganisationInterestStatus").value;
    //     fieldIndustryVisitModel.FVOrignisationOJTStatus = formGroup.get("FVOrignisationOJTStatus").value;
    //     fieldIndustryVisitModel.FeedbackFromOrgnisation = formGroup.get("FeedbackFromOrgnisation").value;
    //     fieldIndustryVisitModel.Remarks = formGroup.get("Remarks").value;
    //     fieldIndustryVisitModel.FVStudentSafety = formGroup.get("FVStudentSafety").value;

    //     //fieldIndustryVisitModel.ModuleId = formGroup.get("ModuleId").value;
    //     //fieldIndustryVisitModel.UnitId = formGroup.get("UnitId").value;
    //     //fieldIndustryVisitModel.SessionIds = formGroup.get("SessionIds").value;
    //     fieldIndustryVisitModel.StudentAttendances = formGroup.get("StudentAttendances").value;
    //     //fieldIndustryVisitModel.UnitSessionsModels = formGroup.get("UnitSessionsModels").value;

    //     fieldIndustryVisitModel.IsVenueVisited = formGroup.get("IsVenueVisited").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsPlaceIntroduced = formGroup.get("IsPlaceIntroduced").value === 'Yes' ? true : false;;
    //     fieldIndustryVisitModel.IsStudentDivided = formGroup.get("IsStudentDivided").value === 'Yes' ? true : false;;
    //     fieldIndustryVisitModel.DetailsOfPlanning = formGroup.get("DetailsOfPlanning").value;
    //     fieldIndustryVisitModel.IsReception = formGroup.get("IsReception").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsFireSafety = formGroup.get("IsFireSafety").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsAccidentFireSafety = formGroup.get("IsAccidentFireSafety").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsBasicSafety = formGroup.get("IsBasicSafety").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsStudentSecurity = true;
    //     fieldIndustryVisitModel.IsTransport = formGroup.get("IsTransport").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.ModeOfTransport = formGroup.get("ModeOfTransport").value;
    //     fieldIndustryVisitModel.TransportCost = formGroup.get("TransportCost").value;
    //     fieldIndustryVisitModel.NoTransportReason = formGroup.get("NoTransportReason").value;
    //     fieldIndustryVisitModel.TransportConfirmed = formGroup.get("TransportConfirmed").value;
    //     fieldIndustryVisitModel.IsHMConsentSigned = formGroup.get("IsHMConsentSigned").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsTeacherAssignedbyHM = formGroup.get("IsTeacherAssignedbyHM").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.TeacherName = formGroup.get("TeacherName").value;
    //     fieldIndustryVisitModel.TeacherContact = formGroup.get("TeacherContact").value;
    //     fieldIndustryVisitModel.IsParentAwareOfVisit = formGroup.get("IsParentAwareOfVisit").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsParentVolunteer = formGroup.get("IsParentVolunteer").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.ParentName = formGroup.get("ParentName").value;
    //     fieldIndustryVisitModel.ParentContact = formGroup.get("ParentContact").value;
    //     fieldIndustryVisitModel.PermissionDetail = formGroup.get("PermissionDetail").value;
    //     fieldIndustryVisitModel.IsBRBehaviour = formGroup.get("IsBRBehaviour").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsBREmergencies = formGroup.get("IsBREmergencies").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsBRAttention = formGroup.get("IsBRAttention").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsBRTransportation = formGroup.get("IsBRTransportation").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsKidViolationBR = formGroup.get("IsKidViolationBR").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.BPDiscussion = formGroup.get("BPDiscussion").value;
    //     fieldIndustryVisitModel.IsFoodsArranged = formGroup.get("IsFoodsArranged").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsStudentIdentity = formGroup.get("IsStudentIdentity").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.IsFirstAidKit = formGroup.get("IsFirstAidKit").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.MaterialsPreparation = formGroup.get("MaterialsPreparation").value;
    //     fieldIndustryVisitModel.IsParentConsent = formGroup.get("IsParentConsent").value === 'Yes' ? true : false;
    //     fieldIndustryVisitModel.NoOfStudentConsent = formGroup.get("NoOfStudentConsent").value;
    //     fieldIndustryVisitModel.IsConsentReceived = formGroup.get("IsConsentReceived").value === 'Yes' ? true : false;
    //     console.log("IsStatus",formGroup.get("IsStatus").value);
        
    //     fieldIndustryVisitModel.IsStatus = formGroup.get("IsStatus").value === 'Yes' ? true : false;


    //     return fieldIndustryVisitModel;
    // }


    getFieldIndustryVisitModelFromFormGroup(formGroup: FormGroup,classId?:string,sectionId?:string,studentAttendance?:any): VTFieldIndustryVisitConductedModel {
        let fieldIndustryVisitModel = new VTFieldIndustryVisitConductedModel();
        console.log(classId,sectionId);
        if(classId && sectionId){
            fieldIndustryVisitModel.ClassTaughtId = classId;
            fieldIndustryVisitModel.SectionIds = sectionId;
        }
        else{
        fieldIndustryVisitModel.ClassTaughtId = formGroup.get("ClassTaughtId").value;
        fieldIndustryVisitModel.SectionIds = formGroup.get("SectionIds").value;
        }

        if(studentAttendance){
            fieldIndustryVisitModel.StudentAttendances = studentAttendance
        }
        else{
            fieldIndustryVisitModel.StudentAttendances = formGroup.get("StudentAttendances").value;
        }
        // fieldIndustryVisitModel.ClassTaughtId = formGroup.get("ClassTaughtId").value;
        fieldIndustryVisitModel.ReportingDate = this.http.getDateTimeFromControl(formGroup.get("ReportingDate").value);
        // fieldIndustryVisitModel.SectionIds = formGroup.get("SectionIds").value;
        fieldIndustryVisitModel.FieldVisitTheme = formGroup.get("FieldVisitTheme").value;
        fieldIndustryVisitModel.FieldVisitActivities = formGroup.get("FieldVisitActivities").value;
        fieldIndustryVisitModel.FVOrganisation = formGroup.get("FVOrganisation").value;
        fieldIndustryVisitModel.FVOrganisationAddress = formGroup.get("FVOrganisationAddress").value;
        fieldIndustryVisitModel.FVDistance = formGroup.get("FVDistance").value;
        //fieldIndustryVisitModel.FVPicture = formGroup.get("FVPhoto").value;
        fieldIndustryVisitModel.FVContactPersonName = formGroup.get("FVContactPersonName").value;
        fieldIndustryVisitModel.FVContactPersonMobile = formGroup.get("FVContactPersonMobile").value;
        fieldIndustryVisitModel.FVContactPersonEmail = formGroup.get("FVContactPersonEmail").value;
        fieldIndustryVisitModel.FVContactPersonDesignation = formGroup.get("FVContactPersonDesignation").value;
        fieldIndustryVisitModel.FVOrganisationInterestStatus = formGroup.get("FVOrganisationInterestStatus").value;
        fieldIndustryVisitModel.FVOrignisationOJTStatus = formGroup.get("FVOrignisationOJTStatus").value;
        fieldIndustryVisitModel.FeedbackFromOrgnisation = formGroup.get("FeedbackFromOrgnisation").value;
        fieldIndustryVisitModel.Remarks = formGroup.get("Remarks").value;
        fieldIndustryVisitModel.FVStudentSafety = formGroup.get("FVStudentSafety").value;
        //fieldIndustryVisitModel.ModuleId = formGroup.get("ModuleId").value;
        //fieldIndustryVisitModel.UnitId = formGroup.get("UnitId").value;
        //fieldIndustryVisitModel.SessionIds = formGroup.get("SessionIds").value;
        // fieldIndustryVisitModel.StudentAttendances = formGroup.get("StudentAttendances").value;
        //fieldIndustryVisitModel.UnitSessionsModels = formGroup.get("UnitSessionsModels").value;
        fieldIndustryVisitModel.IsVenueVisited = formGroup.get("IsVenueVisited").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsPlaceIntroduced = formGroup.get("IsPlaceIntroduced").value === 'Yes' ? true : false;;
        fieldIndustryVisitModel.IsStudentDivided = formGroup.get("IsStudentDivided").value === 'Yes' ? true : false;;
        fieldIndustryVisitModel.DetailsOfPlanning = formGroup.get("DetailsOfPlanning").value;
        fieldIndustryVisitModel.IsReception = formGroup.get("IsReception").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsFireSafety = formGroup.get("IsFireSafety").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsAccidentFireSafety = formGroup.get("IsAccidentFireSafety").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsBasicSafety = formGroup.get("IsBasicSafety").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsStudentSecurity = true;
        fieldIndustryVisitModel.IsTransport = formGroup.get("IsTransport").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.ModeOfTransport = formGroup.get("ModeOfTransport").value;
        fieldIndustryVisitModel.TransportCost = formGroup.get("TransportCost").value;
        fieldIndustryVisitModel.NoTransportReason = formGroup.get("NoTransportReason").value;
        fieldIndustryVisitModel.TransportConfirmed = formGroup.get("TransportConfirmed").value;
        fieldIndustryVisitModel.IsHMConsentSigned = formGroup.get("IsHMConsentSigned").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsTeacherAssignedbyHM = formGroup.get("IsTeacherAssignedbyHM").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.TeacherName = formGroup.get("TeacherName").value;
        fieldIndustryVisitModel.TeacherContact = formGroup.get("TeacherContact").value;
        fieldIndustryVisitModel.IsParentAwareOfVisit = formGroup.get("IsParentAwareOfVisit").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsParentVolunteer = formGroup.get("IsParentVolunteer").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.ParentName = formGroup.get("ParentName").value;
        fieldIndustryVisitModel.ParentContact = formGroup.get("ParentContact").value;
        fieldIndustryVisitModel.PermissionDetail = formGroup.get("PermissionDetail").value;
        fieldIndustryVisitModel.IsBRBehaviour = formGroup.get("IsBRBehaviour").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsBREmergencies = formGroup.get("IsBREmergencies").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsBRAttention = formGroup.get("IsBRAttention").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsBRTransportation = formGroup.get("IsBRTransportation").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsKidViolationBR = formGroup.get("IsKidViolationBR").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.BPDiscussion = formGroup.get("BPDiscussion").value;
        fieldIndustryVisitModel.IsFoodsArranged = formGroup.get("IsFoodsArranged").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsStudentIdentity = formGroup.get("IsStudentIdentity").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.IsFirstAidKit = formGroup.get("IsFirstAidKit").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.MaterialsPreparation = formGroup.get("MaterialsPreparation").value;
        fieldIndustryVisitModel.IsParentConsent = formGroup.get("IsParentConsent").value === 'Yes' ? true : false;
        fieldIndustryVisitModel.NoOfStudentConsent = formGroup.get("NoOfStudentConsent").value;
        fieldIndustryVisitModel.IsConsentReceived = formGroup.get("IsConsentReceived").value === 'Yes' ? true : false;
        console.log("IsStatus",formGroup.get("IsStatus").value);
        fieldIndustryVisitModel.IsStatus = formGroup.get("IsStatus").value === 'Yes' ? true : false;
        return fieldIndustryVisitModel;
    }

}
