import { Component, OnInit, NgZone, ViewEncapsulation } from "@angular/core";
import { CommonService } from "app/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NextMonthPlanModel } from "./next-month-plan.model";
import { BaseListComponent } from "app/common/base-list/base.list.component";
import { fuseAnimations } from "@fuse/animations";
import { DialogService } from "app/common/confirm-dialog/dialog.service";
import { NextMonthPlanService } from "./next-month-plan.service";

@Component({
    selector: "app-next-month-plan",
    templateUrl: "./next-month-plan.component.html",
    animations: fuseAnimations,
    styleUrls: ["./next-month-plan.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NextMonthPlanComponent
    extends BaseListComponent<NextMonthPlanModel>
    implements OnInit
{
    constructor(
        public commonService: CommonService,
        public router: Router,
        public routeParams: ActivatedRoute,
        public snackBar: MatSnackBar,
        public zone: NgZone,
        private nextMonthPlanService: NextMonthPlanService
    ) {
        super(commonService, router, routeParams, snackBar, zone);
    }

    ngOnInit(): void {
        this.nextMonthPlanService
            .GetAllByCriteria({
                UserId: this.UserModel.UserTypeId,
            })
            .subscribe((response) => {
                if(this.UserModel.RoleCode === 'VC'){
                    this.displayedColumns = [
                        "PlanMonth",
                        "VocationalTrainer",
                        "Actions",
                    ];
                }else{
                this.displayedColumns = [
                    "PlanMonth",
                    "Actions",
                ];
            }

                this.tableDataSource.data = response.Results;
                this.tableDataSource.sort = this.ListSort;
                this.tableDataSource.paginator = this.ListPaginator;
                this.tableDataSource.filteredData = this.tableDataSource.data;

                this.zone.run(() => {
                    if (this.tableDataSource.data.length == 0) {
                        this.showNoDataFoundSnackBar();
                    }
                });
                this.IsLoading = false;
            });
    }

    monthValidate(date) {
        let currentDate = new Date();
        let currentMonth = currentDate.getMonth() + 1;
        let currentYear = currentDate.getFullYear();
        let planDate = new Date(date);
        let planMonth = planDate.getMonth()+1
        let planYear = planDate.getFullYear();
        
        // if (currentDate > planDate) {
        //     return true;
        // } else {
        //     if (currentYear < planYear) {
        //         return false;
        //     } else {
                if (planMonth <= currentMonth) {
                    return true;
                } else {
                    return false;
                }
    //         }
    //     }
    }
}
