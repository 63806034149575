import { HMMeetInvolvedModel } from 'app/models/Hmmeet.model';

export class VCHMMeetingModel {
    MembersCount: number;
    Members: HMMeetInvolvedModel[];
    MeetingDetails: string;
    OtherMember: string;
   
    constructor(vcDailyReportingItem?: any) {
        vcDailyReportingItem = vcDailyReportingItem || {};

        this.MembersCount = vcDailyReportingItem.MembersCount || '';
        this.Members = vcDailyReportingItem.Members ||  <HMMeetInvolvedModel[]>[];
        this.MeetingDetails = vcDailyReportingItem.MeetingDetails || '';       
        this.OtherMember = vcDailyReportingItem.OtherMember || '';       
}
}
