import { Injectable } from "@angular/core";

import { CommonService } from 'app/services/common.service';
import { VTDailyReportingModel } from './vt-daily-reportings/vt-daily-reporting.model';
import { FormArray, FormGroup } from '@angular/forms';
// import { VTRParentTeachersMeetingModel } from './vt-parent-teachers-meeting.model';
// import { VTRCommunityHomeVisitModel } from './vt-community-home-visit.model';
// import { VTRTrainingOfTeacherModel } from './vt-training-of-teacher.model';
// import { VTROnJobTrainingCoordinationModel } from './vt-on-job-training-coordination.model';
// import { VTRAssessorInOtherSchoolForExamModel } from './vt-assessor-in-other-school-for-exam.model';
// import { VTRVisitToIndustryModel } from './vt-visit-to-industry.model';
// import { VTRVisitToEducationalInstitutionModel } from './vt-visit-to-educational-institution.model';
// import { VTRAssignmentFromVocationalDepartmentModel } from './vt-assignment-from-vocational-department.model';
// import { VTRTeachingNonVocationalSubjectModel } from './vt-teaching-non-vocational-subject.model';
// import { VTRHolidayModel } from './vt-holiday.model';
// import { VTRLeaveModel } from './vt-leave.model';
// import { VTCommnityWorkModel } from './vt-community-work.model';
// import { VTRTeachingVocationalEducationModel } from './vt-teaching-vocational-education.model';
import { VTHomeVisitModel } from "./vt-daily-reportings/vt-home-visit.model";
import { AnyARecord } from "dns";
import { VCHMMeetingModel } from "./vc-daily-reportings/vc-hm-meetig.model";
import { VCHomeVisitModel } from "./vc-daily-reportings/vc-home-visit.model";
import { VCDailyReportingModel } from "./vc-daily-reportings/vc-daily-reporting.model";
import { VTHMMeetingModel } from "./vt-daily-reportings/vt-hm-meetig.model";

@Injectable()
export class VTVCDailyReportingService {
    constructor(private http: CommonService) { }
        

    getVTDailyReportingModelFromFormGroup(formGroup: FormGroup, role: any) {
        console.log("Form Group:",);
        
        let dailyReportingModel: any;
        if(role == "VT"){
            dailyReportingModel = new VTDailyReportingModel();
            dailyReportingModel.HomeVisit = new VTHomeVisitModel();
            dailyReportingModel.ReportType = formGroup.get("ReportType").value;

            dailyReportingModel.WorkingDayTypeIds = (dailyReportingModel.ReportType == '37') ? formGroup.get("WorkingDayTypeIds").value : [];
            dailyReportingModel.ReportingDate = this.http.getDateTimeFromControl(formGroup.get("ReportingDate").value);


        } else{
            dailyReportingModel = new VCDailyReportingModel();
            dailyReportingModel.HomeVisit = new VCHomeVisitModel();
            dailyReportingModel.ReportType = formGroup.get("ReportType").value;

            dailyReportingModel.HomeVisit.UserId = formGroup.controls.homeVisitGroup.get('UserId').value;
            dailyReportingModel.WorkingDayTypeIds = (dailyReportingModel.ReportType == '49') ? formGroup.get("WorkingDayTypeIds").value : [];
            dailyReportingModel.ReportDate = this.http.getDateTimeFromControl(formGroup.get("ReportDate").value);

        }


        //HM Meeting
        if (formGroup.controls.homeVisitGroup != null) {
            dailyReportingModel.HMMeeting = (role === "VT") ? new VTHMMeetingModel() : new VCHMMeetingModel();

            dailyReportingModel.HMMeeting.MembersCount = formGroup.controls.homeVisitGroup.get('MembersCount').value;
            // dailyReportingModel.HMMeeting.Members = formGroup.controls.homeVisitGroup.get('Members').value;
            dailyReportingModel.HMMeeting.MeetingDetails = formGroup.controls.homeVisitGroup.get('MeetingDetails').value;

        }
       
        
        //Home Visit
        if (formGroup.controls.homeVisitGroup != null) {
            
            dailyReportingModel.HomeVisit.DateOfVisit = formGroup.controls.homeVisitGroup.get('DateOfVisit').value;
            dailyReportingModel.HomeVisit.PurposeOfVisit = formGroup.controls.homeVisitGroup.get('PurposeOfVisit').value;
            dailyReportingModel.HomeVisit.ClassTaughtId = formGroup.controls.homeVisitGroup.get('ClassTaughtId').value;
            dailyReportingModel.HomeVisit.StudentId = formGroup.controls.homeVisitGroup.get('StudentId').value;
            dailyReportingModel.HomeVisit.AcademicYearId = formGroup.controls.homeVisitGroup.get('AcademicYearId').value;
            dailyReportingModel.HomeVisit.FatherName = formGroup.controls.homeVisitGroup.get('FatherName').value;
            dailyReportingModel.HomeVisit.MotherName = formGroup.controls.homeVisitGroup.get('MotherName').value;
            dailyReportingModel.HomeVisit.FatherEducation = formGroup.controls.homeVisitGroup.get('FatherEducation').value;
            dailyReportingModel.HomeVisit.MotherEducation = formGroup.controls.homeVisitGroup.get('MotherEducation').value;
            dailyReportingModel.HomeVisit.Village = formGroup.controls.homeVisitGroup.get('Village').value;
            dailyReportingModel.HomeVisit.LandMark = formGroup.controls.homeVisitGroup.get('LandMark').value;
            dailyReportingModel.HomeVisit.ContactNum = formGroup.controls.homeVisitGroup.get('ContactNum').value;
            dailyReportingModel.HomeVisit.MotherAge = formGroup.controls.homeVisitGroup.get('MotherAge').value;
            dailyReportingModel.HomeVisit.FatherAge = formGroup.controls.homeVisitGroup.get('FatherAge').value;
            dailyReportingModel.HomeVisit.StudentMSFC = formGroup.controls.homeVisitGroup.get('StudentMSFC').value;
            dailyReportingModel.HomeVisit.MainSource = formGroup.controls.homeVisitGroup.get('MainSource').value;
            dailyReportingModel.HomeVisit.AdditionalSouce = formGroup.controls.homeVisitGroup.get('AdditionalSouce').value;
            dailyReportingModel.HomeVisit.Generation = formGroup.controls.homeVisitGroup.get('Generation').value;
            dailyReportingModel.HomeVisit.SibilingsCount = formGroup.controls.homeVisitGroup.get('SibilingsCount').value;
            dailyReportingModel.HomeVisit.SilbilingType = formGroup.controls.homeVisitGroup.get('SilbilingType').value;
            dailyReportingModel.HomeVisit.IsParentAware = (formGroup.controls.homeVisitGroup.get('IsParentAware').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.IsMSChildSpoketoParent = (formGroup.controls.homeVisitGroup.get('IsMSChildSpoketoParent').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.IsMSpracticalworkdone = (formGroup.controls.homeVisitGroup.get('IsMSpracticalworkdone').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.ChildSharedDetails = formGroup.controls.homeVisitGroup.get('ChildSharedDetails').value;
            dailyReportingModel.HomeVisit.IsParentWilling = (formGroup.controls.homeVisitGroup.get('IsParentWilling').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.KindOfEducation = formGroup.controls.homeVisitGroup.get('KindOfEducation').value;
            dailyReportingModel.HomeVisit.StudentDropOut = formGroup.controls.homeVisitGroup.get('StudentDropOut').value;
            dailyReportingModel.HomeVisit.DropoutReason = formGroup.controls.homeVisitGroup.get('DropoutReason').value;
            dailyReportingModel.HomeVisit.IsSchoolingAwareness = (formGroup.controls.homeVisitGroup.get('IsSchoolingAwareness').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.DetailsKnown = formGroup.controls.homeVisitGroup.get('DetailsKnown').value;
            dailyReportingModel.HomeVisit.Reaction = formGroup.controls.homeVisitGroup.get('Reaction').value;
            dailyReportingModel.HomeVisit.IsChronicallyIll = (formGroup.controls.homeVisitGroup.get('IsChronicallyIll').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.IllCount = formGroup.controls.homeVisitGroup.get('IllCount').value;
            dailyReportingModel.HomeVisit.IsParentCommunicate = (formGroup.controls.homeVisitGroup.get('IsParentCommunicate').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.CommunicationDetails = formGroup.controls.homeVisitGroup.get('CommunicationDetails').value;
            dailyReportingModel.HomeVisit.ImportantDetails = formGroup.controls.homeVisitGroup.get('ImportantDetails').value;
            dailyReportingModel.HomeVisit.Remarks = formGroup.controls.homeVisitGroup.get('Remarks').value;
            dailyReportingModel.HomeVisit.Hygienerate = formGroup.controls.homeVisitGroup.get('Hygienerate').value;
        }
        return dailyReportingModel;
    }
}

