<div id="vt-field-industry-visit-conducted" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-field-industry-visit-conducted'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ vtFieldIndustryVisitConductedModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New VT Field / Industry Visit Conducted
                    </div>
                    <div class="subtitle secondary-text">
                        <span>VT Field / Industry Visit Conducted Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vt-field-industry-visit-conducted-button"
                *ngIf="PageRights.ActionType ==='new'" (click)="saveOrUpdateVTFieldIndustryVisitConductedDetails1()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-vt-field-industry-visit-conducted-button"
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateVTFieldIndustryVisitConductedDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="vtFieldIndustryVisitConductedForm" [formGroup]="vtFieldIndustryVisitConductedForm"
                    class="vt-field-industry-visit-conducted w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <fieldset>
                        <legend> Basic </legend>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Name of the place to be visited</mat-label>
                                <input matInput name="fvOrganisation" formControlName="FVOrganisation" required
                                    matTooltip="Please enter the name of the organisation where the visit has taken place."
                                    matTooltipPosition="above" [disabled]="PageRights.IsReadOnly">
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.FVOrganisation.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVOrganisation')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Organisation Address</mat-label>
                                <input matInput name="fvOrganisationAddress" formControlName="FVOrganisationAddress"
                                    required matTooltip="Please enter the venue (address) details of the organisation."
                                    matTooltipPosition="above" [disabled]="PageRights.IsReadOnly">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVOrganisationAddress.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVOrganisationAddress')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Name</mat-label>
                                <input matInput name="fvContactPersonName" formControlName="FVContactPersonName"
                                    required matTooltip="Please enter the name of the contact person at the organisation
                                        Do not add any title. Enter name as [First Name] [Middle Name] [Last Name].."
                                    matTooltipPosition="above" [disabled]="PageRights.IsReadOnly">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVContactPersonName.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVContactPersonName')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Contact</mat-label>
                                <input matInput name="fvContactPersonMobile" formControlName="FVContactPersonMobile"
                                    digitOnly maxlength="10" minlength="10" required
                                    matTooltip="Please enter the mobile number of the contact person at the organisation."
                                    matTooltipPosition="above" [disabled]="PageRights.IsReadOnly">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVContactPersonMobile.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVContactPersonMobile')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Email</mat-label>
                                <input matInput name="fvContactPersonEmail" formControlName="FVContactPersonEmail"
                                    matTooltip="Please enter the email ID of the contact person at the organisation."
                                    matTooltipPosition="above" [disabled]="PageRights.IsReadOnly">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVContactPersonEmail.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVContactPersonEmail')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Designation</mat-label>
                                <input matInput name="fvContactPersonDesignation"
                                    formControlName="FVContactPersonDesignation"
                                    matTooltip="Please enter the designation of the contact person at the organisation."
                                    matTooltipPosition="above" [disabled]="PageRights.IsReadOnly">
                                <!-- <mat-error
                                *ngIf="vtFieldIndustryVisitConductedForm.controls.FVContactPersonDesignation.invalid">
                                {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVContactPersonDesignation')}}
                            </mat-error> -->
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Visit Plan Date</mat-label>
                                <input matInput name="reportingDate" formControlName="ReportingDate"
                                    [matDatepicker]="reportingDate" required [disabled]="PageRights.IsReadOnly">
                                <mat-datepicker-toggle matSuffix [for]="reportingDate"></mat-datepicker-toggle>
                                <mat-datepicker #reportingDate></mat-datepicker>

                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.ReportingDate.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'ReportingDate')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field  *ngIf="PageRights.ActionType !=='new'" appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Class Taught</mat-label>

                                <mat-select name="classTaughtId" formControlName="ClassTaughtId" required
                                    appearance="outline" [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeClassForTaught($event.value)">
                                    <mat-option [value]="vtClassItem.Id" *ngFor="let vtClassItem of classTaughtList">
                                        {{ vtClassItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.ClassTaughtId.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'ClassTaughtId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field *ngIf="PageRights.ActionType ==='new'" appearance="outline" fxFlex="33"
                                fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Class Taught</mat-label>
                                <mat-select name="classTaughtId" formControlName="ClassTaughtId" required multiple
                                    appearance="outline" [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeClassForTaught1($event.value)">
                                    <mat-option [value]="vtClassItem" [disabled]="index==0"
                                        *ngFor="let vtClassItem of classTaughtList;let index=index">
                                        {{ vtClassItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.ClassTaughtId.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'ClassTaughtId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field  *ngIf="PageRights.ActionType !=='new'" appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Section Taught</mat-label>
                                <mat-select name="sectionIds" formControlName="SectionIds" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeSectionForTaught($event.value)">
                                    <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList">
                                        {{ sectionItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.SectionIds.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'SectionIds')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="PageRights.ActionType ==='new'" appearance="outline" fxFlex="34"
                            fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Section Taught</mat-label>
                            <mat-select name="sectionIds" formControlName="SectionIds" multiple required
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeSectionForTaught1($event.value)">
                                <mat-optgroup *ngFor="let classId of SectionListItem" [label]="classId.Name"
                                    [disabled]="classId.IsDisabled">
                                    <mat-option *ngFor="let section of classId.Section"
                                        [value]="[classId.Id,section.Id]">
                                        {{section.Name}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                            <mat-error *ngIf="vtFieldIndustryVisitConductedForm.get('SectionIds').errors?.NotMatching">
                                Selected Class Not matching For selected Section
                            </mat-error>
                        </mat-form-field>
                        </div>

                        

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Purpose Of The Visit</mat-label>
                                <input matInput name="fieldVisitTheme" formControlName="FieldVisitTheme" required [disabled]="PageRights.IsReadOnly">
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.FieldVisitTheme.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FieldVisitTheme')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Field Visit Activities</mat-label>
                                <input matInput name="fieldVisitActivities" formControlName="FieldVisitActivities"
                                    required  required [disabled]="PageRights.IsReadOnly">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FieldVisitActivities.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FieldVisitActivities')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" >
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Planned Modules for FV</mat-label>
                                <mat-select name="moduleId" formControlName="ModuleId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" [required]="unitSessionsModels.length==0"
                                    (selectionChange)="onChangeCourseModule($event.value)">
                                    <mat-option [value]="moduleItem" *ngFor="let moduleItem of moduleTaughtList">
                                        {{ moduleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.ModuleId.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'ModuleId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Planned Units for FV</mat-label>
                                <mat-select name="unitId" formControlName="UnitId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" [required]="unitSessionsModels.length==0"
                                    (selectionChange)="onChangeUnitsTaught($event.value)">
                                    <mat-option [value]="unitItem" *ngFor="let unitItem of unitList">
                                        {{ unitItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.UnitId.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'UnitId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="70" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Planned Sessions for FV</mat-label>
                                <mat-select name="sessionIds" formControlName="SessionIds" multiple appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" [required]="unitSessionsModels.length==0">
                                    <mat-option [value]="sessionItem" *ngFor="let sessionItem of sessionList">
                                        {{ sessionItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.SessionIds.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'SessionIds')}}
                                </mat-error>
                            </mat-form-field>

                            <div class="add-course-taught">
                                <span class="material-icons add-session"
                                    (click)="addUnitSession()">add_circle_outline</span>
                                <span class="add-course-text">Kindly press "+" to add information</span>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-list fxFlex="70" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="mat-list-class">
                                <mat-list-item *ngFor="let sessionItem of unitSessionsModels let sessionIndex=index;">
                                    <div style="float: left; width: 99%;">
                                        <span style="width:5%"> {{sessionIndex + 1}}) </span>
                                        <span style="width:20%; font-weight: bold;"> {{sessionItem.ModuleName}}- </span>
                                        <span style="width:25%"> {{sessionItem.UnitName}}- </span>
                                        <span style="width:50%"> {{sessionItem.SessionNames}} </span>
                                    </div>
                                    <div style="float: right; width: 10%;">
                                        <span class="material-icons remove-session"
                                            (click)="removeUnitSession(sessionItem)">remove_circle_outline</span>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>

                    </fieldset>

                    <br>
                    <fieldset>
                        <legend>Planning</legend>
                        <div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70" class="mx-width">
                                    <b>Have you visited the venue and made sure that the place serves
                                        the purpose of field visit?</b>
                                </div>
                                <div fxFlex="30" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="IsVenueVisited"
                                        formControlName="IsVenueVisited" [disabled]="PageRights.IsReadOnly">
                                        <mat-radio-button value="Yes">
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Have you introduced the place to the students and asked them
                                        to gather more information about it?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="IsPlaceIntroduced"
                                        formControlName="IsPlaceIntroduced" [disabled]="PageRights.IsReadOnly">
                                        <mat-radio-button value="Yes">
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Have you divided the students into groups and assigned tasks
                                        to each student?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="IsStudentDivided"
                                        formControlName="IsStudentDivided" [disabled]="PageRights.IsReadOnly">
                                        <mat-radio-button value="Yes">
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="80" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Details of what you have done regarding above 3 pointers</mat-label>
                                    <textarea matInput name="DetailsOfPlanning" formControlName="DetailsOfPlanning"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25" [disabled]="PageRights.IsReadOnly" required></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                    </fieldset>
                    <br>
                    <fieldset>
                        <legend>Safety & Security</legend>
                        <div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70" class="mx-width">
                                    <b>Does the venue have security guards, a reception/lost and
                                        found desk/help desk?</b>
                                </div>
                                <div fxFlex="30" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="IsReception"
                                        formControlName="IsReception" [disabled]="PageRights.IsReadOnly">
                                        <mat-radio-button value="Yes">
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Is the venue a fire compliant place? Are you familiar with the
                                        fire safety protocol?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="IsFireSafety"
                                        formControlName="IsFireSafety" (change)="onChangeSafetyDetailsStatus($event)" [disabled]="PageRights.IsReadOnly">
                                        <mat-radio-button value="Yes">
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Does the venue have things that could cause major accidents
                                        like live wires, loose metals, pointed objects?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="IsAccidentFireSafety"
                                        formControlName="IsAccidentFireSafety"
                                        (change)="onChangeSafetyDetailsStatus($event)" [disabled]="PageRights.IsReadOnly">
                                        <mat-radio-button value="Yes">
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Does this place meet basic safety requirements?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="isbasicSafety"
                                        formControlName="IsBasicSafety" (change)="onChangeSafetyDetailsStatus($event)" [disabled]="PageRights.IsReadOnly">
                                        <mat-radio-button value="Yes">
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                            </div>


                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" *ngIf="isSafetyDetails === true">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Details of what you have done to ensure safety and security of the
                                        students</mat-label>
                                    <textarea matInput name="IsStudentSecurity" formControlName="IsStudentSecurity"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25" [disabled]="PageRights.IsReadOnly"></textarea>


                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>

                    <br>

                    <fieldset>
                        <legend>Transport</legend>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you organised transport?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="istransport"
                                    formControlName="IsTransport" (change)="onChangeisTransportStatus($event)" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isTransportDetails">
                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Mode of transport</mat-label>
                                <mat-select name="ModeOfTransport" formControlName="ModeOfTransport"
                                    appearance="outline" [disabled]="PageRights.IsReadOnly">
                                    <mat-option [value]="mode.Id" *ngFor="let mode of transPortMode">
                                        {{mode.value}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Field Visit Distance in KM</mat-label>
                                <input matInput name="fvDistance" formControlName="FVDistance" digitOnly maxlength="3"
                                    matTooltip="Please enter the distance (in Kms) from school to field visit organisation."
                                    matTooltipPosition="above" [disabled]="PageRights.IsReadOnly">
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.FVDistance.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVDistance')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Cost</mat-label>
                                <input matInput name="transortCost" formControlName="TransportCost" digitOnly
                                    maxlength="5" [disabled]="PageRights.IsReadOnly">
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="!isTransportDetails">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>If no Why?</mat-label>
                                <textarea matInput name="NoTransportReason" formControlName="NoTransportReason"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" [disabled]="PageRights.IsReadOnly"></textarea>


                            </mat-form-field>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isTransportDetails">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Details of what you have done for transport</mat-label>
                                <textarea matInput name="TransportConfirmed" formControlName="TransportConfirmed"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" [disabled]="PageRights.IsReadOnly"></textarea>


                            </mat-form-field>

                        </div>
                    </fieldset>

                    <br>
                    <fieldset>
                        <legend>
                            Permissions
                        </legend>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Is your HM aware of the purpose, safety precautions and
                                    arrangements made and has he/she signed the consent form?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="IsHMConsentSigned"
                                    formControlName="IsHMConsentSigned"
                                    (change)="onChangePermissionDetailsStatus($event)" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Has the HM assigned a concerned teacher who will be
                                    accompanying you for the field visit?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="IsTeacherAssignedbyHM"
                                    formControlName="IsTeacherAssignedbyHM" (change)="onChangeisTeacherStatus($event)" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isTeacherAssigned">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="70" fxFlex.lt-sm="100">
                                <mat-label>Teacher Name</mat-label>
                                <input matInput name="TeacherName" formControlName="TeacherName"
                                    placeholder="Visited place" [disabled]="PageRights.IsReadOnly">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Teacher Contact number</mat-label>
                                <input matInput name="TeacherContact" formControlName="TeacherContact" digitOnly
                                    minlength="10" maxlength="10" placeholder="Ex. 9800098000" [disabled]="PageRights.IsReadOnly">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Are the students parents aware of the purpose, safety
                                    precautions and arrangements made and has he/she signed the
                                    consent form?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="IsParentAwareOfVisit"
                                    formControlName="IsParentAwareOfVisit" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Are there any parents who are willing to volunteer and help
                                    you out with the logistics?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="IsParentVolunteer"
                                    formControlName="IsParentVolunteer" (change)="onChangeParentwillingStatus($event)" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isParentVoulntry">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="70" fxFlex.lt-sm="100">
                                <mat-label>Parent Name</mat-label>
                                <input matInput name="parentName" formControlName="ParentName"
                                    placeholder="Parent Name" [disabled]="PageRights.IsReadOnly">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Parent Contact number</mat-label>
                                <input matInput name="ParentContact" formControlName="ParentContact" digitOnly
                                    minlength="10" maxlength="10" placeholder="Ex. 9800098000" [disabled]="PageRights.IsReadOnly">
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isPermissionDetails">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Details of what you have done for getting permission and if you want to
                                    mention anything else?</mat-label>
                                <textarea matInput name="PermissionDetail" formControlName="PermissionDetail"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" [disabled]="PageRights.IsReadOnly"></textarea>


                            </mat-form-field>
                        </div>
                    </fieldset>

                    <br>


                    <fieldset>
                        <legend>Behaviour Protocol</legend>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for transportation?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="IsBRBehaviour"
                                    formControlName="IsBRBehaviour" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for emergencies?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="IsBREmergencies"
                                    formControlName="IsBREmergencies" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for getting everyone’s attention?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isbrAttention"
                                    formControlName="IsBRAttention" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for where to come when you get
                                    lost?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="IsBRTransportation"
                                    formControlName="IsBRTransportation" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for consequences if any kid
                                    violates the behaviour protocol?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isKidViolationBR"
                                    formControlName="IsKidViolationBR" (change)="onChangeBPDetailsStatus($event)" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes" >
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isBPtDetails">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Details of what you have discussed with students regarding behaviour
                                    protocol?</mat-label>
                                <textarea matInput name="BPDiscussion" formControlName="BPDiscussion"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" [disabled]="PageRights.IsReadOnly"></textarea>


                            </mat-form-field>
                        </div>
                    </fieldset>
                    <br>
                    <fieldset>
                        <legend>Materials for the Field Visit</legend>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you made arrangements for food and drinks for the
                                    students? (Dehydration can be harmful)</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isFoodsArranged"
                                    formControlName="IsFoodsArranged" (change)="onChangeMaterialDetailsStatus($event)" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you made arrangements to ensure that students have their
                                    full name, blood group and all relevant phone numbers on their
                                    identity card?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isStudentIdentity"
                                    formControlName="IsStudentIdentity" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you got a portable first aid kit organised?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isFirstAidKit"
                                    formControlName="IsFirstAidKit" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>


                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isMaterialDetails">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Details of what preparation you have done for Materials?</mat-label>
                                <textarea matInput name="materialsPreparation" formControlName="MaterialsPreparation"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" [disabled]="PageRights.IsReadOnly"></textarea>


                            </mat-form-field>
                        </div>
                    </fieldset>
                    <br>

                    <fieldset>
                        <legend>Consent</legend>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you got Parent consent</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isParentConsent"
                                    formControlName="IsParentConsent" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">


                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Upload Principal Consent form </mat-label>
                                <ngx-mat-file-input name="uploadPrincipleConsentForm"
                                    formControlName="UploadPrincipleConsentForm" [accept]="AllowedImageExtensions"
                                    (change)="UploadPrincipleConsentForm($event)" [disabled]="PageRights.IsReadOnly">
                                </ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.FVPictureFile.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVPictureFile')}}
                                </mat-error>
                            </mat-form-field>
                            <!-- <div class="default-image-container" fxFlex="30">                            
                                <mat-checkbox matInput formControlName="IsFVPictureFile" [disabled]="PageRights.IsReadOnly" (change)="setMandatoryFieldControl(vtFieldIndustryVisitConductedForm, 'FVPictureFile', $event)">
                                </mat-checkbox>
                                <span class="checkbox-space">{{ Constants.Messages.DefaultImageText }}</span>
                            </div> -->
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>For how many students you received consent</mat-label>
                                <input matInput name="noOfStudentConsent" formControlName="NoOfStudentConsent" digitOnly
                                    maxlength="3" [disabled]="PageRights.IsReadOnly"/>

                            </mat-form-field>
                        </div>

                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you received and kept all the consent forms for future reference</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isConsentReceived"
                                    formControlName="IsConsentReceived" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="Yes">
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                        </div>


                    </fieldset>

                    <!-- <div fxLayout="row" class="view-div" style="padding: 10px;" *ngIf="isNew == true">
                        <div fxFlex="70%" class="mx-width">
                            <b>Form Complete Status</b>
                        </div>
                        <div fxFlex="30%" class="mx-width">
                            <mat-radio-group aria-label="Select an option" name="IsStatus" formControlName="IsStatus"
                                (change)="onChangeIsStatus($event)" [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">
                                    Yes
                                </mat-radio-button>
                                <mat-radio-button value="No" class="mr-left">
                                    No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div> -->


                    <fieldset *ngIf="isPostVisit">
                        <legend>Post Field visit Form</legend>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"s
                            fxLayoutGap.lt-md="0px">


                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Field Visit Picture</mat-label>
                                <ngx-mat-file-input name="fvPictureFile" formControlName="FVPictureFile"
                                    [accept]="AllowedImageExtensions" (change)="uploadedFieldVisitPhotoFile($event)"
                                    ></ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.FVPictureFile.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVPictureFile')}}
                                </mat-error>
                            </mat-form-field>
                            <div class="default-image-container" fxFlex="30">
                                <mat-checkbox matInput formControlName="IsFVPictureFile"
                                    
                                    (change)="setMandatoryFieldControl(vtFieldIndustryVisitConductedForm, 'FVPictureFile', $event)">
                                </mat-checkbox>
                                <span class="checkbox-space">{{ Constants.Messages.DefaultImageText }}</span>
                            </div>
                        </div>



                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Is Organization interested to conduct more FV?</mat-label>
                                <mat-select name="fvOrganisationInterestStatus"
                                    formControlName="FVOrganisationInterestStatus"
                                    matTooltip="Is Organization interested to conduct more FV?"
                                    matTooltipPosition="above" >
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVOrganisationInterestStatus.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm,
                                    'FVOrganisationInterestStatus')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Is FV Organisation interested in conducting OJT/Internship?</mat-label>
                                <mat-select name="fvOrignisationOJTStatus" formControlName="FVOrignisationOJTStatus"
                                    matTooltip="Is FV Organisation interested in conducting OJT/Internship?"
                                    matTooltipPosition="above" >
                                    <mat-option value="Yes">Yes</mat-option>
                                    <mat-option value="No">No</mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVOrignisationOJTStatus.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVOrignisationOJTStatus')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Feedback from Orgnisation</mat-label>
                                <input matInput name="feedbackFromOrgnisation" formControlName="FeedbackFromOrgnisation"
                                    matTooltip="Please enter feedbacks from field visit organisation."
                                    matTooltipPosition="above">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FeedbackFromOrgnisation.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FeedbackFromOrgnisation')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Name of the place to be visited</mat-label>
                                <input matInput name="fvOrganisation" formControlName="FVOrganisation" required
                                    matTooltip="Please enter the name of the organisation where the visit has taken place."
                                    matTooltipPosition="above">
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.FVOrganisation.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVOrganisation')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Organisation Address</mat-label>
                                <input matInput name="fvOrganisationAddress" formControlName="FVOrganisationAddress"
                                    required matTooltip="Please enter the venue (address) details of the organisation."
                                    matTooltipPosition="above">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVOrganisationAddress.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVOrganisationAddress')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Name</mat-label>
                                <input matInput name="fvContactPersonName" formControlName="FVContactPersonName"
                                    required matTooltip="Please enter the name of the contact person at the organisation
                                        Do not add any title. Enter name as [First Name] [Middle Name] [Last Name].."
                                    matTooltipPosition="above">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVContactPersonName.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVContactPersonName')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Contact</mat-label>
                                <input matInput name="fvContactPersonMobile" formControlName="FVContactPersonMobile"
                                    digitOnly maxlength="10" minlength="10" required
                                    matTooltip="Please enter the mobile number of the contact person at the organisation."
                                    matTooltipPosition="above">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVContactPersonMobile.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVContactPersonMobile')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Email</mat-label>
                                <input matInput name="fvContactPersonEmail" formControlName="FVContactPersonEmail"
                                    matTooltip="Please enter the email ID of the contact person at the organisation."
                                    matTooltipPosition="above">
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FVContactPersonEmail.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVContactPersonEmail')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Designation</mat-label>
                                <input matInput name="fvContactPersonDesignation"
                                    formControlName="FVContactPersonDesignation"
                                    matTooltip="Please enter the designation of the contact person at the organisation."
                                    matTooltipPosition="above">
                                <!-- <mat-error
                                *ngIf="vtFieldIndustryVisitConductedForm.controls.FVContactPersonDesignation.invalid">
                                {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FVContactPersonDesignation')}}
                            </mat-error> -->
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Reporting Date</mat-label>
                                <input matInput name="reportingDate" formControlName="ReportingDate"
                                    [matDatepicker]="reportingDate" required [disabled]="PageRights.IsReadOnly">
                                <mat-datepicker-toggle matSuffix [for]="reportingDate"></mat-datepicker-toggle>
                                <mat-datepicker #reportingDate></mat-datepicker>

                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.ReportingDate.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'ReportingDate')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field *ngIf="PageRights.ActionType !=='new'" appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Class Taught</mat-label>

                                <mat-select name="classTaughtId" formControlName="ClassTaughtId" required
                                    appearance="outline" [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeClassForTaught($event.value)">
                                    <mat-option [value]="vtClassItem.Id" *ngFor="let vtClassItem of classTaughtList">
                                        {{ vtClassItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.ClassTaughtId.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'ClassTaughtId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field *ngIf="PageRights.ActionType ==='new'" appearance="outline" fxFlex="33"
                                fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Class Taught</mat-label>
                                <mat-select name="classTaughtId" formControlName="ClassTaughtId" required multiple
                                    appearance="outline" [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeClassForTaught1($event.value)">
                                    <mat-option [value]="vtClassItem" [disabled]="index==0"
                                        *ngFor="let vtClassItem of classTaughtList;let index=index">
                                        {{ vtClassItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.ClassTaughtId.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'ClassTaughtId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field  *ngIf="PageRights.ActionType !=='new'" appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Section Taught</mat-label>
                                <mat-select name="sectionIds" formControlName="SectionIds" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeSectionForTaught($event.value)">
                                    <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList">
                                        {{ sectionItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.SectionIds.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'SectionIds')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field *ngIf="PageRights.ActionType ==='new'" appearance="outline" fxFlex="34"
                                fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Section Taught</mat-label>
                                <mat-select name="sectionIds" formControlName="SectionIds" multiple required
                                    appearance="outline" [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeSectionForTaught1($event.value)">
                                    <mat-optgroup *ngFor="let classId of SectionListItem" [label]="classId.Name"
                                        [disabled]="classId.IsDisabled">
                                        <mat-option *ngFor="let section of classId.Section"
                                            [value]="[classId.Id,section.Id]">
                                            {{section.Name}}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.get('SectionIds').errors?.NotMatching">
                                    Selected Class Not matching For selected Section
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Purpose Of The Visit</mat-label>
                                <input matInput name="fieldVisitTheme" formControlName="FieldVisitTheme" required>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.FieldVisitTheme.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FieldVisitTheme')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Field Visit Activities</mat-label>
                                <input matInput name="fieldVisitActivities" formControlName="FieldVisitActivities"
                                    required>
                                <mat-error
                                    *ngIf="vtFieldIndustryVisitConductedForm.controls.FieldVisitActivities.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'FieldVisitActivities')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="PageRights.IsReadOnly==false">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Modules Covered in FV</mat-label>
                                <mat-select name="moduleId" formControlName="ModuleId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" [required]="unitSessionsModels.length==0"
                                    (selectionChange)="onChangeCourseModule($event.value)">
                                    <mat-option [value]="moduleItem" *ngFor="let moduleItem of moduleTaughtList">
                                        {{ moduleItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.ModuleId.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'ModuleId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Units Covered in FV</mat-label>
                                <mat-select name="unitId" formControlName="UnitId" appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" [required]="unitSessionsModels.length==0"
                                    (selectionChange)="onChangeUnitsTaught($event.value)">
                                    <mat-option [value]="unitItem" *ngFor="let unitItem of unitList">
                                        {{ unitItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.UnitId.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'UnitId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="PageRights.IsReadOnly==false">
                            <mat-form-field appearance="outline" fxFlex="70" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Sessions Covered in FV</mat-label>
                                <mat-select name="sessionIds" formControlName="SessionIds" multiple appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" [required]="unitSessionsModels.length==0">
                                    <mat-option [value]="sessionItem" *ngFor="let sessionItem of sessionList">
                                        {{ sessionItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.SessionIds.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'SessionIds')}}
                                </mat-error>
                            </mat-form-field>

                            <div class="add-course-taught">
                                <span class="material-icons add-session"
                                    (click)="addUnitSession()">add_circle_outline</span>
                                <span class="add-course-text">Kindly press "+" to add information</span>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-list fxFlex="70" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="mat-list-class">
                                <mat-list-item *ngFor="let sessionItem of unitSessionsModels let sessionIndex=index;">
                                    <div style="float: left; width: 99%;">
                                        <span style="width:5%"> {{sessionIndex + 1}}) </span>
                                        <span style="width:20%; font-weight: bold;"> {{sessionItem.ModuleName}}- </span>
                                        <span style="width:25%"> {{sessionItem.UnitName}}- </span>
                                        <span style="width:50%"> {{sessionItem.SessionNames}} </span>
                                    </div>
                                    <div style="float: right; width: 10%;" *ngIf="PageRights.IsReadOnly==false">
                                        <span class="material-icons remove-session"
                                            (click)="removeUnitSession(sessionItem)">remove_circle_outline</span>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Remarks</mat-label>
                                <input matInput name="remarks" formControlName="Remarks">
                                <mat-error *ngIf="vtFieldIndustryVisitConductedForm.controls.Remarks.invalid">
                                    {{getErrorMessage(vtFieldIndustryVisitConductedForm, 'Remarks')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </fieldset>


                    <mat-divider></mat-divider>
                    <div fxLayout="row" *ngIf="isPostVisit">
                        <div>
                            <span class="h2">Student Present in Field Visit</span>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="!isPostVisit">
                        <div>
                            <span class="h2">Student Opted for Field Visit</span>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtFieldIndustryVisitConductedForm.get('StudentAttendances') != null">
                        <div formArrayName="StudentAttendances" class="student-list-group">
                            <!-- iterate formArray -->
                            <div class="students-list" style="background: #f3f3f3;"
                                *ngFor="let studentItem of vtFieldIndustryVisitConductedForm.get('StudentAttendances').controls; let studentIndex=index">
                                <!-- Use the index for each formGroup inside the formArray -->
                                <div [formGroupName]="studentIndex" class="student-list">
                                    <input formControlName="StudentName" [disabled]="true"
                                        style="background: #f3f3f3;" />
                                    <mat-slide-toggle formControlName="IsPresent" [checked]="IsPresent">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>