<div id="student-class" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    STUDENT REGISTRATIONS
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <form name="studentSearchForm" [formGroup]="studentSearchForm">
                    <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon>search</mat-icon>
                        <input formControlName="SearchText" placeholder="Search for students"
                            matTooltip="Search By School Name, UDISE, VT Name, Student Name" matTooltipPosition='above'
                            matTooltipClass="allow-cr">
                    </div>
                </form>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                    <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                </a>

                <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                    (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span>
                </button>

                <button *ngIf="PageRights.IsAdd" mat-raised-button [routerLink]="['./','new']" skipLocationChange="true"
                    replaceUrl="false" class="add-student-class-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="studentFilterForm" [formGroup]="studentFilterForm" class="vc-issue-report" fxLayout="column"
                    fxLayoutAlign="start" fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="10" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label> Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="fill"
                                (selectionChange)="onChangeAY($event.value)">
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>Vocational Training Provider</mat-label>

                            <mat-select formControlName="VTPId" name="vtpId" appearance="fill" #SelectVtp
                                (selectionChange)="onChangeVTP($event.value)">
                                <mat-select-filter class="select-filter" *ngIf="SelectVtp.focused" [array]="vtpList"
                                    (filteredReturn)="this.filteredVTPItems = $event" [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of filteredVTPItems">
                                    {{ vtpItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>Vocational Coordinator</mat-label>

                            <mat-select formControlName="VCId" appearance="fill"
                                (selectionChange)="onChangeVC($event.value)" #SelectVC>
                                <mat-select-filter class="select-filter" *ngIf="SelectVC.focused" [array]="vcList"
                                    (filteredReturn)="filteredVCItems = $event" [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="vcItem.Id" *ngFor="let vcItem of filteredVCItems">
                                    {{ vcItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>Vocational Trainer </mat-label>

                            <mat-select formControlName="VTId" appearance="fill"
                                (selectionChange)="onChangeVT($event.value)" #SelectVT>
                                <mat-select-filter class="select-filter" *ngIf="SelectVT.focused" [array]="vtList"
                                    (filteredReturn)="filteredVTItems = $event" [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="vcItem.Id" *ngFor="let vcItem of filteredVTItems">
                                    {{ vcItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field fxFlex="15" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>

                            <mat-select formControlName="SectorId" name="sectorId" appearance="fill"
                                (selectionChange)="onChangeSector($event.value)">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Job Role</mat-label>

                            <mat-select formControlName="JobRoleId" name="jobRoleId" appearance="fill">
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School</mat-label>

                            <mat-select formControlName="SchoolId" name="SchoolId" appearance="fill" #SelectSchool>
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                    [array]="schoolList" (filteredReturn)="this.filteredSchoolItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="schoolsItem.Id" *ngFor="let schoolsItem of filteredSchoolItems">
                                    {{ schoolsItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="10" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Class</mat-label>

                            <mat-select formControlName="ClassId" name="classId" appearance="fill">
                                <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                    {{ classItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="10" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" formControlName="Status" appearance="fill">
                                <mat-option value="">Status</mat-option>
                                <mat-option value="true">Active</mat-option>
                                <mat-option value="false">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="clear-filter" fxFlex="15" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadStudentsByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- /Filter Section -->


        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="student-class-table responsive-table" #table [dataSource]="tableDataSource" matSort
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- AcademicYear Column -->
                <ng-container matColumnDef="AcademicYear">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Academic Year
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.AcademicYear}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>School Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ClassName Column -->
                <ng-container matColumnDef="ClassName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class</mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.ClassName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectionName Column -->
                <ng-container matColumnDef="SectionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.SectionName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.VTName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- StudentName Column -->
                <ng-container matColumnDef="StudentName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass">
                        <p class="text-truncate">{{studentClass.StudentName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- Gender Column -->
                <ng-container matColumnDef="Gender">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Gender
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.Gender}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfEnrollment Column -->
                <ng-container matColumnDef="DateOfEnrollment">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>Date Of Enrollment
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{studentClass.DateOfEnrollment | date: Constants.ShortDateFormat }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- IsActive Column -->
                <ng-container matColumnDef="IsActive">
                    <mat-header-cell *matHeaderCellDef class="mat-header-center">Is Active?
                    </mat-header-cell>
                    <mat-cell *matCellDef="let studentClass" class="text-center">
                        <mat-icon *ngIf="studentClass.IsActive" class="data-active s-20">check</mat-icon>
                        <mat-icon *ngIf="!studentClass.IsActive" class="data-inactive s-20">close</mat-icon>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef fxShow.gt-xs class="mat-header-center">Actions</mat-header-cell>

                    <mat-cell *matCellDef="let studentClass" fxShow.gt-xs>
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/student-registrations', 'view', studentClass.StudentId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-view s-22">visibility</mat-icon>
                        </button>
                        <button mat-icon-button
                            *ngIf="((PageRights.IsEdit && studentClass.IsActive) || UserModel.RoleCode == 'SUR')"
                            [routerLink]="['/student-registrations', 'edit', studentClass.StudentId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon class="data-edit s-20">edit</mat-icon>
                        </button>
                        <button mat-icon-button
                            *ngIf="((PageRights.IsDelete && studentClass.IsActive)  || UserModel.RoleCode == 'SUR')"
                            color="warn" (click)="onDeleteStudentClass(studentClass.StudentId)">
                            <mat-icon class="data-delete s-22">delete_outline</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let studentClass; columns: displayedColumns;" class="student-class" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>