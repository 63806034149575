
export class VCClassObservationModel {
    SchoolId: string;
    UserId: string;
    ClassTaughtId: string;
    ModuleId: string;
    UnitId: string;
    SubjectId: string;
    PresentStudent: number;
    TotalStudent: number;
    ObservationDate: Date;
    ObservationDuration: number;
    StudentsLearned: any;
    IsLessionPlanPresent: any;
    IsClassroomNeat: any;
    IsBSToolsChecked: any;
    IsChildrenSet: any;
    ObservationBeforeSession: string;
    IsTrainerTaught: any;
    IsContextTaught: any;
    IsGuidelinesForTools: any;
    IsFlowChart: any;
    IsSafetyManual: any;
    ObservationsBeforePractical: string;
    IsStudentFollow: any;
    IsInstructorPerformed: any;
    IsPracticalGuided: any;
    IsStudentTreatedEqual: any;
    IsQualityCheck: any;
    IsCorrelation: any;
    IsStudentShy: any;
    ObservationsDuringPractical: string;
    IsHomework: any;
    IsASToolsChecked: any;
    ObservationsAfterPractical: string;
    IsRespectful: any;
    IsStudentQuestioned: any;
    IsStudentParticipated: any;
    IsTeacherTaughtwell: any;
    ObservationsOverall: string;
    ObserverFeedback: string;



   
    constructor(vtDailyReportingItem?: any) {
        vtDailyReportingItem = vtDailyReportingItem || {};

        this.SchoolId = vtDailyReportingItem.SchoolId || '';
        this.UserId = vtDailyReportingItem.UserId || '';
        this.ClassTaughtId = vtDailyReportingItem.ClassTaughtId || '';
        this.ModuleId = vtDailyReportingItem.ModuleId || '';
        this.UnitId = vtDailyReportingItem.UnitId || '';
        this.SubjectId = vtDailyReportingItem.SubjectId || '';
        this.PresentStudent = vtDailyReportingItem.PresentStudent || '';
        this.TotalStudent = vtDailyReportingItem.TotalStudent || '';
        this.ObservationDate = vtDailyReportingItem.ObservationDate || '';
        this.ObservationDuration = vtDailyReportingItem.ObservationDuration || '';
        this.StudentsLearned = vtDailyReportingItem.StudentsLearned || '';
        this.IsLessionPlanPresent = vtDailyReportingItem.IsLessionPlanPresent || '';
        this.IsClassroomNeat = vtDailyReportingItem.IsClassroomNeat || '';
        this.IsBSToolsChecked = vtDailyReportingItem.IsBSToolsChecked || '';
        this.IsChildrenSet = vtDailyReportingItem.IsChildrenSet || '';
        this.ObservationBeforeSession = vtDailyReportingItem.ObservationBeforeSession || '';
        this.IsTrainerTaught = vtDailyReportingItem.IsTrainerTaught || '';
        this.IsContextTaught = vtDailyReportingItem.IsContextTaught || '';
        this.IsGuidelinesForTools = vtDailyReportingItem.IsGuidelinesForTools || '';
        this.IsFlowChart = vtDailyReportingItem.IsFlowChart || '';
        this.IsSafetyManual = vtDailyReportingItem.Safety || '';
        this.ObservationsBeforePractical = vtDailyReportingItem.ObservationsBeforePractical || '';
        this.IsStudentFollow = vtDailyReportingItem.IsStudentFollow || '';
        this.IsInstructorPerformed = vtDailyReportingItem.IsInstructorPerformed || '';
        this.IsPracticalGuided = vtDailyReportingItem.IsPracticalGuided || '';
        this.IsStudentTreatedEqual = vtDailyReportingItem.IsStudentTreatedEqual || '';
        this.IsQualityCheck = vtDailyReportingItem.IsQualityCheck || '';
        this.IsCorrelation = vtDailyReportingItem.IsCorrelation || '';
        this.IsStudentShy = vtDailyReportingItem.IsStudentShy || '';
        this.ObservationsDuringPractical = vtDailyReportingItem.ObservationsDuringPractical || '';
        this.IsHomework = vtDailyReportingItem.IsHomework || '';
        this.IsASToolsChecked = vtDailyReportingItem.IsASToolsChecked || '';
        this.ObservationsAfterPractical = vtDailyReportingItem.ObservationsAfterPractical || '';
        this.IsRespectful = vtDailyReportingItem.IsRespectful || '';
        this.IsStudentQuestioned = vtDailyReportingItem.IsStudentQuestioned || '';
        this.IsStudentParticipated = vtDailyReportingItem.IsStudentParticipated || '';
        this.IsTeacherTaughtwell = vtDailyReportingItem.IsTeacherTaughtwell || '';
        this.ObservationsOverall = vtDailyReportingItem.ObservationsOverall || '';
        this.ObserverFeedback = vtDailyReportingItem.ObserverFeedback || '';
       
       
       
}
}
