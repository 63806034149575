import { FuseUtils } from '@fuse/utils';
import { ModuleUnitSessionModel } from 'app/models/module-unit-session-model';
import { NextmonthPlanModel } from 'app/models/nextMonthPlan.model';


export class NextMonthPlanModel {
    PlanMonth: Date;
    UserId: string;
    ClassTaughtId: string;
    SectionId: string;
    ActivityTypeId: string;
    ModuleId: string;
    UnitId: string;
    TopicIds: string;
    TopicIdValues: any;
    SchoolSectorId: string;
    NextMonthPlanId: string;
    IsActive: boolean;
    RequestType: number;
    VCId: string;
    NMPClassSectionModels: any
    // NMPUnitSessionModels: ModuleUnitSessionModel

    constructor(nextMonthPlanItem?: any) {
        nextMonthPlanItem = nextMonthPlanItem || {};

        this.PlanMonth = nextMonthPlanItem.PlanMonth || '';
        this.UserId = nextMonthPlanItem.UserId || '';
        this.ClassTaughtId = nextMonthPlanItem.ClassTaughtId || '';
        this.SectionId = nextMonthPlanItem.SectionId || '';
        this.ActivityTypeId = nextMonthPlanItem.ActivityTypeId || '';
        this.ModuleId = nextMonthPlanItem.ModuleId || '';
        this.UnitId = nextMonthPlanItem.UnitId || '';
        this.TopicIds = nextMonthPlanItem.TopicIds || '';
        this.TopicIdValues = nextMonthPlanItem.TopicIdValues || '';
        this.SchoolSectorId = nextMonthPlanItem.SchoolSectorId || FuseUtils.NewGuid();
        this.NextMonthPlanId = nextMonthPlanItem.NextMonthPlanId || FuseUtils.NewGuid();
        this.IsActive = nextMonthPlanItem.IsActive || true;
        this.RequestType = nextMonthPlanItem.RequestType || 0;
        this.VCId = nextMonthPlanItem.VCId || '';
        this.NMPClassSectionModels = nextMonthPlanItem.NMPClassSectionModels || '';
        // this.NMPUnitSessionModels = <ModuleUnitSessionModel[]>[];
        

    }

}