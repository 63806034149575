<div id="vt-next-month-plan" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Next Month Plan
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a month plan">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button mat-raised-button [routerLink]="['./','new']" skipLocationChange="true" replaceUrl="false"
                    class="add-vt-daily-reporting-button fuse-white mt-24 mt-md-0">
                    <span>ADD</span>
                </button>

                <a *ngIf="PageRights.IsExport" class="export-excel"
                    (click)="exporter.exportTable('xlsx', {fileName:'nextMonthPlan', sheet: 'nextMonthPlan', Props: {Author: 'Lighthouse'}})">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="vt-next-month-table" #table [dataSource]="tableDataSource"
                #exporter="matTableExporter" [hiddenColumns]="[5]" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SchoolName Column -->
                <ng-container matColumnDef="PlanMonth">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                        Plan Month
                    </mat-header-cell>
                    <mat-cell *matCellDef="let nextMonthPlan">
                        <p class="text-truncate">{{nextMonthPlan.PlanMonth | date: 'MM/yyyy'}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="VocationalTrainer">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Vocational Trainer
                    </mat-header-cell>
                    <mat-cell *matCellDef="let nextMonthPlan" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{nextMonthPlan.VTName}}</p>
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="ClassTaught">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Class Thaught
                    </mat-header-cell>
                    <mat-cell *matCellDef="let nextMonthPlan" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{nextMonthPlan.ClassTaught}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Section">
                    <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide fxShow.gt-xs>
                        Section
                    </mat-header-cell>
                    <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide fxShow.gt-xs>
                        <p class="text-truncate">
                            {{nextMonthPlan.Section }}
                        </p>
                    </mat-cell>
                </ng-container> -->

                <!-- ReportType Column -->
                <!-- <ng-container matColumnDef="ActivityType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        Activity Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let nextMonthPlan" fxHide fxShow.gt-xs>
                        <p class="text-truncate">{{nextMonthPlan.ActivityType}}</p>
                    </mat-cell>
                </ng-container> -->

                <!-- ReportingDate Column -->
                <!-- <ng-container matColumnDef="Module">
                    <mat-header-cell *matHeaderCellDef class="tab-col-15" mat-sort-header fxHide fxShow.gt-xs>
                        Modules
                    </mat-header-cell>
                    <mat-cell *matCellDef="let nextMonthPlan" class="tab-col-15" fxHide fxShow.gt-xs>
                        <p class="text-truncate">
                            {{nextMonthPlan.Module }}
                        </p>
                    </mat-cell>
                </ng-container> -->

                <ng-container matColumnDef="Actions" stickyEnd>
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let nextMonthPlan">
                        <button *ngIf="PageRights.IsView" mat-icon-button
                            [routerLink]="['/next-month-plan', 'view', nextMonthPlan.NextMonthPlanId]"
                            skipLocationChange="true" replaceUrl="false">
                            <mat-icon color="primary">visibility</mat-icon>
                        </button>
                        <span
                            [matTooltip]="monthValidate(nextMonthPlan.PlanMonth) ? 'Cannot edit previous and current months' : null">
                            <button *ngIf="PageRights.IsEdit"
                                mat-icon-button
                                [routerLink]="['/next-month-plan', 'edit', nextMonthPlan.NextMonthPlanId]"
                                skipLocationChange="true" replaceUrl="false">
                                <mat-icon color="primary">edit</mat-icon>
                            </button>
                        </span>


                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let nextMonthPlan; columns: displayedColumns;" class="vt-next-month-plan"
                    matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0" [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>