import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';
import { VCDailyReportingModel } from './vc-daily-reporting.model';
import { FormGroup } from '@angular/forms';
import { VCIndustryExposureVisitModel } from './vc-industry-exposure-visit.model';
import { VCLeaveModel } from './vc-leave.model';
import { VCHolidayModel } from './vc-holiday.model';
import { VCPraticalModel } from "./vc-pratical.model";
import { UserModel } from "app/models/user.model";
import { CommonService } from 'app/services/common.service';
import { VCCommnityWorkModel } from "./vc-communityworkmodel";
import { VCClassObservationModel } from "./vc-class-observation.model";
import { VCHomeVisitModel } from "./vc-home-visit.model";
import { VCHMMeetingModel } from "./vc-hm-meetig.model";



@Injectable()
export class VCDailyReportingService {
    constructor(private http: BaseService, private http1: CommonService) { }

    getVCDailyReportings(): Observable<any> {
        return this.http
            .HttpGet(this.http.Services.VCDailyReporting.GetAll)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.VCDailyReporting.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    getVCDailyReportingById(vcDailyReportingId: string) {
        let requestParams = {
            DataId: vcDailyReportingId
        };

        return this.http
            .HttpPost(this.http.Services.VCDailyReporting.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

    createOrUpdateVCDailyReporting(formData: any) {
        return this.http
            .HttpPost(this.http.Services.VCDailyReporting.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    deleteVCDailyReportingById(vcDailyReportingId: string) {
        var vcDailyReportingParams = {
            DataId: vcDailyReportingId
        };

        return this.http
            .HttpPost(this.http.Services.VCDailyReporting.Delete, vcDailyReportingParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    getDropdownForVCDailyReporting(): Observable<any[]> {
        let reportTypeRequest = this.http.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'VCReportType', SelectTitle: 'Report Type' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([reportTypeRequest]);
    }

    getDropdownForVCPracticalAssessment(academicYearId: string, schoolId: string, vtId: string): Observable<any[]> {
        let classRequest = this.http.GetMasterDataByType({ DataType: 'ClassesForPA', RoleId: academicYearId, UserId: schoolId, ParentId: vtId, SelectTitle: 'Class' });
        let sectorRequest = this.http.GetMasterDataByType({ DataType: 'SectorsByVT', UserId: vtId, SelectTitle: 'Sector' });
        let jobRoleRequest = this.http.GetMasterDataByType({ DataType: 'JobRolesByVT', ParentId: vtId, SelectTitle: 'Job Role' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
        return forkJoin([classRequest, sectorRequest, jobRoleRequest]);
    }

    getDropdownForClass(currentUser: UserModel, id:any): Observable<any[]> {
        console.log("vt name:",currentUser, id);
        
        let classRequest = this.http1.GetClassesByVTId({ DataId: currentUser.LoginId, DataId1: id, SelectTitle: 'Class' });
        

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6        
        return forkJoin([classRequest]);
    }

    getHMByVCId(id:any): Observable<any[]> {
        console.log("vc name:", id);
        
        let classRequest = this.http1.GetHMByVCId(id);
        

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6        
        return forkJoin([classRequest]);
    }

    getDropdownForClassObservation(): Observable<any[]> {
        let moduleRequest = this.http.GetMasterDataByType({ DataType: 'CourseModules', SelectTitle: 'Modules Taught' });

        // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6        
        return forkJoin([moduleRequest]);
    }

    getVCDailyReportingModelFromFormGroup(formGroup: FormGroup, userModel: UserModel): VCDailyReportingModel {
        let dailyReportingModel = new VCDailyReportingModel();

        dailyReportingModel.ReportType = formGroup.get("ReportType").value;
        dailyReportingModel.ReportDate = this.http.getDateTimeFromControl(formGroup.get("ReportDate").value);
        dailyReportingModel.WorkingDayTypeIds = (dailyReportingModel.ReportType == '49') ? formGroup.get("WorkingDayTypeIds").value : [];
        dailyReportingModel.WorkTypeDetails = formGroup.get("WorkTypeDetails").value;
        dailyReportingModel.SchoolId = formGroup.get("SchoolId").value;

        // Industry Exposure Visit
        if (formGroup.controls.industryExposureVisitGroup != null) {
            dailyReportingModel.IndustryExposureVisit = new VCIndustryExposureVisitModel();
            dailyReportingModel.IndustryExposureVisit.TypeOfIndustryLinkage = formGroup.controls.industryExposureVisitGroup.get('TypeOfIndustryLinkage').value;
            dailyReportingModel.IndustryExposureVisit.ContactPersonName = formGroup.controls.industryExposureVisitGroup.get('ContactPersonName').value;
            dailyReportingModel.IndustryExposureVisit.ContactPersonMobile = formGroup.controls.industryExposureVisitGroup.get('ContactPersonMobile').value;
            dailyReportingModel.IndustryExposureVisit.ContactPersonEmail = formGroup.controls.industryExposureVisitGroup.get('ContactPersonEmail').value;
        }

        // Holiday Type
        if (formGroup.controls.holidayGroup != null) {
            dailyReportingModel.Holiday = new VCHolidayModel();
            dailyReportingModel.Holiday.HolidayTypeId = formGroup.controls.holidayGroup.get('HolidayTypeId').value;
            dailyReportingModel.Holiday.HolidayDetails = formGroup.controls.holidayGroup.get('HolidayDetails').value;
        }

        // Leave
        if (formGroup.controls.leaveGroup != null) {
            dailyReportingModel.Leave = new VCLeaveModel();
            dailyReportingModel.Leave.LeaveTypeId = formGroup.controls.leaveGroup.get('LeaveTypeId').value;
            dailyReportingModel.Leave.LeaveModeId = formGroup.controls.leaveGroup.get('LeaveModeId').value;
            dailyReportingModel.Leave.LeaveApprovalStatus = formGroup.controls.leaveGroup.get('LeaveApprovalStatus').value;
            dailyReportingModel.Leave.LeaveApprover = formGroup.controls.leaveGroup.get('LeaveApprover').value;
            dailyReportingModel.Leave.LeaveReason = formGroup.controls.leaveGroup.get('LeaveReason').value;
        }

        // Pratical
        if (formGroup.controls.praticalGroup != null) {
            dailyReportingModel.Pratical = new VCPraticalModel();

            dailyReportingModel.Pratical.AcademicYearId = userModel.AcademicYearId;
            dailyReportingModel.Pratical.VCId = userModel.UserTypeId;
            dailyReportingModel.Pratical.IsPratical = formGroup.controls.praticalGroup.get('IsPratical').value;
            dailyReportingModel.Pratical.SchoolId = formGroup.controls.praticalGroup.get('SchoolId').value;
            dailyReportingModel.Pratical.VTId = formGroup.controls.praticalGroup.get('VTId').value;
            dailyReportingModel.Pratical.SectorId = formGroup.controls.praticalGroup.get('SectorId').value;
            dailyReportingModel.Pratical.JobRoleId = formGroup.controls.praticalGroup.get('JobRoleId').value;
            dailyReportingModel.Pratical.ClassId = formGroup.controls.praticalGroup.get('ClassId').value;
            dailyReportingModel.Pratical.StudentCount = formGroup.controls.praticalGroup.get('StudentCount').value;
            dailyReportingModel.Pratical.VTPresent = formGroup.controls.praticalGroup.get('VTPresent').value;
            dailyReportingModel.Pratical.PresentStudentCount = formGroup.controls.praticalGroup.get('PresentStudentCount').value;
            dailyReportingModel.Pratical.AssesorName = formGroup.controls.praticalGroup.get('AssesorName').value;
            dailyReportingModel.Pratical.AssesorMobileNo = formGroup.controls.praticalGroup.get('AssesorMobileNo').value;
            dailyReportingModel.Pratical.Remarks = formGroup.controls.praticalGroup.get('Remarks').value;
        }

        // Community work   
        if (formGroup.controls.communityWorkGroup != null) {
            dailyReportingModel.CommunityWork = new VCCommnityWorkModel();
            dailyReportingModel.CommunityWork.UserId = formGroup.controls.communityWorkGroup.get('UserId').value;
            dailyReportingModel.CommunityWork.ClassTaughtId = formGroup.controls.communityWorkGroup.get('ClassTaughtId').value;
            dailyReportingModel.CommunityWork.WorkDetails = formGroup.controls.communityWorkGroup.get('WorkDetails').value;
            dailyReportingModel.CommunityWork.NoOfDays = formGroup.controls.communityWorkGroup.get('NoOfDays').value;
            dailyReportingModel.CommunityWork.NoOfStudents = formGroup.controls.communityWorkGroup.get('NoOfStudents').value;
            dailyReportingModel.CommunityWork.ClientName = formGroup.controls.communityWorkGroup.get('ClientName').value;
            dailyReportingModel.CommunityWork.ClientAddress = formGroup.controls.communityWorkGroup.get('ClientAddress').value;
            dailyReportingModel.CommunityWork.TotalExpense = formGroup.controls.communityWorkGroup.get('TotalExpense').value;
            dailyReportingModel.CommunityWork.TotalProfit = formGroup.controls.communityWorkGroup.get('TotalProfit').value;
            dailyReportingModel.CommunityWork.IsRawMaterial = (formGroup.controls.communityWorkGroup.get('IsRawMaterial').value == '0') ? true : false;
            dailyReportingModel.CommunityWork.StudentsExperience = formGroup.controls.communityWorkGroup.get('StudentsExperience').value;
            dailyReportingModel.CommunityWork.Description = formGroup.controls.communityWorkGroup.get('Description').value;
        }

        // Class observation  
        if (formGroup.controls.classObservationGroup != null) {
            dailyReportingModel.VCClassObservation = new VCClassObservationModel();
            dailyReportingModel.VCClassObservation.SchoolId = formGroup.controls.classObservationGroup.get('SchoolId').value;
            dailyReportingModel.VCClassObservation.UserId = formGroup.controls.classObservationGroup.get('UserId').value;
            dailyReportingModel.VCClassObservation.ClassTaughtId = formGroup.controls.classObservationGroup.get('ClassTaughtId').value;
            dailyReportingModel.VCClassObservation.UnitId = formGroup.controls.classObservationGroup.get('UnitId').value;
            dailyReportingModel.VCClassObservation.ModuleId = formGroup.controls.classObservationGroup.get('ModuleId').value;
            dailyReportingModel.VCClassObservation.SubjectId = formGroup.controls.classObservationGroup.get('SubjectId').value;
            dailyReportingModel.VCClassObservation.PresentStudent = formGroup.controls.classObservationGroup.get('PresentStudent').value;
            dailyReportingModel.VCClassObservation.TotalStudent = formGroup.controls.classObservationGroup.get('TotalStudent').value;
            dailyReportingModel.VCClassObservation.ObservationDate = formGroup.controls.classObservationGroup.get('ObservationDate').value;
            dailyReportingModel.VCClassObservation.ObservationDuration = formGroup.controls.classObservationGroup.get('ObservationDuration').value;
            // dailyReportingModel.ClassObservation.StudentsLearned = formGroup.controls.classObservationGroup.get('StudentsLearned').value;
            dailyReportingModel.VCClassObservation.IsLessionPlanPresent = (formGroup.controls.classObservationGroup.get('IsLessionPlanPresent').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsClassroomNeat = (formGroup.controls.classObservationGroup.get('IsClassroomNeat').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsBSToolsChecked = (formGroup.controls.classObservationGroup.get('IsBSToolsChecked').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsChildrenSet = (formGroup.controls.classObservationGroup.get('IsChildrenSet').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.ObservationBeforeSession = formGroup.controls.classObservationGroup.get('ObservationBeforeSession').value;
            dailyReportingModel.VCClassObservation.IsTrainerTaught = (formGroup.controls.classObservationGroup.get('IsTrainerTaught').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsContextTaught = (formGroup.controls.classObservationGroup.get('IsContextTaught').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsGuidelinesForTools = (formGroup.controls.classObservationGroup.get('IsGuidelinesForTools').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsFlowChart = (formGroup.controls.classObservationGroup.get('IsFlowChart').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsSafetyManual = (formGroup.controls.classObservationGroup.get('IsSafetyManual').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.ObservationsBeforePractical = formGroup.controls.classObservationGroup.get('ObservationsBeforePractical').value;
            dailyReportingModel.VCClassObservation.IsStudentFollow = (formGroup.controls.classObservationGroup.get('IsStudentFollow').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsInstructorPerformed = (formGroup.controls.classObservationGroup.get('IsInstructorPerformed').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsPracticalGuided = (formGroup.controls.classObservationGroup.get('IsPracticalGuided').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsStudentTreatedEqual = (formGroup.controls.classObservationGroup.get('IsStudentTreatedEqual').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsQualityCheck = (formGroup.controls.classObservationGroup.get('IsQualityCheck').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsCorrelation = (formGroup.controls.classObservationGroup.get('IsCorrelation').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsStudentShy = (formGroup.controls.classObservationGroup.get('IsStudentShy').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.ObservationsDuringPractical = formGroup.controls.classObservationGroup.get('ObservationsDuringPractical').value;
            dailyReportingModel.VCClassObservation.IsHomework = (formGroup.controls.classObservationGroup.get('IsHomework').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsASToolsChecked = (formGroup.controls.classObservationGroup.get('IsASToolsChecked').value == '0') ? true : false;;
            dailyReportingModel.VCClassObservation.ObservationsAfterPractical = formGroup.controls.classObservationGroup.get('ObservationsAfterPractical').value;
            dailyReportingModel.VCClassObservation.IsRespectful = (formGroup.controls.classObservationGroup.get('IsRespectful').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsStudentQuestioned = (formGroup.controls.classObservationGroup.get('IsStudentQuestioned').value == '0') ? true : false; 
            dailyReportingModel.VCClassObservation.IsStudentParticipated = (formGroup.controls.classObservationGroup.get('IsStudentParticipated').value == '0') ? true : false;;
            dailyReportingModel.VCClassObservation.IsTrainerTaught = (formGroup.controls.classObservationGroup.get('IsTrainerTaught').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.IsTeacherTaughtwell = (formGroup.controls.classObservationGroup.get('IsTeacherTaughtwell').value == '0') ? true : false;
            dailyReportingModel.VCClassObservation.ObservationsOverall = formGroup.controls.classObservationGroup.get('ObservationsOverall').value;
            dailyReportingModel.VCClassObservation.ObserverFeedback = formGroup.controls.classObservationGroup.get('ObserverFeedback').value;
            }

            //Home Visit
        if (formGroup.controls.homeVisitGroup != null) {
            console.log("Student Id:",formGroup.controls.homeVisitGroup.get('StudentId').value);
            
            dailyReportingModel.HomeVisit = new VCHomeVisitModel();
            dailyReportingModel.HomeVisit.DateOfVisit = formGroup.controls.homeVisitGroup.get('DateOfVisit').value;
            dailyReportingModel.HomeVisit.PurposeOfVisit = formGroup.controls.homeVisitGroup.get('PurposeOfVisit').value;
            dailyReportingModel.HomeVisit.UserId = formGroup.controls.homeVisitGroup.get('UserId').value;
            dailyReportingModel.HomeVisit.ClassTaughtId = formGroup.controls.homeVisitGroup.get('ClassTaughtId').value;
            dailyReportingModel.HomeVisit.ClassSectionIds = formGroup.controls.homeVisitGroup.get('ClassSectionIds').value;
            dailyReportingModel.HomeVisit.StudentId = formGroup.controls.homeVisitGroup.get('StudentId').value;
            dailyReportingModel.HomeVisit.AcademicYearId = formGroup.controls.homeVisitGroup.get('AcademicYearId').value;
            dailyReportingModel.HomeVisit.FatherName = formGroup.controls.homeVisitGroup.get('FatherName').value;
            dailyReportingModel.HomeVisit.MotherName = formGroup.controls.homeVisitGroup.get('MotherName').value;
            dailyReportingModel.HomeVisit.FatherEducation = formGroup.controls.homeVisitGroup.get('FatherEducation').value; 
            dailyReportingModel.HomeVisit.MotherEducation = formGroup.controls.homeVisitGroup.get('MotherEducation').value;
            dailyReportingModel.HomeVisit.Village = formGroup.controls.homeVisitGroup.get('Village').value;
            dailyReportingModel.HomeVisit.LandMark = formGroup.controls.homeVisitGroup.get('LandMark').value;
            dailyReportingModel.HomeVisit.ContactNum = formGroup.controls.homeVisitGroup.get('ContactNum').value;
            dailyReportingModel.HomeVisit.MotherAge = formGroup.controls.homeVisitGroup.get('MotherAge').value;
            dailyReportingModel.HomeVisit.FatherAge = formGroup.controls.homeVisitGroup.get('FatherAge').value;
            dailyReportingModel.HomeVisit.StudentMSFC = formGroup.controls.homeVisitGroup.get('StudentMSFC').value;
            dailyReportingModel.HomeVisit.MainSouce = formGroup.controls.homeVisitGroup.get('MainSouce').value;
            dailyReportingModel.HomeVisit.AdditionalSource = formGroup.controls.homeVisitGroup.get('AdditionalSource').value;
            dailyReportingModel.HomeVisit.Generation = formGroup.controls.homeVisitGroup.get('Generation').value;
            dailyReportingModel.HomeVisit.SibilingsCount = formGroup.controls.homeVisitGroup.get('SibilingsCount').value;
            dailyReportingModel.HomeVisit.SilbilingType = formGroup.controls.homeVisitGroup.get('SilbilingType').value;
            dailyReportingModel.HomeVisit.IsParentAware = (formGroup.controls.homeVisitGroup.get('IsParentAware').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.IsMSChildSpoketoParent = (formGroup.controls.homeVisitGroup.get('IsMSChildSpoketoParent').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.IsMSpracticalworkdone = (formGroup.controls.homeVisitGroup.get('IsMSpracticalworkdone').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.ChildSharedDetails = formGroup.controls.homeVisitGroup.get('ChildSharedDetails').value;
            dailyReportingModel.HomeVisit.IsParentWilling = (formGroup.controls.homeVisitGroup.get('IsParentWilling').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.KindOfEducation = formGroup.controls.homeVisitGroup.get('KindOfEducation').value;
            dailyReportingModel.HomeVisit.StudentDropOut = formGroup.controls.homeVisitGroup.get('StudentDropOut').value;
            dailyReportingModel.HomeVisit.DropoutReason = formGroup.controls.homeVisitGroup.get('DropoutReason').value;
            dailyReportingModel.HomeVisit.IsSchoolingAwareness = (formGroup.controls.homeVisitGroup.get('IsSchoolingAwareness').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.DetailsKnown = formGroup.controls.homeVisitGroup.get('DetailsKnown').value;
            dailyReportingModel.HomeVisit.Reaction = formGroup.controls.homeVisitGroup.get('Reaction').value;
            dailyReportingModel.HomeVisit.IsChronicallyIll = (formGroup.controls.homeVisitGroup.get('IsChronicallyIll').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.IllCount = formGroup.controls.homeVisitGroup.get('IllCount').value;
            dailyReportingModel.HomeVisit.IsParentCommunicate = (formGroup.controls.homeVisitGroup.get('IsParentCommunicate').value == '0') ? true : false;
            dailyReportingModel.HomeVisit.CommunicationDetails = formGroup.controls.homeVisitGroup.get('CommunicationDetails').value;
            dailyReportingModel.HomeVisit.ImportantDetails = formGroup.controls.homeVisitGroup.get('ImportantDetails').value;
            dailyReportingModel.HomeVisit.Remarks = formGroup.controls.homeVisitGroup.get('Remarks').value;
            dailyReportingModel.HomeVisit.Hygienerate = formGroup.controls.homeVisitGroup.get('Hygienerate').value;
        }

        if (formGroup.controls.hmMeetingGroup != null) {
            dailyReportingModel.HMMeeting =  new VCHMMeetingModel();

            dailyReportingModel.HMMeeting.MembersCount = formGroup.controls.hmMeetingGroup.get('MembersCount').value;
            // dailyReportingModel.HMMeeting.Members = formGroup.controls.homeVisitGroup.get('Members').value;
            dailyReportingModel.HMMeeting.MeetingDetails = formGroup.controls.hmMeetingGroup.get('MeetingDetails').value;

        }
        return dailyReportingModel;
    }
}

