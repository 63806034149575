import { Component, OnInit, NgZone, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "app/common/base/base.component";
import { CommonService } from "app/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import { DialogService } from "app/common/confirm-dialog/dialog.service";
import { RouteConstants } from "app/constants/route.constant";
import { VCDailyReportingService } from "../vc-daily-reporting.service";
import { VCDailyReportingModel } from "../vc-daily-reporting.model";
import { DropdownModel } from "app/models/dropdown.model";
import { VCLeaveModel } from "../vc-leave.model";
import { VCHolidayModel } from "../vc-holiday.model";
import { VCIndustryExposureVisitModel } from "../vc-industry-exposure-visit.model";
import { VCCommnityWorkModel } from "../vc-communityworkmodel";
import { VocationalTrainerService } from "../../vocational-trainers/vocational-trainer.service";
import { VCHMMeetingModel } from "../vc-hm-meetig.model";
import { VCHomeVisitModel } from "../vc-home-visit.model";
import { VCClassObservationModel } from "../vc-class-observation.model";
import { StudentClassDetailService } from "../../student-class-details/student-class-detail.service";
import { BaseService } from "app/services/base.service";
import { FileUploadModel } from "app/models/file.upload.model";
import { VCPraticalModel } from '../vc-pratical.model';

import { StudentLearnModel } from "app/models/student-learn.model";
import { ErrorDialogService } from "app/common/error-dialog/error-dialog.service";
import { VTVCDailyReportingService } from "app/main/vt-vc-daily-report.service";
import { DatePipe } from "@angular/common";
import { HMMeetInvolvedModel } from 'app/models/Hmmeet.model';
import { log } from "console";
import { forEach } from "lodash";


@Component({
    selector: "vc-daily-reporting",
    templateUrl: "./create-vc-daily-reporting.component.html",
    styleUrls: ["./create-vc-daily-reporting.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CreateVCDailyReportingComponent
    extends BaseComponent<VCDailyReportingModel>
    implements OnInit {
    vcDailyReportingForm: FormGroup;
    homeVisitGroup: FormGroup;
    vcDailyReportingModel: VCDailyReportingModel;
    vocationalCoordinatorList: [DropdownModel];
    reportTypeList: [DropdownModel];
    workTypeList: [DropdownModel];
    industryLinkageList: [DropdownModel];
    leaveApproverList: [DropdownModel];
    schoolList: [DropdownModel];
    MemberList: HMMeetInvolvedModel[];
    filteredMemberList: HMMeetInvolvedModel[];
    VTList: DropdownModel[];
    // EodStudentLearning: StudentLearnModel[];

    isAllowHoliday: boolean = false;
    isAllowLeave: boolean = false;
    isAllowSchool: boolean = false;
    isAllowIndustryExposureVisit: boolean = false;
    isAllowWorkDetails: boolean = false;
    isAllowPractical: boolean = false;
    paSchoolList: [DropdownModel];
    filteredPASchoolItems: any;
    vtList: [DropdownModel];
    sectorList: [DropdownModel];
    jobRoleList: [DropdownModel];
    classList: any = [];
    observationDuration = new FormControl('');
    
    holidayTypeList: any;
    observationDayList: any;
    leaveTypeList: any;
    leaveModeList: any;
    minReportingDate: Date;
    isAllowCommunityWork: boolean = false;
    vtId: any;
    isAllowHMmeet: boolean = false;
    isAllowHomeVisit: boolean = false;
    ClassId: any;
    sectionList: any;
    studentList: any;
    filteredStudentList: any;
    academicYearList: any = [DropdownModel];
    filteredVTItems: any;
    generationList: any = [
        { Id: "1", value: "First" },
        { Id: "2", value: "Second" },
        { Id: "3", value: "Third" },
    ];
    ageList: any = [
        { Id: "eldest", value: "Eldest" },
        { Id: "youngest", value: "Youngest" },
        { Id: "other", value: "Other" },
    ];
    hygeinList: any = [
        { Id: "excellent", value: "Excellent" },
        { Id: "acceptable", value: "Acceptable" },
        { Id: "notacceptable", value: "Not acceptable" },
    ];
    educationList: any = [
        { Id: "illeterate", value: "Illeterate" },
        { Id: "upto5", value: "Upto 5th standard" },
        { Id: "upto10", value: "Upto 10th standard" },
        { Id: "upto12", value: "Upto 12 th standard" },
        { Id: "graduate", value: "Graduate" },
        { Id: "pgraduate", value: "Post Graduate" },
        { Id: "iti", value: "ITI" },
    ];
    EodStudentLearning: any = [];
    unitList: any;
    moduleTaughtList: any;

    filteredSchoolItems: any;
    isIsMSpracticalworkdoneStatus: boolean = false;
    isParentIntrestedEdu: boolean = false;
    isKeentoknow: boolean = false;
    isChronical: boolean = false;
    isParentCommunicatd: boolean = false;
    isAllowClassObservation: boolean = false;
    isStudentLearn: boolean = false;
    isAddHMMember: boolean = false;
    isRawMaterialBill: boolean = false;
    isOtherSelected: boolean = false;
    meetList: any = [];
    otherMemberList: any = [];
    academicYear: any;
    presentyear: any = "";
    MaterialBillFilesFile: FileUploadModel;
    vcDailyReport: any;
    workTypesArray: any;

    constructor(
        public commonService: CommonService,
        public router: Router,
        public routeParams: ActivatedRoute,
        public snackBar: MatSnackBar,
        private zone: NgZone,
        private route: ActivatedRoute,
        private vcDailyReportingService: VCDailyReportingService,
        private dialogService: DialogService,
        private formBuilder: FormBuilder,
        private vocationalTrainerService: VocationalTrainerService,
        private studentClassDetailService: StudentClassDetailService,
        private baseService: BaseService,
        private errorDialog: ErrorDialogService,
        private vtvcDailyReportingService: VTVCDailyReportingService,
        private datePipe: DatePipe,
    ) {
        super(commonService, router, routeParams, snackBar);

        // Set the default vcDailyReporting Model
        this.vcDailyReportingModel = new VCDailyReportingModel();

        let dateOfAllocation = new Date(this.UserModel.DateOfAllocation);
        let maxDate = new Date(Date.now());

        let Time = maxDate.getTime() - dateOfAllocation.getTime();
        let Days = Math.floor(Time / (1000 * 3600 * 24));
        if (Days < this.Constants.BackDatedReportingDays) {
            this.minReportingDate = new Date(this.UserModel.DateOfAllocation);
        } else {
            let past7days = maxDate;
            past7days.setDate(  
                past7days.getDate() - this.Constants.BackDatedReportingDays
            );
            this.minReportingDate = past7days;
        }
        this.MaterialBillFilesFile = new FileUploadModel();
    }

    ngOnInit(): void {
        // this.meetList = <DropdownModel[]>[];

        this.baseService
            .GetMasterDataByType({
                DataType: "AcademicYears",
                SelectTitle: "Academic Year",
            })
            .subscribe((response) => {
                this.academicYearList = response.Results;

                this.academicYear = this.academicYearList.find(
                    (ay) => ay.IsSelected == true
                );
                this.presentyear = this.academicYear.Id;

                // this.vcDailyReportingModel.HomeVisit.AcademicYearId = this.academicYear.Id
            });

        this.vcDailyReportingService
            .getDropdownForVCDailyReporting()
            .subscribe((results) => {
                if (results[0].Success) {
                    this.reportTypeList = results[0].Results;

                    this.route.paramMap.subscribe((params) => {
                        if (params.keys.length > 0) {
                            this.PageRights.ActionType =
                                params.get("actionType");

                            if (
                                this.PageRights.ActionType ==
                                this.Constants.Actions.New
                            ) {
                                this.vcDailyReportingModel =
                                    new VCDailyReportingModel();
                            } else {
                                var vcDailyReportingId: string =
                                    params.get("vcDailyReportingId");

                                this.vcDailyReportingService
                                    .getVCDailyReportingById(vcDailyReportingId)
                                    .subscribe((response: any) => {
                                        this.vcDailyReportingModel =
                                            response.Result;
                                        this.vcDailyReport = response.Result;

                                        if (
                                            this.PageRights.ActionType ==
                                            this.Constants.Actions.Edit
                                        )
                                            this.vcDailyReportingModel.RequestType =
                                                this.Constants.PageType.Edit;
                                        else if (
                                            this.PageRights.ActionType ==
                                            this.Constants.Actions.View
                                        ) {
                                            this.vcDailyReportingModel.RequestType =
                                                this.Constants.PageType.View;
                                            this.PageRights.IsReadOnly = true;
                                        }

                                        if (this.vcDailyReportingModel.Pratical != null) {
                                            this.onPratical(this.vcDailyReportingModel.Pratical.IsPratical);
                                            this.onChangeSchool(this.vcDailyReportingModel.Pratical.SchoolId).then(response => {
                                                this.onChangeVT(this.vcDailyReportingModel.Pratical.VTId).then(response => {
                                                    this.vcDailyReportingForm.controls.praticalGroup.get('StudentCount').disable();
                                                });
                                            });
                                        }

                                        this.vcDailyReportingForm =
                                            this.createVCDailyReportingForm();
                                        this.onChangeReportType(
                                            this.vcDailyReportingModel
                                                .ReportType
                                        ).then(
                                            (PageRightsPageRightsresponse) => {
                                                if (
                                                    this.vcDailyReportingModel
                                                        .WorkingDayTypeIds
                                                        .length > 0
                                                ) {
                                                    (this.vcDailyReportingModel.WorkingDayTypeIds).forEach(element => {                        
                                                        let result = this.workTypeList.find(ele => ele.Id === element)
                                                        const index = (this.vcDailyReportingModel.WorkingDayTypeIds).indexOf(element); 
                                                        this.vcDailyReportingModel.WorkingDayTypeIds.splice(index, 1, result.Name);
                                                    });
                                                    this.onChangeWorkingType(
                                                        this
                                                            .vcDailyReportingModel
                                                            .WorkingDayTypeIds
                                                    );
                                                }
                                            }
                                        );
                                    });
                            }
                        }
                    });
                }
            });

        this.vcDailyReportingForm = this.createVCDailyReportingForm();
    }

    onChangeReportingDate(): boolean {
        let reportingDate = this.vcDailyReportingForm.get("ReportDate").value;

        if (reportingDate != null && new Date(reportingDate).getDay() == 0) {
            this.dialogService.openShowDialog("User cannot submit the VC Daily Reporting on Sunday");
            return false
        }
        return true
    }

    formatInput(event: any) {
        const input = event.target as HTMLInputElement;
        let formattedValue = input.value.replace(/\D/g, '');
    
        if (formattedValue.length > 4) {
          formattedValue = formattedValue.slice(0, 4);
        }
    
        const hours = formattedValue.slice(0, 2);
        const minutes = formattedValue.slice(2);
    
        if (+minutes >= 60) {
          formattedValue = hours + ':59';
        } else {
          formattedValue = hours + ':' + minutes;
        }
    
        input.value = formattedValue;
        this.observationDuration.setValue(formattedValue);
      }

    onChangeReportType(reportTypeId): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            this.commonService
                .GetMasterDataByType(
                    {
                        DataType: "VCWorkType",
                        ParentId: reportTypeId,
                        SelectTitle: "Working Day",
                    },
                    false
                )
                .subscribe((response: any) => {
                    if (response.Success) {
                        this.resetReportTypeFormGroups();

                        // On Leave
                        if (reportTypeId == 47) {
                            this.isAllowLeave = true;
                            this.vcDailyReportingModel.Leave = new VCLeaveModel(
                                this.vcDailyReportingModel.Leave
                            );

                            this.vcDailyReportingForm = this.formBuilder.group({
                                ...this.vcDailyReportingForm.controls,

                                leaveGroup: this.formBuilder.group({
                                    LeaveTypeId: new FormControl({
                                        value: this.vcDailyReportingModel.Leave
                                            .LeaveTypeId,
                                        disabled: this.PageRights.IsReadOnly,
                                    }),
                                    LeaveModeId: new FormControl({
                                        value: (this.vcDailyReportingModel.Leave.LeaveModeId === '0'? '' :(this.vcDailyReportingModel.Leave.LeaveModeId === '2'?'383':'384')),
                                        disabled: this.PageRights.IsReadOnly,
                                    }),
                                    LeaveApprovalStatus: new FormControl({
                                        value: this.vcDailyReportingModel.Leave
                                            .LeaveApprovalStatus,
                                        disabled: this.PageRights.IsReadOnly,
                                    }),
                                    LeaveApprover: new FormControl({
                                        value: this.vcDailyReportingModel.Leave
                                            .LeaveApprover,
                                        disabled: this.PageRights.IsReadOnly,
                                    }),
                                    LeaveReason: new FormControl(
                                        {
                                            value: this.vcDailyReportingModel
                                                .Leave.LeaveReason,
                                            disabled:
                                                this.PageRights.IsReadOnly,
                                        },
                                        [Validators.maxLength(350)]
                                    ),
                                }),
                            });

                            this.leaveTypeList = response.Results;
                            this.onChangeLeaveApprovalStatus();
                            this.commonService
                                .GetMasterDataByType({
                                    DataType: "DataValues",
                                    ParentId: "VCLeaveApprover",
                                    SelectTitle: "Leave Approver",
                                })
                                .subscribe((response) => {
                                    if (response.Success) {
                                        this.leaveApproverList =
                                            response.Results;
                                    }
                                });

                            this.commonService
                                .GetMasterDataByType({
                                    DataType: "DataValues",
                                    ParentId: "LeaveMode",
                                    SelectTitle: "Leave Mode",
                                })
                                .subscribe((response) => {
                                    if (response.Success) {
                                        this.leaveModeList = response.Results;
                                    }
                                });
                        }

                        // Holiday/ School Off
                        else if (reportTypeId == 48) {
                            this.isAllowHoliday = true;
                            this.vcDailyReportingModel.Holiday =
                                new VCHolidayModel(
                                    this.vcDailyReportingModel.Holiday
                                );

                            this.vcDailyReportingForm = this.formBuilder.group({
                                ...this.vcDailyReportingForm.controls,

                                holidayGroup: this.formBuilder.group({
                                    HolidayTypeId: new FormControl(
                                        {
                                            value: this.vcDailyReportingModel
                                                .Holiday.HolidayTypeId,
                                            disabled:
                                                this.PageRights.IsReadOnly,
                                        },
                                        Validators.maxLength(50)
                                    ),
                                    HolidayDetails: new FormControl(
                                        {
                                            value: this.vcDailyReportingModel
                                                .Holiday.HolidayDetails,
                                            disabled:
                                                this.PageRights.IsReadOnly,
                                        },
                                        Validators.maxLength(250)
                                    ),
                                }),
                            });

                            this.holidayTypeList = response.Results;
                        } else {
                            this.vcDailyReportingForm.controls[
                                "WorkingDayTypeIds"
                            ].setValidators([Validators.required]);
                            this.vcDailyReportingForm.controls[
                                "WorkingDayTypeIds"
                            ].updateValueAndValidity();

                            this.workTypeList = response.Results;
                        }

                        this.onChangeReportingDate();
                        resolve(true);
                    }
                });
        });
        return promise;
    }

    getClassDetails(id): void {
        this.vtId = id;
        this.vcDailyReportingService
            .getDropdownForClass(this.UserModel, id)
            .subscribe((response) => {
                if (response[0].Success) {
                    this.classList = response[0].Results;
                }
            });
    }



    onChangeWorkingType(workingTypes): void {
        this.resetWorkTypesFormGroups();

        workingTypes.forEach((workTypeId) => {
            //1. Industry Exposure Visit
            if (workTypeId == "Industry Exposure Visit") {
                this.isAllowIndustryExposureVisit = true;
                this.vcDailyReportingModel.IndustryExposureVisit =
                    new VCIndustryExposureVisitModel(
                        this.vcDailyReportingModel.IndustryExposureVisit
                    );

                this.vcDailyReportingForm = this.formBuilder.group({
                    ...this.vcDailyReportingForm.controls,

                    industryExposureVisitGroup: this.formBuilder.group({
                        TypeOfIndustryLinkage: new FormControl({
                            value: this.vcDailyReportingModel
                                .IndustryExposureVisit.TypeOfIndustryLinkage,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ContactPersonName: new FormControl(
                            {
                                value: this.vcDailyReportingModel
                                    .IndustryExposureVisit.ContactPersonName,
                                disabled: this.PageRights.IsReadOnly,
                            },
                            [
                                Validators.maxLength(50),
                                Validators.pattern(
                                    this.Constants.Regex.CharsWithSpace
                                ),
                            ]
                        ),
                        ContactPersonMobile: new FormControl(
                            {
                                value: this.vcDailyReportingModel
                                    .IndustryExposureVisit.ContactPersonMobile,
                                disabled: this.PageRights.IsReadOnly,
                            },
                            [
                                Validators.pattern(
                                    this.Constants.Regex.MobileNumber
                                ),
                                Validators.minLength(10),
                                Validators.maxLength(10),
                            ]
                        ),
                        ContactPersonEmail: new FormControl(
                            {
                                value: this.vcDailyReportingModel
                                    .IndustryExposureVisit.ContactPersonEmail,
                                disabled: this.PageRights.IsReadOnly,
                            },
                            [
                                Validators.maxLength(100),
                                Validators.pattern(this.Constants.Regex.Email),
                            ]
                        ),
                    }),
                });

                this.commonService
                    .GetMasterDataByType({
                        DataType: "DataValues",
                        ParentId: "IndustryLinkageType",
                        SelectTitle: "Industry Linkage",
                    })
                    .subscribe((response) => {
                        if (response.Success) {
                            this.industryLinkageList = response.Results;
                        }
                    });
            }

            //School
            else if (workTypeId == "School Visit/ Visits") {
                this.isAllowSchool = true;

                this.getSchoolbyVCId();

                // this.commonService.GetSchoolsByVCId({ DataId: this.UserModel.LoginId, DataId1: this.UserModel.UserTypeId, SelectTitle: 'School' }).subscribe((response) => {
                //   if (response.Success) {
                //     this.schoolList = response.Results;
                //   }
                // });
            } else if (workTypeId == "Community Work") {
                this.vocationalTrainerService
                    .GetAllByCriteria({
                        AcademicYearId: this.UserModel.AcademicYearId,
                        VTPId: null,
                        VCId: this.UserModel.UserTypeId,
                        SocialCategoryId: null,
                        Status: null,
                        Name: null,
                        CharBy: null,
                        PageIndex: 0,
                    })
                    .subscribe(
                        (response) => {
                            if (response.Results.length === 0) {
                                this.errorDialog.popupErrorMessage(
                                    "There is no VT data"
                                );
                            } else {
                                response.Results.forEach(function (obj) {
                                    if (obj.hasOwnProperty("IsActive")) {
                                        obj.IsActive = obj.IsActive
                                            ? "Yes"
                                            : "No";
                                    }
                                    if (obj.hasOwnProperty("IsResigned")) {
                                        obj.IsResigned = obj.IsResigned
                                            ? "Yes"
                                            : "No";
                                    }
                                    delete obj.TotalRows;
                                });
                                this.vtList = response.Results;
                                this.filteredVTItems = this.vtList.slice();
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                this.isAllowCommunityWork = true;
                this.vcDailyReportingModel.CommunityWork =
                    new VCCommnityWorkModel(
                        this.vcDailyReportingModel.CommunityWork
                    );




                if (this.PageRights.ActionType == this.Constants.Actions.View) {
                    let homeVisit = this.vcDailyReport.CommunityWork;

                    this.getClassDetails(
                        this.vcDailyReportingModel.CommunityWork.UserId
                    );
                }

                this.vcDailyReportingForm = this.formBuilder.group({
                    ...this.vcDailyReportingForm.controls,

                    communityWorkGroup: this.formBuilder.group({
                        UserId: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .UserId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ClassTaughtId: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .ClassTaughtId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        WorkDetails: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .WorkDetails,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        NoOfDays: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .NoOfDays,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        NoOfStudents: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .NoOfStudents,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ClientName: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .ClientName,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ClientAddress: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .ClientAddress,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        TotalExpense: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .TotalExpense,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsRawMaterial: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .IsRawMaterial,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        TotalProfit: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .TotalProfit,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        StudentsExperience: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .StudentsExperience,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        MaterialBillFile: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .MaterialBillFile,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        Description: new FormControl({
                            value: this.vcDailyReportingModel.CommunityWork
                                .Description,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                    }),
                });

                if (this.PageRights.ActionType == this.Constants.Actions.View) {
                    this.vcDailyReportingForm
                        .get("communityWorkGroup.IsRawMaterial")
                        .setValue(
                            this.vcDailyReportingModel.CommunityWork
                                .IsRawMaterial == true
                                ? "0"
                                : "1"
                        );
                }
            } //pratical
            else if (workTypeId == '10') {
                this.isAllowPractical = true;
                this.vcDailyReportingModel.Pratical = new VCPraticalModel(this.vcDailyReportingModel.Pratical);
                this.vcDailyReportingForm = this.formBuilder.group({
                    ...this.vcDailyReportingForm.controls,

                    praticalGroup: this.formBuilder.group({
                        IsPratical: new FormControl({ value: this.vcDailyReportingModel.Pratical.IsPratical, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
                        SchoolId: new FormControl({ value: this.vcDailyReportingModel.Pratical.SchoolId, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
                        VTId: new FormControl({ value: this.vcDailyReportingModel.Pratical.VTId, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
                        SectorId: new FormControl({ value: this.vcDailyReportingModel.Pratical.SectorId, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
                        JobRoleId: new FormControl({ value: this.vcDailyReportingModel.Pratical.JobRoleId, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
                        ClassId: new FormControl({ value: this.vcDailyReportingModel.Pratical.ClassId, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
                        StudentCount: new FormControl({ value: this.vcDailyReportingModel.Pratical.StudentCount, disabled: this.PageRights.IsReadOnly }, [Validators.required, , Validators.pattern(this.Constants.Regex.Number)]),
                        VTPresent: new FormControl({ value: this.vcDailyReportingModel.Pratical.VTPresent, disabled: this.PageRights.IsReadOnly }, [Validators.required]),
                        PresentStudentCount: new FormControl({ value: this.vcDailyReportingModel.Pratical.PresentStudentCount, disabled: this.PageRights.IsReadOnly }, [Validators.required, , Validators.pattern(this.Constants.Regex.Number)]),
                        AssesorName: new FormControl({ value: this.vcDailyReportingModel.Pratical.AssesorName, disabled: this.PageRights.IsReadOnly }, [Validators.required, Validators.maxLength(100), Validators.pattern(this.Constants.Regex.CharWithTitleCaseSpaceAndSpecialChars)]),
                        AssesorMobileNo: new FormControl({ value: this.vcDailyReportingModel.Pratical.AssesorMobileNo, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(10)]),
                        Remarks: new FormControl({ value: this.vcDailyReportingModel.Pratical.Remarks, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(350)]),
                    })
                });
            }


            else if (workTypeId == "HM Meeting") {
                this.IsLoading = true;

                this.getVTlist();
                this.getHM(this.UserModel.UserTypeId);
                this.isAllowHMmeet = true;
                this.IsLoading = false;

                this.vcDailyReportingModel.HMMeeting = new VCHMMeetingModel(
                    this.vcDailyReportingModel.HMMeeting
                );

                this.vcDailyReportingForm = this.formBuilder.group({
                    ...this.vcDailyReportingForm.controls,

                    hmMeetingGroup: this.formBuilder.group({
                        MembersCount: new FormControl({
                            value: this.vcDailyReportingModel.HMMeeting
                                .MembersCount,
                            disabled: true,
                        }),
                        Members: new FormControl({
                            value: this.vcDailyReportingModel.HMMeeting.Members,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        MeetingDetails: new FormControl(
                            {
                                value: this.vcDailyReportingModel.HMMeeting
                                    .MeetingDetails,
                                disabled: this.PageRights.IsReadOnly,
                            },
                            [Validators.maxLength(100)]
                        ),
                        OtherMember: new FormControl({ value: this.vcDailyReportingModel.HMMeeting.OtherMember, disabled: this.PageRights.IsReadOnly }),

                    }),
                });
            }

            if (workTypeId == "Home Visit") {
                this.IsLoading = true;
                let year: any;
                this.getVTlist();

                this.IsLoading = false;

                this.isAllowHomeVisit = true;
                this.vcDailyReportingModel.HomeVisit = new VCHomeVisitModel(
                    this.vcDailyReportingModel.HomeVisit
                );

                this.vcDailyReportingForm = this.formBuilder.group({
                    ...this.vcDailyReportingForm.controls,

                    homeVisitGroup: this.formBuilder.group({
                        DateOfVisit: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .DateOfVisit,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        PurposeOfVisit: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .PurposeOfVisit,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        UserId: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit.UserId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ClassTaughtId: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .ClassTaughtId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ClassSectionIds: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .ClassSectionIds,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        StudentId: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .StudentId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        AcademicYearId: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .AcademicYearId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        FatherName: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .FatherName,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        MotherName: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .MotherName,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        FatherEducation: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .FatherEducation,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        MotherEducation: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .MotherEducation,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        Village: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit.Village,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        LandMark: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .LandMark,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ContactNum: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .ContactNum,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        MotherAge: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .MotherAge,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        FatherAge: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .FatherAge,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        StudentMSFC: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .StudentMSFC,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        MainSouce: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .MainSouce,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        AdditionalSource: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .AdditionalSource,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        Generation: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .Generation,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        SibilingsCount: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .SibilingsCount,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        SilbilingType: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .SilbilingType,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsParentAware: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .IsParentAware,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsMSChildSpoketoParent: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .IsMSChildSpoketoParent,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsMSpracticalworkdone: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .IsMSpracticalworkdone,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ChildSharedDetails: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .ChildSharedDetails,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsParentWilling: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .IsParentWilling,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        KindOfEducation: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .KindOfEducation,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        StudentDropOut: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .StudentDropOut,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        DropoutReason: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .DropoutReason,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsSchoolingAwareness: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .IsSchoolingAwareness,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        DetailsKnown: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .DetailsKnown,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        Reaction: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .Reaction,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsChronicallyIll: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .IsChronicallyIll,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IllCount: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .IllCount,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsParentCommunicate: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .IsParentCommunicate,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        CommunicationDetails: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .CommunicationDetails,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ImportantDetails: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .ImportantDetails,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ImpInHV2: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .ImpInHV2,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ImpInHV3: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .ImpInHV3,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        Remarks: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit.Remarks,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        Hygienerate: new FormControl({
                            value: this.vcDailyReportingModel.HomeVisit
                                .Hygienerate,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                    }),
                });

                this.vcDailyReportingForm.controls.homeVisitGroup[
                    "controls"
                ].AcademicYearId.setValue(this.academicYear.Id);

                // let year = this.academicYearList.find(ay => ay.IsSelected == true)

                // this.vcDailyReportingForm.get('homeVisitGroup.AcademicYearId').setValue(year.Id)

                if (this.PageRights.ActionType == this.Constants.Actions.View) {
                    let homeVisit = this.vcDailyReport.HomeVisitView;

                    this.getClassDetails(homeVisit.VTId);
                    this.onChangeClass(homeVisit.ClassTaughtId);
                    this.onChangeSectionForTaught(homeVisit.SectionId);
                    this.onViewHomeVisit();
                }
            }

            // Class observation
            if (workTypeId == "Class Observation") {
                this.isAllowClassObservation = true;
                this.IsLoading = true;
                this.getVTlist();
                this.getSchoolbyVCId();
                this.vcDailyReportingService
                    .getDropdownForClassObservation()
                    .subscribe((response) => {
                        if (response[0].Success) {
                            this.moduleTaughtList = response[0].Results;
                        }
                    });
                this.IsLoading = false;

                this.vcDailyReportingModel.VCClassObservation =
                    new VCClassObservationModel(
                        this.vcDailyReportingModel.VCClassObservation
                    );

                this.vcDailyReportingForm = this.formBuilder.group({
                    ...this.vcDailyReportingForm.controls,

                    classObservationGroup: this.formBuilder.group({
                        SchoolId: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .SchoolId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        UserId: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .UserId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ClassTaughtId: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ClassTaughtId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ModuleId: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ModuleId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        UnitId: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .UnitId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        SubjectId: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .SubjectId,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        PresentStudent: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .PresentStudent,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        TotalStudent: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .TotalStudent,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ObservationDate: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ObservationDate,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ObservationDuration: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ObservationDuration,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        StudentsLearned: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .StudentsLearned,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsLessionPlanPresent: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsLessionPlanPresent,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsClassroomNeat: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsClassroomNeat,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsBSToolsChecked: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsBSToolsChecked,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsChildrenSet: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsChildrenSet,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ObservationBeforeSession: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ObservationBeforeSession,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsTrainerTaught: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsTrainerTaught,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsContextTaught: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsContextTaught,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsGuidelinesForTools: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsGuidelinesForTools,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsFlowChart: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsFlowChart,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsSafetyManual: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsSafetyManual,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ObservationsBeforePractical: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ObservationsBeforePractical,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsStudentFollow: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsStudentFollow,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsInstructorPerformed: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsInstructorPerformed,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsPracticalGuided: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsPracticalGuided,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsStudentTreatedEqual: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsStudentTreatedEqual,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsQualityCheck: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsQualityCheck,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsCorrelation: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsCorrelation,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsStudentShy: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsStudentShy,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ObservationsDuringPractical: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ObservationsDuringPractical,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsHomework: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsHomework,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsASToolsChecked: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsASToolsChecked,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ObservationsAfterPractical: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ObservationsAfterPractical,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsRespectful: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsRespectful,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsStudentQuestioned: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsStudentQuestioned,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsStudentParticipated: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsStudentParticipated,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        IsTeacherTaughtwell: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .IsTeacherTaughtwell,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ObservationsOverall: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ObservationsOverall,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                        ObserverFeedback: new FormControl({
                            value: this.vcDailyReportingModel.VCClassObservation
                                .ObserverFeedback,
                            disabled: this.PageRights.IsReadOnly,
                        }),
                    }),
                });

                if (this.PageRights.ActionType == this.Constants.Actions.View) {
                    this.vcDailyReportingForm
                        .get("classObservationGroup.ObservationDate")
                        .setValue(this.datePipe.transform(this.vcDailyReportingModel.VCClassObservation.ObservationDate, 'yyyy-MM-dd'));

                    this.onChangeSchool(
                        this.vcDailyReportingModel.VCClassObservation.SchoolId
                    );
                    this.getClassDetails(
                        this.vcDailyReportingModel.VCClassObservation.UserId
                    );
                    this.onChangeClass(
                        this.vcDailyReportingModel.VCClassObservation
                            .ClassTaughtId
                    );

                    this.vcDailyReportingForm
                        .get("classObservationGroup.UserId")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation.UserId
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsLessionPlanPresent")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsLessionPlanPresent == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsClassroomNeat")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsClassroomNeat == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsBSToolsChecked")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsBSToolsChecked == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsChildrenSet")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsChildrenSet == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsTrainerTaught")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsTrainerTaught == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsContextTaught")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsContextTaught == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsGuidelinesForTools")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsGuidelinesForTools == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsFlowChart")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsFlowChart == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsSafetyManual")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsSafetyManual == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsStudentFollow")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsStudentFollow == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsPracticalGuided")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsPracticalGuided == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsStudentTreatedEqual")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsStudentTreatedEqual == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsQualityCheck")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsQualityCheck == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsCorrelation")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsCorrelation == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsStudentShy")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsStudentShy == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsHomework")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsHomework == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsASToolsChecked")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsASToolsChecked == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsRespectful")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsRespectful == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsStudentQuestioned")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsStudentQuestioned == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsStudentParticipated")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsStudentParticipated == true
                                ? "0"
                                : "1"
                        );
                    this.vcDailyReportingForm
                        .get("classObservationGroup.IsTeacherTaughtwell")
                        .setValue(
                            this.vcDailyReportingModel.VCClassObservation
                                .IsStudentParticipated == true
                                ? "0"
                                : "1"
                        );
                }
            } 
            
            if (workTypeId == "Samagra Shiksha Office" || workTypeId == "VTP's Office" || workTypeId == "Attending Training" 
            || workTypeId == "Events" || workTypeId == "VT Review Meeting" || workTypeId == "Meeting Goverment Official" 
            || workTypeId == "On Job Training/ Internship" || workTypeId == "Others" )
            this.isAllowWorkDetails = true;

            this.onChangeReportingDate();
        });

        if (this.PageRights.ActionType != this.Constants.Actions.View) {
            let initialFormValues = this.vcDailyReportingForm.value;
            this.vcDailyReportingForm.reset(initialFormValues);
        }
    }

    onPratical(pratical): void {
        this.IsLoading = true;

        if (pratical == 'Yes') {
            this.commonService.GetSchoolsByVCId({ DataId: this.UserModel.LoginId, DataId1: this.UserModel.UserTypeId, SelectTitle: 'School' }).subscribe((response) => {
                if (response.Success) {
                    this.paSchoolList = response.Results;
                    this.filteredPASchoolItems = this.paSchoolList.slice();
                }
            });
        } else {
            this.commonService.GetSchoolsByAcademicYearId(this.UserModel.AcademicYearId).subscribe((response) => {
                if (response.Success) {
                    this.paSchoolList = response.Results;
                    this.filteredPASchoolItems = this.paSchoolList.slice();
                }
            });
        }
    }

    onChangeVT(vtId): Promise<any> {
        this.IsLoading = true;
        let schoolId = this.vcDailyReportingForm.controls.praticalGroup.get('SchoolId').value;

        let promise = new Promise((resolve, reject) => {
            this.vcDailyReportingService.getDropdownForVCPracticalAssessment(this.UserModel.AcademicYearId, schoolId, vtId).subscribe(results => {
                if (results[0].Success) {
                    this.classList = results[0].Results;
                }

                if (results[1].Success) {
                    this.sectorList = results[1].Results;
                }

                if (results[2].Success) {
                    this.jobRoleList = results[2].Results;
                }

                resolve(true);
            }, error => {
                console.log(error);
                resolve(false);
            });
        });

        return promise;
    }

    onChangeVTClass(classId): Promise<any> {
        this.IsLoading = true;

        let schoolId = this.vcDailyReportingForm.controls.praticalGroup.get('SchoolId').value;
        let vtId = this.vcDailyReportingForm.controls.praticalGroup.get('VTId').value;

        let promise = new Promise((resolve, reject) => {
            this.commonService.GetMasterDataByType({ DataType: 'StudentEnrolledCount', RoleId: schoolId, UserId: vtId, ParentId: classId, SelectTitle: 'StudentCount' }, false).subscribe((response) => {
                if (response.Success) {
                    if (response.Results.length == 1) {
                        this.vcDailyReportingForm.controls.praticalGroup.get('StudentCount').setValue(response.Results[0].Description);
                        this.vcDailyReportingForm.controls.praticalGroup.get('StudentCount').disable();
                    }
                    else {
                        this.vcDailyReportingForm.controls.praticalGroup.get('StudentCount').enable();
                    }
                }
                resolve(true);
            }, error => {
                console.log(error);
                resolve(false);
            });
        });
        return promise;
    }

    getSchoolbyVCId() {
        this.commonService
            .GetSchoolsByVCId({
                DataId: this.UserModel.LoginId,
                DataId1: this.UserModel.UserTypeId,
                SelectTitle: "School",
            })
            .subscribe((response) => {
                if (response.Success) {
                    this.schoolList = response.Results;
                    this.filteredSchoolItems = this.schoolList.slice();
                }
            });
    }

    getHM(id) {
        this.vtId = id;
        this.vcDailyReportingService
            .getHMByVCId({
                DataId: this.UserModel.UserTypeId,
            })
            .subscribe((response) => {
                if (response[0].Success) {
                    this.MemberList = <HMMeetInvolvedModel[]>[];
                    response[0].Results.forEach((HMItem) => {
                        this.MemberList.push(
                            {
                                Id: HMItem.HMId,
                                Name: HMItem.HMFullName,
                                IsPresent: false,
                            }
                        );
                        this.MemberList.push(
                            {
                                Id: HMItem.VTId,
                                Name: HMItem.VTFullName,
                                IsPresent: false,
                            }
                        );
                    });
                    this.MemberList.push(
                        {
                            Id: 'Other',
                            Name: 'Other',
                            IsPresent: false,
                        }
                    );
                    this.filteredMemberList = this.MemberList.slice()
                    // this.MemberList.push(new DropdownModel({ Id: "other", Name: "Other" }));
                }
            });
    }

    toggle(Item, event) {

        if (event.source._selected === true)
            this.meetList.push(Item.Id);
        else {
            const memberIndex = this.meetList.indexOf(Item.Id);
            this.meetList.splice(memberIndex, 1);
        }


    }

    getVTlist() {
        this.vocationalTrainerService
            .GetAllByCriteria({
                AcademicYearId: this.UserModel.AcademicYearId,
                VTPId: null,
                VCId: this.UserModel.UserTypeId,
                SocialCategoryId: null,
                Status: null,
                Name: null,
                CharBy: null,
                PageIndex: 0,
            })
            .subscribe(
                (response) => {
                    if (response.Results.length === 0) {
                        this.errorDialog.popupErrorMessage(
                            "There is no VT data"
                        );
                    } else {
                        response.Results.forEach(function (obj) {
                            if (obj.hasOwnProperty("IsActive")) {
                                obj.IsActive = obj.IsActive ? "Yes" : "No";
                            }
                            if (obj.hasOwnProperty("IsResigned")) {
                                obj.IsResigned = obj.IsResigned ? "Yes" : "No";
                            }
                            delete obj.TotalRows;
                        });
                        this.vtList = response.Results;
                        this.filteredVTItems = this.vtList.slice();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    onEnterOtherMembers() {

        this.otherMemberList.push(this.vcDailyReportingForm.get('hmMeetingGroup.OtherMember').value)
        this.vcDailyReportingForm.get("hmMeetingGroup.OtherMember").setValue("");
        let memberCount = this.meetList.length + this.otherMemberList.length
        this.vcDailyReportingForm.get("hmMeetingGroup.MembersCount").setValue(memberCount);


    }

    removeOtherMembers(member) {
        const memberIndex = this.otherMemberList.indexOf(member);
        this.otherMemberList.splice(memberIndex, 1);
        let memberCount = this.meetList.length + this.otherMemberList.length
        this.vcDailyReportingForm.get("hmMeetingGroup.MembersCount").setValue(memberCount);
    }


    OnSelectMembers(event) {

        if (event.source._selected === true) {
            if (event.source.value === 'Other')
                this.isOtherSelected = true;
            else
                this.meetList.push(event.source.value);
        }

        else {
            const memberIndex = this.meetList.indexOf(event.source.value);
            this.meetList.splice(memberIndex, 1);
        }
        let memberCount = this.meetList.length + this.otherMemberList.length
        this.vcDailyReportingForm.get("hmMeetingGroup.MembersCount").setValue(memberCount);

    }

    addRecord() {
        let studentLearn = this.vcDailyReportingForm.get(
            "classObservationGroup.StudentsLearned"
        );

        if (studentLearn.value !== "") {
            let details = studentLearn.value;

            this.EodStudentLearning.push(details);
            this.isStudentLearn = true;
        }
        studentLearn.setValue("");
    }

    removeRecord(sessionItem) {
        const sessionIndex = this.EodStudentLearning.indexOf(sessionItem);
        this.EodStudentLearning.splice(sessionIndex, 1);
    }

    onChangeCourseModule(moduleId): void {
        let classId = this.vcDailyReportingForm.get(
            "classObservationGroup.ClassTaughtId"
        ).value;

        if (classId != "" && moduleId != null) {
            this.commonService
                .GetUnitsByClassAndModuleId({
                    DataId: classId,
                    DataId1: moduleId,
                    DataId2: this.UserModel.UserTypeId,
                    SelectTitle: "Unit Taught",
                })
                .subscribe((response: any) => {
                    if (response.Success) {
                        this.unitList = response.Results;
                    }
                });
        } else {
            this.unitList = <DropdownModel[]>[];
        }
    }

    onChangeClass(classId): Promise<any> {
        this.ClassId = classId;
        this.IsLoading = true;
        let promise = new Promise((resolve, reject) => {
            this.commonService
                .GetSectionsByVTClassId({ DataId: this.vtId, DataId1: classId })
                .subscribe((response) => {
                    if (response.Success) {
                        this.sectionList = response.Results;
                    }
                });
        });
        return promise;
    }

    onChangeSectionForTaught(sectionId): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            if (sectionId != null) {
                this.commonService
                    .getStudentsByClassId({
                        DataId: this.vtId,
                        DataId1: this.ClassId,
                        DataId2: sectionId,
                    })
                    .subscribe((response) => {
                        if (response.Success) {
                            this.studentList = response.Results;
                            this.filteredStudentList = this.studentList.slice();
                        }
                    });
            }
        });

        return promise;
    }

    onChangeOfStudents(studentId) {

        this.vcDailyReportingForm
            .get("homeVisitGroup.StudentId")
            .setValue(studentId);
        this.studentClassDetailService
            .getStudentClassDetailById(studentId)
            .subscribe((response: any) => {
                this.vcDailyReportingForm
                    .get("homeVisitGroup.FatherName")
                    .setValue(response.Result.FatherName);
                this.vcDailyReportingForm
                    .get("homeVisitGroup.MotherName")
                    .setValue(response.Result.MotherName);
                // this.vcDailyReportingForm.get('homeVisitGroup.ContactNum').setValue(response.Result.Mobile)
            });
    }

    onChangeIsMRawMaterialStatus(chk) {
        if (chk.value == "0") this.isRawMaterialBill = true;
        else {
            this.isRawMaterialBill = false;
            this.vcDailyReportingForm
                .get("communityWorkGroup.MaterialBillFile")
                .setValue("");
        }
    }
    onChangeIsMSpracticalworkdoneStatus(chk) {
        if (chk.value == "0") this.isIsMSpracticalworkdoneStatus = true;
        else {
            this.isIsMSpracticalworkdoneStatus = false;
            this.vcDailyReportingForm
                .get("homeVisitGroup.ChildSharedDetails")
                .setValue("");
        }
    }

    onChangeParentInterestStatus(chk) {
        if (chk.value == "0") this.isParentIntrestedEdu = true;
        else {
            this.isParentIntrestedEdu = false;
            this.vcDailyReportingForm
                .get("homeVisitGroup.KindOfEducation")
                .setValue("");
        }
    }

    onChangeKeentoMoreStatus(chk) {
        if (chk.value == "0") this.isKeentoknow = true;
        else {
            this.isKeentoknow = false;
            this.vcDailyReportingForm
                .get("homeVisitGroup.Reaction")
                .setValue("");
        }
    }

    onChangeChronicalStatus(chk) {
        if (chk.value == "0") this.isChronical = true;
        else {
            this.isChronical = false;
            this.vcDailyReportingForm
                .get("homeVisitGroup.IllCount")
                .setValue(0);
        }
    }
    onChangeCommunicationStatus(chk) {
        if (chk.value == "0") this.isParentCommunicatd = true;
        else {
            this.isParentCommunicatd = false;
            this.vcDailyReportingForm
                .get("homeVisitGroup.CommunicationDetails")
                .setValue("");
        }
    }

    onChangeIsRawmaterialStatus(chk) {
        if (chk.value == "0") {
            this.vcDailyReportingForm
                .get("communityWorkGroup.IsRawMaterial")
                .setValue(true);
        } else {
            this.vcDailyReportingForm
                .get("communityWorkGroup.IsRawMaterial")
                .setValue(false);
        }
    }

    onChangeVTByClass(Vtid): Promise<any> {
        this.vtId = Vtid;
        this.IsLoading = true;
        let promise = new Promise((resolve, reject) => {
            this.commonService
                .GetMasterDataByType({
                    DataType: "ClassesByVT",
                    ParentId: this.UserModel.AcademicYearId,
                    UserId: this.vtId,
                    SelectTitle: "Class",
                })
                .subscribe((response) => {
                    if (response.Success) {
                        this.classList = response.Results;
                    }
                    resolve(true);
                });
        });
        return promise;
    }

    onChangeSchool(schoolId): Promise<any> {
        this.IsLoading = true;
        let promise = new Promise((resolve, reject) => {
            let vtRequest = null;

            vtRequest = this.commonService.GetMasterDataByType(
                {
                    DataType: "TrainersBySchool",
                    ParentId: schoolId,
                    SelectTitle: "Vocational Trainer",
                },
                false
            );

            vtRequest.subscribe((response: any) => {
                if (response.Success) {
                    this.vtList = response.Results;
                }
                this.IsLoading = false;
                resolve(true);
            });
        });
        return promise;
    }

    resetReportTypeFormGroups(): void {
        this.isAllowHoliday = false;
        this.isAllowLeave = false;
        this.isAllowWorkDetails = false;
        this.isAllowSchool = false;
        this.isAllowIndustryExposureVisit = false;
        this.isAllowPractical = false;
        this.isAllowCommunityWork = false;
        this.isAllowHMmeet = false;
        this.isAllowHomeVisit = false;
        this.isAllowClassObservation = false;
        this.isStudentLearn = false;
        this.isAddHMMember = false;
        this.isRawMaterialBill = false;

        if (this.PageRights.ActionType != this.Constants.Actions.View) {
            delete this.vcDailyReportingForm.controls["leaveGroup"];
            delete this.vcDailyReportingForm.value["leaveGroup"];
            delete this.vcDailyReportingForm.controls["holidayGroup"];
            delete this.vcDailyReportingForm.value["holidayGroup"];
            delete this.vcDailyReportingForm.controls[
                "industryExposureVisitGroup"
            ];
            delete this.vcDailyReportingForm.value[
                "industryExposureVisitGroup"
            ];
            delete this.vcDailyReportingForm.controls["communityWorkGroup"];
            delete this.vcDailyReportingForm.value["communityWorkGroup"];
            delete this.vcDailyReportingForm.controls["hmMeetingGroup"];
            delete this.vcDailyReportingForm.value["hmMeetingGroup"];
            delete this.vcDailyReportingForm.controls["homeVisitGroup"];
            delete this.vcDailyReportingForm.value["homeVisitGroup"];
            delete this.vcDailyReportingForm.controls["classObservationGroup"];
            delete this.vcDailyReportingForm.value["classObservationGroup"];
            delete this.vcDailyReportingForm.controls['praticalGroup'];
            delete this.vcDailyReportingForm.value['praticalGroup'];

            this.vcDailyReportingForm.controls[
                "WorkingDayTypeIds"
            ].clearValidators();
            this.vcDailyReportingForm.controls["SchoolId"].clearValidators();

            this.vcDailyReportingForm.controls[
                "WorkingDayTypeIds"
            ].updateValueAndValidity();
            this.vcDailyReportingForm.controls[
                "SchoolId"
            ].updateValueAndValidity();

            let initialFormValues = this.vcDailyReportingForm.value;
            this.vcDailyReportingForm.reset(initialFormValues);
        }
    }

    resetWorkTypesFormGroups(): void {
        this.isAllowIndustryExposureVisit = false;
        this.isAllowSchool = false;
        this.isAllowWorkDetails = false;
        this.isAllowCommunityWork = false;
        this.isAllowHMmeet = false;
        this.isAllowHomeVisit = false;
        this.isAllowClassObservation = false;
        this.isStudentLearn = false;
        this.isAddHMMember = false;
        this.isRawMaterialBill = false;
        this.isAllowPractical = false;
    }

    onViewCommunityWork() {
        this.vcDailyReportingForm
            .get("communityWorkGroup.UserId")
            .setValue(this.vcDailyReport.CommunityWork.UserId);
        this.vcDailyReportingForm
            .get("communityWorkGroup.ClassTaughtId")
            .setValue(this.vcDailyReport.CommunityWork.ClassTaughtId);

    }

    onViewHomeVisit() {
        let homeVisit = this.vcDailyReport.HomeVisitView;

        this.vcDailyReportingForm
            .get("homeVisitGroup.DateOfVisit")
            .setValue(this.datePipe.transform(homeVisit.DateOfVisit, 'yyyy-MM-dd'));
        this.vcDailyReportingForm
            .get("homeVisitGroup.PurposeOfVisit")
            .setValue(homeVisit.PurposeOfVisit);
        this.vcDailyReportingForm
            .get("homeVisitGroup.UserId")
            .setValue(homeVisit.VTId);
        this.vcDailyReportingForm
            .get("homeVisitGroup.ClassTaughtId")
            .setValue(homeVisit.ClassTaughtId);
        this.vcDailyReportingForm
            .get("homeVisitGroup.ClassSectionIds")
            .setValue(homeVisit.SectionId);
        this.vcDailyReportingForm
            .get("homeVisitGroup.StudentId")
            .setValue(homeVisit.Student.StudentId);
        this.vcDailyReportingForm
            .get("homeVisitGroup.AcademicYearId")
            .setValue(homeVisit.AcademicYearId);
        this.vcDailyReportingForm
            .get("homeVisitGroup.FatherName")
            .setValue(homeVisit.Student.FatherName);
        this.vcDailyReportingForm
            .get("homeVisitGroup.MotherName")
            .setValue(homeVisit.Student.MotherName);
        this.vcDailyReportingForm
            .get("homeVisitGroup.FatherEducation")
            .setValue(homeVisit.FatherEducation);
        this.vcDailyReportingForm
            .get("homeVisitGroup.MotherEducation")
            .setValue(homeVisit.MotherEducation);
        this.vcDailyReportingForm
            .get("homeVisitGroup.Village")
            .setValue(homeVisit.Village);
        this.vcDailyReportingForm
            .get("homeVisitGroup.LandMark")
            .setValue(homeVisit.Landmark);
        this.vcDailyReportingForm
            .get("homeVisitGroup.ContactNum")
            .setValue(homeVisit.ContactNum);
        this.vcDailyReportingForm
            .get("homeVisitGroup.MotherAge")
            .setValue(homeVisit.MotherAge);
        this.vcDailyReportingForm
            .get("homeVisitGroup.FatherAge")
            .setValue(homeVisit.FatherAge);
        this.vcDailyReportingForm
            .get("homeVisitGroup.StudentMSFC")
            .setValue(homeVisit.StudentMSFC);
        this.vcDailyReportingForm
            .get("homeVisitGroup.MainSouce")
            .setValue(homeVisit.MainSouce);
        this.vcDailyReportingForm
            .get("homeVisitGroup.AdditionalSource")
            .setValue(homeVisit.AdditionalSource);
        this.vcDailyReportingForm
            .get("homeVisitGroup.Generation")
            .setValue(homeVisit.Generation);
        this.vcDailyReportingForm
            .get("homeVisitGroup.SibilingsCount")
            .setValue(homeVisit.SibilingsCount);
        this.vcDailyReportingForm
            .get("homeVisitGroup.SilbilingType")
            .setValue(homeVisit.SilbilingType);
        this.vcDailyReportingForm
            .get("homeVisitGroup.IsParentAware")
            .setValue(homeVisit.IsParentAware == true ? "0" : "1");
        this.vcDailyReportingForm
            .get("homeVisitGroup.IsMSChildSpoketoParent")
            .setValue(homeVisit.IsMSChildSpoketoParent == true ? "0" : "1");
        this.vcDailyReportingForm
            .get("homeVisitGroup.IsMSpracticalworkdone")
            .setValue(homeVisit.IsMSpracticalworkdone == true ? "0" : "1");
        if (homeVisit.IsMSpracticalworkdone == true) {
            this.isIsMSpracticalworkdoneStatus = true;
        }
        this.vcDailyReportingForm
            .get("homeVisitGroup.ChildSharedDetails")
            .setValue(homeVisit.ChildSharedDetails);
        this.vcDailyReportingForm
            .get("homeVisitGroup.IsParentWilling")
            .setValue(homeVisit.IsParentWilling == true ? "0" : "1");
        if (homeVisit.IsParentWilling == true) {
            this.isParentIntrestedEdu = true;
        }
        this.vcDailyReportingForm
            .get("homeVisitGroup.KindOfEducation")
            .setValue(homeVisit.KindOfEducation);
        this.vcDailyReportingForm
            .get("homeVisitGroup.StudentDropOut")
            .setValue(homeVisit.StudentDropOut);
        this.vcDailyReportingForm
            .get("homeVisitGroup.DropoutReason")
            .setValue(homeVisit.DropoutReason);
        this.vcDailyReportingForm
            .get("homeVisitGroup.IsSchoolingAwareness")
            .setValue(homeVisit.IsSchoolingAwareness == true ? "0" : "1");
        if (homeVisit.IsSchoolingAwareness == true) {
            this.isKeentoknow = true;
        }
        this.vcDailyReportingForm
            .get("homeVisitGroup.DetailsKnown")
            .setValue(homeVisit.DetailsKnown);
        this.vcDailyReportingForm
            .get("homeVisitGroup.Reaction")
            .setValue(homeVisit.Reaction);
        this.vcDailyReportingForm
            .get("homeVisitGroup.IsChronicallyIll")
            .setValue(homeVisit.IsChronicallyIll == true ? "0" : "1");
        if (homeVisit.IsChronicallyIll == true) {
            this.isChronical = true;
        }
        this.vcDailyReportingForm
            .get("homeVisitGroup.IllCount")
            .setValue(homeVisit.IllCount);
        this.vcDailyReportingForm
            .get("homeVisitGroup.IsParentCommunicate")
            .setValue(homeVisit.IsParentCommunicate == true ? "0" : "1");
        if (homeVisit.IsParentCommunicate == true) {
            this.isParentCommunicatd = true;
        }
        this.vcDailyReportingForm
            .get("homeVisitGroup.CommunicationDetails")
            .setValue(homeVisit.CommunicationDetails);
        this.vcDailyReportingForm
            .get("homeVisitGroup.ImportantDetails")
            .setValue(homeVisit.ImportantDetails);
        this.vcDailyReportingForm
            .get("homeVisitGroup.Remarks")
            .setValue(homeVisit.Remarks);
        this.vcDailyReportingForm
            .get("homeVisitGroup.Hygienerate")
            .setValue(homeVisit.Hygienerate);
    }

    saveOrUpdateVCDailyReportingDetails() {
        if (!this.vcDailyReportingForm.valid) {
            this.validateAllFormFields(this.vcDailyReportingForm);
            return;
        }

        if (!this.onChangeReportingDate()) {
            return;
        }

        // this.vcDailyReportingModel = this.vtvcDailyReportingService.getVTDailyReportingModelFromFormGroup(this.vcDailyReportingForm, "VC");
        this.vcDailyReportingModel =
            this.vcDailyReportingService.getVCDailyReportingModelFromFormGroup(
                this.vcDailyReportingForm
                , this.UserModel);
        this.vcDailyReportingModel.ReportDate = this.DateFormatPipe.transform(
            this.vcDailyReportingForm.get("ReportDate").value,
            this.Constants.ServerDateFormat
        );
        this.vcDailyReportingModel.VCId = this.UserModel.UserTypeId;

        if (this.vcDailyReportingModel.CommunityWork != undefined) {

            this.vcDailyReportingModel.CommunityWork.MaterialBillFile =
                this.MaterialBillFilesFile.Base64Data != ""
                    ? this.setUploadedFile(this.MaterialBillFilesFile)
                    : null;
        } else {
            delete this.vcDailyReportingModel["CommunityWork"];
        }

        if (this.vcDailyReportingModel.VCClassObservation != undefined) {

            this.vcDailyReportingModel.VCClassObservation.StudentsLearned =
                this.EodStudentLearning;
        } else {
            delete this.vcDailyReportingModel["ClassObservation"];
        }

        if (this.vcDailyReportingModel.HMMeeting != undefined) {
            if (this.isOtherSelected) {
                this.otherMemberList.forEach(element => {
                    this.meetList.push(element)
                });
            }
            this.vcDailyReportingModel.HMMeeting.Members = this.meetList;
        } else {
            delete this.vcDailyReportingModel["HMMeeting"];
        }
        
        
        (this.vcDailyReportingModel.WorkingDayTypeIds).forEach(element => {
            console.log(element);
            let result = this.workTypeList.find(ele => ele.Name === element)
            console.log("REsult",result)  
            
            const index = (this.vcDailyReportingModel.WorkingDayTypeIds).indexOf(element); 

            this.vcDailyReportingModel.WorkingDayTypeIds.splice(index, 1, result.Id);

            
        });


        this.vcDailyReportingService
            .createOrUpdateVCDailyReporting(this.vcDailyReportingModel)
            .subscribe(
                (vcDailyReportingResp: any) => {
                    if (vcDailyReportingResp.Success) {
                        this.zone.run(() => {
                            this.showActionMessage(
                                this.Constants.Messages.RecordSavedMessage,
                                this.Constants.Html.SuccessSnackbar
                            );

                            this.router.navigate([
                                RouteConstants.VCDailyReporting.List,
                            ]);
                        });
                    } else {
                        var errorMessages = this.getHtmlMessage(
                            vcDailyReportingResp.Errors
                        );
                        this.dialogService.openShowDialog(errorMessages);
                    }
                },
                (error) => {
                    console.log("VCDailyReporting deletion errors =>", error);
                }
            );
    }

    uploadedBillsPhotoFile(event) {
        if (event.target.files.length > 0) {
            var fileExtn = event.target.files[0].name
                .split(".")
                .pop()
                .toLowerCase();

            if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
                this.vcDailyReportingForm.controls.communityWorkGroup
                    .get("MaterialBillFile")
                    .setValue(null);
                this.dialogService.openShowDialog(
                    "Please upload image file only."
                );
                return;
            }

            this.getUploadedFileData(
                event,
                this.Constants.DocumentType.VTReporting
            ).then((response: FileUploadModel) => {
                this.MaterialBillFilesFile = response;

                // this.vcDailyReportingForm.controls.acommunityWorkGroup.get('MaterialBillFile').setValue(false);
                // this.setMandatoryFieldControl(this.vcDailyReportingForm.controls.acommunityWorkGroup as FormGroup, 'BillsPhotoFile', this.Constants.DefaultImageState);
            });
        }
    }

    //Create vcDailyReporting form and returns {FormGroup}
    createVCDailyReportingForm(): FormGroup {
        return this.formBuilder.group({
            VCDailyReportingId: new FormControl(
                this.vcDailyReportingModel.VCDailyReportingId
            ),
            VCId: new FormControl({ value: this.vcDailyReportingModel.VCId }),
            ReportDate: new FormControl(
                {
                    value: new Date(this.vcDailyReportingModel.ReportDate),
                    disabled: this.PageRights.IsReadOnly,
                },
                Validators.required
            ),
            ReportType: new FormControl(
                {
                    value: this.vcDailyReportingModel.ReportType,
                    disabled: this.PageRights.IsReadOnly,
                },
                Validators.required
            ),
            WorkingDayTypeIds: new FormControl({
                value: this.vcDailyReportingModel.WorkingDayTypeIds,
                disabled: this.PageRights.IsReadOnly,
            }),
            SchoolId: new FormControl({
                value: this.vcDailyReportingModel.SchoolId,
                disabled: this.PageRights.IsReadOnly,
            }),
            WorkTypeDetails: new FormControl(
                {
                    value: this.vcDailyReportingModel.WorkTypeDetails,
                    disabled: this.PageRights.IsReadOnly,
                },
                Validators.maxLength(350)
            ),
        });
    }

    private onChangeLeaveApprovalStatus() {
        this.vcDailyReportingForm.controls.leaveGroup
            .get("LeaveApprovalStatus")
            .valueChanges.subscribe((leaveApprovalStatusId) => {
                if (leaveApprovalStatusId == "Yes") {
                    this.vcDailyReportingForm.controls.leaveGroup
                        .get("LeaveApprover")
                        .setValidators([Validators.required]);
                } else {
                    this.vcDailyReportingForm.controls.leaveGroup
                        .get("LeaveApprover")
                        .clearValidators();
                }

                this.vcDailyReportingForm.controls.leaveGroup
                    .get("LeaveApprover")
                    .updateValueAndValidity();
            });
    }
}
