<div id="report-001" class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="subtitle secondary-text">
                        <span>Monthly Report 001</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

        </div>

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="report001Form" [formGroup]="report001Form" class="report-001 w-100-p" fxLayout="column"
                    fxFlex>



                    <!-- <mat-tab label="Basic Info"> -->
                    <div class="tab-content p-24" fusePerfectScrollbar>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">


                            <!-- <mat-form-field appearance="outline" fxFlex="0 1 calc(35%)" fxFlex.lt-md="0 1 calc(70%)"
                                fxFlex.lt-sm="100%">
                                <mat-label>Report 001 Month</mat-label>

                                <input matInput name="reportDate" formControlName="ReportDate" required
                                    [matDatepicker]="reportDate" [max]="CurrentDate">
                                <mat-datepicker-toggle matSuffix [for]="reportDate"></mat-datepicker-toggle>
                                <mat-datepicker #reportDate startView="year" (yearSelected)="chosenYearHandler($event)"
                                    (monthSelected)="chosenMonthHandler($event, reportDate)"></mat-datepicker>
                                <mat-error *ngIf="report001Form.controls.ReportDate.invalid">
                                    {{getErrorMessage(report001Form, 'ReportDate')}}
                                </mat-error>
                            </mat-form-field> -->

                            <mat-form-field appearance="outline" fxFlex="45" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Report Month</mat-label>

                                <input matInput name="reportDate" formControlName="ReportDate" required
                                    [matDatepicker]="reportDate" [max]="CurrentDate">
                                <mat-datepicker-toggle matSuffix [for]="reportDate"></mat-datepicker-toggle>
                                <mat-datepicker #reportDate startView="year" (yearSelected)="chosenYearHandler($event)"
                                    (monthSelected)="chosenMonthHandler($event, reportDate)"></mat-datepicker>

                                    <mat-error *ngIf="report001Form.controls.ReportDate.invalid">
                                        {{getErrorMessage(report001Form, 'ReportDate')}}
                                    </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="45" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                *ngIf="this.UserModel.RoleCode == 'ADM' ">
                                <mat-label>Select Vocational Coordinator</mat-label>

                                <mat-select formControlName="VCId" appearance="outline" required
                                    matTooltip="Select Vocational Coordinator" matTooltipPosition='above'
                                    matTooltipClass="allow-cr"  #SelectVc>
                            
                                    <mat-select-filter class="select-filter" *ngIf="SelectVc.focused" [array]="filteredVCItems"
                                        (filteredReturn)="this.filteredVCItems = $event"  [displayMember]="'FullName'" >
                                    </mat-select-filter> 
                                     <mat-option [value]="vcItem.VCId" *ngFor="let vcItem of filteredVCItems"  (click)="getVocationalTrainer()">
                                        {{ vcItem.FullName }}
                                    </mat-option>  
                                </mat-select> 
                                    <mat-error *ngIf="report001Form.controls.VCId.invalid">
                                    {{getErrorMessage(report001Form, 'VCId')}}
                                </mat-error>        
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="45" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                *ngIf="this.UserModel.RoleCode == 'VC' || this.UserModel.RoleCode == 'ADM' ">
                                <mat-label>Select Vocational Trainer</mat-label>

                                <mat-select formControlName="VTId" appearance="outline" required
                                    matTooltip="Select Vocational Trainer" matTooltipPosition='above'
                                    matTooltipClass="allow-cr" #SelectVt>
                            
                                    <mat-select-filter class="select-filter" *ngIf="SelectVt.focused" [array]="filteredVTItems"
                                        (filteredReturn)="this.filteredVTItems = $event" [displayMember]="'VTName'">
                                    </mat-select-filter> 
                                     <mat-option [value]="vtItem.VTId" *ngFor="let vtItem of filteredVTItems">
                                        {{ vtItem.VTName }}
                                    </mat-option>  
                                </mat-select> 
                                    <mat-error *ngIf="report001Form.controls.VTId.invalid">
                                    {{getErrorMessage(report001Form, 'VTId')}}  
                                </mat-error>        
                            </mat-form-field>

                            <span class="material-icons action-btn" (click)="getMonthlyReport001()">save_alt</span>
                        </div>
                    </div>
                </form>

            </div>
        </div>


    </div>


</div>