import { Component, OnInit, NgZone } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { NextMonthPlanModel } from '../next-month-plan.model';
import { VocationalTrainerService } from '../../vocational-trainers/vocational-trainer.service'
import { VCDailyReportingService } from '../../vc-daily-reportings/vc-daily-reporting.service'
import { BaseService } from '../../../services/base.service'
import { NextMonthPlanService } from '../next-month-plan.service'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import * as _moment from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import { default as _rollupMoment, Moment } from "moment";
import { DropdownModel } from 'app/models/dropdown.model';
import { ModuleUnitSessionModel } from 'app/models/module-unit-session-model';
import { BaseListComponent } from "app/common/base-list/base.list.component";


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: 'app-create-next-month-plan',
  templateUrl: './create-next-month-plan.component.html',
  styleUrls: ['./create-next-month-plan.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateNextMonthPlanComponent extends BaseComponent<NextMonthPlanModel> implements OnInit {

  nextMonthPlanForm: FormGroup;
  nextMonthPlanModel: NextMonthPlanModel;

  vtList: any;
  filteredVTItems: any;
  classList: any;
  academicYear: any;
  vtId: any;
  sectionList: any;
  activityTypeList: any;
  moduleList: any;
  unitList: any;
  topicList: any;
  selectedTopics: any = [];
  isEditCompleteStatus: boolean = false;
  selectedunitList: DropdownModel[];
  sessionList: DropdownModel[];
  displayedColumns: string[]
  dataSource: any = [];
  classSectionModules: any = [];
  plannedSectionModules: any = [];
  unitSessionsModels: any = [];
  // unitSessionsModels = <ModuleUnitSessionModel[]>[]

  completedUnitSessionModels: any = []

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    public vocationalTrainerService: VocationalTrainerService,
    public vcDailyReportingService: VCDailyReportingService,
    public nextMonthPlanService: NextMonthPlanService,
    public dialogService: DialogService,
    private baseService: BaseService) {
    super(commonService, router, routeParams, snackBar)
    this.nextMonthPlanModel = new NextMonthPlanModel();

  }


  ngOnInit(): void {

    this.displayedColumns = [
      "Class",
      "Section",
      "ActivityType",
      "Module",
      "Units",
      "Sessions",
      "Status"

    ];
    this.routeParams.paramMap.subscribe(params => {

      if (params.keys.length > 0) {
        this.PageRights.ActionType = params.get('actionType')
      }

      if (this.PageRights.ActionType == this.Constants.Actions.New) {
        this.isEditCompleteStatus = false;
        this.nextMonthPlanModel = new NextMonthPlanModel();

      } else {
        var nextMonthPlanId: string = params.get('nextMonthPlanId')

        this.nextMonthPlanService.GetPlanbyId({
          DataId: nextMonthPlanId
        })
          .subscribe((response: any) => {
            let month = new Date(response.Result.PlanMonth).getMonth();
            let actionType = ''

            actionType = this.PageRights.ActionType
            this.nextMonthPlanModel = response.Result
            this.classSectionModules = response.Result.NMPClassSectionModels

            response.Result.NMPClassSectionModels.forEach(element => {

              if (element.NMPUnitSessionModels[0].IsStatus === true)
                this.completedUnitSessionModels.push(element)
              else {
                this.plannedSectionModules.push(element)
              }

              this.completedUnitSessionModels = [...this.completedUnitSessionModels]
              this.plannedSectionModules = [...this.plannedSectionModules]
            });



            this.vtId = this.nextMonthPlanModel.UserId
            this.onChangeClass(this.nextMonthPlanModel.ClassTaughtId)
            this.onChangeCourseModule(this.nextMonthPlanModel.ModuleId)
            this.onChangeUnitsTaught(this.nextMonthPlanModel.UnitId)

            // }
            if (actionType == this.Constants.Actions.Edit) {
              this.nextMonthPlanModel.RequestType = this.Constants.PageType.Edit;
              this.isEditCompleteStatus = true;
            }
            else
              if (this.PageRights.ActionType == this.Constants.Actions.View) {
                this.nextMonthPlanModel.RequestType = this.Constants.PageType.View;
                this.PageRights.IsReadOnly = true;
              }
            this.nextMonthPlanForm = this.createNextmonthPlanForm();

          });
      }
    });
    this.baseService.GetMasterDataByType({ DataType: 'AcademicYears', SelectTitle: 'Academic Year' }).subscribe(response => {
      let academicYearList = response.Results;

      let year = academicYearList.find(ay => ay.IsSelected == true)
      this.academicYear = year.Name
    })

    this.baseService.GetMasterDataByType({ DataType: 'DataValues', ParentId: 'VTActivityType', SelectTitle: 'Activity Type' }, false).subscribe(response => {
      this.activityTypeList = response.Results;

    })

    this.baseService.GetMasterDataByType({ DataType: 'CourseModules', SelectTitle: 'Modules Taught' }).subscribe(response => {
      this.moduleList = response.Results;
    })

    if (this.UserModel.RoleCode === "VT") {
      this.vtId = this.UserModel.UserTypeId;

      this.vcDailyReportingService.getDropdownForClass(this.UserModel, this.UserModel.UserTypeId).subscribe((response) => {
        if (response[0].Success) {
          this.classList = response[0].Results;
        }
      })
    }

    if (this.UserModel.RoleCode === "VC") {
      this.vocationalTrainerService.GetAllByCriteria({
        AcademicYearId: this.UserModel.AcademicYearId,
        VTPId: null,
        VCId: this.UserModel.UserTypeId,
        SocialCategoryId: null,
        Status: null,
        Name: null,
        CharBy: null,
        PageIndex: 0
      }).subscribe(response => {

        if (response.Results.length === 0) {
          window.alert("There is no VT data")
        } else {
          response.Results.forEach(

            function (obj) {
              if (obj.hasOwnProperty('IsActive')) {
                obj.IsActive = obj.IsActive ? 'Yes' : 'No';
              }
              if (obj.hasOwnProperty('IsResigned')) {
                obj.IsResigned = obj.IsResigned ? 'Yes' : 'No';
              }
              delete obj.TotalRows;

            });
          this.vtList = response.Results;
          this.filteredVTItems = this.vtList.slice();

        }

      }, error => {
        console.log(error);
      });
    }
    this.nextMonthPlanForm = this.createNextmonthPlanForm();
  }

  createNextmonthPlanForm(): FormGroup {

    return this.formBuilder.group({
      PlanMonth: new FormControl({value:moment(), disabled: this.PageRights.IsReadOnly}, Validators.required),
      // PlanMonth: new FormControl({ value: new Date(this.nextMonthPlanModel.PlanMonth), disabled: this.PageRights.IsReadOnly }, Validators.required),
      UserId: new FormControl(this.nextMonthPlanModel.UserId),
      ClassTaughtId: new FormControl(this.nextMonthPlanModel.ClassTaughtId),
      SectionId: new FormControl({ value: this.nextMonthPlanModel.SectionId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ActivityTypeId: new FormControl({ value: this.nextMonthPlanModel.ActivityTypeId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ModuleId: new FormControl({ value: this.nextMonthPlanModel.ModuleId, disabled: this.PageRights.IsReadOnly }),
      UnitId: new FormControl({ value: this.nextMonthPlanModel.UnitId, disabled: this.PageRights.IsReadOnly }),
      TopicIdValues: new FormControl({ value: this.nextMonthPlanModel.TopicIdValues, disabled: this.PageRights.IsReadOnly }),
      // VCId: new FormControl({ value: this.UserModel.RoleCode == "VC" ? this.UserModel.UserTypeId : ''},  Validators.required)
    });
  }


  addUnitSession() {

    let isDuplicateSession = false

    let moduleCtrl = this.nextMonthPlanForm.get('ModuleId');
    let unitCtrl = this.nextMonthPlanForm.get('UnitId');
    let sessionIdsCtrl = this.nextMonthPlanForm.get('TopicIdValues');
    let classIdCtrl = this.nextMonthPlanForm.get('ClassTaughtId');
    let sectionIdCtrl = this.nextMonthPlanForm.get('SectionId');
    let activityIdsCtrl = this.nextMonthPlanForm.get('ActivityTypeId');

    if (moduleCtrl.value != '' && unitCtrl.value != '' && sessionIdsCtrl.value != '' && classIdCtrl.value != '' && sectionIdCtrl.value != '' && activityIdsCtrl.value != '') {

      if (this.unitSessionsModels !== undefined) {
       
        sessionIdsCtrl.value.map(item => {
          if((this.unitSessionsModels).includes(item.Id)){
            isDuplicateSession = true
            this.dialogService.openShowDialog(`${item.Name}  is already added`);
          }
        })
      }

      if(!isDuplicateSession){
        this.classSectionModules.push(
          {
            NMPClassSectionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            ClassTaughtId: classIdCtrl.value.Id,
            ClassTaught: classIdCtrl.value.Name,
            SectionId: sectionIdCtrl.value.Id,
            Section: sectionIdCtrl.value.Name,
            ActivityTypeId: activityIdsCtrl.value.Id,
            ActivityType: activityIdsCtrl.value.Name,
            ModuleId: moduleCtrl.value.Id,
            ModuleName: moduleCtrl.value.Name,
            UnitId: unitCtrl.value.Id,
            UnitName: unitCtrl.value.Name,
            SessionIds: sessionIdsCtrl.value.map(x => x.Id),
            SessionNames: sessionIdsCtrl.value.map(x => x.Name).join(", "),
            NMPUnitSessionModels: [{
              NMPClassSectionId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              // ClassId: classIdCtrl.value.Id,
              ModuleId: moduleCtrl.value.Id,
              // SectionId: sectionIdCtrl.value.Id,
              ModuleName: moduleCtrl.value.Name,
              UnitId: unitCtrl.value.Id,
              UnitName: unitCtrl.value.Name,
              SessionIds: sessionIdsCtrl.value.map(x => {
                this.unitSessionsModels.push(x.Id)  
                return x.Id
              }),
            SessionNames: sessionIdsCtrl.value.map(x => x.Name).join(", "),
              IsStatus: false
            }]
          }
        )
      }


    }
    

    this.classSectionModules = [...this.classSectionModules]
    this.plannedSectionModules = [...this.classSectionModules]

    this.nextMonthPlanForm.get('UnitId').setValue('');
    this.nextMonthPlanForm.get('TopicIdValues').setValue('');
    this.nextMonthPlanForm.get('ModuleId').setValue('');
    isDuplicateSession = false
  }

  toggle(Item, event) {

    if (event.checked === true) {
      this.completedUnitSessionModels.push(Item)

      const memberIndex = this.plannedSectionModules.indexOf(Item);
      this.plannedSectionModules.splice(memberIndex, 1);

      const memberIndex1 = this.classSectionModules.indexOf(Item);
      this.classSectionModules[memberIndex1].NMPUnitSessionModels[0].IsStatus = true

    }
    else {
      this.plannedSectionModules.push(Item)
      const memberIndex = this.completedUnitSessionModels.indexOf(Item);
      this.completedUnitSessionModels.splice(memberIndex, 1);

      const memberIndex1 = this.classSectionModules.indexOf(Item);
      this.classSectionModules[memberIndex1].NMPUnitSessionModels[0].IsStatus = false

    }

    this.plannedSectionModules = [...this.plannedSectionModules]
    this.completedUnitSessionModels = [...this.completedUnitSessionModels]


  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.nextMonthPlanForm.get("PlanMonth").value;
    ctrlValue.year(normalizedYear.year());
    this.academicYear = ctrlValue;
    this.nextMonthPlanForm.get("PlanMonth").setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.nextMonthPlanForm.get("PlanMonth").value;
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.year(normalizedMonth.year());
    this.nextMonthPlanForm.get("PlanMonth").setValue(ctrlValue);

    this.academicYear = ctrlValue.year();

    datepicker.close();
  }

  onChangeVT(vtId): void {
    this.vtId = vtId;
    this.vcDailyReportingService.getDropdownForClass(this.UserModel, vtId).subscribe((response) => {
      if (response[0].Success) {
        this.classList = response[0].Results;
      }
    })
  }

  onChangeClass(classId): void {

    this.nextMonthPlanForm.get("SectionId").setValue('')
    this.nextMonthPlanForm.get("ActivityTypeId").setValue('')
    this.nextMonthPlanForm.get("ModuleId").setValue('')
    this.nextMonthPlanForm.get("UnitId").setValue('')
    this.nextMonthPlanForm.get("TopicIdValues").setValue('')

    this.IsLoading = true;

    this.commonService.GetSectionsByVTClassId({ DataId: this.vtId, DataId1: typeof classId === 'object' ? classId.Id : classId }).subscribe(response => {
      if (response.Success) {
        this.sectionList = response.Results;

      }
    });
  }

  onChangeCourseModule(moduleId): void {
    let classId = this.nextMonthPlanModel.ClassTaughtId === '' ? this.nextMonthPlanForm.get("ClassTaughtId").value.Id : this.nextMonthPlanModel.ClassTaughtId;
    if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
      classId = this.nextMonthPlanForm.get("ClassTaughtId").value.Id
    }

    if (classId != '' && moduleId != null) {
      this.commonService.GetUnitsByClassAndModuleId({ DataId: classId, DataId1: moduleId.Id, DataId2: this.vtId, SelectTitle: 'Unit Taught' }).subscribe((response: any) => {
        if (response.Success) {
          this.unitList = response.Results;

        }
      });
    }
    else {
      this.unitList = [];
    }
  }

  onChangeUnitsTaught(unitItem): void {

    if (unitItem != null) {
      this.commonService.GetSessionsByUnitId({ DataId: unitItem.Id !== undefined ? unitItem.Id : unitItem, SelectTitle: 'Sessions Taught' }).subscribe((response: any) => {
        if (response.Success) {
          this.topicList = response.Results;
        }
      });
    }
    else {
      this.topicList = [];
    }
  }

  OnSelectTopics(event) {

    this.selectedTopics.push(event.Id);
  }

  saveOrUpdateNextMonthPlanDetails() {
    this.setValueFromFormGroup(this.nextMonthPlanForm, this.nextMonthPlanModel);
    this.nextMonthPlanModel.UnitId = "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    this.nextMonthPlanModel.UserId = this.UserModel.RoleCode == "VC" ? this.nextMonthPlanForm.get("UserId").value : this.UserModel.UserTypeId;
    this.nextMonthPlanModel.ClassTaughtId = this.nextMonthPlanForm.get("ClassTaughtId").value.Id
    this.nextMonthPlanModel.SectionId = this.nextMonthPlanForm.get("SectionId").value.Id
    this.nextMonthPlanModel.ActivityTypeId = this.nextMonthPlanForm.get("ActivityTypeId").value.Id
    this.nextMonthPlanModel.ModuleId = this.nextMonthPlanForm.get("ModuleId").value.Id


    this.nextMonthPlanModel.NMPClassSectionModels = this.classSectionModules

    this.nextMonthPlanModel.VCId = this.UserModel.RoleCode == "VC" ? this.UserModel.UserTypeId : ''

    if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
      this.nextMonthPlanModel.PlanMonth = this.nextMonthPlanForm.get("PlanMonth").value
      this.nextMonthPlanModel.IsActive = true
      this.nextMonthPlanModel.RequestType = 4;
    }

    this.nextMonthPlanService.createOrUpdateEvents(this.nextMonthPlanModel)
      .subscribe((districtResp: any) => {

        if (districtResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.NextMonthPlan.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(districtResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Events deletion errors =>', error);
      });
  }

}
