<div id="vocational-education-assessment-data" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VOCATIONAL EDUCATION ASSESSMENT DATA
                    <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                        <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                    </a>
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a Student Class & Assessment Details"
                        matTooltip="Search By Student Name" matTooltipPosition='above' matTooltipClass="allow-cr">
                </div>
            </div>
            <!-- / SEARCH -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': true}">

            <!-- Filter Section -->
            <div class="filter-content-card">
                <div class="filter-content">
                    <form name="vocationalEducationAssessmentData" [formGroup]="VocationalEducationAssessmentDataForm"
                        class="student-enrollment-report" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"
                        fxFlex>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label> Academic Year</mat-label>

                                <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="fill"
                                    (selectionChange)="onChangeAcademicYear($event.value)" required>
                                    <mat-option [value]="academicYearItem.Id"
                                        *ngFor="let academicYearItem of academicyearList">
                                        {{ academicYearItem.Name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex="25" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>School</mat-label>

                                <mat-select formControlName="SchoolId" name="schoolId" appearance="fill" required
                                    (selectionChange)="onChangeSchool($event.value)" #SelectSchool>
                                    <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                        [array]="schoolList" (filteredReturn)="this.filteredSchoolItems = $event"
                                        [displayMember]="'Name'"></mat-select-filter>
                                    <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of filteredSchoolItems">
                                        {{ schoolItem.Name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                *ngIf="UserModel.RoleCode != 'VT'">
                                <mat-label>Vocational Trainer</mat-label>

                                <mat-select formControlName="VTId" name="vtId" appearance="fill" required #SelectVtp>
                                    <mat-select-filter class="select-filter" *ngIf="SelectVtp.focused" [array]="vtList"
                                        (filteredReturn)="this.vtList = $event" [displayMember]="'Name'">
                                    </mat-select-filter>

                                    <mat-option [value]="vtItem.Id" *ngFor="let vtItem of vtList">
                                        {{ vtItem.Name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <button mat-raised-button class="fuse-white filter-button"
                                    (click)="getVocationalEducationAssessmentDataPrint()"><span>SEARCH</span></button>
                                <span class="material-icons action-btn" style="font-size: 28px;
                            color: royalblue;
                            margin-top: 10px;
                            margin-right: 8px;cursor: pointer;"
                                    (click)="getVocationalEducationAssessmentDataReport()">save_alt</span>
                                <button mat-raised-button class="fuse-white filter-button"
                                    (click)="resetReportFilters()"><span>CLEAR</span></button>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
            <!-- /Filter Section -->

            <!-- CONTENT CARD -->
            <div class="content-card" style="flex: none; border-radius: 8px;height: 230px;background-color: white;"
                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">
                <div fxLayout="column" fxFlex="1 0 auto" fxFlex fusePerfectScrollbar
                    class="student-class-detail w-100-p p-24 mr-24">
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span style="width:25%;height:30px;"><b>Name of School</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.SchoolName}}</span>

                        <span style="width:25%;height:30px;"><b>VTP Name</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.VTPName}}</span>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span style="width:25%;height:30px;"><b>UDISE</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.UDISE}}</span>

                        <span style="width:25%;height:30px;"><b>Vocational Trainer Name</b></span><span
                            style="width:25%;" *ngIf="vEAHeader != null">{{vEAHeader.VTName}}</span>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span style="width:25%;height:30px;"><b>School Head/Co-ordinating Name</b></span><span
                            style="width:25%;" *ngIf="vEAHeader != null">{{vEAHeader.HMName}}</span>

                        <span style="width:25%;height:30px;"><b>Contact Number (VT)</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.VTMobile}}</span>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span style="width:25%;height:30px;"><b>Contact Number (HM)</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.HMMobile}}</span>

                        <span style="width:25%;height:30px;"><b>Vocational Coordinator Name</b></span><span
                            style="width:25%;" *ngIf="vEAHeader != null">{{vEAHeader.VCName}}</span>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span style="width:25%;height:30px;"><b>HM Email-ID</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.HMEmailId}}</span>

                        <span style="width:25%;height:30px;"><b>Contact Number (VC)</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.VCMobile}}</span>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span style="width:25%;height:30px;"><b>School Address</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.SchoolAddress}}</span>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <span style="width:25%;height:30px;"><b>Total No of Students</b></span><span style="width:25%;"
                            *ngIf="vEAHeader != null">{{vEAHeader.TotalNoOfStudents}}</span><br>
                    </div>
                </div>
            </div>
            <!-- CONTENT CARD -->

            <!-- CONTENT CARD -->
            <div class="content-card" style="flex: initial; margin-top: 7px;">

                <mat-table class="student-enrollment-report-table responsive-table" #table
                    [dataSource]="tableDataSource" matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0]"
                    matSort fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                    <!-- SrNo Column -->
                    <ng-container matColumnDef="SrNo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>SrNo
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData; let RowIndex = index;">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.SrNo}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- StudentName Column -->
                    <ng-container matColumnDef="StudentName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Name of Candidate
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.StudentName}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Class Column -->
                    <ng-container matColumnDef="Class">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Class / Standard
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.Class}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Gender Column -->
                    <ng-container matColumnDef="Gender">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Gender
                            (M/F)</mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.Gender}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- DOB Column -->
                    <ng-container matColumnDef="DOB">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Date of Birth
                            (DD-MM-YYYY)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.DOB | date: 'dd/MM/yyyy'}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- AadhaarNumber Column -->
                    <ng-container matColumnDef="AadhaarNumber">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student ID 1
                            AADHAAR number
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.AadhaarNumber}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- StudentRollNumber Column -->
                    <ng-container matColumnDef="StudentRollNumber">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Student ID 2<br />
                            If AADHAAR is not available, Unique ID generated by <br />the Education Department /
                            Education
                            Board/Council
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.StudentRollNumber}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- PrimaryContact Column -->
                    <ng-container matColumnDef="PrimaryContact">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Primary Contact
                            Number
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.PrimaryContact}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- AlternativeContact Column -->
                    <ng-container matColumnDef="AlternativeContact">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Alternative Contact
                            Number
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.AlternativeContact}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- FatherName Column -->
                    <ng-container matColumnDef="FatherName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Father's Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.FatherName}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Category Column -->
                    <ng-container matColumnDef="Category">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>
                            Category(Gen/OBC/SC/ST)
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.Category}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Sector Column -->
                    <ng-container matColumnDef="Sector">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sector
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            {{vocationalEducationAssessmentData.Sector}}

                        </mat-cell>
                    </ng-container>

                    <!-- JobRole Column -->
                    <ng-container matColumnDef="JobRole">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Job Role
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.JobRole}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- StreamName Column -->
                    <ng-container matColumnDef="StreamName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Stream
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.StreamName}}</p>
                        </mat-cell>
                    </ng-container>

                    <!-- Assesment Column -->
                    <ng-container matColumnDef="Assesment">
                        <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Assessment to be
                            conducted on curriculum, pertaining to Level
                        </mat-header-cell>
                        <mat-cell *matCellDef="let vocationalEducationAssessmentData">
                            <p class="text-truncate">{{vocationalEducationAssessmentData.Assesment}}</p>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                    <mat-row *matRowDef="let vocationalEducationAssessmentData; columns: displayedColumns;"
                        class="student-enrollment-report" matRipple>
                    </mat-row>
                </mat-table>

                <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0"
                    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>

            </div>
            <!-- / CONTENT CARD -->

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>