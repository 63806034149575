import { Component, OnInit,NgZone , ViewEncapsulation} from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { EventsModel } from '../events.model';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { FileUploadModel } from 'app/models/file.upload.model';
import { EventService } from '../event.service'
import { RouteConstants } from 'app/constants/route.constant'
import { fuseAnimations } from '@fuse/animations';




@Component({
  selector: 'app-create-new-events',
  templateUrl: './create-new-events.component.html',
  styleUrls: ['./create-new-events.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateNewEventsComponent extends BaseComponent<EventsModel> implements OnInit {

  newEventForm: FormGroup;
  newEventModel: EventsModel;
  EventList: any = [
    {Id:"World YouthSkill Day", Value: "World Youth Skill Day"},
    {Id:"Engineers Day", Value: "Engineers Day"},
    {Id:"Teachers Day ", Value: "Teachers Day"},
    {Id:"Swadheen Yatra", Value: "Swadheen Yatra"},
    {Id:"Diwali", Value: "Diwali"},
    {Id:"State Festival ", Value: "State Festival "},
    {Id:"Competition", Value: "Competition"},
    {Id:"Other", Value: "Other"},
]
 
isOtherSelected: boolean = false;
selectetdEvent: any;
EventFile: any;
minReportingDate: Date;


  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public eventService:EventService,
    private dialogService: DialogService) { 
    super(commonService,router,routeParams,snackBar)
    this.newEventModel = new EventsModel();

    let dateOfAllocation = new Date(this.UserModel.DateOfAllocation);
    let maxDate = new Date(Date.now());

    let Time = maxDate.getTime() - dateOfAllocation.getTime();
    let Days = Math.floor(Time / (1000 * 3600 * 24));
    if (Days < this.Constants.BackDatedReportingDays) {
      this.minReportingDate = new Date(this.UserModel.DateOfAllocation);
    }
    else {
      let past7days = maxDate;
      past7days.setDate(past7days.getDate() - this.Constants.BackDatedReportingDays)
      this.minReportingDate = past7days;
    }

  }


  ngOnInit(): void {
    // this.newEventForm = this.createEventForm();
    this.route.paramMap.subscribe(params => {

      console.log("params:",params);
      
      if (params.keys.length > 0) {
        this.PageRights.ActionType = params.get('actionType');

        if (this.PageRights.ActionType == this.Constants.Actions.New) {
          this.newEventModel = new EventsModel();

        } else {
          var eventDetailId: string = params.get('vtDailyReportingId')
          console.log("eventid",eventDetailId);

          this.eventService.GetEventbyId({
            DataId : eventDetailId
          })
            .subscribe((response: any) => {
                            
              this.newEventModel = response.Result

              if (this.PageRights.ActionType == this.Constants.Actions.Edit)
                this.newEventModel.RequestType = this.Constants.PageType.Edit;
              else 
              if (this.PageRights.ActionType == this.Constants.Actions.View) {
                this.newEventModel.RequestType = this.Constants.PageType.View;
                this.PageRights.IsReadOnly = true;
              }

              this.newEventForm = this.createEventForm();
            });
        }
      }
    });
    this.newEventForm = this.createEventForm();
  }

  createEventForm(): FormGroup {
    return this.formBuilder.group({
      EventDetails: new FormControl({ value: this.newEventModel.EventDetails, disabled: this.PageRights.IsReadOnly }, Validators.required),
      Other: new FormControl({ value: this.newEventModel.Other, disabled: this.PageRights.IsReadOnly }),
      CaseStudy: new FormControl({ value: this.newEventModel.CaseStudy, disabled: this.PageRights.IsReadOnly }),
      EventFileUpload: new FormControl({ value: this.newEventModel.EventFileUpload, disabled: this.PageRights.IsReadOnly }),
      EventDate: new FormControl({ value: new Date(this.newEventModel.EventDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      // VCId: new FormControl({ value: this.UserModel.RoleCode == "VC" ? this.UserModel.UserTypeId : ''},  Validators.required)
     });

  }

  onChangeEvent(event){
    console.log("--", event);
    
    if(event === 'Other'){
      this.isOtherSelected = true;
      this.selectetdEvent = '';
    } else{
      this.isOtherSelected = false;
      this.selectetdEvent = this.newEventForm.get("EventDetails").value;

    }
    console.log("Event:",this.selectetdEvent);
    

  }

  onChangeOtherEvent(event){
    console.log("other Event:",event);
    this.selectetdEvent = event.target.value;
    
  }

  uploadedEventFile(event) {
    if (event.target.files.length > 0) {  
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      // if (this.AllowedPDFExtensions.indexOf(fileExtn) == -1) {
      //   this.newEventForm.get('EventFileUpload').setValue(null);
      //   this.dialogService.openShowDialog("Please upload PDF file only.");
      //   return;
      // }

      this.getUploadedFileData(event, this.Constants.DocumentType.EventDetails).then((response: FileUploadModel) => {
        this.EventFile = response;
console.log("Response:",response);

       });
    }
  }


  saveOrUpdateEventDetails() {
    this.setValueFromFormGroup(this.newEventForm, this.newEventModel);
    this.newEventModel.UserId = this.UserModel.UserTypeId;
    this.newEventModel.EventDetails = this.selectetdEvent;
    // if (this.PageRights.ActionType == this.Constants.Actions.Edit){
      this.newEventModel.EventDetails = this.newEventForm.get("EventDetails").value != 'Other' ? this.newEventForm.get("EventDetails").value : this.newEventForm.get("Other").value
    // }
    // this.newEventModel.CaseStudy = this.newEventForm.get("CaseStudy").value;
    this.newEventModel.EventFileUpload = (this.EventFile != '' ? this.setUploadedFile(this.EventFile) : null);
    this.newEventModel.VCId = this.UserModel.RoleCode == "VC" ? this.UserModel.UserTypeId : ''
    
    console.log("New Event",this.newEventModel);
    

    this.eventService.createOrUpdateEvents(this.newEventModel)
      .subscribe((districtResp: any) => {

        if (districtResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.Events.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(districtResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('Events deletion errors =>', error);
      });
  }
}
