<div id="vt-student-exit-survey-detail" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vt-student-exit-survey-details'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ vtStudentExitSurveyDetailModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New VT Student Exit Survey Detail
                    </div>
                    <div class="subtitle secondary-text">
                        <span>VT Student Exit Survey Detail Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vt-student-exit-survey-detail-button"
                *ngIf="PageRights.ActionType ==='add'" (click)="saveOrUpdateVTStudentExitSurveyDetailDetails()">
                <span>ADD</span>
            </button>

            <button mat-raised-button class="save-vt-student-exit-survey-detail-button"
                *ngIf="PageRights.ActionType ==='edit'" (click)="saveOrUpdateVTStudentExitSurveyDetailDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="vtStudentExitSurveyDetailForm" [formGroup]="vtStudentExitSurveyDetailForm"
                    class="vt-student-exit-survey-detail w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->
                    <!-- formGroupName="vtStudentDetailForm" -->
                    <div>
                        <div class="vtStudentDetailForm" formGroupName="studentDetailGroup">
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Student Unique Id</mat-label>
                                    <input matInput name="StudentUniqueId" formControlName="StudentUniqueId" digitOnly>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>District</mat-label>
                                    <input matInput name="District" formControlName="District">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Name of the School</mat-label>
                                    <input matInput name="NameOfSchool" formControlName="NameOfSchool">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>School UDISE</mat-label>
                                    <input matInput name="UdiseCode" formControlName="UdiseCode">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Select Class</mat-label>
                                    <input matInput name="Class" formControlName="Class">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Select Sector</mat-label>
                                    <mat-select formControlName="Sector" required appearance="outline"
                                        (selectionChange)="onChangeSector($event.value)">
                                        <mat-option [value]="sectorItem.Name" *ngFor="let sectorItem of sectorList">
                                            {{ sectorItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Select Job Role</mat-label>
                                    <input matInput name="JobRole" formControlName="JobRole">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>VT Name</mat-label>
                                    <input matInput name="VTName" formControlName="VTName">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>VT Mobile</mat-label>
                                    <input matInput name="VTMobile" formControlName="VTMobile">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Select VTP</mat-label>
                                    <input matInput name="VTPName" formControlName="VTPName">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                    <mat-label>Student Full Name</mat-label>
                                    <input matInput name="studentFullName" formControlName="StudentFullName">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Student Father Name</mat-label>
                                    <input matInput name="fatherName" formControlName="FatherName">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Student Mother Name</mat-label>
                                    <input matInput name="motherName" formControlName="MotherName">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Gender</mat-label>
                                    <input matInput name="Gender" formControlName="Gender">
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Date Of Birth</mat-label>
                                    <input matInput [matDatepicker]="dateOfBirth" name="DOB" formControlName="DOB"
                                        [disabled]="IsDisabled">
                                    <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
                                    <mat-datepicker #dateOfBirth></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                    <mat-label>Select Social Category</mat-label>
                                    <input matInput name="Category" formControlName="Category">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!-- Actual Survey Form -->

                    <!-- <div class="ExitSurveyForm" *ngIf="PageRights.ActionType !=='new'"> -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Religion</mat-label>
                            <mat-select name="Religion" formControlName="Religion" appearance="outline"
                                [disabled]="PageRights.IsReadOnly" required>
                                <mat-option *ngFor="let religionItem of religionList" [value]="religionItem.Name">
                                    {{religionItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Religion.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Religion')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student Seat No. provided by Board</mat-label>
                            <input matInput name="SeatNo" formControlName="SeatNo" required>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.SeatNo.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'SeatNo')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student Mobile Number</mat-label>
                            <input matInput name="StudentMobileNo" formControlName="StudentMobileNo" required digitOnly
                                minlength="10" maxlength="10">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentMobileNo.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentMobileNo')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Student WhatsApp Number</mat-label>
                            <input matInput name="StudentWANo" formControlName="StudentWANo" required digitOnly
                                minlength="10" maxlength="10">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StudentWANo.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StudentWANo')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Parent Mobile Number Available</mat-label>
                            <input matInput name="ParentMobileNo" formControlName="ParentMobileNo" required digitOnly
                                minlength="10" maxlength="10">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.ParentMobileNo.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'ParentMobileNo')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Enter Village/City/Town of Residence</mat-label>
                            <input matInput name="CityOfResidence" formControlName="CityOfResidence"
                                [disabled]="PageRights.IsReadOnly" required>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CityOfResidence.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CityOfResidence')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>District Of Residence</mat-label>
                            <mat-select name="districtCode" formControlName="DistrictOfResidence" required
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeDistrict($event.value)">
                                <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Name">
                                    {{districtItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DistrictOfResidence.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DistrictOfResidence')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Block Of Residence</mat-label>
                            <input matInput name="BlockOfResidence" formControlName="BlockOfResidence"
                                [disabled]="PageRights.IsReadOnly" required>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.BlockOfResidence.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'BlockOfResidence')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Pincode</mat-label>
                            <input matInput name="pinCode" formControlName="PinCode" [disabled]="PageRights.IsReadOnly"
                                digitOnly minlength="6" maxlength="6" required>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.PinCode.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'PinCode')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <!-- Status Of Employement -->
                    <!-- For 12th class students -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" *ngIf="className == 'Class 12'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Did You Do Internship in Class 10th or 12th?</mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="DoneInternship" formControlName="DoneInternship" appearance="outline"
                                fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DoneInternship.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DoneInternship')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" *ngIf="className == 'Class 10'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Do you want to continue your eduction post 10th? </mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="WillContHigherStudies" formControlName="WillContHigherStudies"
                                appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly"
                                (change)="onChangeHigherStudies($event.value)">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WillContHigherStudies.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'WillContHigherStudies')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" *ngIf="className == 'Class 12'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Are you willing to continue your higher education?</mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="WillContHigherStudies" formControlName="WillContHigherStudies"
                                appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly"
                                (change)="onChangeHigherStudies($event.value)">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WillContHigherStudies.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'WillContHigherStudies')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.WillContHigherStudies.value == 'Yes'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Whether Full Time/Part Time?</mat-label>

                            <mat-select name="WillBeFullTime" formControlName="WillBeFullTime" appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let natureOfWorkItem of natureOfWorkList"
                                    [value]="natureOfWorkItem.Id">
                                    {{natureOfWorkItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WillBeFullTime.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'WillBeFullTime')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.WillContHigherStudies.value == 'Yes'">
                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Which Course Will You Pursue? </mat-label>

                            <mat-select name="CourseToPursue" formControlName="CourseToPursue" appearance="outline"
                                [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeCourseToPursue($event.value)">
                                <mat-option *ngFor="let courseToBePursueItem of courseToBePursueList"
                                    [value]="courseToBePursueItem.Name">
                                    {{courseToBePursueItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CourseToPursue.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CourseToPursue')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Other Course Details </mat-label>

                            <input matInput name="otherCourse" formControlName="OtherCourse">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.OtherCourse.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'OtherCourse')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="34" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Stream Of Education </mat-label>

                            <mat-select name="StreamOfEducation" formControlName="StreamOfEducation"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let streamOfEducationItem of streamOfEducationList"
                                    [value]="streamOfEducationItem.Name">
                                    {{streamOfEducationItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.StreamOfEducation.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'StreamOfEducation')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" *ngIf="className == 'Class 12'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Are you willing to continue any skill training?</mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="WillingToContSkillTraining"
                                formControlName="WillingToContSkillTraining" appearance="outline" fxFlex="100"
                                fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly"
                                (change)="onChangeWillingToContSkillTraining($event.value)">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.WillingToContSkillTraining.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'WillingToContSkillTraining')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="className == 'Class 12'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.WillingToContSkillTraining.value == 'Yes'">
                            <mat-label>Full time/Part Time (willingness)</mat-label>

                            <mat-select name="SkillTrainingType" formControlName="SkillTrainingType"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let natureOfWorkItem of natureOfWorkList"
                                    [value]="natureOfWorkItem.Id">
                                    {{natureOfWorkItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.SkillTrainingType.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'SkillTrainingType')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.WillingToContSkillTraining.value == 'Yes'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Course Name/ Scheme</mat-label>

                            <input matInput name="CourseForTraining" formControlName="CourseForTraining">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CourseForTraining.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CourseForTraining')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.WillingToContSkillTraining.value == 'Yes'">
                            <mat-label>Sector/Trade</mat-label>

                            <mat-select name="SectorForTraining" formControlName="SectorForTraining"
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeSectorForTraining($event.value)">
                                <mat-option *ngFor="let sectorOfEmployementItem of sectorOfEmployementList"
                                    [value]="sectorOfEmployementItem.Name">
                                    {{sectorOfEmployementItem.Name}}
                                </mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.SectorForTraining.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'SectorForTraining')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.SectorForTraining.value == 'Other'">
                            <mat-label>Other if any </mat-label>

                            <input matInput name="OtherSectorsIfAny" formControlName="OtherSectorsIfAny">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.OtherSectorsIfAny.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'OtherSectorsIfAny')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="className == 'Class 12'">
                            <mat-label>Are you interested in Job or Self Employment after 12th?</mat-label>

                            <mat-select name="InterestedInJobOrSelfEmployment"
                                (selectionChange)="onChangeInterestedInJobOrSE($event.value)"
                                formControlName="InterestedInJobOrSelfEmployment" appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let Item of InterestedInJob" [value]="Item.value">
                                    {{Item.key}}</mat-option>
                            </mat-select>

                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInJobOrSelfEmployment.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'InterestedInJobOrSelfEmployment')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInJobOrSelfEmployment.value == 'Yes'">
                            <mat-label>What are you interested in?</mat-label>

                            <mat-select name="TopicsOfInterest" multiple formControlName="TopicsOfInterest"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let topicsOfJobInterestItem of topicsOfJobInterestList"
                                    [value]="topicsOfJobInterestItem.Name">
                                    {{topicsOfJobInterestItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.TopicsOfInterest.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'TopicsOfInterest')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInJobOrSelfEmployment.value == 'Yes'">
                            <mat-label>Employment is Full Time or Part Time?</mat-label>

                            <mat-select name="IsFullTime" formControlName="IsFullTime" appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let natureOfWorkItem of natureOfWorkList"
                                    [value]="natureOfWorkItem.Id">
                                    {{natureOfWorkItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.IsFullTime.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'IsFullTime')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                            *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInJobOrSelfEmployment.value == 'Yes'">
                            <mat-label>Sector Of Employement</mat-label>

                            <mat-select name="SectorsOfEmployment" formControlName="SectorsOfEmployment"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let sectorOfEmployementItem of sectorOfEmployementList"
                                    [value]="sectorOfEmployementItem.Name">
                                    {{sectorOfEmployementItem.Name}}
                                </mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.SectorsOfEmployment.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'SectorsOfEmployment')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.InterestedInJobOrSelfEmployment.value == 'Yes'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Tools & Equipment Receiving Status</mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="IsRelevantToVocCourse" formControlName="IsRelevantToVocCourse"
                                appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.IsRelevantToVocCourse.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'IsRelevantToVocCourse')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Do you want to pursue any skill training? (ITI, PMKVY, Private Training)
                            </mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="WantToPursueAnySkillTraining"
                                formControlName="WantToPursueAnySkillTraining" appearance="outline" fxFlex="100"
                                fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly"
                                (change)="WantToPursueAnySkillTraining($event.value)">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.WantToPursueAnySkillTraining.value == 'Yes'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Full time/Part Time (willingness)</mat-label>

                            <mat-select name="TrainingType" formControlName="TrainingType" appearance="outline"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-option *ngFor="let natureOfWorkItem of natureOfWorkList"
                                    [value]="natureOfWorkItem.Id">
                                    {{natureOfWorkItem.Name}}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.TrainingType.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'TrainingType')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Sectors in which you are interested?</mat-label>

                            <mat-select name="SectorForSkillTraining" formControlName="SectorForSkillTraining"
                                appearance="outline" [disabled]="PageRights.IsReadOnly"
                                (selectionChange)="onChangeSectorForSkillTraining($event.value)">
                                <mat-option *ngFor="let sectorOfEmployementItem of sectorOfEmployementList"
                                    [value]="sectorOfEmployementItem.Name">
                                    {{sectorOfEmployementItem.Name}}
                                </mat-option>
                                <mat-option value="Other">Other</mat-option>
                            </mat-select>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.SectorForSkillTraining.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'SectorForSkillTraining')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        *ngIf="vtStudentExitSurveyDetailForm.controls.SectorForSkillTraining.value == 'Other'">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>If other reasons, please mention </mat-label>

                            <input matInput name="OthersIfAny" formControlName="OthersIfAny">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.OthersIfAny.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'OthersIfAny')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" *ngIf="className == 'Class 12'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Are you willing to go for higher technical education?</mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-radio-group name="WillingToGoForTechHighEdu"
                                formControlName="WillingToGoForTechHighEdu" appearance="outline" fxFlex="100"
                                fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly" (change)="onChangeHigherStudies($event.value)">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.WillingToGoForTechHighEdu.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'WillingToGoForTechHighEdu')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" *ngIf="className == 'Class 12'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>There are different programs available for job and continued education together.
                                For Example HCL-Tech Bee, Tech Mahindra.
                                Will you be interested to know more about these opportunities?
                            </mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="WantToKnowAbtPgmsForJobsNContEdu"
                                formControlName="WantToKnowAbtPgmsForJobsNContEdu" appearance="outline" fxFlex="100"
                                fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.WantToKnowAbtPgmsForJobsNContEdu.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'WantToKnowAbtPgmsForJobsNContEdu')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" *ngIf="className == 'Class 10'">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Do you want to know about the opportunities in technical education,
                                Apprenticeship, Self employment?
                            </mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="WantToKnowAboutOpportunities"
                                formControlName="WantToKnowAboutOpportunities" appearance="outline" fxFlex="100"
                                fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                                [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.WantToKnowAboutOpportunities.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'WantToKnowAboutOpportunities')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-label>Can Lend A Hand India get in touch with you for such programs?
                            </mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="CanLahiGetInTouch" formControlName="CanLahiGetInTouch"
                                appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CanLahiGetInTouch.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CanLahiGetInTouch')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>Can Send The Updates?</mat-label>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row mat-radio" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-radio-group name="CanSendTheUpdates" formControlName="CanSendTheUpdates"
                                appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>
                                <mat-radio-button value="No">No</mat-radio-button>
                            </mat-radio-group>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CanSendTheUpdates.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CanSendTheUpdates')}}
                            </mat-error>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="70" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>collect email id/confirm if already there</mat-label>

                            <input matInput name="CollectedEmailId" formControlName="CollectedEmailId">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.CollectedEmailId.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'CollectedEmailId')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="70" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Survey completed by Student/Parent</mat-label>

                            <input matInput name="SurveyCompletedByStudentORParent"
                                formControlName="SurveyCompletedByStudentORParent">

                            <mat-error
                                *ngIf="vtStudentExitSurveyDetailForm.controls.SurveyCompletedByStudentORParent.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm,
                                'SurveyCompletedByStudentORParent')}}
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Date of Survey Completion / Last Status</mat-label>

                            <input matInput name="dateOfIntv" formControlName="DateOfIntv" [matDatepicker]="date"
                                required [disabled]="PageRights.IsReadOnly" [max]="CurrentDate">
                            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                            <mat-datepicker #date></mat-datepicker>

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.DateOfIntv.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'DateOfIntv')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Remark</mat-label>

                            <input matInput name="Remark" formControlName="Remark">

                            <mat-error *ngIf="vtStudentExitSurveyDetailForm.controls.Remark.invalid">
                                {{getErrorMessage(vtStudentExitSurveyDetailForm, 'Remark')}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- </div> -->

                    <!-- </mat-tab>
    </mat-tab-group> -->
                </form>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>