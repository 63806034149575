<div id="vt-transfer" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2 logo-text">
                        <span>VTP Transfer Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                    <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">
                <form name="vtpTransferForm" [formGroup]="vtpTransferForm" class="vt-transfer w-100-p" fxLayout="column"
                    fxFlex>
                    <div class="tab-content p-24">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Vocational Training Provider</mat-label>

                                <mat-select formControlName="VTPId" name="VTPId" appearance="fill" #SelectVtp
                                    (selectionChange)="onChangeVTP($event.value)">
                                    <mat-select-filter class="select-filter" *ngIf="SelectVtp.focused" [array]="vtpList"
                                        (filteredReturn)="this.filteredVTPItems = $event" [displayMember]="'Name'">
                                    </mat-select-filter>
                                    <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of filteredVTPItems">
                                        {{ vtpItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vtpTransferForm.controls.VTPId.invalid">
                                    {{getErrorMessage(vtpTransferForm, 'VTPId')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Sector</mat-label>

                                <mat-select formControlName="SectorId" required appearance="outline"
                                    [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeSector($event.value)">
                                    <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                        {{ sectorItem.Name }}
                                    </mat-option>
                                </mat-select>

                                <mat-error *ngIf="vtpTransferForm.controls.SectorId.invalid">
                                    {{getErrorMessage(vtpTransferForm, 'SectorId')}}
                                </mat-error>
                            </mat-form-field>

                            <button mat-raised-button class="search-button fuse-white mt-24 mt-md-0"
                                (click)="SearchVTPSchools()">
                                <span>Search VTP Schools</span>
                            </button>

                            <button mat-raised-button class="submit-button fuse-white mt-24 mt-md-0"
                                (click)="saveVTTransfers()">
                                <span>Transfer VTP</span>
                            </button>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-label>List of VTP's Schools</mat-label>
                        </div>

                        <!-- CONTENT -->
                        <div class="content-card">
                            <table mat-table class="school-table responsive-table" [dataSource]="tableDataSource"
                                fusePerfectScrollbar [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                                <!-- SrNo Column -->
                                <ng-container matColumnDef="SrNo">
                                    <th mat-header-cell *matHeaderCellDef class="mat-header-center">
                                        Sr.No.
                                    </th>
                                    <td mat-cell *matCellDef="let vcSchool; let rowIndex = index">
                                        <p class="text-truncate">{{rowIndex + 1}}</p>
                                    </td>
                                </ng-container>

                                <!-- School Column -->
                                <ng-container matColumnDef="School">
                                    <th mat-header-cell *matHeaderCellDef class="mat-header-center">
                                        School Name
                                    </th>
                                    <td mat-cell *matCellDef="let schoolName">
                                        <p class="text-truncate">{{schoolName.School}}</p>
                                    </td>
                                </ng-container>

                                <!-- IsSelected Column -->
                                <ng-container matColumnDef="IsSelected">
                                    <th mat-header-cell *matHeaderCellDef class="mat-header-center">Is Transfer?
                                    </th>
                                    <td mat-cell *matCellDef="let schoolName" class="text-center">
                                        <mat-checkbox [checked]="schoolName.IsSelected"
                                            (change)="onVTPSchoolForTransfer($event, schoolName)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <!-- ToVTPId Column -->
                                <ng-container matColumnDef="ToVTPId">
                                    <th mat-header-cell *matHeaderCellDef class="mat-header-center">
                                        To VTP
                                    </th>
                                    <td mat-cell *matCellDef="let schoolName">
                                        <mat-select appearance="outline" placeholder="Select VTP"
                                            [disabled]="!schoolName.IsSelected" [required]="schoolName.IsSelected"
                                            (selectionChange)="onChangeToVTP($event.value,schoolName)">
                                            <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of toVtpList">
                                                {{ vtpItem.Name }}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                </ng-container>

                                <!-- ToVCId Column -->
                                <ng-container matColumnDef="ToVCId">
                                    <th mat-header-cell *matHeaderCellDef class="mat-header-center">
                                        To VC
                                    </th>
                                    <td mat-cell *matCellDef="let schoolName">
                                        <mat-select appearance="outline" placeholder="Select VC"
                                            [disabled]="!schoolName.IsSelected" [required]="schoolName.IsSelected"
                                            (selectionChange)="onChangeVC($event.value,schoolName)">
                                            <mat-option [value]="vcItem.Id" *ngFor="let vcItem of schoolName.ToVCList">
                                                {{ vcItem.Name }}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                </ng-container>

                                <!-- DateOfAllocation Column -->
                                <ng-container matColumnDef="DateOfAllocation">
                                    <th mat-header-cell *matHeaderCellDef class="mat-header-center">Date Of Allocation
                                    </th>
                                    <td mat-cell *matCellDef="let schoolName">
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="DateOfAllocation" name="DateOfAllocation"
                                                (dateChange)="onChangeDOA($event.value,schoolName)"
                                                [disabled]="!schoolName.IsSelected" [required]="schoolName.IsSelected">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="DateOfAllocation"></mat-datepicker-toggle>
                                            <mat-datepicker #DateOfAllocation></mat-datepicker>
                                        </mat-form-field>
                                    </td>
                                </ng-container>

                                <!-- DateOfJoining Column -->
                                <ng-container matColumnDef="DateOfJoining">
                                    <th mat-header-cell *matHeaderCellDef class="mat-header-center">Date Of Joining</th>
                                    <td mat-cell *matCellDef="let schoolName">
                                        <mat-form-field appearance="outline">
                                            <input matInput [matDatepicker]="DateOfJoining" name="DateOfJoining"
                                                (dateChange)="onChangeDOJ($event.value,schoolName)"
                                                [disabled]="!schoolName.IsSelected" [required]="schoolName.IsSelected">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="DateOfJoining"></mat-datepicker-toggle>
                                            <mat-datepicker #DateOfJoining></mat-datepicker>
                                        </mat-form-field>
                                    </td>
                                </ng-container>

                                <!-- ToSectorId Column -->
                                <ng-container matColumnDef="ToSectorId">
                                    <th mat-header-cell *matHeaderCellDef class="mat-header-center">
                                        To Sector
                                    </th>
                                    <td mat-cell *matCellDef="let schoolName">
                                        <mat-select appearance="outline" placeholder="Select Sector"
                                            [disabled]="!schoolName.IsSelected" [required]="schoolName.IsSelected"
                                            (selectionChange)="onChangeToSector($event.value,schoolName)">
                                            <mat-option [value]="sectorItem.Id"
                                                        *ngFor="let sectorItem of schoolName.toSectorList">
                                                {{ sectorItem.Name }}
                                            </mat-option>
                                        </mat-select>
                                    </td>
                                </ng-container>

                                <!-- Remarks Column -->
                                <ng-container matColumnDef="Remarks">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Remarks
                                    </th>
                                    <td mat-cell *matCellDef="let schoolName">
                                        <p class="text-truncate">{{schoolName.Remarks}}</p>
                                    </td>
                                </ng-container>


                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                                <tr mat-row *matRowDef="let vcSchool; columns: displayedColumns; let i = index"
                                    matRipple>
                                </tr>
                            </table>
                            <mat-paginator #ListPaginator [length]="tableDataSource.filteredData.length" [pageIndex]="0"
                                [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </div>
                        <!-- / CONTENT -->

                    </div>
                </form>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>