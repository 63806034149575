<div id="vt-daily-attendance-tracking-report" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center">

            <!-- APP TITLE -->
            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    shopping_basket
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    VT DAILY ATTENDANCE TRACKING
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div class="search-wrapper mx-32 mx-md-0">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon>search</mat-icon>
                    <input #filter (keyup)="applySearchFilter($event.target.value)"
                        placeholder="Search for a daily attendance tracking">
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <div class="action-buttons">
                <button mat-raised-button class="fuse-white filter-button" style="margin-bottom: 10px;"
                    (click)="IsShowFilter=!IsShowFilter"><span>FILTERS</span></button>

                <a [routerLink]="['./']" class="refresh-data mt-24 mt-md-0">
                    <mat-icon [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">refresh</mat-icon>
                </a>

                <a *ngIf="PageRights.IsExport" class="export-excel" (click)="exportExcel()">
                    <mat-icon> cloud_download</mat-icon>
                </a>
            </div>
            <!-- / ADD BUTTON -->
        </div>
        <!-- / HEADER -->

        <!-- Filter Section -->
        <div class="filter-content-card" *ngIf="IsShowFilter">
            <div class="filter-content">
                <form name="dailyAttendanceTrackingForm" [formGroup]="dailyAttendanceTrackingForm"
                    class="vt-daily-attendance-tracking-report" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex>
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="15" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label> Academic Year</mat-label>

                            <mat-select formControlName="AcademicYearId" name="academicYearId" appearance="fill"
                                required>
                                <mat-option [value]="academicYearItem.Id"
                                    *ngFor="let academicYearItem of academicYearList">
                                    {{ academicYearItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>Vocational Training Provider</mat-label>

                            <mat-select formControlName="VTPId" appearance="fill" #SelectVTP
                                (selectionChange)="onChangeVTP($event.value)">
                                <mat-select-filter class="select-filter" *ngIf="SelectVTP.focused" [array]="vtpList"
                                    (filteredReturn)="filteredVTPItems = $event" [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="vtpItem.Id" *ngFor="let vtpItem of filteredVTPItems">
                                    {{ vtpItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>Vocational Coordinator</mat-label>

                            <mat-select formControlName="VCId" appearance="fill" #SelectVC
                                (selectionChange)="onChangeVC($event.value)">
                                <mat-select-filter class="select-filter" *ngIf="SelectVC.focused" [array]="vcList"
                                    (filteredReturn)="filteredVCItems = $event" [displayMember]="'Name'">
                                </mat-select-filter>
                                <mat-option [value]="vcItem.Id" *ngFor="let vcItem of filteredVCItems">
                                    {{ vcItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>School</mat-label>

                            <mat-select formControlName="SchoolId" name="SchoolId" appearance="fill" #SelectSchool>
                                <mat-select-filter class="select-filter" *ngIf="SelectSchool.focused"
                                    [array]="schoolList" (filteredReturn)="this.filteredSchoolItems = $event"
                                    [displayMember]="'Name'"></mat-select-filter>
                                <mat-option [value]="schoolsItem.Id" *ngFor="let schoolsItem of filteredSchoolItems">
                                    {{ schoolsItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Sector</mat-label>

                            <mat-select formControlName="SectorId" name="sectorId" appearance="fill"
                                (selectionChange)="onChangeSector($event.value)">
                                <mat-option [value]="sectorItem.Id" *ngFor="let sectorItem of sectorList">
                                    {{ sectorItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Select Job Role</mat-label>

                            <mat-select formControlName="JobRoleId" name="jobRoleId" appearance="fill">
                                <mat-option [value]="jobRoleItem.Id" *ngFor="let jobRoleItem of jobRoleList">
                                    {{ jobRoleItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Division</mat-label>

                            <mat-select formControlName="DivisionId" name="divisionId" appearance="fill"
                                (selectionChange)="onChangeDivision($event.value)"
                                [required]="Constants.UserRoleIds.includes(UserModel.RoleCode)">
                                <mat-option [value]="DivisionItem.Id" *ngFor="let DivisionItem of divisionList">
                                    {{ DivisionItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>District</mat-label>
                            <mat-select #districtMatSelect formControlName="DistrictId" name="districtId"
                                appearance="fill" multiple
                                [required]="Constants.UserRoleIds.includes(UserModel.RoleCode)">
                                <mat-option (click)="toggleDistrictSelections($event)" [value]="0">All Districts
                                </mat-option>
                                <mat-option *ngFor="let districtItem of districtList" [value]="districtItem.Id">
                                    {{districtItem.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School Management</mat-label>

                            <mat-select formControlName="SchoolManagementId" name="schoolManagementId"
                                appearance="fill">
                                <mat-option [value]="schoolManagementItem.Id"
                                    *ngFor="let schoolManagementItem of schoolManagementList">
                                    {{ schoolManagementItem.Name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">

                       

                        <mat-form-field fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>From Date</mat-label>
                            <input matInput name="fromDate" formControlName="FromDate" [matDatepicker]="fromDate"
                                [max]="CurrentDate">
                            <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                            <mat-datepicker #fromDate></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="filter-disabled">
                            <mat-label>To Date</mat-label>
                            <input matInput name="toDate" formControlName="ToDate" [matDatepicker]="toDate"
                                [max]="CurrentDate">
                            <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                            <mat-datepicker #toDate></mat-datepicker>
                        </mat-form-field>

                        <div class="clear-filter pr-1" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="onLoadVTDailyAttendanceTrackingReportsByFilters()"><span>SEARCH</span></button>
                            <button mat-raised-button class="fuse-white filter-button"
                                (click)="resetReportFilters()"><span>CLEAR</span></button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
        <!-- /Filter Section -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table matTableExporter class="vt-daily-attendance-tracking-report-table responsive-table" #table
                [dataSource]="tableDataSource" #exporter="matTableExporter" matSort fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{'suppressScrollX': false}">

                <!-- SrNo Column -->
                <ng-container matColumnDef="SrNo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sr No</mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking; let RowIndex = index;">
                        <p class="text-truncate">{{dailyAttendanceTracking.SrNo}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTPName Column -->
                <ng-container matColumnDef="VTPName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VTP Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.VTPName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCName Column -->
                <ng-container matColumnDef="VCName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.VCName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCMobile Column -->
                <ng-container matColumnDef="VCMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.VCMobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VCEmail Column -->
                <ng-container matColumnDef="VCEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VC Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.VCEmail}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTName Column -->
                <ng-container matColumnDef="VTName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.VTName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTMobile Column -->
                <ng-container matColumnDef="VTMobile">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Mobile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.VTMobile}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTEmail Column -->
                <ng-container matColumnDef="VTEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.VTEmail}}</p>
                    </mat-cell>
                </ng-container>

                <!-- VTDateOfJoining Column -->
                <ng-container matColumnDef="VTDateOfJoining">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>VT Date Of Joining
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">
                            {{dailyAttendanceTracking.VTDateOfJoining | date: Constants.ShortDateFormat }}</p>
                    </mat-cell>
                </ng-container>

                <!-- UDISE Column -->
                <ng-container matColumnDef="UDISE">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>UDISE</mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.UDISE}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SchoolName Column -->
                <ng-container matColumnDef="SchoolName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>School
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.SchoolName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- JobRoleName Column -->
                <ng-container matColumnDef="JobRoleName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Job Role
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.JobRoleName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- SectorName Column -->
                <ng-container matColumnDef="SectorName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Sector
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.SectorName}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ReportType Column -->
                <ng-container matColumnDef="ReportType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Report Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.ReportType}}</p>
                    </mat-cell>
                </ng-container>

                <!-- DateOfReport Column -->
                <ng-container matColumnDef="DateOfReport">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Date Of Report
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.DateOfReport}}</p>
                    </mat-cell>
                </ng-container>

                <!-- ActualSubmissionDateTime Column -->
                <ng-container matColumnDef="ActualSubmissionDateTime">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Submission Date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.ActualSubmissionDateTime}}</p>
                    </mat-cell>
                </ng-container>

                <!-- GeoLocation Column -->
                <ng-container matColumnDef="GeoLocation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow.lt-sm fxShow.gt-md>Geo Location
                    </mat-header-cell>
                    <mat-cell *matCellDef="let dailyAttendanceTracking">
                        <p class="text-truncate">{{dailyAttendanceTracking.GeoLocation}}</p>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let dailyAttendanceTracking; columns: displayedColumns;"
                    class="vt-daily-attendance-tracking-report" matRipple>
                </mat-row>
            </mat-table>

            <mat-paginator #ListPaginator [length]="SearchBy.TotalResults" [pageIndex]="SearchBy.PageIndex"
                [pageSize]="SearchBy.PageSize" [pageSizeOptions]="SearchBy.PageSizeOptions"
                [showFirstLastButtons]="SearchBy.ShowFirstLastButtons" (page)="onPageIndexChanged($event)">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>