import { Component, OnInit ,NgZone ,ViewEncapsulation} from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseListComponent } from 'app/common/base-list/base.list.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { EventsModel } from './events.model';
import { EventService } from './event.service';
import { fuseAnimations } from '@fuse/animations';


@Component({
  
  selector: 'data-list-view',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})

export class EventsComponent extends BaseListComponent<EventsModel> implements OnInit {

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public eventService:EventService) { 
    super(commonService,router,routeParams,snackBar,zone)
  }

  ngOnInit(): void {

    this.eventService.GetAllByCriteria({
      UserId : this.UserModel.UserTypeId
    }).subscribe(response => {
      this.displayedColumns = ['EventDate', 'EventName', 'CaseStudy','Actions'];

      this.tableDataSource.data = response.Results;
      this.tableDataSource.sort = this.ListSort;
      this.tableDataSource.paginator = this.ListPaginator;
      this.tableDataSource.filteredData = this.tableDataSource.data;

      this.zone.run(() => {
        if (this.tableDataSource.data.length == 0) {
          this.showNoDataFoundSnackBar();
        }
      });
      this.IsLoading = false;
      
    })
  }
}
