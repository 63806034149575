import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Report001FormModel } from '../report001-form.model'
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'app/services/common.service';


@Component({
  selector: 'app-create-new-report001-form',
  templateUrl: './create-new-report001-form.component.html',
  styleUrls: ['./create-new-report001-form.component.scss']
})
export class CreateNewReport001FormComponent extends BaseComponent<Report001FormModel> implements OnInit {

  report001Form: FormGroup;
  report001FormModel: Report001FormModel;

  regexStr: "^[a-zA-Z0–9 _]*$"

  @ViewChild('autosize') autosize: CdkTextareaAutosize;


  constructor(private formBuilder: FormBuilder,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    public commonService: CommonService) {
    super(commonService, router, routeParams, snackBar);

    this.report001FormModel = new Report001FormModel();
  }

  ngOnInit(): void {
    this.report001Form = this.createReportForm001();

  }

  createReportForm001(): FormGroup {
    return this.formBuilder.group({
      VPlaceName: new FormControl(),
      FName: new FormControl(),
      FContact: new FormControl('', [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      FDesignation: new FormControl(),
      DateOfVisit: new FormControl({ value: this.report001FormModel.DateOfVisit }, [Validators.required]),
      SectionId: new FormControl({ value: this.report001FormModel.SectionId}, Validators.required),
      ClassId: new FormControl({ value: this.report001FormModel.ClassId}, Validators.required),
      ModuleId: new FormControl({ value: this.report001FormModel.ModuleId }, Validators.required),
      UnitId: new FormControl({ value: this.report001FormModel.UnitId }, Validators.required),
      AcademicYearId: new FormControl({ value: this.report001FormModel.AcademicYearId}, Validators.required),
      IsVisitedTheVennu:  new FormControl('', Validators.required),
      IsIntroducedPlace: new FormControl('', Validators.required),
      IsDividedTheStudents:  new FormControl({ value: this.report001FormModel.IsDividedTheStudents}, Validators.required),
      DetailsOfPlanning:  new FormControl('', Validators.required),
      IsAnySecurityGurad:  new FormControl({ value: this.report001FormModel.IsAnySecurityGurad}, Validators.required),
      IsFireSafty:  new FormControl({ value: this.report001FormModel.IsFireSafty}, Validators.required),
      IsAccidentFireSafety: new FormControl({ value: this.report001FormModel.IsAccidentFireSafety}, Validators.required),
      IsBasicSafety:  new FormControl({ value: this.report001FormModel.IsBasicSafety}, Validators.required),
      StudentSecurity:  new FormControl('', Validators.required),
      IsTransport:  new FormControl({ value: this.report001FormModel.IsTransport}, Validators.required),
      TransportMode:  new FormControl({ value: this.report001FormModel.TransportMode}, Validators.required),
      TransportCost:  new FormControl(),
      TransportlReason:  new FormControl('', Validators.required),
      TransportDetails:  new FormControl(),
      IsHMAware:  new FormControl({ value: this.report001FormModel.IsHMAware}, Validators.required),
      IsConcernedTeacher:  new FormControl({ value: this.report001FormModel.IsConcernedTeacher}, Validators.required),
      TName:  new FormControl('', Validators.required),
      TContact:  new FormControl('', Validators.required),
      IsParentAware:  new FormControl({ value: this.report001FormModel.IsParentAware}, Validators.required),
      IsVoluntererParent:  new FormControl({ value: this.report001FormModel.IsVoluntererParent}, Validators.required),
      ParentName:  new FormControl(),
      PContact:  new FormControl(),
      DetailsOfpermission:  new FormControl(),
      IsBehaviourRules:  new FormControl({ value: this.report001FormModel.IsBehaviourRules}, Validators.required),
      IsBRForEmergency:  new FormControl({ value: this.report001FormModel.IsBRForEmergency}, Validators.required),
      IsBRAttention:  new FormControl({ value: this.report001FormModel.IsBRAttention}, Validators.required),
      IsBrWhereToCome:  new FormControl({ value: this.report001FormModel.IsBrWhereToCome}, Validators.required),
      IsKidViolationBR:  new FormControl({ value: this.report001FormModel.IsKidViolationBR}, Validators.required),
      BRDetails:  new FormControl(),
    });
  }

  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z0-9_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false; 
  }

  alphaOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z_ ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false; 
  }
}
