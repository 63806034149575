import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from 'app/common/base/base.component';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DialogService } from 'app/common/confirm-dialog/dialog.service';
import { RouteConstants } from 'app/constants/route.constant'
import { VTFieldIndustryVisitConductedService } from '../vt-field-industry-visit-conducted.service';
import { VTFieldIndustryVisitConductedModel } from '../vt-field-industry-visit-conducted.model';
import { DropdownModel } from 'app/models/dropdown.model';
import { ModuleUnitSessionModel } from 'app/models/module-unit-session-model';
import { StudentAttendanceModel } from 'app/models/student.attendance.model';
import { el } from 'date-fns/locale';
import { FileUploadModel } from 'app/models/file.upload.model';

@Component({
  selector: 'vt-field-industry-visit-conducted',
  templateUrl: './create-vt-field-industry-visit-conducted.component.html',
  styleUrls: ['./create-vt-field-industry-visit-conducted.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CreateVTFieldIndustryVisitConductedComponent extends BaseComponent<VTFieldIndustryVisitConductedModel> implements OnInit {
  vtFieldIndustryVisitConductedForm: FormGroup;
  vtFieldIndustryVisitConductedModel: VTFieldIndustryVisitConductedModel;
  unitSessionsModels: ModuleUnitSessionModel[];
  studentAttendanceModel: StudentAttendanceModel[];
  sectionList: DropdownModel[];
  dataSource: any;
  displayColumns: string[] = ['StudentName', 'PresentStatus'];
  classTaughtList: [DropdownModel];
  moduleTaughtList: [DropdownModel];
  unitList: DropdownModel[];
  sessionList: DropdownModel[];
  fieldVisitPhotoFile: FileUploadModel;
  minReportingDate: Date;

  isDetailsOfPlan: boolean = false;
  isSafetyDetails: boolean = false;
  isTransportDetails: boolean = false;
  isBPtDetails: boolean = false;
  isMaterialDetails: boolean = false;
  isPermissionDetails: boolean = false;
  isTransport: boolean = false;
  isTeacherAssigned: boolean = false;
  isParentVoulntry: boolean = false;
  isfvOjIntrest: boolean = false;
  isfvIntrest: boolean = false;
  vtfeildId: any;
  isPostVisit: boolean = false;
  seestionModel: any = []

  ConcentPhotoFile: FileUploadModel;
  transPortMode = [
    { Id: 'Earth', value: 'Earth' },
    { Id: 'Air', value: 'Air' },
    { Id: 'Water', value: 'Water' }

  ]
  isComplete: boolean = false
  vtFieldvist: any;
  isNew: boolean = false;

  SectionListItem = []
  FinalSection = []
  SelectedClass = []

  constructor(public commonService: CommonService,
    public router: Router,
    public routeParams: ActivatedRoute,
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private route: ActivatedRoute,
    private vtFieldIndustryVisitConductedService: VTFieldIndustryVisitConductedService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder) {
    super(commonService, router, routeParams, snackBar);

    // Set the default vtFieldIndustryVisitConducted Model
    this.vtFieldIndustryVisitConductedModel = new VTFieldIndustryVisitConductedModel();
    this.unitSessionsModels = <ModuleUnitSessionModel[]>[];
    this.fieldVisitPhotoFile = new FileUploadModel();

    let dateOfAllocation = new Date(this.UserModel.DateOfAllocation);
    let maxDate = new Date(Date.now());

    let Time = maxDate.getTime() - dateOfAllocation.getTime();
    let Days = Math.floor(Time / (1000 * 3600 * 24));
    if (Days < this.Constants.BackDatedReportingDays) {
      this.minReportingDate = new Date(this.UserModel.DateOfAllocation);
    }
    else {
      let past7days = maxDate;
      past7days.setDate(past7days.getDate() - this.Constants.BackDatedReportingDays)
      this.minReportingDate = past7days;
    }
    this.ConcentPhotoFile = new FileUploadModel();
    this.minReportingDate = new Date(this.UserModel.DateOfAllocation);
  }

  ngOnInit(): void {
    this.vtFieldIndustryVisitConductedService.getDropdownForVTFieldIndustry(this.UserModel).subscribe((response) => {
      if (response[0].Success) {
        this.classTaughtList = response[0].Results;
      }

      if (response[1].Success) {
        this.moduleTaughtList = response[1].Results;
      }

      this.route.paramMap.subscribe(params => {
        if (params.keys.length > 0) {
          this.PageRights.ActionType = params.get('actionType');

          if (this.PageRights.ActionType == this.Constants.Actions.New) {
            this.vtFieldIndustryVisitConductedModel = new VTFieldIndustryVisitConductedModel();

          } else {
            var vtFieldIndustryVisitConductedId: string = params.get('vtFieldIndustryVisitConductedId')
            this.vtfeildId = vtFieldIndustryVisitConductedId;

            this.vtFieldIndustryVisitConductedService.getVTFieldIndustryVisitConductedById(vtFieldIndustryVisitConductedId)
              .subscribe((response: any) => {
                this.vtFieldIndustryVisitConductedModel = response.Result;
                this.vtFieldvist = response.Result;


                if (new Date() > new Date(response.Result.ReportingDate)) {
                  this.isPostVisit = true
                }
                if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
                  this.vtFieldIndustryVisitConductedModel.RequestType = this.Constants.PageType.Edit;
                  this.isNew = true;
                  this.PageRights.IsReadOnly = true;
                }
                else if (this.PageRights.ActionType == this.Constants.Actions.View) {
                  this.vtFieldIndustryVisitConductedModel.RequestType = this.Constants.PageType.View;
                  this.PageRights.IsReadOnly = true;
                }

                this.unitSessionsModels = this.vtFieldIndustryVisitConductedModel.UnitSessionsModels;

                this.onChangeClassForTaught(this.vtFieldIndustryVisitConductedModel.ClassTaughtId);
                this.onChangeSectionForTaught(response.Result.SectionTaughtId);
                this.onChangeCourseModule(response.Result.UnitSessionsModels.ModuleId);
                this.onChangeUnitsTaught(response.Result.UnitSessionsModels.UnitId)
                this.onViewVtfieldVisit();



              });
          }
        }
      });
    });

    this.vtFieldIndustryVisitConductedForm = this.createVTFieldIndustryVisitConductedForm();
  }

  onChangeReportingDate(): boolean {
    let reportingDate = this.vtFieldIndustryVisitConductedForm.get('ReportingDate').value;

    if (reportingDate != null && new Date(reportingDate).getDay() == 0) {
      this.dialogService.openShowDialog("User cannot submit the VT Field Visit Conducted on Sunday");
      return false
    }
    return true
  }



  onChangeClassForTaught(classId): void {
    if (classId != null) {
      this.commonService.GetSectionsByVTClassId({ DataId: this.UserModel.UserTypeId, DataId1: classId }).subscribe(response => {
        if (response.Success) {
          this.sectionList = response.Results;
        }
      });

      let moduleItem = this.vtFieldIndustryVisitConductedForm.get('ModuleId').value;
      if (moduleItem != null && moduleItem.Id != null) {
        this.onChangeCourseModule(moduleItem);
      }
    }
    if (this.PageRights.ActionType !== this.Constants.Actions.Edit) {
      this.sectionList = <DropdownModel[]>[];
      this.unitList = <DropdownModel[]>[];
      this.sessionList = <DropdownModel[]>[];
      // this.unitSessionsModels = <ModuleUnitSessionModel[]>[];
    }

    this.vtFieldIndustryVisitConductedForm.get("SectionIds").setValue(null);

    let studentAttendancesControls = <FormArray>this.vtFieldIndustryVisitConductedForm.get('StudentAttendances');
    studentAttendancesControls.clear();
  }

  onChangeSectionForTaught(sectionId) {
    
    let studentAttendancesControls = <FormArray>this.vtFieldIndustryVisitConductedForm.get('StudentAttendances');
    studentAttendancesControls.clear();
    this.vtFieldvist.StudentAttendances.forEach(studentItem => {
      studentAttendancesControls.push(this.formBuilder.group(studentItem));
    });
    let initialFormValues = this.vtFieldIndustryVisitConductedForm.value;
    this.vtFieldIndustryVisitConductedForm.reset(initialFormValues);
    
  }

  onChangeCourseModule(moduleItem): void {
    let classId = this.vtFieldIndustryVisitConductedForm.get("ClassTaughtId").value === null ? this.vtFieldvist.ClassTaughtId : this.vtFieldIndustryVisitConductedForm.get("ClassTaughtId").value;

    if (typeof (classId) == 'object') {
      if (classId.length != 0 && moduleItem.Id != null) {
        this.unitList = []
        classId.map(classTaught => {
          this.commonService.GetUnitsByClassAndModuleId({ DataId: classTaught.Id, DataId1: moduleItem.Id, DataId2: this.UserModel.UserTypeId, SelectTitle: 'Unit Taught' }).subscribe((response: any) => {
            if (response.Success) {
              response.Results.map(unit => {
                this.unitList.push(unit)
              })
            }
          });
        })
      }
    }
    else if (typeof (classId) == 'string') {
      if (classId != '' && moduleItem.Id != null) {
        this.commonService.GetUnitsByClassAndModuleId({ DataId: classId, DataId1: moduleItem.Id, DataId2: this.UserModel.UserTypeId, SelectTitle: 'Unit Taught' }).subscribe((response: any) => {
          if (response.Success) {
            this.unitList = response.Results;
          }
        });
      }
    }
    else {
      this.unitList = <DropdownModel[]>[];
      this.sessionList = <DropdownModel[]>[];
    }
  }

  // onChangeUnitsTaught(unitItem): void {
  //   let classId = this.vtFieldIndustryVisitConductedForm.get('ClassTaughtId').value;

  //   if (classId != '' && unitItem.Id != null) {
  //     this.commonService.GetSessionsByUnitId({ DataId: unitItem.Id, SelectTitle: 'Sessions Taught' }).subscribe((response: any) => {
  //       if (response.Success) {
  //         this.sessionList = response.Results;
  //       }
  //     });
  //   }
  //   else {
  //     this.sessionList = <DropdownModel[]>[];
  //   }
  // }

  onChangeUnitsTaught(unitItem): void {
    let classId = this.vtFieldIndustryVisitConductedForm.get('ClassTaughtId').value;

    if (typeof (classId) == "object") {
      if (classId.length != 0 && unitItem.Id != null) {
        this.commonService.GetSessionsByUnitId({ DataId: unitItem.Id, SelectTitle: 'Sessions Taught' }).subscribe((response: any) => {
          if (response.Success) {
            this.sessionList = response.Results;
          }
        });
      }
    }
    else if (typeof (classId) == "string") {
      if (classId != '' && unitItem.Id != null) {
        this.commonService.GetSessionsByUnitId({ DataId: unitItem.Id, SelectTitle: 'Sessions Taught' }).subscribe((response: any) => {
          if (response.Success) {
            this.sessionList = response.Results;
          }
        });
      }
    }
    else {
      this.sessionList = <DropdownModel[]>[];
    }


  }

  onChangeClassForTaught1(event) {
    this.SelectedClass = event
    this.SectionListItem = []
    event.map(res => {
      if (res.Id) {
        this.commonService.GetSectionsByVTClassId({ DataId: this.UserModel.UserTypeId, DataId1: res.Id }).subscribe(response => {
          if (response.Success) {
            let classObject = []
            response.Results.map(result => {
              if (result.Id) {
                classObject.push(result)
              }
            })
            this.SectionListItem.push({ ...res, Section: classObject })
          }
        });
        let moduleItem = this.vtFieldIndustryVisitConductedForm.get('ModuleId').value;
        if (moduleItem != null && moduleItem.Id != null) {
          this.onChangeCourseModule(moduleItem);
        }
      }
    })
    if (this.PageRights.ActionType !== this.Constants.Actions.Edit) {
      this.sectionList = <DropdownModel[]>[];
      this.unitList = <DropdownModel[]>[];
      this.sessionList = <DropdownModel[]>[];
      // this.unitSessionsModels = <ModuleUnitSessionModel[]>[];
    }
    this.vtFieldIndustryVisitConductedForm.get("SectionIds").setValue(null);
    let studentAttendancesControls = <FormArray>this.vtFieldIndustryVisitConductedForm.get('StudentAttendances');
    studentAttendancesControls.clear();
  }

  onChangeSectionForTaught1(event) {

    this.FinalSection = event
    if (this.SectionListItem.length > 0) {
      let studentAttendancesControls = <FormArray>this.vtFieldIndustryVisitConductedForm.get('StudentAttendances');
      studentAttendancesControls.clear();
      event.map(res => {
        this.commonService.getStudentsByClassId({ DataId: this.UserModel.UserTypeId, DataId1: res[0], DataId2: res[1] }).subscribe(response => {
          if (response.Success) {
            response.Results.forEach(studentItem => {

              studentAttendancesControls.push(this.formBuilder.group({ ...studentItem, SectionId: res[1] }));
            });
            let initialFormValues = this.vtFieldIndustryVisitConductedForm.value;
            this.vtFieldIndustryVisitConductedForm.reset(initialFormValues);
          }
        });
      })
      console.log(this.vtFieldIndustryVisitConductedForm.get('StudentAttendances'));
    }
    else {
      let studentAttendancesControls = <FormArray>this.vtFieldIndustryVisitConductedForm.get('StudentAttendances');
      studentAttendancesControls.clear();
    }
  }

  addUnitSession() {
    let isDuplicateSession = false
    let moduleCtrl = this.vtFieldIndustryVisitConductedForm.get('ModuleId');
    let unitCtrl = this.vtFieldIndustryVisitConductedForm.get('UnitId');
    let sessionIdsCtrl = this.vtFieldIndustryVisitConductedForm.get('SessionIds');

    if (moduleCtrl.value != '' && unitCtrl.value != '' && sessionIdsCtrl.value != '') {

      if (this.seestionModel !== undefined) {
       
        sessionIdsCtrl.value.map(item => {
          if((this.seestionModel).includes(item.Id)){
            isDuplicateSession = true
            this.dialogService.openShowDialog(`${item.Name}  is already added`);
          }
        })
      }

      if(!isDuplicateSession){
      this.unitSessionsModels.push(
        new ModuleUnitSessionModel({
          ModuleId: moduleCtrl.value.Id, ModuleName: moduleCtrl.value.Name,
          UnitId: unitCtrl.value.Id, UnitName: unitCtrl.value.Name,
          SessionIds: sessionIdsCtrl.value.map(x => {
            this.seestionModel.push(x.Id)
            return x.Id
          }), SessionNames: sessionIdsCtrl.value.map(x => x.Name).join(", ")
        }));
      }

      moduleCtrl.setValue('');
      unitCtrl.setValue('');
      sessionIdsCtrl.setValue('');

      this.unitList = <DropdownModel[]>[];
      this.sessionList = <DropdownModel[]>[];
      isDuplicateSession = false
    }
  }

  removeUnitSession(sessionItem) {
    const sessionIndex = this.unitSessionsModels.indexOf(sessionItem);
    this.unitSessionsModels.splice(sessionIndex, 1);
  }

  uploadedFieldVisitPhotoFile(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.vtFieldIndustryVisitConductedForm.get('FVPictureFile').setValue(null);
        this.dialogService.openShowDialog("Please upload image file only.");
        return;
      }

      this.getUploadedFileData(event, this.Constants.DocumentType.FieldVisit).then((response: FileUploadModel) => {
        this.fieldVisitPhotoFile = response;

        this.vtFieldIndustryVisitConductedForm.get('IsFVPictureFile').setValue(false);
        this.setMandatoryFieldControl(this.vtFieldIndustryVisitConductedForm, 'FVPictureFile', this.Constants.DefaultImageState);
      });
    }
  }

  saveOrUpdateVTFieldIndustryVisitConductedDetails1() {
   
    if (this.unitSessionsModels.length == 0) {
      this.dialogService.openShowDialog("Please add course module taught first!");
      return;
    }
   
    this.FinalSection.map(result => {
      console.log(result);
      let ClassStudent = []
      this.vtFieldIndustryVisitConductedForm.get('StudentAttendances').value.map(student => {
        console.log(student);
        if ((student.ClassId == result[0]) && (student.SectionId == result[1])) {
          console.log(student);
          ClassStudent.push({
            ClassId: student.ClassId,
            IsPresent: student.IsPresent,
            StudentId: student.StudentId,
            StudentName: student.StudentName
          })
        }
      })
      
      this.vtFieldIndustryVisitConductedModel = this.vtFieldIndustryVisitConductedService.getFieldIndustryVisitModelFromFormGroup(this.vtFieldIndustryVisitConductedForm, result[0], result[1], ClassStudent);
      ClassStudent = []
      this.vtFieldIndustryVisitConductedModel.VTId = this.UserModel.UserTypeId;
      this.vtFieldIndustryVisitConductedModel.UnitSessionsModels = this.unitSessionsModels;
      this.vtFieldIndustryVisitConductedModel.FVPictureFile = (this.fieldVisitPhotoFile.Base64Data != '' ? this.setUploadedFile(this.fieldVisitPhotoFile) : null);
      this.vtFieldIndustryVisitConductedModel.UploadPrincipleConsentForm = (this.ConcentPhotoFile.Base64Data != '' ? this.setUploadedFile(this.ConcentPhotoFile) : null);
      if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
        this.vtFieldIndustryVisitConductedModel.RequestType = 4;
        this.vtFieldIndustryVisitConductedModel.VTFieldIndustryVisitConductedId = this.vtfeildId;
      }
      this.vtFieldIndustryVisitConductedService.createOrUpdateVTFieldIndustryVisitConducted(this.vtFieldIndustryVisitConductedModel)
        .subscribe((vtFieldIndustryVisitConductedResp: any) => {
          if (vtFieldIndustryVisitConductedResp.Success) {
            this.zone.run(() => {
              this.showActionMessage(
                this.Constants.Messages.RecordSavedMessage,
                this.Constants.Html.SuccessSnackbar
              );
              this.router.navigate([RouteConstants.VTFieldIndustryVisitConducted.List]);
            });
          }
          else {
            var errorMessages = this.getHtmlMessage(vtFieldIndustryVisitConductedResp.Errors)
            this.dialogService.openShowDialog(errorMessages);
          }
        }, error => {
          console.log('VTFieldIndustryVisitConducted deletion errors =>', error);
        });
    })
  }
  
  saveOrUpdateVTFieldIndustryVisitConductedDetails() {

    if (this.unitSessionsModels.length == 0) {
      this.dialogService.openShowDialog("Please add course module taught first!");
      return;
    }



    this.vtFieldIndustryVisitConductedModel = this.vtFieldIndustryVisitConductedService.getFieldIndustryVisitModelFromFormGroup(this.vtFieldIndustryVisitConductedForm);
    this.vtFieldIndustryVisitConductedModel.VTId = this.UserModel.UserTypeId;
    this.vtFieldIndustryVisitConductedModel.UnitSessionsModels = this.unitSessionsModels;
    this.vtFieldIndustryVisitConductedModel.FVPictureFile = (this.fieldVisitPhotoFile.Base64Data != '' ? this.setUploadedFile(this.fieldVisitPhotoFile) : null);
    this.vtFieldIndustryVisitConductedModel.UploadPrincipleConsentForm = (this.ConcentPhotoFile.Base64Data != '' ? this.setUploadedFile(this.ConcentPhotoFile) : null);
    if (this.PageRights.ActionType == this.Constants.Actions.Edit) {
      this.vtFieldIndustryVisitConductedModel.RequestType = 4;
      this.vtFieldIndustryVisitConductedModel.VTFieldIndustryVisitConductedId = this.vtfeildId;

    }
    this.vtFieldIndustryVisitConductedService.createOrUpdateVTFieldIndustryVisitConducted(this.vtFieldIndustryVisitConductedModel)
      .subscribe((vtFieldIndustryVisitConductedResp: any) => {

        if (vtFieldIndustryVisitConductedResp.Success) {
          this.zone.run(() => {
            this.showActionMessage(
              this.Constants.Messages.RecordSavedMessage,
              this.Constants.Html.SuccessSnackbar
            );

            this.router.navigate([RouteConstants.VTFieldIndustryVisitConducted.List]);
          });
        }
        else {
          var errorMessages = this.getHtmlMessage(vtFieldIndustryVisitConductedResp.Errors)
          this.dialogService.openShowDialog(errorMessages);
        }
      }, error => {
        console.log('VTFieldIndustryVisitConducted deletion errors =>', error);
      });
  }


  onViewVtfieldVisit() {
    // let vtFieldvist = this.vtFieldIndustryVisitConductedModel
    this.vtFieldIndustryVisitConductedForm.get('ClassTaughtId').setValue(this.vtFieldvist.ClassTaughtId)
    this.vtFieldIndustryVisitConductedForm.get('ReportingDate').setValue(new Date(this.vtFieldvist.ReportingDate))
    this.vtFieldIndustryVisitConductedForm.get('SectionIds').setValue(this.vtFieldvist.SectionTaughtId)
    this.vtFieldIndustryVisitConductedForm.get('FieldVisitTheme').setValue(this.vtFieldvist.FieldVisitTheme)
    this.vtFieldIndustryVisitConductedForm.get('FieldVisitActivities').setValue(this.vtFieldvist.FieldVisitActivities)
    this.vtFieldIndustryVisitConductedForm.get('ModuleId').setValue(this.vtFieldvist.UnitSessionsModels.ModuleId)
    this.vtFieldIndustryVisitConductedForm.get('UnitId').setValue(this.vtFieldvist.UnitSessionsModels.UnitId)
    this.vtFieldIndustryVisitConductedForm.get('SessionIds').setValue(this.vtFieldvist.UnitSessionsModels.SessionIds)
    this.vtFieldIndustryVisitConductedForm.get('FVOrganisation').setValue(this.vtFieldvist.FVOrganisation)
    this.vtFieldIndustryVisitConductedForm.get('FVOrganisationAddress').setValue(this.vtFieldvist.FVOrganisationAddress)
    this.vtFieldIndustryVisitConductedForm.get('FVDistance').setValue(this.vtFieldvist.FVDistance)
    this.vtFieldIndustryVisitConductedForm.get('FVPictureFile').setValue(this.vtFieldvist.FVPictureFile)
    this.vtFieldIndustryVisitConductedForm.get('FVContactPersonName').setValue(this.vtFieldvist.FVContactPersonName)
    this.vtFieldIndustryVisitConductedForm.get('FVContactPersonMobile').setValue(this.vtFieldvist.FVContactPersonMobile)
    this.vtFieldIndustryVisitConductedForm.get('FVContactPersonDesignation').setValue(this.vtFieldvist.FVContactPersonDesignation)
    // this.vtFieldIndustryVisitConductedForm.get('StudentAttendances').setValue(this.vtFieldvist.StudentAttendances)
    this.vtFieldIndustryVisitConductedForm.get('IsVenueVisited').setValue(this.vtFieldvist.IsVenueVisited === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsPlaceIntroduced').setValue(this.vtFieldvist.IsPlaceIntroduced === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsStudentDivided').setValue(this.vtFieldvist.IsStudentDivided === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('DetailsOfPlanning').setValue(this.vtFieldvist.DetailsOfPlanning)
    this.vtFieldIndustryVisitConductedForm.get('IsReception').setValue(this.vtFieldvist.IsReception === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsFireSafety').setValue(this.vtFieldvist.IsFireSafety === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsAccidentFireSafety').setValue(this.vtFieldvist.IsAccidentFireSafety === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsBasicSafety').setValue(this.vtFieldvist.IsBasicSafety === true ? "Yes" : "No")
    this.onChangeSafetyDetailsStatus(this.vtFieldvist.IsBasicSafety === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsStudentSecurity').setValue(this.vtFieldvist.IsStudentSecurity)
    this.vtFieldIndustryVisitConductedForm.get('IsTransport').setValue(this.vtFieldvist.IsTransport === true ? "Yes" : "No")
    this.onChangeisTransportStatus(this.vtFieldvist.IsTransport === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('ModeOfTransport').setValue(this.vtFieldvist.ModeOfTransport)
    this.vtFieldIndustryVisitConductedForm.get('NoTransportReason').setValue(this.vtFieldvist.NoTransportReason)
    this.vtFieldIndustryVisitConductedForm.get('TransportConfirmed').setValue(this.vtFieldvist.TransportConfirmed)
    this.vtFieldIndustryVisitConductedForm.get('TeacherName').setValue(this.vtFieldvist.TeacherName)
    this.vtFieldIndustryVisitConductedForm.get('ParentContact').setValue(this.vtFieldvist.ParentContact)
    this.vtFieldIndustryVisitConductedForm.get('IsHMConsentSigned').setValue(this.vtFieldvist.IsHMConsentSigned === true ? "Yes" : "No")
    this.onChangePermissionDetailsStatus(this.vtFieldvist.IsHMConsentSigned === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsTeacherAssignedbyHM').setValue(this.vtFieldvist.IsTeacherAssignedbyHM === true ? "Yes" : "No")
    this.onChangeisTeacherStatus(this.vtFieldvist.IsTeacherAssignedbyHM === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsParentAwareOfVisit').setValue(this.vtFieldvist.IsParentAwareOfVisit === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsParentVolunteer').setValue(this.vtFieldvist.IsParentVolunteer === true ? "Yes" : "No")
    this.onChangeParentwillingStatus(this.vtFieldvist.IsParentVolunteer === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('ParentName').setValue(this.vtFieldvist.ParentName)
    this.vtFieldIndustryVisitConductedForm.get('TeacherContact').setValue(this.vtFieldvist.TeacherContact)
    this.vtFieldIndustryVisitConductedForm.get('PermissionDetail').setValue(this.vtFieldvist.PermissionDetail)
    this.vtFieldIndustryVisitConductedForm.get('IsBRBehaviour').setValue(this.vtFieldvist.IsBRBehaviour === true ? "Yes" : "No")
    this.onChangeBPDetailsStatus(this.vtFieldvist.IsBRBehaviour === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsBREmergencies').setValue(this.vtFieldvist.IsBREmergencies === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsBRAttention').setValue(this.vtFieldvist.IsBRAttention === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsBRTransportation').setValue(this.vtFieldvist.IsBRTransportation === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsKidViolationBR').setValue(this.vtFieldvist.IsKidViolationBR === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('BPDiscussion').setValue(this.vtFieldvist.BPDiscussion)
    this.vtFieldIndustryVisitConductedForm.get('IsFoodsArranged').setValue(this.vtFieldvist.IsFoodsArranged === true ? "Yes" : "No")
    this.onChangeMaterialDetailsStatus(this.vtFieldvist.IsFoodsArranged === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsStudentIdentity').setValue(this.vtFieldvist.IsStudentIdentity === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsFirstAidKit').setValue(this.vtFieldvist.IsFirstAidKit === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('MaterialsPreparation').setValue(this.vtFieldvist.MaterialsPreparation)
    this.vtFieldIndustryVisitConductedForm.get('IsParentConsent').setValue(this.vtFieldvist.IsParentConsent === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('NoOfStudentConsent').setValue(this.vtFieldvist.NoOfStudentConsent)
    this.vtFieldIndustryVisitConductedForm.get('IsConsentReceived').setValue(this.vtFieldvist.IsConsentReceived === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('IsStatus').setValue(this.vtFieldvist.IsStatus === true ? "Yes" : "No")
    this.onChangeIsStatus(this.vtFieldvist.IsStatus === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('FVOrganisationInterestStatus').setValue(this.vtFieldvist.FVOrganisationInterestStatus === true ? "Yes" : "No")
    this.onChangeFVIntrestStatus(this.vtFieldvist.FVOrganisationInterestStatus === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('FVOrignisationOJTStatus').setValue(this.vtFieldvist.FVOrignisationOJTStatus === true ? "Yes" : "No")
    this.onChangeFVOJStatus(this.vtFieldvist.FVOrignisationOJTStatus === true ? "Yes" : "No")
    this.vtFieldIndustryVisitConductedForm.get('FVContactPersonEmail').setValue(this.vtFieldvist.FVContactPersonEmail)

  }

  //Create vtFieldIndustryVisitConducted form and returns {FormGroup}
  createVTFieldIndustryVisitConductedForm(): FormGroup {
    return this.formBuilder.group({
      FVOrganisation: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVOrganisation, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(50)),
      FVOrganisationAddress: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVOrganisationAddress, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(50)),
      VTFieldIndustryVisitConductedId: new FormControl(this.vtFieldIndustryVisitConductedModel.VTFieldIndustryVisitConductedId),
      ClassTaughtId: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.ClassTaughtId, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ReportingDate: new FormControl({ value: new Date(this.vtFieldIndustryVisitConductedModel.ReportingDate), disabled: this.PageRights.IsReadOnly }, Validators.required),
      SectionIds: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.SectionIds, disabled: this.PageRights.IsReadOnly }, Validators.required),
      FieldVisitTheme: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FieldVisitTheme, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(150)),
      FieldVisitActivities: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FieldVisitActivities, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(200)),
      ModuleId: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.ModuleId, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50)]),
      UnitId: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.UnitId, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50)]),
      SessionIds: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.SessionIds, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50)]),
      FVDistance: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVDistance, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(50)),
      FVPictureFile: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVPictureFile, disabled: this.PageRights.IsReadOnly }, Validators.required),
      FVContactPersonName: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVContactPersonName, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50), Validators.pattern(this.Constants.Regex.CharsWithTitleCase)]),
      FVContactPersonMobile: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVContactPersonMobile, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(10), Validators.minLength(10), Validators.pattern(this.Constants.Regex.MobileNumber)]),
      FVContactPersonEmail: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVContactPersonEmail, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50), Validators.pattern(this.Constants.Regex.Email)]),
      FVContactPersonDesignation: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVContactPersonDesignation, disabled: this.PageRights.IsReadOnly }, [Validators.maxLength(50), Validators.pattern(this.Constants.Regex.CharsWithTitleCase)]),
      FVOrganisationInterestStatus: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVOrganisationInterestStatus, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(50)),
      FVOrignisationOJTStatus: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVOrignisationOJTStatus, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(50)),
      FeedbackFromOrgnisation: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FeedbackFromOrgnisation, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
      Remarks: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.Remarks, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(350)),
      FVStudentSafety: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.FVStudentSafety, disabled: this.PageRights.IsReadOnly }, Validators.maxLength(50)),
      IsFVPictureFile: new FormControl({ value: false, disabled: this.PageRights.IsReadOnly }),
      StudentAttendances: this.formBuilder.array(this.vtFieldIndustryVisitConductedModel.StudentAttendances.map(studentModel => this.setStudentAttendanceFormGroup(studentModel))),


      IsVenueVisited: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsVenueVisited, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsPlaceIntroduced: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsPlaceIntroduced, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsStudentDivided: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsStudentDivided, disabled: this.PageRights.IsReadOnly }, Validators.required),
      DetailsOfPlanning: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.DetailsOfPlanning, disabled: this.PageRights.IsReadOnly },Validators.required),
      IsReception: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsReception, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsFireSafety: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsFireSafety, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsAccidentFireSafety: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsAccidentFireSafety, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsBasicSafety: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsBasicSafety, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsStudentSecurity: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsStudentSecurity, disabled: this.PageRights.IsReadOnly }),
      IsTransport: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsTransport, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ModeOfTransport: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.ModeOfTransport, disabled: this.PageRights.IsReadOnly }, Validators.required),
      TransportCost: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.TransportCost, disabled: this.PageRights.IsReadOnly }),
      NoTransportReason: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.NoTransportReason, disabled: this.PageRights.IsReadOnly }),
      TransportConfirmed: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.TransportConfirmed, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsHMConsentSigned: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsHMConsentSigned, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsTeacherAssignedbyHM: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsTeacherAssignedbyHM, disabled: this.PageRights.IsReadOnly }, Validators.required),
      TeacherName: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.TeacherName, disabled: this.PageRights.IsReadOnly }),
      TeacherContact: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.TeacherContact, disabled: this.PageRights.IsReadOnly }),
      IsParentAwareOfVisit: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsParentAwareOfVisit, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsParentVolunteer: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsParentVolunteer, disabled: this.PageRights.IsReadOnly }, Validators.required),
      ParentName: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.ParentName, disabled: this.PageRights.IsReadOnly }),
      ParentContact: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.ParentContact, disabled: this.PageRights.IsReadOnly }),
      PermissionDetail: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.PermissionDetail, disabled: this.PageRights.IsReadOnly }),
      IsBRBehaviour: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsBRBehaviour, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsBREmergencies: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsBREmergencies, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsBRAttention: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsBRAttention, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsBRTransportation: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsBRTransportation, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsKidViolationBR: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsKidViolationBR, disabled: this.PageRights.IsReadOnly }, Validators.required),
      BPDiscussion: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.BPDiscussion, disabled: this.PageRights.IsReadOnly }),
      IsFoodsArranged: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsFoodsArranged, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsStudentIdentity: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsStudentIdentity, disabled: this.PageRights.IsReadOnly }),
      IsFirstAidKit: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsFirstAidKit, disabled: this.PageRights.IsReadOnly }, Validators.required),
      MaterialsPreparation: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.MaterialsPreparation, disabled: this.PageRights.IsReadOnly }),
      IsParentConsent: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsParentConsent, disabled: this.PageRights.IsReadOnly }, Validators.required),
      NoOfStudentConsent: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.NoOfStudentConsent, disabled: this.PageRights.IsReadOnly }, Validators.required),
      IsConsentReceived: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsConsentReceived, disabled: this.PageRights.IsReadOnly }, Validators.required),
      UploadPrincipleConsentForm: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.UploadPrincipleConsentForm, disabled: this.PageRights.IsReadOnly }),
      IsStatus: new FormControl({ value: this.vtFieldIndustryVisitConductedModel.IsStatus, disabled: this.PageRights.IsReadOnly }, Validators.required),

    });
  }

  UploadPrincipleConsentForm(event) {
    if (event.target.files.length > 0) {
      var fileExtn = event.target.files[0].name.split('.').pop().toLowerCase();

      if (this.AllowedImageExtensions.indexOf(fileExtn) == -1) {
        this.vtFieldIndustryVisitConductedForm.get('UploadPrincipleConsentForm').setValue(null);
        this.dialogService.openShowDialog("Please upload PDF file only.");
        return;
      }

      this.getUploadedFileData(event, this.Constants.DocumentType.FieldVisit).then((response: FileUploadModel) => {
        this.ConcentPhotoFile = response;

        // this.vtFieldIndustryVisitConductedForm.get('IsFVPictureFile').setValue(false);
        // this.setMandatoryFieldControl(this.vtFieldIndustryVisitConductedForm, 'FVPictureFile', this.Constants.DefaultImageState);
      });
    }
  }


  onChangeDetailsOfPlanStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isDetailsOfPlan = true;
    else {
      this.isDetailsOfPlan = false;
      this.vtFieldIndustryVisitConductedForm.get('DetailsOfPlanning').setValue('')
    }
  }

  onChangeFVIntrestStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isfvIntrest = true;
    else {
      this.isfvIntrest = false;
    }
  }

  onChangeFVOJStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isfvOjIntrest = true;
    else {
      this.isfvOjIntrest = false;
    }
  }

  onChangeSafetyDetailsStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isSafetyDetails = true;
    else {
      this.isSafetyDetails = false;
      this.vtFieldIndustryVisitConductedForm.get('IsStudentSecurity').setValue('')
    }
  }

  onChangeIsStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isComplete = true;
    else {
      this.isComplete = false;
      // this.vtFieldIndustryVisitConductedForm.get('IsStudentSecurity').setValue('')
    }
  }

  onChangeTranssportDetailsStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isTransportDetails = true;
    else {
      this.isTransportDetails = false;
      this.vtFieldIndustryVisitConductedForm.get('DetailsOfPlanning').setValue('')
    }
  }
  onChangePermissionDetailsStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isPermissionDetails = true;
    else {
      this.isPermissionDetails = false;
      this.vtFieldIndustryVisitConductedForm.get('PermissionDetail').setValue('')
    }
  }
  onChangeBPDetailsStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isBPtDetails = true;
    else {
      this.isBPtDetails = false;
      this.vtFieldIndustryVisitConductedForm.get('BPDiscussion').setValue('')
    }
  }
  onChangeMaterialDetailsStatus(chk) {
    if (chk.value == 'Yes' || chk == 'Yes')
      this.isMaterialDetails = true;
    else {
      this.isMaterialDetails = false;
      this.vtFieldIndustryVisitConductedForm.get('MaterialsPreparation').setValue('')
    }
  }

  onChangeisTransportStatus(chk) {

    if (chk.value == 'Yes' || chk == 'Yes')
      this.isTransportDetails = true;
    else {
      this.isTransportDetails = false;
      this.vtFieldIndustryVisitConductedForm.get('TransportConfirmed').setValue('')
    }
  }

  onChangeisTeacherStatus(chk) {

    if (chk.value == 'Yes' || chk == 'Yes')
      this.isTeacherAssigned = true;
    else {
      this.isTeacherAssigned = false;
      this.vtFieldIndustryVisitConductedForm.get('TeacherName').setValue('')
      this.vtFieldIndustryVisitConductedForm.get('TeacherContact').setValue('')
    }
  }

  onChangeParentwillingStatus(chk) {

    if (chk.value == 'Yes' || chk == 'Yes')
      this.isParentVoulntry = true;
    else {
      this.isParentVoulntry = false;
      this.vtFieldIndustryVisitConductedForm.get('ParentName').setValue('')
      this.vtFieldIndustryVisitConductedForm.get('ParentContact').setValue('')
    }
  }

  private setStudentAttendanceFormGroup(studentAttendanceItem) {
    return this.formBuilder.group({
      StudentId: this.formBuilder.control({ value: studentAttendanceItem.StudentId, disabled: true }),
      ClassId: this.formBuilder.control({ value: studentAttendanceItem.ClassId, disabled: true }),
      StudentName: this.formBuilder.control({ value: studentAttendanceItem.StudentName, disabled: true }),
      IsPresent: this.formBuilder.control({ value: studentAttendanceItem.IsPresent, disabled: true })
    });
  }
}
