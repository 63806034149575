import { FuseUtils } from '@fuse/utils';

export class EventsModel{
    UserId: string;
    EventDetails: string;
    CaseStudy: string;
    Other: string;
    EventFileUpload: any;
    EventDetailId: any;
    RoleId: any;
    EventDate: Date;
    IsActive: boolean;
    RequestType: number;
    VCId: string

    constructor(eventItem?: any) {
        eventItem = eventItem || {};
        this.UserId = eventItem.UserId || '';
        this.EventDetails = eventItem.EventDetails || '';
        this.Other = eventItem.Other || '';
        this.CaseStudy = eventItem.CaseStudy || '';
        this.EventFileUpload = eventItem.EventFileUpload || '';
        this.EventDetailId = eventItem.EventDetailId ||  FuseUtils.NewGuid();;
        this.RoleId = eventItem.RoleId || FuseUtils.NewGuid();;
        this.EventDate = eventItem.EventDate || '';
        this.IsActive = eventItem.IsActive || true;
        this.RequestType = eventItem.RequestType || 0;
        this.VCId = eventItem.VCId || ''


    }

}        
