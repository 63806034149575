export class VTHomeVisitModel {
    DateOfVisit: Date;
    PurposeOfVisit: string;
    ClassTaughtId: string;
    ClassSectionIds: string;
    StudentId: string;
    AcademicYearId: string;
    FatherName: string;
    MotherName: string;
    FatherEducation: string;
    MotherEducation: string;
    Village: string;
    LandMark: string;
    ContactNum: number;
    MotherAge: number;
    FatherAge: number;
    StudentMSFC: string;
    MainSouce: string;
    AdditionalSource: string;
    Generation: string;
    SibilingsCount: number;
    SilbilingType: string;
    IsParentAware: any;
    IsMSChildSpoketoParent: any;
    IsMSpracticalworkdone: any;
    ChildSharedDetails: string;
    IsParentWilling: any;
    KindOfEducation: string;
    StudentDropOut: string;
    DropoutReason: string;
    IsSchoolingAwareness: any;
    DetailsKnown: string;
    Reaction: string;
    IsChronicallyIll: any;
    IllCount: number;
    IsParentCommunicate: any;
    CommunicationDetails: string;
    ImportantDetails: string;
    ImpInHV2: string;
    ImpInHV3: string;
    Remarks: string;
    Hygienerate: string;
    HomeVisitPictureFile: any;

    constructor(vtDailyReportingItem?: any) {
        vtDailyReportingItem = vtDailyReportingItem || {};

        this.DateOfVisit = vtDailyReportingItem.DateOfVisit || '';
        this.PurposeOfVisit = vtDailyReportingItem.PurposeOfVisit || '';
        this.ClassTaughtId = vtDailyReportingItem.ClassTaughtId || '';
        this.ClassSectionIds = vtDailyReportingItem.ClassSectionIds || '';
        this.StudentId = vtDailyReportingItem.StudentId || '';
        this.AcademicYearId = vtDailyReportingItem.AcademicYearId || '';
        this.FatherName = vtDailyReportingItem.FatherName || '';
        this.MotherName = vtDailyReportingItem.MotherName || '';
        this.FatherEducation = vtDailyReportingItem.FatherEducation || '';
        this.MotherEducation = vtDailyReportingItem.MotherEducation || '';
        this.Village = vtDailyReportingItem.Village || '';
        this.LandMark = vtDailyReportingItem.LandMark || '';
        this.ContactNum = vtDailyReportingItem.ContactNum || '';
        this.FatherAge = vtDailyReportingItem.FatherAge || '';
        this.MotherAge = vtDailyReportingItem.MotherAge || '';
        this.StudentMSFC = vtDailyReportingItem.StudentMSFC || '';
        this.MainSouce = vtDailyReportingItem.MainSouce || '';
        this.AdditionalSource = vtDailyReportingItem.AdditionalSource || '';
        this.Generation = vtDailyReportingItem.Generation || '';
        this.SibilingsCount = vtDailyReportingItem.SibilingsCount || '';
        this.SilbilingType= vtDailyReportingItem.SilbilingType|| '';
        this.IsParentAware = vtDailyReportingItem.IsParentAware || '';
        this.IsMSChildSpoketoParent = vtDailyReportingItem.IsMSChildSpoketoParent || '';
        this.IsMSpracticalworkdone = vtDailyReportingItem.IsMSpracticalworkdone || '';
        this.ChildSharedDetails = vtDailyReportingItem.ChildSharedDetails || '';
        this.IsParentWilling = vtDailyReportingItem.IsParentWilling || '';
        this.KindOfEducation = vtDailyReportingItem.KindOfEducation || '';
        this.StudentDropOut = vtDailyReportingItem.StudentDropOut || '';
        this.DropoutReason = vtDailyReportingItem.DropoutReason || '';
        this.IsSchoolingAwareness = vtDailyReportingItem.IsSchoolingAwareness || '';
        this.DetailsKnown = vtDailyReportingItem.DetailsKnown || '';
        this.Reaction = vtDailyReportingItem.Reaction || '';
        this.IsChronicallyIll = vtDailyReportingItem.IsChronicallyIll || '';
        this.IllCount = vtDailyReportingItem.IllCount || 0;
        this.IsParentCommunicate = vtDailyReportingItem.IsParentCommunicate || '';
        this.CommunicationDetails = vtDailyReportingItem.CommunicationDetails || '';
        this.ImportantDetails = vtDailyReportingItem.ImportantDetails || '';
        this.Remarks = vtDailyReportingItem.Remarks || '';
        this.Hygienerate = vtDailyReportingItem.Hygienerate || '';
        this.HomeVisitPictureFile = vtDailyReportingItem.HomeVisitPictureFile || '';
        
       
}
}
