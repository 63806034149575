<div id="vc-daily-reporting" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/vc-daily-reporting'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2" *ngIf="PageRights.PageType ==='Edit'">
                        {{ vcDailyReportingModel.Name }}
                    </div>
                    <div class="h2" *ngIf="PageRights.PageType ==='New'">
                        New VC Daily Reporting
                    </div>
                    <div class="subtitle secondary-text">
                        <span>VC Daily Reporting Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->

            <button mat-raised-button class="add-vc-daily-reporting-button" *ngIf="PageRights.ActionType ==='new'"
                (click)="saveOrUpdateVCDailyReportingDetails()">
                <span>ADD</span>
            </button>
            <!-- [disabled]="vcDailyReportingForm.invalid" -->
            <button mat-raised-button class="save-vc-daily-reporting-button" *ngIf="PageRights.ActionType ==='edit'"
                (click)="saveOrUpdateVCDailyReportingDetails()">
                <span>SAVE</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">

                <form name="vcDailyReportingForm" [formGroup]="vcDailyReportingForm"
                    class="vc-daily-reporting w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fxFlex fusePerfectScrollbar>
                    <!-- <mat-tab label="Basic Info"> -->

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Reporting Date</mat-label>
                            <input matInput [matDatepicker]="picker" name="reportDate" required [min]="minReportingDate"
                                [max]="CurrentDate" formControlName="ReportDate" [disabled]="PageRights.IsReadOnly"
                                (dateChange)="onChangeReportingDate()">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="vcDailyReportingForm.controls.ReportDate.invalid">
                                {{getErrorMessage(vcDailyReportingForm, 'ReportDate')}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Report Type</mat-label>
                            <mat-select name="reportType" formControlName="ReportType" required appearance="outline"
                                [disabled]="PageRights.IsReadOnly" (selectionChange)="onChangeReportType($event.value)">
                                <mat-option [value]="reportTypeItem.Id" *ngFor="let reportTypeItem of reportTypeList">
                                    {{ reportTypeItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vcDailyReportingForm.controls.ReportType.invalid">
                                {{getErrorMessage(vcDailyReportingForm, 'ReportType')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="vc-daily-type work-type">
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="vcDailyReportingForm.controls.ReportType.value == '49'">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Working Day Type</mat-label>
                                <mat-select name="workingDayTypeIds" formControlName="WorkingDayTypeIds" multiple
                                    [required]="vcDailyReportingForm.controls.ReportType.value == '49'"
                                    appearance="outline" [disabled]="PageRights.IsReadOnly"
                                    (selectionChange)="onChangeWorkingType($event.value)">
                                    <mat-option [value]="workTypeItem.Name"  id="" *ngFor="let workTypeItem of workTypeList">
                                        {{ workTypeItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="vcDailyReportingForm.controls.WorkingDayTypeIds.invalid">
                                    {{getErrorMessage(vcDailyReportingForm, 'WorkingDayTypeIds')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Industry Exposure Visit -->
                    <fieldset formGroupName="industryExposureVisitGroup" *ngIf="isAllowIndustryExposureVisit">
                        <legend>Industry Exposure Visit</legend>
                        <div class="vc-daily-type industry-exposure-visit" *ngIf="isAllowIndustryExposureVisit">

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="70" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Type of Industry Linkage</mat-label>
                                    <mat-select name="typeOfIndustryLinkage" formControlName="TypeOfIndustryLinkage"
                                        required appearance="outline" [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="industryLinkageItem.Id"
                                            *ngFor="let industryLinkageItem of industryLinkageList">
                                            {{ industryLinkageItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.industryExposureVisitGroup.controls.TypeOfIndustryLinkage.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.industryExposureVisitGroup,
                                        'TypeOfIndustryLinkage')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Contact Person Name</mat-label>
                                    <input matInput name="contactPersonName" formControlName="ContactPersonName"
                                        required>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.industryExposureVisitGroup.controls.ContactPersonName.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.industryExposureVisitGroup,
                                        'ContactPersonName')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Contact Person Mobile</mat-label>
                                    <input matInput name="contactPersonMobile" formControlName="ContactPersonMobile"
                                        digitOnly maxlength="10" minlength="10" required>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.industryExposureVisitGroup.controls.ContactPersonMobile.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.industryExposureVisitGroup,
                                        'ContactPersonMobile')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Contact Person Email</mat-label>
                                    <input matInput name="contactPersonEmail" formControlName="ContactPersonEmail"
                                        required>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.industryExposureVisitGroup.controls.ContactPersonEmail.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.industryExposureVisitGroup,
                                        'ContactPersonEmail')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                        </div>
                    </fieldset>

                    <!-- School -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="isAllowSchool">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>School</mat-label>
                            <mat-select name="schoolId" formControlName="SchoolId" [required]="isAllowSchool"
                                appearance="outline" [disabled]="PageRights.IsReadOnly">
                                <mat-option [value]="schoolItem.Id" *ngFor="let schoolItem of schoolList">
                                    {{ schoolItem.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vcDailyReportingForm.controls.SchoolId.invalid">
                                {{getErrorMessage(vcDailyReportingForm, 'SchoolId')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Pratical -->
                    <fieldset formGroupName="praticalGroup" *ngIf="isAllowPractical">
                        <legend>Practical </legend>
                        <div class="vc-daily-type pratical" *ngIf="isAllowPractical">

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-label>Are you trying to submit Practical Assessment details for your schools or
                                    for other VCs?</mat-label>

                                <mat-radio-group fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    appearance="outline" class="radio-space-tc" name="ispratical"
                                    formControlName="IsPratical"
                                    [disabled]="PageRights.IsReadOnly || PageRights.ActionType ==='edit'"
                                    [required]="isAllowPractical" (change)="onPratical($event.value)"
                                    [ngClass]="{'mat-form-field-invalid': isValidRequiredFields(vcDailyReportingForm.controls.praticalGroup.controls.IsPratical)}">
                                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                                    <mat-radio-button value="No">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>School</mat-label>
                                    <mat-select name="schoolId" formControlName="SchoolId" [required]="isAllowPractical"
                                        appearance="outline" (selectionChange)="onChangeSchool($event.value)"
                                        [disabled]="PageRights.IsReadOnly || PageRights.ActionType ==='edit'"
                                        #SelectPASchool>

                                        <mat-select-filter class="select-filter" *ngIf="SelectPASchool.focused"
                                            [array]="paSchoolList"
                                            (filteredReturn)="this.filteredPASchoolItems = $event"
                                            [displayMember]="'Name'">
                                        </mat-select-filter>
                                        <mat-option [value]="schoolItem.Id"
                                            *ngFor="let schoolItem of filteredPASchoolItems">
                                            {{ schoolItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.SchoolId.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup, 'SchoolId')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Vocataional Trainer</mat-label>
                                    <mat-select name="vTId" formControlName="VTId" [required]="isAllowPractical"
                                        (selectionChange)="onChangeVT($event.value)" appearance="outline"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="vtItem.Id" *ngFor="let vtItem of vtList">
                                            {{ vtItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.VTId.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup, 'VTId')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Class</mat-label>
                                    <mat-select name="classId" formControlName="ClassId" [required]="isAllowPractical"
                                        appearance="outline" [disabled]="PageRights.IsReadOnly"
                                        (selectionChange)="onChangeVTClass($event.value)">
                                        <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                            {{ classItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.ClassId.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup, 'ClassId')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="60" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Sector</mat-label>
                                    <mat-select name="sectorId" formControlName="SectorId" [required]="isAllowPractical"
                                        appearance="outline" [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="sectorItem.Description"
                                            *ngFor="let sectorItem of sectorList">
                                            {{ sectorItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.SectorId.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup, 'SectorId')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="40" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Job Role</mat-label>
                                    <mat-select name="jobRoleId" formControlName="JobRoleId"
                                        [required]="isAllowPractical" appearance="outline"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="jobItem.Id" *ngFor="let jobItem of jobRoleList">
                                            {{ jobItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.JobRoleId.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup, 'JobRoleId')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Enrolled Student Count</mat-label>

                                    <input matInput name="studentCount" formControlName="StudentCount" maxlength="4"
                                        digitOnly [required]="isAllowPractical"
                                        matTooltip="Enter enrolled student count against trainer"
                                        matTooltipPosition="above">

                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.StudentCount.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup, 'StudentCount')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Present Student Count</mat-label>

                                    <input matInput name="presentStudentCount" formControlName="PresentStudentCount"
                                        maxlength="4" digitOnly [required]="isAllowPractical"
                                        matTooltip="Enter present student count for Practical Assessmnet"
                                        matTooltipPosition="above">

                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.PresentStudentCount.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup,
                                        'PresentStudentCount')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field fxFlex="40" fxFlex.lt-md="75" fxFlex.lt-sm="100" appearance="outline">
                                    <mat-label>VT's Presence</mat-label>

                                    <mat-select name="vTPresent" formControlName="VTPresent" appearance="outline"
                                        [required]="isAllowPractical" [disabled]="PageRights.IsReadOnly">
                                        <mat-option value="Present">Present</mat-option>
                                        <mat-option value="Absent">Absent</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.VTPresent.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup, 'VTPresent')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="60" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Assessor's Name</mat-label>

                                    <input matInput name="assesorName" formControlName="AssesorName"
                                        [required]="isAllowPractical"
                                        matTooltip="Enter assesor's name. Ensure first letter should be capital of each word."
                                        matTooltipPosition="above">

                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.praticalGroup.controls.AssesorName.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.praticalGroup, 'AssesorName')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="40" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Assessor's Mobile Number</mat-label>
                                    <input matInput name="assesorMobileNo" formControlName="AssesorMobileNo" digitOnly
                                        minlength="10" maxlength="10" placeholder="Ex. 9800098000">
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                    <mat-label>Remarks/Issues</mat-label>
                                    <input matInput name="remarks" formControlName="Remarks">
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>

                    <!-- Work details -->
                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px" *ngIf="isAllowWorkDetails">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Work Details</mat-label>
                            <input matInput name="workTypeDetails" formControlName="WorkTypeDetails">
                            <mat-error *ngIf="vcDailyReportingForm.controls.WorkTypeDetails.invalid">
                                {{getErrorMessage(vcDailyReportingForm, 'WorkTypeDetails')}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!-- Holiday -->
                    <fieldset formGroupName="holidayGroup" *ngIf="isAllowHoliday">
                        <legend>Holiday</legend>
                        <div class="vc-daily-type holiday"
                            *ngIf="vcDailyReportingForm.controls.ReportType.value == '48'">
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Holiday Type</mat-label>
                                    <mat-select name="holidayTypeId" formControlName="HolidayTypeId"
                                        [required]="isAllowHoliday" appearance="outline"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="holidayTypeItem.Id"
                                            *ngFor="let holidayTypeItem of holidayTypeList">
                                            {{ holidayTypeItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.holidayGroup.controls.HolidayTypeId.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.holidayGroup, 'HolidayTypeId')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    *ngIf="vcDailyReportingForm.controls.holidayGroup.controls.HolidayTypeId.value == '166' || vcDailyReportingForm.controls.holidayGroup.controls.HolidayTypeId.value == '167'">
                                    <mat-label>Holiday Details</mat-label>
                                    <input matInput name="holidayDetails" formControlName="HolidayDetails"
                                        [required]="vcDailyReportingForm.controls.holidayGroup.controls.HolidayTypeId.value == '166' || vcDailyReportingForm.controls.holidayGroup.controls.HolidayTypeId.value == '167'">
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.holidayGroup.controls.HolidayDetails.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.holidayGroup,
                                        'HolidayDetails')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>

                    <!--Community work  -->
                    <fieldset formGroupName="communityWorkGroup" *ngIf="isAllowCommunityWork">
                        <legend>Community work</legend>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Vocational Trainer</mat-label>

                                <mat-select formControlName="UserId" required appearance="outline"
                                    (selectionChange)="getClassDetails($event.value)"
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-select-filter class="select-filter" [array]="vtList"
                                        (filteredReturn)="this.filteredVTItems = $event" [displayMember]="'VTName'">
                                    </mat-select-filter>
                                    <mat-option [value]="vtItem.VTId" *ngFor="let vtItem of filteredVTItems">
                                        {{ vtItem.VTName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.UserId.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup, 'UserId')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Class </mat-label>

                                <mat-select formControlName="ClassTaughtId" appearance="fill" required
                                    (selectionChange)="onChangeClass($event.value)">
                                    <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                        {{ classItem.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.ClassTaughtId.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup,
                                    'ClassTaughtId')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>No of work days/hours?</mat-label>
                                <input matInput name="NoOfDays" formControlName="NoOfDays" digitOnly maxlength="2"
                                    placeholder="No of work days/hours?" required>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.NoOfDays.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup, 'NoOfDays')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>No of students involved in work?</mat-label>
                                <input matInput name="NoOfStudents" formControlName="NoOfStudents" digitOnly
                                    maxlength="3" placeholder="No of students involved in work?" required>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.NoOfStudents.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup,
                                    'NoOfStudents')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Details of work done. </mat-label>
                                <textarea matInput name="WorkDetails" formControlName="WorkDetails" cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="25"
                                    required></textarea>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.WorkDetails.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup, 'WorkDetails')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Client Name</mat-label>
                                <input matInput name="clientName" formControlName="ClientName" placeholder="Client Name"
                                    required>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.ClientName.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup, 'ClientName')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Client Address</mat-label>
                                <textarea matInput name="clientAddress" formControlName="ClientAddress"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="25" required></textarea>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.ClientAddress.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup,
                                    'ClientAddress')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Total Expenses (In Rupees) incurred in work?</mat-label>
                                <input matInput name="TotalExpense" formControlName="TotalExpense" digitOnly
                                    maxlength="5" placeholder="Total Expenses (In Rupees) incurred in work?" required>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.TotalExpense.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup,
                                    'TotalExpense')}}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Total Profit (In Rupees) (Direct/Notional)</mat-label>
                                <input matInput name="totalProfit" formControlName="TotalProfit" digitOnly maxlength="5"
                                    placeholder="Total Profit (In Rupees) (Direct/Notional)" required>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.TotalProfit.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup, 'TotalProfit')}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Student Experience</mat-label>
                                <textarea matInput name="StudentsExperience" formControlName="StudentsExperience"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="25" required></textarea>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.StudentsExperience.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup,
                                    'StudentsExperience')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                            fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                            <mat-label fxFlex="70" class="radio-label-tc">Was Raw Material funded by school or purchased
                                by students/VT?
                            </mat-label>
                            <mat-radio-group
                                aria-labelledby="Was Raw Material funded by school or purchased by students/VT?"
                                name="IsRawMaterial" formControlName="IsRawMaterial" required appearance="outline"
                                fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                class="radio-space-tc" [disabled]="PageRights.IsReadOnly"
                                (change)="onChangeIsMRawMaterialStatus($event)">
                                <mat-radio-button value="0">Yes</mat-radio-button>
                                <mat-radio-button value="1">No</mat-radio-button>

                            </mat-radio-group>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isRawMaterialBill">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Bill of Materials</mat-label>
                                <ngx-mat-file-input name="MaterialBillFile" formControlName="MaterialBillFile"
                                    [accept]="AllowedImageExtensions" (change)="uploadedBillsPhotoFile($event)"
                                    [disabled]="PageRights.IsReadOnly"></ngx-mat-file-input>
                                <mat-icon matSuffix>folder</mat-icon>

                            </mat-form-field>
                            <!-- <div class="default-image-container" fxFlex="70">
                            <mat-checkbox matInput formControlName="IsAssignmentPhotoFile"
                                [disabled]="PageRights.IsReadOnly"
                                (change)="setMandatoryFieldControl(vtDailyReportingForm.controls.assignmentFromVocationalDepartmentGroup, 'AssignmentPhotoFile', $event)">
                            </mat-checkbox>
                            <span class="checkbox-space">{{ Constants.Messages.DefaultImageText }}</span>
                        </div> -->
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Description</mat-label>
                                <textarea matInput name="description" formControlName="Description" cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="25"
                                    required></textarea>
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.communityWorkGroup.controls.Description.invalid">
                                    {{getErrorMessage(vcDailyReportingForm.controls.communityWorkGroup, 'Description')}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </fieldset>
                    <!--Community work  -->

                    <!--HM meeting  -->
                    <fieldset formGroupName="hmMeetingGroup" *ngIf="isAllowHMmeet">
                        <legend>HM Meeting</legend>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>How many member Present on meeting</mat-label>
                                <input matInput name="membersCount" formControlName="MembersCount"
                                    [required]="isAllowHMmeet " digitOnly maxlength="2"
                                    placeholder="How many member Present on meeting">
                                <mat-error
                                    *ngIf="vcDailyReportingForm.controls.hmMeetingGroup.controls.MembersCount.errors?.mismatch">
                                    Entered Number should match the selected members
                                </mat-error>
                            </mat-form-field>


                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Members Involved</mat-label>
                                <mat-select name="members" formControlName="Members" [required]="isAllowHMmeet "
                                    [disabled]="PageRights.IsReadOnly" appearance="outline" multiple>
                                    <mat-select-filter class="select-filter" [array]="MemberList"
                                        (filteredReturn)="this.filteredMemberList = $event" [displayMember]="'Name'">
                                    </mat-select-filter>
                                    <mat-option [value]="member.Id" *ngFor="let member of filteredMemberList"
                                        (onSelectionChange)="OnSelectMembers($event)">
                                        {{ member.Name }}
                                    </mat-option>
                                </mat-select>


                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>HM Involved in the meeting</mat-label>
                                <mat-select name="members" formControlName="Members" [required]="isAllowHMmeet "
                                    [disabled]="PageRights.IsReadOnly" appearance="outline" multiple>
                                    <mat-option [value]="HM.Id" *ngFor="let HM of HMList"
                                        (onSelectionChange)="OnSelectMembers($event)">
                                        {{ HM.Name }}
                                    </mat-option>
                                </mat-select> -->

                            <!-- </mat-form-field> -->
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px" *ngIf="isOtherSelected">
                            <mat-form-field appearance="outline" fxFlex="75" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Other Member</mat-label>
                                <input matInput name="OtherMember" formControlName="OtherMember"
                                    placeholder="Enter the names with comma(,) separated">
                            </mat-form-field>
                            <div class="add-course-taught">
                                <span class="material-icons add-session"
                                    (click)="onEnterOtherMembers()">add_circle_outline</span>
                                <span class="add-course-text">Kindly press "+" to add information</span>
                            </div>
<!-- 
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" *ngIf="isAddHMMember==true">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <input matInput name="otherMember" formControlName="OtherMember"
                                        [required]="isAddHMMember" placeholder="Enter other member involved in meeting">
                                </mat-form-field>

                            </div> -->
                        </div>
                        
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                            <mat-list fxFlex="70" fxFlex.lt-md="75" fxFlex.lt-sm="100" class="mat-list-class">
                                <mat-list-item *ngFor="let member of otherMemberList let memberIndex=index;">
                                    <div style="float: left; width: 99%;">
                                        <span style="width:5%"> {{memberIndex + 1}} </span>
                                        <span style="width:20%; font-weight: bold;"> {{member}}- </span>
                                        
                                    </div>
                                    <div style="float: right; width: 10%;" *ngIf="PageRights.IsReadOnly==false">
                                        <span class="material-icons remove-session"
                                            (click)="removeOtherMembers(member)">remove_circle_outline</span>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>


                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Details of meetings</mat-label>
                                <textarea matInput name="meetingDetails" formControlName="MeetingDetails"
                                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="25"></textarea>

                            </mat-form-field>
                        </div>
                    </fieldset>
                    <!--HM meeting -->

                    <!-- Home visit -->
                    <fieldset formGroupName="homeVisitGroup" *ngIf="isAllowHomeVisit">
                        <legend>Home Visit</legend>
                        <fieldset>
                            <legend>Visit Date & Purpose</legend>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Visit date</mat-label>
                                    <input matInput [matDatepicker]="reportingDate" name="dateOfVisit"
                                        [min]="minReportingDate" [max]="CurrentDate" formControlName="DateOfVisit"
                                        required (dateChange)="onChangeReportingDate()"
                                        [disabled]="PageRights.IsReadOnly">
                                    <mat-datepicker-toggle matSuffix [for]="reportingDate"></mat-datepicker-toggle>
                                    <mat-datepicker #reportingDate></mat-datepicker>

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Purpose of visit</mat-label>
                                    <textarea matInput name="purposeOfVisit" formControlName="PurposeOfVisit"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                        cdkAutosizeMaxRows="5" required></textarea>

                                </mat-form-field>
                            </div>

                        </fieldset>
                        <br>
                        <fieldset>
                            <legend>Student Details</legend>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">

                                    <mat-label>Vocational Trainer</mat-label>

                                    <mat-select formControlName="UserId" required appearance="outline"
                                        (selectionChange)="getClassDetails($event.value)"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-select-filter class="select-filter" [array]="vtList"
                                            (filteredReturn)="this.filteredVTItems = $event" [displayMember]="'VTName'">
                                        </mat-select-filter>
                                        <mat-option [value]="vtItem.VTId" *ngFor="let vtItem of filteredVTItems">
                                            {{ vtItem.VTName }}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    class="filter-disabled">
                                    <mat-label> Academic Year</mat-label>

                                    <mat-select formControlName="AcademicYearId" name="academicYearId" required
                                        appearance="fill">
                                        <mat-option [value]="academicYearItem.Id"
                                            *ngFor="let academicYearItem of academicYearList">
                                            {{academicYearItem.Name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Class </mat-label>

                                    <mat-select formControlName="ClassTaughtId" appearance="fill" required
                                        (selectionChange)="onChangeClass($event.value)">
                                        <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                            {{ classItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Section Taught</mat-label>
                                    <mat-select name="classSectionIds" formControlName="ClassSectionIds" required
                                        (selectionChange)="onChangeSectionForTaught($event.value)" appearance="outline"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="sectionItem.Id" *ngFor="let sectionItem of sectionList">
                                            {{ sectionItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Student Name </mat-label>

                                    <mat-select [formControlName]="StudentId" appearance="fill" required
                                        (selectionChange)="onChangeOfStudents($event.value)">
                                        <mat-select-filter class="select-filter" [array]="studentList"
                                            (filteredReturn)="this.filteredStudentList = $event"
                                            [displayMember]="'StudentName'"></mat-select-filter>
                                        <mat-option [value]="student.StudentId"
                                            *ngFor="let student of filteredStudentList">
                                            {{ student.StudentName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Father Name</mat-label>
                                    <input matInput name="fatherName" formControlName="FatherName"
                                        placeholder="Father Name">

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Mother Name</mat-label>
                                    <input matInput name="motherName" formControlName="MotherName"
                                        placeholder="Mother Name">

                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Contact Number</mat-label>
                                    <input matInput name="contactNum" formControlName="ContactNum" digitOnly required
                                        maxlength="10" placeholder="Contact Number">

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Village</mat-label>
                                    <input matInput name="village" formControlName="Village" placeholder="Village">

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Landmark</mat-label>
                                    <input matInput name="landMark" formControlName="LandMark" placeholder="Landmark">

                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Student performance in StudentMSFC</mat-label>
                                    <textarea matInput name="studentMSFC" formControlName="StudentMSFC"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25"></textarea>

                                </mat-form-field>
                            </div>

                        </fieldset>
                        <fieldset>
                            <legend>Brief Report</legend>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Mother Age</mat-label>
                                    <input matInput name="motherAge" formControlName="MotherAge" digitOnly maxlength="3"
                                        placeholder="Mother Age" required>

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Mother Education </mat-label>

                                    <mat-select formControlName="FatherEducation" appearance="fill" required>
                                        <mat-option [value]="Item.Id" *ngFor="let Item of educationList">
                                            {{ Item.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Father Age</mat-label>
                                    <input matInput name="fatherAge" formControlName="FatherAge" digitOnly maxlength="3"
                                        placeholder="Father Age" required>

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Father Education </mat-label>

                                    <mat-select formControlName="MotherEducation" appearance="fill" required>
                                        <mat-option [value]="Item.Id" *ngFor="let Item of educationList">
                                            {{ Item.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Main souce of Livelihood</mat-label>
                                    <input matInput name="mainSouce" formControlName="MainSouce"
                                        placeholder="Main souce of Livelihood">

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Additional source of Livelihood</mat-label>
                                    <input matInput name="AdditionalSource" formControlName="AdditionalSource"
                                        placeholder="Additional source of Livelihood">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Student Generation learner</mat-label>

                                    <mat-select formControlName="Generation" appearance="fill" required>
                                        <mat-option [value]="Item.Id" *ngFor="let Item of generationList">
                                            {{ Item.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>How many sibling does the child have?</mat-label>
                                    <input matInput name="SibilingsCount" formControlName="SibilingsCount" digitOnly
                                        maxlength="1" placeholder="How many sibling does the child have?">

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Is s/he the eldest,youngest,other </mat-label>

                                    <mat-select formControlName="SilbilingType" appearance="fill" required>
                                        <mat-option [value]="age.Id" *ngFor="let age of ageList">
                                            {{ age.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Are the parents/ relatives aware of what
                                    all their child learns at school?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Are the parents/ relatives aware of what all their child learns at school?"
                                    name="IsParentAware" formControlName="IsParentAware" required appearance="outline"
                                    fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Has the child spoken to them about Multi
                                    Skill Foundation Course?</mat-label>
                                <mat-radio-group
                                    aria-labelledby="Has the child spoken to them about Multi Skill Foundation Course?"
                                    name="IsMSChildSpoketoParent" formControlName="IsMSChildSpoketoParent" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Taking a cue from Multi Skill, has the
                                    child done some practical work at home?</mat-label>
                                <mat-radio-group (change)="onChangeIsMSpracticalworkdoneStatus($event)"
                                    aria-labelledby="Taking a cue from Multi Skill, has the child done some practical work at home?"
                                    name="IsMSpracticalworkdone" formControlName="IsMSpracticalworkdone" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" *ngIf="isIsMSpracticalworkdoneStatus">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Share what child has shared with parents and how s/he has used Multi
                                        Skill at home?</mat-label>
                                    <textarea matInput name="ChildSharedDetails" formControlName="ChildSharedDetails"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                        cdkAutosizeMaxRows="10"></textarea>

                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Are the parents keen to provide further
                                    education to the child?</mat-label>
                                <mat-radio-group (change)="onChangeParentInterestStatus($event)"
                                    aria-labelledby="Are the parents keen to provide further education to the child?"
                                    name="IsParentWilling" formControlName="IsParentWilling" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" *ngIf="isParentIntrestedEdu">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>What kind of education?</mat-label>
                                    <textarea matInput name="KindOfEducation" formControlName="KindOfEducation"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                        cdkAutosizeMaxRows="10"></textarea>

                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">After providing them with basic
                                    information regarding open schooling /distance education pattern, did you observe
                                    that they were keen to know more?</mat-label>
                                <mat-radio-group (change)="onChangeKeentoMoreStatus($event)"
                                    aria-labelledby="After providing them with basic information regarding open schooling /distance education pattern, did you observe that they were keen to know more?"
                                    name="isSchoolingAwareness" formControlName="IsSchoolingAwareness" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" *ngIf="isKeentoknow">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Explain their reaction in your own language.</mat-label>
                                    <textarea matInput name="reaction" formControlName="Reaction" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                        cdkAutosizeMaxRows="10"></textarea>

                                </mat-form-field>

                            </div>

                        </fieldset>

                        <fieldset>
                            <legend>Observation</legend>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Are there any chronically ill people
                                </mat-label>
                                <mat-radio-group (change)="onChangeChronicalStatus($event)"
                                    aria-labelledby="Are there any chronically ill people" name="IsChronicallyIll"
                                    formControlName="IsChronicallyIll" required appearance="outline" fxFlex="30"
                                    fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" *ngIf="isChronical">


                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>How many</mat-label>
                                    <input matInput name="illCount" [formControlName]="IllCount" digitsOnly
                                        maxlength="1" placeholder="How Many">

                                </mat-form-field>


                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>How was the basic hygiene of the place (overall)</mat-label>

                                    <mat-select formControlName="Hygienerate" appearance="fill" required>
                                        <mat-option [value]="hygein.Id" *ngFor="let hygein of hygeinList">
                                            {{ hygein.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Was there anything that the parents
                                    specifically communicated to you?</mat-label>
                                <mat-radio-group (change)="onChangeCommunicationStatus($event)"
                                    aria-labelledby="Was there anything that the parents specifically communicated to you?"
                                    name="PIsParentCommunicate" formControlName="IsParentCommunicate" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" *ngIf="isParentCommunicatd">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Mention in brief.</mat-label>
                                    <textarea matInput name="CommunicationDetails"
                                        formControlName="CommunicationDetails" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                        cdkAutosizeMaxRows="10"></textarea>

                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>List down in short, any three important things that caught your attention
                                        during the home visit.</mat-label>
                                    <textarea matInput name="ImportantDetails" formControlName="ImportantDetails"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
                                        cdkAutosizeMaxRows="10"></textarea>

                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Remarks</mat-label>
                                    <input matInput name="Remarks" [formControlName]="Remarks"
                                        placeholder="Home visit point1">

                                </mat-form-field>
                            </div>

                        </fieldset>

                    </fieldset>
                    <!-- Home visit -->

                    <!--Class Observation  -->
                    <fieldset formGroupName="classObservationGroup" *ngIf="isAllowClassObservation">
                        <legend>Class Observation</legend>
                        <fieldset>
                            <legend>Basic</legend>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>School </mat-label>

                                    <mat-select formControlName="SchoolId" appearance="outline" required #SelectSchool>
                                        <!-- (selectionChange)="onChangeSchool($event.value)"> -->

                                        <mat-select-filter class="select-filter" [array]="schoolList"
                                            (filteredReturn)="this.filteredSchoolItems = $event"
                                            [displayMember]="'Name'"></mat-select-filter>
                                        <mat-option [value]="schoolItem.Id"
                                            *ngFor="let schoolItem of filteredSchoolItems">
                                            {{ schoolItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Vocational Trainer </mat-label>

                                    <mat-select formControlName="UserId" appearance="outline" required
                                        (selectionChange)="getClassDetails($event.value)">

                                        <mat-select-filter class="select-filter" [array]="vtList"></mat-select-filter>
                                        <mat-option [value]="vtItem.VTId" *ngFor="let vtItem of vtList">
                                            {{ vtItem.VTName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Class </mat-label>

                                    <mat-select formControlName="ClassTaughtId" appearance="fill" required
                                        (selectionChange)="onChangeClass($event.value)">
                                        <mat-option [value]="classItem.Id" *ngFor="let classItem of classList">
                                            {{ classItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Modules Taught</mat-label>
                                    <mat-select name="ModuleId" formControlName="ModuleId" appearance="outline"
                                        [disabled]="PageRights.IsReadOnly"
                                        (selectionChange)="onChangeCourseModule($event.value)">
                                        <mat-option [value]="moduleItem.Id" *ngFor="let moduleItem of moduleTaughtList">
                                            {{ moduleItem.Name }}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>

                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" *ngIf="PageRights.IsReadOnly==false">


                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Subject</mat-label>
                                    <mat-select name="subjectId" formControlName="SubjectId" appearance="outline"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="unitItem.Id" *ngFor="let unitItem of unitList">
                                            {{ unitItem.Name }}
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>

                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Present student</mat-label>
                                    <input matInput name="presentStudent" formControlName="PresentStudent"
                                        [required]="isAllowClassObservation " digitOnly maxlength="3"
                                        placeholder="Present student">

                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Total number of student</mat-label>
                                    <input matInput name="totalStudent" formControlName="TotalStudent"
                                        [required]="isAllowClassObservation " digitOnly maxlength="3"
                                        placeholder="Total number of student">

                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Observation date</mat-label>
                                    <!-- <input matInput name="ObservationDate" formControlName="ObservationDate"
                                        [required]="isAllowClassObservation " digitOnly maxlength="3"
                                        placeholder="Observation date"> -->

                                    <input matInput [matDatepicker]="reportingDate" name="observationDate"
                                        [min]="minReportingDate" [max]="CurrentDate" formControlName="ObservationDate"
                                        required (dateChange)="onChangeReportingDate()"
                                        [disabled]="PageRights.IsReadOnly" placeholder="Observation date">
                                    <mat-datepicker-toggle matSuffix [for]="reportingDate"></mat-datepicker-toggle>
                                    <mat-datepicker #reportingDate></mat-datepicker>


                                </mat-form-field>
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Observation duration</mat-label>
                                    <input matInput name="ObservationDuration" formControlName="ObservationDuration"
                                    [required]="isAllowClassObservation" pattern="[0-9]{2}:[0-9]{2}"
                                    (input)="formatInput($event)" placeholder="00:00">

                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-label>What should the students shall learn by end of the practical?</mat-label>
                                <mat-form-field appearance="outline" fxFlex="60" fxFlex.lt-md="100" fxFlex.lt-sm="100">
                                    <input matInput name="StudentsLearned" formControlName="StudentsLearned"
                                        placeholder="What should the students shall learn by end of the practical?">

                                </mat-form-field>

                                <div class="add-course-taught">
                                    <span class="material-icons add-session"
                                        (click)="addRecord()">add_circle_outline</span>
                                    <span class="add-course-text">Kindly press "+" to add information</span>
                                </div>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px" class="mat-list-class" *ngIf="isStudentLearn==true">
                                <mat-list fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-list-item
                                        *ngFor="let sessionItem of EodStudentLearning let sessionIndex=index;">
                                        <div style="float: left; width: 90%;">
                                            <span style="width:5%"> {{sessionIndex + 1}}) </span>
                                            <span style="width:20%; font-weight: bold;">
                                                {{sessionItem}}
                                            </span>

                                        </div>
                                        <div style="float: right; width: 10%;" *ngIf="PageRights.IsReadOnly==false">
                                            <span class="material-icons remove-session"
                                                (click)="removeRecord(sessionItem)">
                                                remove_circle_outline
                                            </span>
                                        </div>
                                    </mat-list-item>
                                </mat-list>
                            </div>


                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Is lession plan present?
                                </mat-label>
                                <mat-radio-group aria-labelledby="Is lession plan present?" name="IsLessionPlanPresent"
                                    formControlName="IsLessionPlanPresent" required appearance="outline" fxFlex="30"
                                    fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>


                        </fieldset>
                        <fieldset>
                            <legend>Before Session</legend>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Classroom ambience is proper (ample light,
                                    air, space and cleanliness)
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Classroom ambience is proper (ample light, air, space and cleanliness)"
                                    name="IsClassroomNeat" formControlName="IsClassroomNeat" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Raw materials and tools are ready to be
                                    used 15 minutes before session?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Raw materials and tools are ready to be used 15 minutes before session?"
                                    name="isBSToolsChecked" formControlName="IsBSToolsChecked" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Children settled down within the first 10
                                    minutes of starting the class?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Children settled down within the first 10 minutes of starting the class?"
                                    name="IsChildrenSet" formControlName="IsChildrenSet" required appearance="outline"
                                    fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>What are your Observations?</mat-label>
                                    <textarea matInput name="ObservationBeforeSession"
                                        formControlName="ObservationBeforeSession" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25"></textarea>

                                </mat-form-field>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>Before Practical</legend>


                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Objective communicated clearly by trainer
                                </mat-label>
                                <mat-radio-group aria-labelledby="Objective communicated clearly by trainer"
                                    name="IisTrainerTaught" formControlName="IsTrainerTaught" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Context, Tool & concepts has been
                                    established
                                </mat-label>
                                <mat-radio-group aria-labelledby="Context, Tool & concepts has been established"
                                    name="isContextTaught" formControlName="IsContextTaught" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Safety instructions & guidelines to use
                                    the tools given.
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Safety instructions & guidelines to use the tools given"
                                    name="IsGuidelinesForTools" formControlName="IsGuidelinesForTools" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Procedure is illustrated using Flowchart/
                                    Diagram/ Any other method? (Please mention if any method use.
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Procedure is illustrated using Flowchart/ Diagram/ Any other method? (Please mention if any method use"
                                    name="IsFlowChart" formControlName="IsFlowChart" required appearance="outline"
                                    fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Safety manual is being used (Provided by
                                    LAHI)
                                </mat-label>
                                <mat-radio-group aria-labelledby="Safety manual is being used (Provided by LAHI)"
                                    name="isSafetyManual" formControlName="IsSafetyManual" required appearance="outline"
                                    fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>What are your Observations?</mat-label>
                                    <textarea matInput name="ObservationsBeforePractical"
                                        formControlName="ObservationsBeforePractical" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25"></textarea>

                                </mat-form-field>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>During Practical</legend>


                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Safety rules are being followed by
                                    students and instructors strictly?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Safety rules are being followed by students and instructors strictly?"
                                    name="IsStudentFollow" formControlName="IsStudentFollow" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Practical is being performed real time by
                                    the instructor with Check for Understanding Questions in between?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Practical is being performed real time by the instructor with Check for Understanding Questions in between?"
                                    name="isInstructorPerformed" formControlName="IsInstructorPerformed" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Practical guidelines are communicated
                                    clearly when groups are performing the practical?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Practical guidelines are communicated clearly when groups are performing the practical?"
                                    name="IsPracticalGuided" formControlName="IsPracticalGuided" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">All students are given equal opportunity
                                    for performing the practical?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="All students are given equal opportunity for performing the practical?"
                                    name="IsStudentTreatedEqual" formControlName="IsStudentTreatedEqual" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Quality check is conducted during the
                                    practical and feedback is given?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Quality check is conducted during the practical and feedback is given?"
                                    name="IsQualityCheck" formControlName="IsQualityCheck" required appearance="outline"
                                    fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Correlation to mainstream subjects is
                                    happening often?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Correlation to mainstream subjects is happening often?"
                                    name="IsCorrelation" formControlName="IsCorrelation" required appearance="outline"
                                    fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Shy and non-participating students are
                                    recognized and pushed to participate in the class?
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Shy and non-participating students are recognized and pushed to participate in the class?"
                                    name="IsStudentShy" formControlName="IsStudentShy" required appearance="outline"
                                    fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>What are your Observations?</mat-label>
                                    <textarea matInput name="ObservationsDuringPractical"
                                        formControlName="ObservationsDuringPractical" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25"></textarea>

                                </mat-form-field>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>After Practical</legend>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Homework is assigned
                                </mat-label>
                                <mat-radio-group aria-labelledby="Homework is assigned" name="corelationOfMainStream"
                                    formControlName="IsHomework" required appearance="outline" fxFlex="30"
                                    fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px" class="radio-space-tc"
                                    [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Tools are cleaned and kept back in their
                                    proper place
                                </mat-label>
                                <mat-radio-group aria-labelledby="Tools are cleaned and kept back in their proper place"
                                    name="isASToolsChecked" formControlName="IsASToolsChecked" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>What are your Observations?</mat-label>
                                    <textarea matInput name="observationsAfterPractical"
                                        formControlName="ObservationsAfterPractical" cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25"></textarea>

                                </mat-form-field>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Overall classroom management</legend>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Classroom environment is respectful (
                                    teachers and students share a good equation)
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Classroom environment is respectful ( teachers and students share a good equation)"
                                    name="IsRespectful" formControlName="IsRespectful" required appearance="outline"
                                    fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100" fxLayoutGap="20px"
                                    class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Students are encouraged to ask questions
                                </mat-label>
                                <mat-radio-group aria-labelledby="Students are encouraged to ask questions"
                                    name="IsStudentQuestioned" formControlName="IsStudentQuestioned" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Inactive or non-participatory students are
                                    identified and encouraged to be a part of the class
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Inactive or non-participatory students are identified and encouraged to be a part of the class"
                                    name="IsStudentParticipated" formControlName="IsStudentParticipated" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row radio-option-height"
                                fxLayoutGap="10px" fxLayoutGap.lt-md="0px">
                                <mat-label fxFlex="70" class="radio-label-tc">Instructor is using simple and effective
                                    language throughout the classroom
                                </mat-label>
                                <mat-radio-group
                                    aria-labelledby="Instructor is using simple and effective language throughout the classroom?"
                                    name="IsTeacherTaughtwell" formControlName="IsTeacherTaughtwell" required
                                    appearance="outline" fxFlex="30" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    fxLayoutGap="20px" class="radio-space-tc" [disabled]="PageRights.IsReadOnly">
                                    <mat-radio-button value="0">Yes</mat-radio-button>
                                    <mat-radio-button value="1">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>What are your Observations?</mat-label>
                                    <textarea matInput name="ObservationsOverall" formControlName="ObservationsOverall"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25"></textarea>

                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Feedback by observer</mat-label>
                                    <textarea matInput name="ObserverFeedback" formControlName="ObserverFeedback"
                                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                        cdkAutosizeMaxRows="25"></textarea>

                                </mat-form-field>
                            </div>
                        </fieldset>
                    </fieldset>
                    <!--Class Observation  -->

                    <!-- Leave -->
                    <fieldset formGroupName="leaveGroup" *ngIf="isAllowLeave">
                        <legend>Leave</legend>
                        <div class="vc-daily-type leave" *ngIf="vcDailyReportingForm.controls.ReportType.value == '47'">
                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Leave Type</mat-label>
                                    <mat-select name="leaveTypeId" formControlName="LeaveTypeId"
                                        [required]="isAllowLeave" appearance="outline"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="leaveTypeItem.Id"
                                            *ngFor="let leaveTypeItem of leaveTypeList">
                                            {{ leaveTypeItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.leaveGroup.controls.LeaveTypeId.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.leaveGroup, 'LeaveTypeId')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Leave Mode</mat-label>
                                    <mat-select name="leaveModeId" formControlName="LeaveModeId"
                                        [required]="isAllowLeave" appearance="outline"
                                        [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="leaveModeItem.Id"
                                            *ngFor="let leaveModeItem of leaveModeList">
                                            {{ leaveModeItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.leaveGroup.controls.LeaveModeId.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.leaveGroup, 'LeaveModeId')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Leave Approval Status</mat-label>
                                    <mat-select name="leaveApprovalStatus" formControlName="LeaveApprovalStatus"
                                        required appearance="outline" [disabled]="PageRights.IsReadOnly">
                                        <mat-option value="yes">Yes</mat-option>
                                        <mat-option value="no">No</mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.leaveGroup.controls.LeaveApprovalStatus.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.leaveGroup,
                                        'LeaveApprovalStatus')}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100"
                                    *ngIf="vcDailyReportingForm.controls.leaveGroup.controls.LeaveApprovalStatus.value == 'yes'">
                                    <mat-label>Leave Approver</mat-label>
                                    <mat-select name="leaveApprover" formControlName="LeaveApprover" required
                                        appearance="outline" [disabled]="PageRights.IsReadOnly">
                                        <mat-option [value]="leaveApproverItem.Id"
                                            *ngFor="let leaveApproverItem of leaveApproverList">
                                            {{ leaveApproverItem.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.leaveGroup.controls.LeaveApprover.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.leaveGroup, 'LeaveApprover')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                                fxLayoutGap.lt-md="0px">
                                <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                    <mat-label>Leave Reason</mat-label>
                                    <input matInput name="leaveReason" formControlName="LeaveReason" required>
                                    <mat-error
                                        *ngIf="vcDailyReportingForm.controls.leaveGroup.controls.LeaveReason.invalid">
                                        {{getErrorMessage(vcDailyReportingForm.controls.leaveGroup, 'LeaveReason')}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>