import { FuseUtils } from '@fuse/utils';
import { VCLeaveModel } from './vc-leave.model';
import { VCHolidayModel } from './vc-holiday.model';
import { VCIndustryExposureVisitModel } from './vc-industry-exposure-visit.model';
import { VCPraticalModel } from './vc-pratical.model';
import { VCCommnityWorkModel } from './vc-communityworkmodel';
import { VCHMMeetingModel } from './vc-hm-meetig.model';
import { VCHomeVisitModel } from './vc-home-visit.model';
import { VCClassObservationModel } from './vc-class-observation.model';

export class VCDailyReportingModel {
    VCDailyReportingId: string;
    VCId: string;
    ReportDate: any;
    ReportType: string;
    WorkingDayTypeIds: any;
    WorkTypeDetails: string;
    SchoolId: string;

    Leave: VCLeaveModel;
    Holiday: VCHolidayModel;
    IndustryExposureVisit: VCIndustryExposureVisitModel;
    Pratical: VCPraticalModel;
    CommunityWork :VCCommnityWorkModel;
    HMMeeting: VCHMMeetingModel;
    HomeVisit: VCHomeVisitModel;
    VCClassObservation: VCClassObservationModel


    GeoLocation: string;
    Latitude: string;
    Longitude: string;
    IsActive: boolean;
    RequestType: any;

    constructor(vcDailyReportingItem?: any) {
        vcDailyReportingItem = vcDailyReportingItem || {};

        this.VCDailyReportingId = vcDailyReportingItem.VCDailyReportingId || FuseUtils.NewGuid();
        this.VCId = vcDailyReportingItem.VCId || FuseUtils.NewGuid();
        this.ReportDate = vcDailyReportingItem.ReportDate || '';
        this.ReportType = vcDailyReportingItem.ReportType || '';
        this.WorkingDayTypeIds = vcDailyReportingItem.WorkingDayTypeIds || '';
        this.SchoolId = vcDailyReportingItem.SchoolId || '';
        this.WorkTypeDetails = vcDailyReportingItem.WorkTypeDetails || '';

        if (vcDailyReportingItem.IndustryExposureVisit != null) {
            this.IndustryExposureVisit = new VCIndustryExposureVisitModel();
            vcDailyReportingItem.IndustryExposureVisit = vcDailyReportingItem.IndustryExposureVisit || new VCIndustryExposureVisitModel();

            this.IndustryExposureVisit.TypeOfIndustryLinkage = vcDailyReportingItem.IndustryExposureVisit.TypeOfIndustryLinkage || '';
            this.IndustryExposureVisit.ContactPersonName = vcDailyReportingItem.IndustryExposureVisit.ContactPersonName || '';
            this.IndustryExposureVisit.ContactPersonMobile = vcDailyReportingItem.IndustryExposureVisit.ContactPersonMobile || '';
            this.IndustryExposureVisit.ContactPersonEmail = vcDailyReportingItem.IndustryExposureVisit.ContactPersonEmail || '';
        }

        if (vcDailyReportingItem.CommunityWork != null) {
            this.CommunityWork = new VCCommnityWorkModel()
            vcDailyReportingItem.CommunityWork = vcDailyReportingItem.CommunityWork || new VCCommnityWorkModel();

            this.CommunityWork.ClassTaughtId = vcDailyReportingItem.CommunityWork.ClassTaughtId || '';
            this.CommunityWork.UserId = vcDailyReportingItem.CommunityWork.UserId || '';
            this.CommunityWork.WorkDetails = vcDailyReportingItem.CommunityWork.WorkDetails || '';
            this.CommunityWork.NoOfDays = vcDailyReportingItem.CommunityWork.NoOfDays || '';
            this.CommunityWork.NoOfStudents = vcDailyReportingItem.CommunityWork.NoOfStudents || '';
            this.CommunityWork.ClientName = vcDailyReportingItem.CommunityWork.ClientName || '';
            this.CommunityWork.ClientAddress = vcDailyReportingItem.CommunityWork.ClientAddress || '';
            this.CommunityWork.TotalExpense = vcDailyReportingItem.CommunityWork.TotalExpense || '';
            this.CommunityWork.IsRawMaterial = vcDailyReportingItem.CommunityWork.IsRawMaterial || '';
            this.CommunityWork.TotalProfit = vcDailyReportingItem.CommunityWork.TotalProfit || '';
            this.CommunityWork.StudentsExperience = vcDailyReportingItem.CommunityWork.StudentsExperience || '';
            this.CommunityWork.Description = vcDailyReportingItem.CommunityWork.Description || '';
            this.CommunityWork.MaterialBillFile = vcDailyReportingItem.CommunityWork.MaterialBillFile || '';



        }

        //Class Observation
        if (vcDailyReportingItem.ClassObservation != null) {
            this.VCClassObservation = new VCClassObservationModel()
            vcDailyReportingItem.VCClassObservation = vcDailyReportingItem.VCClassObservation || new VCClassObservationModel();

            this.VCClassObservation.SchoolId = vcDailyReportingItem.ClassObservation.SchoolId || '';
            this.VCClassObservation.UserId = vcDailyReportingItem.VCClassObservation.UserId || '';
            this.VCClassObservation.ClassTaughtId = vcDailyReportingItem.VCClassObservation.ClassId || '';
            this.VCClassObservation.ModuleId = vcDailyReportingItem.VCClassObservation.ModuleId || '';
            this.VCClassObservation.UnitId = vcDailyReportingItem.VCClassObservation.UnitId || '';
            this.VCClassObservation.SubjectId = vcDailyReportingItem.VCClassObservation.SubjectId || '';
            this.VCClassObservation.PresentStudent = vcDailyReportingItem.VCClassObservation.PresentStudent || '';
            this.VCClassObservation.TotalStudent = vcDailyReportingItem.VCClassObservation.TotalStudent || '';
            this.VCClassObservation.ObservationDate = vcDailyReportingItem.VCClassObservation.ObservationDate || '';
            this.VCClassObservation.ObservationDuration = vcDailyReportingItem.VCClassObservation.ObservationDuration || '';
            this.VCClassObservation.StudentsLearned = vcDailyReportingItem.VCClassObservation.StudentsLearned || '';
            this.VCClassObservation.IsLessionPlanPresent = vcDailyReportingItem.VCClassObservation.IsLessionPlanPresent || '';
            this.VCClassObservation.IsClassroomNeat = vcDailyReportingItem.VCClassObservation.IsClassroomNeat || '';
            this.VCClassObservation.IsBSToolsChecked = vcDailyReportingItem.VCClassObservation.IsBSToolsChecked || '';
            this.VCClassObservation.IsChildrenSet = vcDailyReportingItem.VCClassObservation.IsChildrenSet || '';
            this.VCClassObservation.ObservationBeforeSession = vcDailyReportingItem.VCClassObservation.ObservationBeforeSession || '';
            this.VCClassObservation.IsTrainerTaught = vcDailyReportingItem.VCClassObservation.IsTrainerTaught || '';
            this.VCClassObservation.IsContextTaught = vcDailyReportingItem.VCClassObservation.IsContextTaught || '';
            this.VCClassObservation.IsGuidelinesForTools = vcDailyReportingItem.VCClassObservation.IsGuidelinesForTools || '';
            this.VCClassObservation.IsFlowChart = vcDailyReportingItem.VCClassObservation.IsFlowChart || '';
            this.VCClassObservation.IsSafetyManual = vcDailyReportingItem.VCClassObservation.IsSafetyManual || '';
            this.VCClassObservation.ObservationsBeforePractical = vcDailyReportingItem.VCClassObservation.ObservationsBeforePractical || '';
            this.VCClassObservation.IsStudentFollow = vcDailyReportingItem.VCClassObservation.IsStudentFollow || '';
            this.VCClassObservation.IsInstructorPerformed = vcDailyReportingItem.VCClassObservation.IsInstructorPerformed || '';
            this.VCClassObservation.IsPracticalGuided = vcDailyReportingItem.VCClassObservation.IsPracticalGuided || '';
            this.VCClassObservation.IsStudentTreatedEqual = vcDailyReportingItem.VCClassObservation.IsStudentTreatedEqual || '';
            this.VCClassObservation.IsQualityCheck = vcDailyReportingItem.VCClassObservation.IsQualityCheck || '';
            this.VCClassObservation.IsCorrelation = vcDailyReportingItem.VCClassObservation.IsCorrelation || '';
            this.VCClassObservation.IsStudentShy = vcDailyReportingItem.VCClassObservation.IsStudentShy || '';
            this.VCClassObservation.ObservationsDuringPractical = vcDailyReportingItem.VCClassObservation.ObservationsDuringPractical || '';
            this.VCClassObservation.IsHomework = vcDailyReportingItem.VCClassObservation.IsHomework || '';
            this.VCClassObservation.IsASToolsChecked = vcDailyReportingItem.VCClassObservation.IsASToolsChecked || '';
            this.VCClassObservation.ObservationsAfterPractical = vcDailyReportingItem.VCClassObservation.ObservationsAfterPractical || '';
            this.VCClassObservation.IsRespectful = vcDailyReportingItem.VCClassObservation.IsRespectful || '';
            this.VCClassObservation.IsStudentQuestioned = vcDailyReportingItem.VCClassObservation.IsStudentQuestioned || '';
            this.VCClassObservation.IsStudentParticipated = vcDailyReportingItem.VCClassObservation.IsStudentParticipated || '';
            this.VCClassObservation.IsTeacherTaughtwell = vcDailyReportingItem.VCClassObservation.IsTeacherTaughtwell || '';
            this.VCClassObservation.ObservationsOverall = vcDailyReportingItem.VCClassObservation.ObservationsOverall || '';
            this.VCClassObservation.ObserverFeedback = vcDailyReportingItem.VCClassObservation.ObserverFeedback || '';

        }

        if (vcDailyReportingItem.HomeVisitModel != null) {
            this.HomeVisit = new VCHomeVisitModel()
            vcDailyReportingItem.HomeVisit = vcDailyReportingItem.HomeVisit || new VCHomeVisitModel();

            this.HomeVisit.DateOfVisit = vcDailyReportingItem.HomeVisit.DateOfVisit || '';
            this.HomeVisit.PurposeOfVisit = vcDailyReportingItem.HomeVisit.PurposeOfVisit || '';
            this.HomeVisit.UserId = vcDailyReportingItem.HomeVisit.UserId || '';
            this.HomeVisit.ClassTaughtId = vcDailyReportingItem.HomeVisit.ClassTaughtId || '';
            this.HomeVisit.ClassSectionIds = vcDailyReportingItem.HomeVisit.ClassSectionIds || '';
            this.HomeVisit.StudentId = vcDailyReportingItem.HomeVisit.StudentId || '';
            this.HomeVisit.AcademicYearId = vcDailyReportingItem.HomeVisit.AcademicYearId || '';
            this.HomeVisit.FatherName = vcDailyReportingItem.HomeVisit.FatherName || '';
            this.HomeVisit.MotherName = vcDailyReportingItem.HomeVisit.MotherName || '';
            this.HomeVisit.FatherEducation = vcDailyReportingItem.HomeVisit.FatherEducation || '';
            this.HomeVisit.MotherEducation = vcDailyReportingItem.HomeVisit.MotherEducation || '';
            this.HomeVisit.Village = vcDailyReportingItem.HomeVisit.Village || '';
            this.HomeVisit.LandMark = vcDailyReportingItem.HomeVisit.LandMark || '';
            this.HomeVisit.ContactNum = vcDailyReportingItem.HomeVisit.ContactNum || '';
            this.HomeVisit.FatherAge = vcDailyReportingItem.HomeVisit.FatherAge || '';
            this.HomeVisit.MotherAge = vcDailyReportingItem.HomeVisit.MotherAge || '';
            this.HomeVisit.StudentMSFC = vcDailyReportingItem.HomeVisit.StudentMSFC || '';
            this.HomeVisit.MainSouce = vcDailyReportingItem.HomeVisit.MainSouce || '';
            this.HomeVisit.AdditionalSource = vcDailyReportingItem.HomeVisit.AdditionalSource || '';
            this.HomeVisit.Generation = vcDailyReportingItem.HomeVisit.Generation || '';
            this.HomeVisit.SibilingsCount = vcDailyReportingItem.HomeVisit.SibilingsCount || '';
            this.HomeVisit.SilbilingType = vcDailyReportingItem.HomeVisit.SilbilingType|| '';
            this.HomeVisit.IsParentAware = vcDailyReportingItem.HomeVisit.IsParentAware || '';
            this.HomeVisit.IsMSChildSpoketoParent = vcDailyReportingItem.HomeVisit.IsMSChildSpoketoParent || '';
            this.HomeVisit.IsMSpracticalworkdone = vcDailyReportingItem.HomeVisit.IsMSpracticalworkdone || '';
            this.HomeVisit.ChildSharedDetails = vcDailyReportingItem.HomeVisit.ChildSharedDetails || '';
            this.HomeVisit.IsParentWilling = vcDailyReportingItem.HomeVisit.IsParentWilling || '';
            this.HomeVisit.KindOfEducation = vcDailyReportingItem.HomeVisit.KindOfEducation || '';
            this.HomeVisit.StudentDropOut = vcDailyReportingItem.HomeVisit.StudentDropOut || '';
            this.HomeVisit.DropoutReason = vcDailyReportingItem.HomeVisit.DropoutReason || '';
            this.HomeVisit.IsSchoolingAwareness = vcDailyReportingItem.HomeVisit.IsSchoolingAwareness || '';
            this.HomeVisit.DetailsKnown = vcDailyReportingItem.HomeVisit.DetailsKnown || '';
            this.HomeVisit.Reaction = vcDailyReportingItem.HomeVisit.Reaction || '';
            this.HomeVisit.IsChronicallyIll = vcDailyReportingItem.HomeVisit.IsChronicallyIll || '';
            this.HomeVisit.IllCount = vcDailyReportingItem.HomeVisit.IllCount || '';
            this.HomeVisit.IsParentCommunicate = vcDailyReportingItem.HomeVisit.IsParentCommunicate || '';
            this.HomeVisit.CommunicationDetails = vcDailyReportingItem.HomeVisit.CommunicationDetails || '';
            this.HomeVisit.ImportantDetails = vcDailyReportingItem.HomeVisit.ImportantDetails || '';
            this.HomeVisit.ImpInHV2 = vcDailyReportingItem.HomeVisit.ImpInHV2 || '';
            this.HomeVisit.ImpInHV3 = vcDailyReportingItem.HomeVisit.ImpInHV3 || '';


        }
        //HMMeeting
        if (vcDailyReportingItem.HMMeeting != null) {
            this.HMMeeting = new VCHMMeetingModel()
            vcDailyReportingItem.HMMeeting = vcDailyReportingItem.HMMeeting || new VCHMMeetingModel();

            this.HMMeeting.MembersCount = vcDailyReportingItem.HMMeeting.MembersCount || 0;
            this.HMMeeting.Members = vcDailyReportingItem.HMMeeting.Members || '';
            this.HMMeeting.MeetingDetails = vcDailyReportingItem.HMMeeting.MeetingDetails || '';
            this.HMMeeting.OtherMember = vcDailyReportingItem.HMMeeting.OtherMember || '';

        }

        if (vcDailyReportingItem.Holiday != null) { 
            this.Holiday = new VCHolidayModel();
            vcDailyReportingItem.Holiday = vcDailyReportingItem.Holiday || new VCHolidayModel();

            this.Holiday.HolidayTypeId = vcDailyReportingItem.Holiday.HolidayTypeId || '';
            this.Holiday.HolidayDetails = vcDailyReportingItem.Holiday.HolidayDetails || '';
        }

        if (vcDailyReportingItem.Leave != null) {
            this.Leave = new VCLeaveModel();
            vcDailyReportingItem.Leave = vcDailyReportingItem.Leave || new VCLeaveModel();

            this.Leave.LeaveTypeId = vcDailyReportingItem.Leave.LeaveTypeId || '';
            this.Leave.LeaveApprovalStatus = vcDailyReportingItem.Leave.LeaveApprovalStatus || '';
            this.Leave.LeaveApprover = vcDailyReportingItem.Leave.LeaveApprover || '';
            this.Leave.LeaveReason = vcDailyReportingItem.Leave.LeaveReason || '';
        }

        if (vcDailyReportingItem.Pratical != null) {
            this.Pratical = new VCPraticalModel();
            vcDailyReportingItem.Pratical = vcDailyReportingItem.Pratical || new VCLeaveModel();

            this.Pratical.IsPratical = vcDailyReportingItem.Pratical.isPratical || '';
            this.Pratical.SchoolId = vcDailyReportingItem.Pratical.VCSchoolId || '';
            this.Pratical.VTId = vcDailyReportingItem.Pratical.VTId || '';
            this.Pratical.SectorId = vcDailyReportingItem.Pratical.SectorId || '';
            this.Pratical.JobRoleId = vcDailyReportingItem.Pratical.JobRoleId || '';
            this.Pratical.ClassId = vcDailyReportingItem.Pratical.ClassId || '';
            this.Pratical.StudentCount = vcDailyReportingItem.Pratical.StudentCount || '';
            this.Pratical.VTPresent = vcDailyReportingItem.Pratical.VTPresent || '';
            this.Pratical.PresentStudentCount = vcDailyReportingItem.Pratical.PresentStudentCount || 0;
            this.Pratical.AssesorName = vcDailyReportingItem.Pratical.AssesorName || '';
            this.Pratical.AssesorMobileNo = vcDailyReportingItem.Pratical.AssesorMobileNo || '';
            this.Pratical.Remarks = vcDailyReportingItem.Pratical.Remarks || '';
        }

        this.IsActive = vcDailyReportingItem.IsActive || true;
        this.GeoLocation = '3-3';
        this.Latitude = '3';
        this.Longitude = '3';
        this.RequestType = 0; // New
    }
}
