<div id="vt-daily-reporting" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" [routerLink]="'/report-form'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                    [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">

                    <div class="subtitle secondary-text">
                        <span>Report 001 Details</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <!-- [disabled]="vtDailyReportingForm.invalid" -->
            <button mat-raised-button class="add-vt-daily-reporting-button">
                <span>ADD</span>
            </button>

            <!-- <button mat-raised-button class="save-vt-daily-reporting-button"
                >
                <span>SAVE</span>
            </button> -->
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">
                <form name="report001Form" [formGroup]="report001Form" class="vt-daily-reporting w-100-p p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                    fxFlex="1 0 auto" fusePerfectScrollbar>
                    <fieldset>
                        <legend> Basic </legend>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="70" fxFlex.lt-sm="100">
                                <mat-label>Name of the place to be visited</mat-label>
                                <input matInput name="vPlaceName" formControlName="VPlaceName" (keypress)="alphaNumberOnly($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Name</mat-label>
                                <input matInput name="fName" formControlName="FName" (keypress)="alphaOnly($event)">

                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Contact</mat-label>
                                <input matInput name="fContact" formControlName="FContact" digitOnly minlength="10"
                                maxlength="10">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Facilitator Designation</mat-label>
                                <input matInput name="fDesignation" formControlName="FDesignation" (keypress)="alphaOnly($event)">
                            </mat-form-field>

                            <mat-form-field  appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Date Of Visit</mat-label>
                                <input matInput  [matDatepicker]="dateOfVisit" name="dateOfVisit" formControlName="DateOfVisit" [min]="CurrentDate" required>
                                <mat-datepicker-toggle matSuffix [for]="dateOfVisit"></mat-datepicker-toggle>
                                <mat-datepicker #dateOfVisit></mat-datepicker>

                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Select Academic Year</mat-label>

                                <mat-select name="academicYearId"formControlName="AcademicYearId" required appearance="outline">
                                    <mat-option>

                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field  appearance="outline" fxFlex="34" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Select Class</mat-label>

                                <mat-select  name="classId" formControlName="ClassId" required appearance="outline">
                                    <mat-option>

                                    </mat-option>
                                </mat-select>

                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="33" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Select Section</mat-label>

                                <mat-select name="sectionId" formControlName="SectionId" formControlName="SectionId" required appearance="outline">
                                    <mat-option>

                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Modules Taught</mat-label>
                                <mat-select name="moduleId" formControlName="ModuleId" appearance="outline">
                                    <mat-option>
                                        option 
                                    </mat-option>
                                </mat-select>
                              
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                                <mat-label>Units Taught</mat-label>
                                <mat-select name="unitId" formControlName="UnitId" appearance="outline">
                                    <mat-option>
                                        <option value="option1">option1</option>
                                    </mat-option>
                                </mat-select>
                                
                            </mat-form-field>
                        </div>

                    </fieldset>

                    <br>
                    <fieldset>
                        <legend>Planning</legend>
                        <div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70" class="mx-width">
                                    <b>Have you visited the venue and made sure that the place serves
                                        the purpose of field visit?</b>
                                </div>
                                <div fxFlex="30" class="mx-width">
                                    <mat-radio-group aria-label="Select an option"  name="ssVisitedTheVennu" formControlName="IsVisitedTheVennu">
                                        <mat-radio-button value="Yes">
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                          
                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Have you introduced the place to the students and asked them
                                        to gather more information about it?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option"  name="sIntroducedPlace" formControlName="IsIntroducedPlace">
                                        <mat-radio-button value="Yes" [>
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left" >
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                          
                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Have you divided the students into groups and assigned tasks
                                        to each student?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option"  name="isDividedTheStudents" formControlName="IsDividedTheStudents">
                                        <mat-radio-button value="Yes" [>
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left" >
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                          
                            </div>

                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="80" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Details of what you have done regarding above 3 pointers</mat-label>
                            <textarea matInput name="detailsOfPlanning" formControlName="DetailsOfPlanning" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                cdkAutosizeMaxRows="25"></textarea>

                           
                            </mat-form-field>
                          </div>
                        </div>

                    </fieldset>
                    <br>
                    <fieldset>
                        <legend>Safety & Security</legend>
                        <div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70" class="mx-width">
                                    <b>Does the venue have security guards, a reception/lost and
                                        found desk/help desk?</b>
                                </div>
                                <div fxFlex="30" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="isAnysecurityGurad" formControlName="IsAnySecurityGurad" >
                                        <mat-radio-button value="Yes" [ >
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left" >
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                          
                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Is the venue a fire compliant place? Are you familiar with the
                                        fire safety protocol?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="isfireSafty" formControlName="IsFireSafty">
                                        <mat-radio-button value="Yes" [>
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left" >
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                          
                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Does the venue have things that could cause major accidents
                                        like live wires, loose metals, pointed objects?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="isAccidentSafety" formControlName="IsAccidentFireSafety">
                                        <mat-radio-button value="Yes" [>
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left" >
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                          
                            </div>
                            <div fxLayout="row" class="view-div" style="padding: 10px;">
                                <div fxFlex="70%" class="mx-width">
                                    <b>Does this place meet basic safety requirements?</b>
                                </div>
                                <div fxFlex="30%" class="mx-width">
                                    <mat-radio-group aria-label="Select an option" name="isbasicSafety" formControlName="IsBasicSafety">
                                        <mat-radio-button value="Yes" [>
                                            Yes
                                        </mat-radio-button>
                                        <mat-radio-button value="No" class="mr-left" >
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                          
                            </div>


                            <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Details of what you have done to ensure safety and security of the students</mat-label>
                            <textarea matInput name="studentSecurity" formControlName="StudentSecurity" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                cdkAutosizeMaxRows="25"></textarea>

                           
                            </mat-form-field>
                          </div>
                        </div>
                    </fieldset>

                    <br>

                    <fieldset>
                        <legend>Transport</legend>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you organised transport?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="istransport" formControlName="IsTransport">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="75" fxFlex.lt-sm="100">
                            <mat-label>Mode of transport</mat-label>
                            <mat-select name="transportMode" formControlName="TransportMode" appearance="outline">
                                <mat-option>
                                    transport 1
                                </mat-option>
                            </mat-select>
                          
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                            <mat-label>Cost</mat-label>
                            <input matInput name="transortCost"  formControlName="TransportCost" digitOnly minlength="10" maxlength="10" required>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                    <mat-label>If no Why?</mat-label>
                    <textarea matInput name="transportlReason" formControlName="TransportlReason" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="10"></textarea>

                   
                    </mat-form-field>

                  </div>
                  <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                    fxLayoutGap.lt-md="0px">
                    <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                    <mat-label>Details of what you have done for transport</mat-label>
                    <textarea matInput name="transportDetails" formControlName="TransportDetails" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="10"></textarea>

                   
                    </mat-form-field>
                    
                  </div>
                    </fieldset> 

                    <br>
                    <fieldset>
                        <legend>
                            Permissions
                        </legend>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Is your HM aware of the purpose, safety precautions and
                                    arrangements made and has he/she signed the consent form?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isHMAware" formControlName="IsHMAware">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Has the HM assigned a concerned teacher who will be
                                    accompanying you for the field visit?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isConcernedTeacher" formControlName="IsConcernedTeacher">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="70" fxFlex.lt-sm="100">
                                <mat-label>Teacher Name</mat-label>
                                <input matInput name="tName"  formControlName="TName" placeholder="Visited place" required>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Teacher Contact number</mat-label>
                                <input matInput name="tContact"  formControlName="TContact" digitOnly minlength="10" maxlength="10"
                                    placeholder="Ex. 9800098000" required>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Are the students parents aware of the purpose, safety
                                    precautions and arrangements made and has he/she signed the
                                    consent form?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isParentAware"  formControlName="IsParentAware">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Are there any parents who are willing to volunteer and help
                                    you out with the logistics?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isVoluntererParent"  formControlName="IsVoluntererParent">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                            fxLayoutGap.lt-md="0px">
                            <mat-form-field appearance="outline" fxFlex="50" fxFlex.lt-md="70" fxFlex.lt-sm="100">
                                <mat-label>Parent Name</mat-label>
                                <input matInput name="parentName"  formControlName="ParentName" placeholder="Parent Name" required>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="35" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                                <mat-label>Parent Contact number</mat-label>
                                <input matInput name="pContact"  formControlName="PContact" digitOnly minlength="10" maxlength="10"
                                    placeholder="Ex. 9800098000" required>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Details of what you have done for getting permission an dif you want to mention anything else?</mat-label>
                        <textarea matInput name="detailsOfpermission" formControlName="DetailsOfpermission" cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="10"></textarea>
    
                       
                        </mat-form-field>
                        </div>
                    </fieldset>

                    <br>
                    <fieldset>
                        <legend>Behaviour Protocol</legend>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for transportation?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isbehaviourRules"  formControlName="IsBehaviourRules">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for emergencies?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isbrForEmergency"  formControlName="IsBRForEmergency">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for getting everyone’s attention?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isbrAttention"  formControlName="IsBRAttention">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for where to come when you get
                                    los?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isBrWhereToCome"  formControlName="IsBrWhereToCome">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" class="view-div" style="padding: 10px;">
                            <div fxFlex="70%" class="mx-width">
                                <b>Have you created behaviour rules for consequences if any kid
                                    violates the behaviour protocol?</b>
                            </div>
                            <div fxFlex="30%" class="mx-width">
                                <mat-radio-group aria-label="Select an option" name="isKidViolationBR"  formControlName="IsKidViolationBR">
                                    <mat-radio-button value="Yes" [>
                                        Yes
                                    </mat-radio-button>
                                    <mat-radio-button value="No" class="mr-left" >
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                      
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" class="input-row" fxLayoutGap="10px"
                        fxLayoutGap.lt-md="0px">
                        <mat-form-field appearance="outline" fxFlex="100" fxFlex.lt-md="50" fxFlex.lt-sm="100">
                        <mat-label>Details of what you have discussed with students regarding behaviour protocol?</mat-label>
                        <textarea matInput name="brDetails" formControlName="BRDetails" cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                            cdkAutosizeMaxRows="10"></textarea>
    
                       
                        </mat-form-field>
                        </div>
                    </fieldset>
                </form>
            </div>
            <!-- / CONTENT -->
        </div>
        <!-- / CONTENT CARD -->

        

    </div>
    <!-- / CENTER -->

</div>