import { FuseUtils } from '@fuse/utils';

export class Report001Model {
    VTId: string;
    UserId: string;
    ReportDate: Date;
    VCId: string;

    constructor(reportItem?: any) {
        reportItem = reportItem || {};

        this.VTId = reportItem.VTId || FuseUtils.NewGuid();
        this.UserId = reportItem.UserId || '';
        this.ReportDate = reportItem.ReportDate || '';
        this.VCId = reportItem.VCId || '';
    }

}
