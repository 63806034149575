export class VCHomeVisitModel {
    DateOfVisit: Date;
    PurposeOfVisit: string;
    UserId: string;
    ClassTaughtId: string;
    ClassSectionIds: string;
    StudentId: string;
    AcademicYearId: string;
    FatherName: string;
    MotherName: string;
    FatherEducation: string;
    MotherEducation: string;
    Village: string;
    LandMark: string;
    ContactNum: number;
    MotherAge: number;
    FatherAge: number;
    StudentMSFC: string;
    MainSouce: string;
    AdditionalSource: string;
    Generation: string;
    SibilingsCount: number;
    SilbilingType: string;
    IsParentAware: any;
    IsMSChildSpoketoParent: any;
    IsMSpracticalworkdone: any;
    ChildSharedDetails: string;
    IsParentWilling: any;
    KindOfEducation: string;
    StudentDropOut: string;
    DropoutReason: string;
    IsSchoolingAwareness: any;
    DetailsKnown: string;
    Reaction: string;
    IsChronicallyIll: any;
    IllCount: number;
    IsParentCommunicate: any;
    CommunicationDetails: string;
    ImportantDetails: string;
    ImpInHV2: string;
    ImpInHV3: string;
    Remarks: string;
    Hygienerate: string;

    constructor(hmVisitItem?: any) {
        hmVisitItem = hmVisitItem || {};

        this.DateOfVisit = hmVisitItem.DateOfVisit || '';
        this.PurposeOfVisit = hmVisitItem.PurposeOfVisit || '';
        this.UserId = hmVisitItem.UserId || '';
        this.ClassTaughtId = hmVisitItem.ClassTaughtId || '';
        this.ClassSectionIds = hmVisitItem.ClassSectionIds || '';
        this.StudentId = hmVisitItem.StudentId || '';
        this.AcademicYearId = hmVisitItem.AcademicYearId || '';
        this.FatherName = hmVisitItem.FatherName || '';
        this.MotherName = hmVisitItem.MotherName || '';
        this.FatherEducation = hmVisitItem.FatherEducation || '';
        this.MotherEducation = hmVisitItem.MotherEducation || '';
        this.Village = hmVisitItem.Village || '';
        this.LandMark = hmVisitItem.LandMark || '';
        this.ContactNum = hmVisitItem.ContactNum || 0;
        this.FatherAge = hmVisitItem.FatherAge || '';
        this.MotherAge = hmVisitItem.MotherAge || '';
        this.StudentMSFC = hmVisitItem.StudentMSFC || '';
        this.MainSouce = hmVisitItem.MainSouce || '';
        this.AdditionalSource = hmVisitItem.AdditionalSource || '';
        this.Generation = hmVisitItem.Generation || '';
        this.SibilingsCount = hmVisitItem.SibilingsCount || ''; 
        this.SilbilingType= hmVisitItem.SilbilingType|| '';
        this.IsParentAware = hmVisitItem.IsParentAware || '';
        this.IsMSChildSpoketoParent = hmVisitItem.IsMSChildSpoketoParent || '';
        this.IsMSpracticalworkdone = hmVisitItem.IsMSpracticalworkdone || '';
        this.ChildSharedDetails = hmVisitItem.ChildSharedDetails || '';
        this.IsParentWilling = hmVisitItem.IsParentWilling || '';
        this.KindOfEducation = hmVisitItem.KindOfEducation || '';
        this.StudentDropOut = hmVisitItem.StudentDropOut || '';
        this.DropoutReason = hmVisitItem.DropoutReason || '';
        this.IsSchoolingAwareness = hmVisitItem.IsSchoolingAwareness || '';
        this.DetailsKnown = hmVisitItem.DetailsKnown || '';
        this.Reaction = hmVisitItem.Reaction || '';
        this.IsChronicallyIll = hmVisitItem.IsChronicallyIll || '';
        this.IllCount = hmVisitItem.IllCount || 0;
        this.IsParentCommunicate = hmVisitItem.IsParentCommunicate || '';
        this.CommunicationDetails = hmVisitItem.CommunicationDetails || '';
        this.ImportantDetails = hmVisitItem.ImportantDetails || '';
        this.ImpInHV2 = hmVisitItem.ImpInHV2 || '';
        this.ImpInHV3 = hmVisitItem.ImpInHV3 || '';
        this.Remarks = hmVisitItem.Remarks || '';
        this.Hygienerate = hmVisitItem.Hygienerate || '';
        
       
}
}
