import { HMMeetInvolvedModel } from 'app/models/Hmmeet.model';


export class VTHMMeetingModel {
    MembersCount: number;
    Members: HMMeetInvolvedModel[];
    MeetingDetails: string;
    OtherMember: string;
   
    constructor(vtDailyReportingItem?: any) {
        vtDailyReportingItem = vtDailyReportingItem || {};

        this.MembersCount = vtDailyReportingItem.MembersCount || '';
        this.Members = vtDailyReportingItem.Members ||  <HMMeetInvolvedModel[]>[];
        this.MeetingDetails = vtDailyReportingItem.MeetingDetails || '';
        this.OtherMember = vtDailyReportingItem.OtherMember || '';
       
}
}
