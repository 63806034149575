import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { BaseService } from 'app/services/base.service';

import { CommonService } from 'app/services/common.service';




@Injectable()
export class NextMonthPlanService {
    constructor(private http: BaseService, private http1: CommonService) { }

   
    createOrUpdateEvents(formData: any) {
        return this.http
            .HttpPost(this.http.Services.NextMonthPlan.CreateOrUpdate, formData)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response;
                })
            );
    }

    GetAllByCriteria(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.NextMonthPlan.GetAllByCriteria, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }

    GetPlanbyId(filters: any): Observable<any> {
        return this.http
            .HttpPost(this.http.Services.NextMonthPlan.GetById, filters)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(response => {
                    return response.Results;
                })
            );
    }
    getVCDailyReportingById(vcDailyReportingId: string) {
        let requestParams = {
            DataId: vcDailyReportingId
        };

        return this.http
            .HttpPost(this.http.Services.VCDailyReporting.GetById, requestParams)
            .pipe(
                retry(this.http.Services.RetryServieNo),
                catchError(this.http.HandleError),
                tap(function (response: any) {
                    return response.Results;
                })
            );
    }

   
}

